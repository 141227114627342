import React, { FC } from "react";

import { useQuery } from "@apollo/client";
import { useState } from "hooks/useState";
import useUnsafeEffect from "hooks/useUnsafeEffect";
import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  Dialog,
  DialogContent
} from "utils/material-ui-core";
import RHTextField from "component-rh-text-field";
import SelectionDisplay from "./SelectionDisplay";
import RHImage from "component-rh-image";
import { MONOGRAM_ORDER_DEFAULT } from "utils/constants";
import { RHLinkListCollapse } from "component-rh-link-list";
import getImageUrlWithPreset from "utils/getImageUrlWithPreset";
import FormDialog from "layout-form-dialog";
import RHDialogTitle from "component-rh-dialog-title";
import monogramOptionsTranslates from "resources/monogramOptions.json";
import {
  getBorder,
  getBorders,
  getColor,
  getFont,
  validateMonogramOrder
} from "utils/monogramUtils";
import { queryMonogramInfo } from "graphql-client/queries/monogram-info";
import { getMonogramOptions } from "graphql-client/queries/product-monogram-options";
import memoize from "utils/memoize";
import { getTranslate } from "utils/getTranslate";
import { getFieldTitle } from "./utils/getFieldTitle";
import classNames from "classnames";
import useTypographyStyles from "hooks/useTypographyStyles";
import { usePageContent } from "customProviders/LocationProvider";
import { useRhUserAtomValue } from "hooks/atoms";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    overflowY: "auto"
  },
  fonts: {
    padding: theme.spacing(5, 0, 3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3.5, 0, 3)
    }
  },
  letterDrawer: {
    minHeight: 50
  },
  letters: {
    ...theme.typography.body1,
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center"
  },
  sectionDisplay: {
    paddingBottom: theme.spacing(0.5)
  },
  userMessage: {
    color: "red",
    marginBottom: theme.spacing(1)
  },
  container: {
    paddingTop: theme.breakpoints.up("md") ? 0 : 20
  },
  label: {
    marginBottom: theme.spacing(0.3)
  },
  textField: {
    maxWidth: 171,
    paddingBottom: theme.spacing(2)
  },
  button: {
    minWidth: theme.breakpoints.up("md") ? 0 : 20 ? 287 : "100%"
  },
  buttonContainer: {
    padding: theme.spacing(3, 0, 8)
  },
  MuiButton: {
    root: {
      color: "#999999"
    }
  },
  containedPrimary: {
    color: "#FFFFFF",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#404040",
      borderColor: "#404040"
    }
  }
}));

export interface MonogramOrderProps {
  borderName?: string;
  colorName?: string;
  fontName?: string;
  borderId?: string;
  colorId?: string;
  fontId?: string;
  fontCode?: string;
  fontColorCode?: string;
  borderCode?: string;
  fulfillmentCode?: string;
  lines?: string[];
  maxLines?: number;
  styleId?: string;
  description?: string;
}

export interface MonogramDialogProps {
  monogramOptions: MonogramResponse;
  monogramOrder: MonogramOrderProps;
  productId?: string;
  open: boolean;
  setMonogramDialog: (value: boolean) => void;
  setMonogramOrder: (value: object) => void;
  monogramInfo?: string;
}

export const MonogramDialog: FC<MonogramDialogProps> = memoize(
  ({
    monogramOptions,
    monogramOrder,
    open,
    setMonogramDialog,
    setMonogramOrder,
    monogramInfo
  }) => {
    const { pageContent } = usePageContent();
    const classes = useStyles();
    const rhTypoClasses = useTypographyStyles({
      keys: ["rhBaseBody1", "rhBaseH2"]
    });
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const translate = getTranslate(monogramOptionsTranslates);
    const [borders, setBorders] = useState<MonogramBaseFields[]>([]);
    const [lettersImage, setLettersImage] = useState<string>("");
    const [min, setMin] = useState<number>(0);
    const [max, setMax] = useState<number>(0);
    const [numberOfLines, setNumberOfLines] = useState<number>(0);
    const [userMessage, setUserMessage] = useState<string>("");
    const [addToOrder, setAddToOrder] = useState<MonogramOrderProps>(
      { ...monogramOrder } ?? MONOGRAM_ORDER_DEFAULT
    );
    const fontId = addToOrder?.fontId;
    const borderId = addToOrder.borderId;

    // Reset text, input lines and message if user selects a different font
    const resetMonogramSelection = () => {
      const numberOfInputs = addToOrder;
      numberOfInputs.lines = new Array(1);
      setAddToOrder(numberOfInputs);
      setUserMessage("");
    };

    useUnsafeEffect(() => {
      if (!monogramOrder.fontId) {
        const font = getFont(monogramOptions?.fonts, fontId);

        if (font?.borders?.length) {
          const borders = getBorders(font);
          const border = getBorder(borders, borderId);

          setBorders(borders);
          setMin(border?.minLength ?? 0);
          setMax(border?.maxLength ?? 0);
          setNumberOfLines(border?.numberOfLines || 1);
        } else {
          setMin(font?.minLength ?? 0);
          setMax(font?.maxLength ?? 0);
          setNumberOfLines(font?.numberOfLines || 1);
        }

        if (font) {
          setLettersImage(font?.altImageUrl || lettersImage);
        }
      }
    }, [monogramOptions]);

    useUnsafeEffect(() => {
      const font = getFont(monogramOptions?.fonts, fontId);

      if (font?.borders) {
        const borders = getBorders(font) ?? [];
        const border = getBorder(borders, borderId);

        setMin(border?.minLength ?? 0);
        setMax(border?.maxLength ?? 0);
        setNumberOfLines(border?.numberOfLines || 1);
        setAddToOrder({
          ...addToOrder,
          borderCode: border.cwCode,
          description: border.displayName,
          fontCode: font.cwCode,
          fulfillmentCode: font?.cwCode,
          styleId: border.id
        });
      } else {
        setMin(font?.minLength ?? 0);
        setMax(font?.maxLength ?? 0);
        setNumberOfLines(font?.numberOfLines || 1);
      }
    }, [borderId]);

    useUnsafeEffect(() => {
      const font = getFont(monogramOptions?.fonts, fontId);

      if (font?.borders) {
        const borders = getBorders(font) ?? [];
        setBorders(borders);
      } else if (font) {
        setMin(font?.minLength ?? 0);
        setMax(font?.maxLength ?? 0);
        setNumberOfLines(font?.numberOfLines ?? 1);
        setAddToOrder({
          ...addToOrder,
          description: font.displayName,
          fontCode: font.cwCode,
          fulfillmentCode: font.cwCode,
          styleId: font.id
        });
      } else {
        setMin(font?.minLength ?? 0);
        setMax(font?.maxLength ?? 0);
        setNumberOfLines(font?.numberOfLines || 1);
      }

      if (font) {
        setLettersImage(font?.altImageUrl || lettersImage);
      }
    }, [fontId, monogramOptions]);

    // Render when data is ready
    if (monogramOptions === undefined) return null;
    const colors = monogramOptions.colors;
    const fonts = monogramOptions.fonts;

    const handleAddMonogram = () => {
      const color = getColor(colors, addToOrder.colorId);
      const font = getFont(monogramOptions?.fonts, fontId);
      const hasBorders = font?.borders?.length || 0;
      const hasColors = colors.length || 0;
      const colorCode = hasColors ? color.cwCode : "";
      let order = {
        ...addToOrder,
        fontColorCode: colorCode,
        maxLines: numberOfLines
      };
      const usrMsg = validateMonogramOrder(
        hasBorders,
        hasColors,
        order,
        min,
        max,
        {
          textInput: pageContent.MONOGRAM_INVALID_TEXT_INPUT_MESSAGE,
          specialSymbol: pageContent.MONOGRAM_SYMBOL_INPUT_ERROR_MESSAGE
        }
      );
      if (usrMsg.length) {
        setUserMessage(usrMsg);
      } else {
        setMonogramOrder(order);
        setMonogramDialog(false);
      }
    };

    const handleClose = () => {
      setAddToOrder(MONOGRAM_ORDER_DEFAULT);
      setMonogramDialog(false);
    };

    const MonogramContent = (
      <>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Hidden smDown>
              <Typography className={classNames([rhTypoClasses.rhBaseH2])}>
                {translate("title")}
              </Typography>
            </Hidden>
            <Grid item xs={12} className={classes.fonts}>
              {fonts.length && (
                <SelectionDisplay
                  step="1."
                  order={addToOrder}
                  options={fonts}
                  prevChoice={
                    fonts.find(c => c.id === addToOrder.fontId)?.displayName ??
                    ""
                  }
                  addToOrder={setAddToOrder}
                  resetMonogramSelection={resetMonogramSelection}
                  title={translate("font.title")}
                  type={"fontId"}
                />
              )}
            </Grid>
            <RHLinkListCollapse
              title={translate("font.seeFull")}
              className={classes.letterDrawer}
              divider="both"
              minHeight={50}
            >
              <Grid item container className={classes.letters}>
                <RHImage
                  src={
                    mdUp
                      ? getImageUrlWithPreset(lettersImage, "pdp-hero-md")
                      : lettersImage
                  }
                  alt={translate("font.alt")}
                />
              </Grid>
              {monogramInfo && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: monogramInfo
                  }}
                />
              )}
            </RHLinkListCollapse>
          </Grid>

          {borders.length ? (
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <SelectionDisplay
                addToOrder={setAddToOrder}
                resetMonogramSelection={resetMonogramSelection}
                options={borders}
                order={addToOrder}
                prevChoice={
                  borders.find(c => c.id === addToOrder.borderId)
                    ?.displayName ?? ""
                }
                step="2."
                title={translate("border.title")}
                type={"borderId"}
              />
            </Grid>
          ) : null}

          {!!colors.length && (
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <SelectionDisplay
                addToOrder={setAddToOrder}
                options={colors}
                order={addToOrder}
                prevChoice={
                  colors.find(c => c.id === addToOrder.colorId)?.displayName ??
                  ""
                }
                step={borders.length ? "3." : "2."}
                title={translate("color.title")}
                type={"colorId"}
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.container}>
            {new Array(numberOfLines).fill("").map((_, index) => (
              <div key={`number-of-lines-${index}`}>
                <Typography
                  paragraph
                  className={classNames([
                    classes.label,
                    rhTypoClasses.rhBaseBody1
                  ])}
                >
                  {getFieldTitle({
                    index,
                    isOneLine: numberOfLines === 1,
                    min,
                    max,
                    prevSteps: [borders, colors],
                    translate
                  })}
                </Typography>

                <RHTextField
                  value={addToOrder.lines?.[index] || ""}
                  className={classes.textField}
                  onChange={e => {
                    const { lines = [], borderId } = addToOrder;

                    lines[index] = borderId?.indexOf("YA")
                      ? e.target.value
                      : e.target.value.toLowerCase();

                    setAddToOrder({ ...addToOrder, ...{ lines: [...lines] } });
                  }}
                  inputProps={{
                    "data-testid": `monogram-input${index}`,
                    maxLength: max
                  }}
                />
              </div>
            ))}

            <Grid item xs={12} className={classes.buttonContainer}>
              {userMessage && (
                <Typography className={classes.userMessage}>
                  {userMessage}
                </Typography>
              )}

              <Button
                data-testid="monogram-add-button"
                variant="contained"
                color="primary"
                className={classNames([
                  classes.button,
                  classes.containedPrimary
                ])}
                onClick={handleAddMonogram}
              >
                {translate("button")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );

    return mdUp ? (
      <FormDialog
        data-testid={`monogram-dialog`}
        open={open}
        fullScreen
        onClose={handleClose}
      >
        {MonogramContent}
      </FormDialog>
    ) : (
      <Dialog
        data-testid={`mobile-monogram-dialog`}
        open={open}
        fullScreen
        onClose={handleClose}
      >
        <RHDialogTitle
          title={pageContent?.MONOGRAM_OPTIONS}
          onBack={handleClose}
        />
        <DialogContent className={classes.dialogContent}>
          {MonogramContent}
        </DialogContent>
      </Dialog>
    );
  }
);

export default memoize(
  (props: Omit<MonogramDialogProps, "monogramOptions">) => {
    const {
      monogramOrder,
      open,
      productId,
      setMonogramDialog,
      setMonogramOrder
    } = props;
    let newMonogramOptions;
    const rhUser = useRhUserAtomValue();

    const { data: { monogramOptions } = {} as Query } = useQuery<Query>(
      getMonogramOptions,
      {
        variables: { email: rhUser?.email || "", productId: productId }
      }
    );
    newMonogramOptions = monogramOptions;

    const { data: { contentFragment } = {} as Query } = useQuery<Query>(
      queryMonogramInfo,
      { variables: { key: "monogram" } }
    );

    return (
      <MonogramDialog
        monogramInfo={contentFragment?.text ?? ""}
        monogramOptions={newMonogramOptions}
        monogramOrder={monogramOrder}
        open={open}
        setMonogramDialog={setMonogramDialog}
        setMonogramOrder={setMonogramOrder}
      />
    );
  }
);
