import React, { createContext, useCallback, useContext, useState } from "react";
import { queryShoppingCart } from "graphql-client-cart-broker/queries/get-cart-page-shopping-cart";
import { ApolloQueryResult, useQuery } from "@apollo/client";
import isEmpty from "lodash.isempty";
import useLocale from "hooks/useLocale/useLocale";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { countryCurrencyMapper } from "resources/countries-config.json";
import useSite from "hooks/useSite";
import { useUpdateCart } from "hooks/useUpdateCart";
import { useMembershipInfoAtomValue } from "hooks/atoms";
import { useUserSessionAtomValue } from "hooks/atoms";
import { UserPreferencesContext } from "customProviders/UserPreferencesProvider";

interface ShoppingCartContextType {
  cartId?: string | null;
  cart: CartType | null;
  setCart: React.Dispatch<React.SetStateAction<CartType>>;
  loading: boolean;
  refetch: (
    variables?: Partial<QueryGetCartArgs>
  ) => Promise<ApolloQueryResult<Pick<Query, "getCart">>>;
  handlePostalCodeCountryChange: (event: {
    postalCode: string;
    country: string;
  }) => void;
  memberShipLoading: boolean;
  setMembershipLoading: React.Dispatch<React.SetStateAction<Boolean>>;
}

const checkoutV1ContextDefault: ShoppingCartContextType = {
  cartId: null,
  cart: null,
  setCart: () => {},
  loading: true,
  refetch: (() => {}) as ShoppingCartContextType["refetch"],
  handlePostalCodeCountryChange: () => {},
  memberShipLoading: false,
  setMembershipLoading: () => {}
};

export const ShoppingCartContext = createContext<ShoppingCartContextType>(
  checkoutV1ContextDefault
);

export function ShoppingCartContextProvider({ children }) {
  const [cart, setCart] = useState<CartType | null>({});
  const { loading: loadingSession } = useUserSessionAtomValue();
  const { rhUser, currentCartId: cartId } = useUserSessionAtomValue();
  const membershipInfo = useMembershipInfoAtomValue();
  const siteId = useSite();
  const locale = useLocale();
  const currencyCode = countryCurrencyMapper?.[getCountryFromUrl() || "US"];
  const [memberShipLoading, setMembershipLoading] = useState(false);
  const { updateCart, loading: loadingUpdateCart } = useUpdateCart({
    onCompleted: async data => {
      setCart(data.updateCart);
    }
  });
  const { cartUpdateloading } = useContext(UserPreferencesContext);

  const { loading, refetch } = useQuery<
    Pick<Query, "getCart">,
    QueryGetCartArgs
  >(queryShoppingCart, {
    skip:
      isEmpty(cartId) ||
      (loadingSession && !!cart) ||
      loadingUpdateCart ||
      memberShipLoading ||
      cartUpdateloading,
    variables: {
      cartId,
      locale,
      userType: membershipInfo?.userHasActiveMembership
        ? "MEMBER"
        : rhUser?.userType,
      currencyCode,
      email: rhUser?.email,
      siteId
      // we are probably don't need that anymore, locale is passed with request headers
      // locale
    },
    onCompleted: data => {
      setCart(data?.getCart);
    },
    onError: () => {},
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const handlePostalCodeCountryChange = useCallback<
    ShoppingCartContextType["handlePostalCodeCountryChange"]
  >(
    ({ postalCode, country }) => {
      console.warn("handlePostalCodeCountryChange", { postalCode, country });
      updateCart({
        cartId,
        postalCode,
        country
      });
    },
    [cartId, updateCart]
  );

  return (
    <ShoppingCartContext.Provider
      value={{
        cartId,
        cart,
        setCart,
        loading,
        refetch,
        handlePostalCodeCountryChange,
        memberShipLoading,
        setMembershipLoading
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
