import React, { FC } from "react";
import useState from "hooks/useState";
import {
  Checkbox,
  GridList,
  GridListTile,
  Typography,
  useTheme,
  makeStyles,
  createStyles,
  Theme,
  useMediaQuery
} from "utils/material-ui-core";
import RHImage from "component-rh-image";
import useMediaString from "hooks/useMediaString";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import { MonogramOrderProps } from "page-monogram-dialog";
import useTypographyStyles, {
  commonTypography
} from "hooks/useTypographyStyles";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

const env = useEnv();

const useStyles = makeStyles((theme: Theme) => {
  const commonTypographyStyles = commonTypography(theme);
  return createStyles({
    checkbox: {
      "&.MuiCheckbox-root": {
        background: "transparent !important",
        position: "absolute !important" as "absolute",
        width: "100% !important",
        height: "100% !important",
        padding: "0px !important",
        border: "transparent !important",
        margin: "0px !important"
      },
      "&.MuiCheckbox-root:hover": {
        background: "transparent !important"
      },
      "&.MuiCheckbox-colorSecondary.Mui-checked.MuiCheckbox-colorSecondary.Mui-checked:hover":
        {
          background: "transparent !important"
        }
    },
    checked: {
      border: "solid 1px black",
      padding: 2,
      [theme.breakpoints.up("md")]: {
        padding: 4
      }
    },
    stepTitle: {
      ...commonTypographyStyles?.rhBaseH3,
      textTransform: "uppercase",
      marginBottom: 32,
      [theme.breakpoints.down("xs")]: {
        ...commonTypographyStyles?.rhBaseH4,
        fontWeight: 400,
        marginBottom: 24
      }
    }
  });
});

export interface SelectionDisplayProps {
  addToOrder: (value: object) => void;
  options: MonogramBaseFields[];
  order: MonogramOrderProps;
  prevChoice: string;
  resetMonogramSelection?: () => void;
  step: string;
  title: string;
  type: string;
}

export const SelectionDisplay: FC<SelectionDisplayProps> = ({
  addToOrder,
  options,
  order,
  prevChoice,
  resetMonogramSelection,
  step,
  title,
  type
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const rhTypoClasses = useTypographyStyles({
    keys: ["rhBaseBody2", "rhBaseH3"]
  });
  const mediaString = useMediaString();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const [choice, setChoice] = useState(prevChoice ?? options[0].displayName);
  const newTitle = title ?? "select font style";

  const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
  const smlgBetween = useMediaQuery<Theme>(theme =>
    theme.breakpoints.between("sm", "lg")
  );
  const xsOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("xs"));

  const monogramContentCols = yn(env?.FEATURE_PDP)
    ? xlUp
      ? 6
      : smlgBetween
      ? 5
      : xsOnly
      ? 3
      : 3
    : mdUp
    ? 8
    : 3;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const option = options?.find(o => o.displayName === value);
    let name = "";
    if (type.includes("Id")) {
      name = type?.slice(0, -2) + "Name" || "";
    }
    const orderPair = { [type]: option?.id, [name]: option?.displayName };

    // reset for font and border selection change
    resetMonogramSelection?.();

    e.persist();
    setChoice(option?.displayName || "");
    addToOrder({ ...order, ...orderPair });
    e.preventDefault();
  };

  const selection = options.map(
    (option: { displayName: string; imageUrl: string }, index) => (
      <GridListTile cols={1} key={`select-${option.displayName}-${index}`}>
        <div
          className={choice === option.displayName ? classes.checked : ""}
          onChange={handleChange}
        >
          <RHImage
            preset={`monogram-large-${mediaString}` as ProductImagePresetKeys}
            src={option.imageUrl}
            alt={"Monogram Selection"}
          >
            <Checkbox
              value={option.displayName}
              checked={choice === option.displayName}
              name={`checkbox${option.displayName}`}
              className={classes?.checkbox}
              style={{ background: "transparent !important" }}
              icon={<></>}
              checkedIcon={<></>}
            />
          </RHImage>
        </div>
      </GridListTile>
    )
  );

  return (
    <div>
      <Typography paragraph className={classes.stepTitle}>
        {step} {newTitle} - {choice}
      </Typography>
      <GridList
        cols={monogramContentCols}
        cellHeight="auto"
        spacing={theme.spacing(yn(env?.FEATURE_PDP) ? 3.5 : 2.5)}
      >
        {selection}
      </GridList>
    </div>
  );
};

SelectionDisplay.defaultProps = {};

export default SelectionDisplay;
