import { Typography, Grid, useMediaQuery, Theme } from "utils/material-ui-core";
import React, { FC, useEffect, useMemo, useState } from "react";
import { RHLink } from "component-rh-link";
import RHPriceRangeDisplay, {
  RHRUniversalPriceRangeDisplay
} from "component-rh-price-range-display";
import useStyles from "./useStyles";
import { usePageContent } from "customProviders/LocationProvider";
import { convertFusionPriceToRange } from "component-price-range-display/utils/convertFusionPriceToRange";
import { convertSkuResponsePriceInfoToRange } from "component-price-range-display/utils/convertSkuResponsePriceInfoToRange";
import memoize from "utils/memoize";
import { Configured_PRICE, STARTING_AT } from "utils/constants";
import styled from "styled-components";
import InStockProductsDialog from "dialog-instock-products";
import { useParams2 } from "hooks/useParams";
import {
  getAllOptionTypes,
  getSelectedOptionLength
} from "@RHCommerceDev/build-lineitem-available-options";
import he from "he";
import { isEqual } from "lodash";
import { countryNameMapper } from "resources/countries-config.json";
import { useRhUserAtomValue } from "hooks/atoms";

export interface ProductTitlePriceProps {
  data: any;
  shouldShow: any;
  lineItem: any;
  availableOptions?: any;
  selectedOptions: ProductAvailableOption[];
  metaSkuPricingV2: any;
  country: any;
  openInStockOnSaleModal: boolean;
  handleCloseInStockOnSaleModal: () => void;
  isPostalCodeAvailable: boolean;
  postalCode: any;
  authenticated: any;
  onViewSelectItemsOnSaleClick: () => void;
  isConfiguredPrice: boolean;
  handleStartingPrice: (isConfigured: boolean) => void;
  configureLabel?: string;
  isSkuLevel?: boolean;
}

export interface ProductPriceProps {
  lineItem: any;
  selectedOptions: ProductAvailableOption[];
  metaSkuPricingV2: any;
  country: any;
  data: any;
  topLabel?: string;
  multiskuInventory?: any;
}

export const ProductPrice = props => {
  const {
    lineItem,
    metaSkuPricingV2,
    country,
    openInStockOnSaleModal,
    data,
    topLabel,
    hideSalePrice,
    handleCloseInStockOnSaleModal,
    onViewSelectItemsOnSaleClick,
    isConfiguredPrice,
    configureLabel,
    centerAlignFlag,
    hideUpSellSale,
    postalCode,
    isNewPDPLayout,
    isSkuLevel,
    chosenLineItemOptions,
    chosenLineItemOptionId,

    availableOptions,
    isNewPDPButton
  } = props;
  const currencyCode = countryNameMapper[country];
  const rhUser = useRhUserAtomValue();
  const { pageContent } = usePageContent();
  const STARTING_AT_PRICE = pageContent?.["STARTING_AT"];

  const [initialOptionIds, setInitialOptionIds] = useState([]);
  const [displayMessage, setDisplayMessage] = useState(true);

  const getSelectedLengthValue = availableOptions =>
    availableOptions
      ?.find(item => item?.type?.toLowerCase() === "length")
      ?.options?.find(option => option?.status === "selected")?.value;

  let productLengthOptionValue = Object.keys(chosenLineItemOptions || {})
    ?.length
    ? getSelectedLengthValue(availableOptions) || ""
    : "";

  useEffect(() => {
    if (chosenLineItemOptionId?.length && !initialOptionIds?.length) {
      setInitialOptionIds(chosenLineItemOptionId);
    }
    if (
      initialOptionIds?.length &&
      !isEqual(chosenLineItemOptionId, initialOptionIds)
    ) {
      setDisplayMessage(false);
    }
  }, [chosenLineItemOptionId]);

  useEffect(() => {
    setDisplayMessage(true);
    setInitialOptionIds([]);
  }, [data]);

  // const priceRange = metaSkuPricingV2
  //   ? convertFusionPriceToRange(metaSkuPricingV2, rhUser?.userType)
  //   : convertSkuResponsePriceInfoToRange?.(
  //       null,
  //       lineItem?.sku?.info?.skuPriceInfo ?? {},
  //       rhUser?.userType
  //     );

  const priceRange = useMemo(() => {
    const isMultiSkuPopulated =
      !!data.priceRangeMultiSkuDisplay &&
      !data.priceRangeMultiSkuDisplay.isUnavailable &&
      !data?.metaProduct;

    return isMultiSkuPopulated
      ? {
          ...data.priceRangeDisplay,
          listPrices: data?.priceRangeMultiSkuDisplay?.listPrices,
          salePrices: data?.priceRangeMultiSkuDisplay?.salePrices,
          memberPrices: data?.priceRangeMultiSkuDisplay?.memberPrices,
          priceRange: data?.priceRangeMultiSkuDisplay?.priceRange,
          overrideLowestSkuListPrice:
            data?.priceRangeMultiSkuDisplay?.overrideLowestSkuListPrice,
          overrideLowestSkuSalePrice:
            data?.priceRangeMultiSkuDisplay?.overrideLowestSkuSalePrice,
          overrideLowestSkuMemberPrice:
            data?.priceRangeMultiSkuDisplay?.overrideLowestSkuMemberPrice,
          skulowestListPrice:
            data?.priceRangeMultiSkuDisplay?.skulowestListPrice,
          skulowestMemberPrice:
            data?.priceRangeMultiSkuDisplay?.skulowestMemberPrice,
          skulowestSalePrice:
            data?.priceRangeMultiSkuDisplay?.skulowestSalePrice
        }
      : data.priceRangeDisplay;
  }, [data.priceRangeDisplay, data.priceRangeMultiSkuDisplay]);

  const CONFIGURED_PRICE =
    productLengthOptionValue && !priceRange.sale && displayMessage
      ? `${he.decode(
          productLengthOptionValue
        )} ${STARTING_AT_PRICE?.toLowerCase()}`
      : getAllOptionTypes(data?.productConfiguration)?.length !==
          getSelectedOptionLength(chosenLineItemOptions) || displayMessage
      ? STARTING_AT_PRICE
      : "";
  return (
    <>
      {!lineItem?.customProductOptions?.customProductInformation ? (
        getAllOptionTypes(data?.productConfiguration)?.length ===
          getSelectedOptionLength(chosenLineItemOptions) &&
        (metaSkuPricingV2 || lineItem?.sku?.info?.skuPriceInfo) ? (
          <>
            <RHRUniversalPriceRangeDisplay
              isNewPDPButton={isNewPDPButton}
              topLabel={
                topLabel === "no-label"
                  ? ""
                  : isConfiguredPrice
                  ? CONFIGURED_PRICE
                  : data.priceRangeDisplay?.overridePriceLabel ||
                    STARTING_AT_PRICE ||
                    undefined
              }
              centerAlignFlag={centerAlignFlag}
              display="inline"
              variant="medium"
              range={
                metaSkuPricingV2
                  ? convertFusionPriceToRange(
                      metaSkuPricingV2,
                      rhUser?.userType
                    )
                  : convertSkuResponsePriceInfoToRange?.(
                      null,
                      lineItem?.sku?.info?.skuPriceInfo ?? {},
                      rhUser?.userType
                    )
              }
              currencyLabel={
                lineItem?.sku?.info?.skuPriceInfo?.currencyLabel || ""
              }
              currencySymbol={
                lineItem?.sku?.info?.skuPriceInfo?.currencySymbol || "$"
              }
              isSkuLevel={isSkuLevel}
              viewPriceOnly={topLabel === "no-label"}
              hideSalePrice={hideSalePrice}
              onViewSelectItemsOnSaleClick={onViewSelectItemsOnSaleClick}
              configureLabel={configureLabel}
              hideUpSellSale={hideUpSellSale}
              isNewPDPLayout={isNewPDPLayout}
              currency={currencyCode}
            />
          </>
        ) : (
          <>
            <RHPriceRangeDisplay
              isNewPDPButton={isNewPDPButton}
              showSalePriceRange={true}
              centerAlignFlag={centerAlignFlag}
              topLabel={
                topLabel === "no-label"
                  ? topLabel
                  : isConfiguredPrice
                  ? CONFIGURED_PRICE
                  : data.priceRangeDisplay?.overridePriceLabel ||
                    STARTING_AT_PRICE ||
                    undefined
              }
              data={data?.priceRangeDisplay}
              display="inline"
              variant="medium"
              showSaleMessage={data?.saleInfo?.showSaleMessage ?? false}
              isMetaProduct={data?.metaProduct!}
              isSkuLevel={isSkuLevel}
              nextGenDrivenOnSale={
                data?.priceRangeDisplay?.nextGenDrivenOnSale!
              }
              skuOnSalePercentage={parseInt(data?.saleInfo?.percentSaleSkus!)}
              viewPriceOnly={topLabel === "no-label"}
              hideSalePrice={hideSalePrice}
              onViewSelectItemsOnSaleClick={onViewSelectItemsOnSaleClick}
              configureLabel={configureLabel}
              hideUpSellSale={hideUpSellSale}
              isNewPDPLayout={isNewPDPLayout}
              currency={currencyCode}
            />
          </>
        )
      ) : null}
      {isNewPDPLayout && openInStockOnSaleModal ? (
        <InStockProductsDialog
          open={!!openInStockOnSaleModal}
          onClose={() => handleCloseInStockOnSaleModal()}
          postalCode={postalCode}
          productId={data?.id}
          displayName={data?.displayName}
          metaProduct={data?.metaProduct}
          salePriceLabel={data?.priceRangeDisplay?.salePriceLabel ?? ""}
          productLineItem={data?.productLineItem}
          saleSelected={true}
        />
      ) : null}
    </>
  );
};

const StyledCartProductPrice = styled.div`
  & span#swatchMemberPrice > p,
  & span#swatchSkuMemberPrice > p {
    color: #fff !important;
    font-family: RHSans !important;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 120% !important;
    letter-spacing: 0.48px !important;
  }

  & span#swatchMemberPrice,
  & span#swatchSkuMemberPrice {
    line-height: 1 !important;
  }

  & span#regularPriceContainer,
  & span#swatchSkuRegularPrice {
    line-height: 1 !important;
  }

  & span#regularPriceContainer > p,
  & span#swatchSkuRegularPrice > p {
    color: #fff !important;
    font-family: RHSans !important;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 12px !important;
    line-height: 120% !important;
    font-weight: 100 !important;
    letter-spacing: 0.48px !important;
  }
`;
export const CartProductPrice = (props: any) => {
  return (
    <StyledCartProductPrice>
      <ProductPrice {...props} />
    </StyledCartProductPrice>
  );
};
const StyledSwatchPanelProductPrice = styled.div`
  & > div {
    align-items: center;
    gap: 4px;
  }
  & > div > div {
    align-items: center;
    gap: 4px;
  }
  & p#first-price-label-swatch {
    font-family: RHSans-Thin, Helvetica, Arial !important;
    font-size: 11px !important;
    text-transform: uppercase !important;
    font-feature-settings: "clig" off, "liga" off;
    font-weight: 100 !important;
    line-height: 120% !important;
  }

  & span#swatchMemberPrice > p,
  & span#swatchSkuMemberPrice > p {
    font-family: RHSans-Thin, Helvetica, Arial !important;
    font-size: 20px !important;
    text-transform: uppercase !important;
    font-weight: 100 !important;
    line-height: 115% !important;
    letter-spacing: -0.4px !important;
  }

  & span#swatchMemberPrice,
  & span#swatchSkuMemberPrice {
    line-height: 1 !important;
  }

  & span#regularPriceContainer,
  & span#swatchSkuRegularPrice {
    line-height: 1 !important;
  }

  & span#regularPriceContainer > p,
  & span#swatchSkuRegularPrice > p {
    font-family: RHSans-Thin, Helvetica, Arial !important;
    font-size: 11px !important;
    text-transform: uppercase !important;
    line-height: 110% !important; /* 12.1px */
    font-weight: 100 !important;
    letter-spacing: 0.44px !important;
  }
`;
export const SwatchPanelProductPrice = (props: any) => {
  const { lineItem, selectedOptions, metaSkuPricingV2, data, isSkuLevel } =
    props;

  const rhUser = useRhUserAtomValue();

  return (
    <StyledSwatchPanelProductPrice>
      {!lineItem?.customProductOptions?.customProductInformation ? (
        selectedOptions.length === lineItem?.availableOptions?.length &&
        (metaSkuPricingV2 || lineItem?.sku?.info?.skuPriceInfo) ? (
          <>
            <RHRUniversalPriceRangeDisplay
              topLabel={Configured_PRICE}
              display="inline"
              variant="medium"
              range={
                metaSkuPricingV2
                  ? convertFusionPriceToRange(
                      metaSkuPricingV2,
                      rhUser?.userType
                    )
                  : convertSkuResponsePriceInfoToRange?.(
                      null,
                      lineItem?.sku?.info?.skuPriceInfo ?? {},
                      rhUser?.userType
                    )
              }
              currencyLabel={
                lineItem?.sku?.info?.skuPriceInfo?.currencyLabel || ""
              }
              currencySymbol={
                lineItem?.sku?.info?.skuPriceInfo?.currencySymbol || "$"
              }
              isSkuLevel={isSkuLevel}
              drivedStyles={{
                textTransform: "uppercase"
              }}
              isSwatchPanelOpen={true}
            />
          </>
        ) : (
          <>
            <RHPriceRangeDisplay
              topLabel={STARTING_AT}
              data={data?.priceRangeDisplay}
              display="inline"
              variant="medium"
              showSaleMessage={data?.saleInfo?.showSaleMessage ?? false}
              isMetaProduct={data?.metaProduct!}
              isSkuLevel={isSkuLevel}
              nextGenDrivenOnSale={
                data?.priceRangeDisplay?.nextGenDrivenOnSale!
              }
              skuOnSalePercentage={parseInt(data?.saleInfo?.percentSaleSkus!)}
              drivedStyles={{
                textTransform: "uppercase"
              }}
              isSwatchPanelOpen={true}
            />
          </>
        )
      ) : null}
    </StyledSwatchPanelProductPrice>
  );
};

export const ProductTitlePrice: FC<ProductTitlePriceProps> = props => {
  const {
    data,
    shouldShow,
    lineItem,
    availableOptions,
    selectedOptions,
    metaSkuPricingV2,
    country,
    openInStockOnSaleModal,
    handleCloseInStockOnSaleModal,
    postalCode,
    onViewSelectItemsOnSaleClick,
    isConfiguredPrice,
    handleStartingPrice,
    configureLabel,
    isSkuLevel
  } = props;
  const { params: queryParams } = useParams2<{ [key: string]: string }>({
    version: ""
  });
  const isNewPdpLayout = useMemo(() => {
    return Boolean(queryParams?.version === "v2" || data?.newPdpLayout);
  }, [data?.newPdpLayout, queryParams?.version]);

  const classes = useStyles({ isNewPdpLayout: isNewPdpLayout })();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const { pageContent } = usePageContent();

  const priceRangeDisplay = useMemo(() => {
    const isMultiSkuPopulated =
      !!data.priceRangeMultiSkuDisplay &&
      !data.priceRangeMultiSkuDisplay.isUnavailable;

    return isMultiSkuPopulated
      ? {
          ...data.priceRangeDisplay,
          listPrices: data?.priceRangeMultiSkuDisplay?.listPrices,
          salePrices: data?.priceRangeMultiSkuDisplay?.salePrices,
          memberPrices: data?.priceRangeMultiSkuDisplay?.memberPrices,
          priceRange: data?.priceRangeMultiSkuDisplay?.priceRange,
          overrideLowestSkuListPrice:
            data?.priceRangeMultiSkuDisplay?.overrideLowestSkuListPrice,
          overrideLowestSkuSalePrice:
            data?.priceRangeMultiSkuDisplay?.overrideLowestSkuSalePrice,
          overrideLowestSkuMemberPrice:
            data?.priceRangeMultiSkuDisplay?.overrideLowestSkuMemberPrice
        }
      : data.priceRangeDisplay;
  }, [data.priceRangeDisplay, data.priceRangeMultiSkuDisplay]);

  return (
    <div className={classes.productHeader}>
      <Grid container id="product-title-price">
        <Grid
          item
          xs={12}
          style={{
            marginTop: mdUp ? 0 : "16px"
          }}
        >
          <Typography
            dangerouslySetInnerHTML={{
              __html: data.displayName
            }}
            className={classes.productTitle}
            data-testid={"product-title-display-name"}
          />
          {!data.subName?.match(/(^|\W)cat($|\W)/i) && (
            <Typography
              variant={mdUp ? "h3" : "h1"}
              dangerouslySetInnerHTML={{
                __html: data.subName
              }}
              data-testid={"product-title-sub-name"}
            />
          )}
        </Grid>
        {!shouldShow ? (
          <Grid
            container
            item
            xs={12}
            style={{ background: "rgb(241,241,241)" }}
          >
            <div>
              <Typography data-testid="product-not-available-text">
                {pageContent?.PRODUCT_NOT_AVAILABLE}
                {/* //product no longer available */}
              </Typography>

              <RHLink style={{ display: "inline" }} href="/">
                {pageContent?.PLEASE_TEXT} {pageContent?.CONTINUE_BROWSING}
                {/* continue browsing */}
              </RHLink>
            </div>
          </Grid>
        ) : null}
        <Grid item xs={12} className={classes.priceContainer}>
          {shouldShow &&
          !data?.layout?.includes("custom") &&
          data?.layout !== "track-hardware"
            ? !!priceRangeDisplay && (
                <>
                  <ProductPrice
                    lineItem={lineItem}
                    availableOptions={availableOptions}
                    selectedOptions={selectedOptions}
                    metaSkuPricingV2={metaSkuPricingV2}
                    country={country}
                    data={data}
                    onViewSelectItemsOnSaleClick={onViewSelectItemsOnSaleClick}
                    configureLabel={configureLabel}
                    isConfiguredPrice={isConfiguredPrice}
                    isSkuLevel={isSkuLevel}
                  />
                  {openInStockOnSaleModal ? (
                    <InStockProductsDialog
                      open={!!openInStockOnSaleModal}
                      onClose={() => handleCloseInStockOnSaleModal()}
                      postalCode={postalCode}
                      productId={data?.id}
                      displayName={data?.displayName}
                      metaProduct={data?.metaProduct}
                      salePriceLabel={
                        data?.priceRangeDisplay?.salePriceLabel ?? ""
                      }
                      productLineItem={data?.productLineItem}
                      saleSelected={true}
                    />
                  ) : null}
                </>
              )
            : null}
        </Grid>
      </Grid>
      <div onClick={() => handleStartingPrice(true)}></div>
    </div>
  );
};

export default memoize(ProductTitlePrice);
