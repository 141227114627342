import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS
} from "utils/constants";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionTitle: {
      "&.search-title": {
        display: "none"
      }
    },
    galleryContainer: {
      display: "block"
    },
    actionTitleWrapper: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        flexDirection: "column"
      }
    },
    membershipContainer: {
      marginLeft: "auto",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        margin: "auto"
      },
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        flexDirection: "column"
      }
    },
    actionSaleTitle: {
      fontSize: 18,
      fontFamily: "RHSans-Thin",
      lineHeight: "27px",
      color: "#CA2022 !important",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      }
    },
    actionSaleBanner: {
      color: "var(--ReimagineWarm-RH-Red, #CA2022)",
      fontFamily: "RHSans-Thin",
      fontSize: "20px",
      lineHeight: "120%",
      letterSpacing: "1.6px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      }
    },
    actionSaleMessage: {
      color: "var(--ReimagineWarm-RH-Red, #CA2022)",
      fontFamily: "RHSans-Roman",
      fontSize: "11px",
      lineHeight: "135%",
      letterSpacing: "0.165px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      }
    },
    actionStandardMembershipMessage: {
      fontFamily: "RHSans-Roman",
      fontSize: "11px",
      lineHeight: "135%",
      letterSpacing: "0.165px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      }
    },
    refinementContainer: {
      paddingBottom: 40,
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        paddingBottom: "24px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingBottom: "36px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingBottom: "48px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingBottom: "60px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        paddingBottom: "80px !important"
      }
    },
    refinementContainerMobile: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingBottom: "32px !important"
      }
    },
    sticky: {
      position: "sticky",
      top: 0,
      zIndex: 1
    },
    refinementBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    tabinfoWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      cursor: "pointer",
      margin: "25px 0"
    },
    defaultTabinfo: {
      padding: "17.5px 20px !important",
      borderRadius: "50px",
      fontSize: "11px",
      fontFamily: "RHSans-Light",
      lineHeight: "120%",
      letterSpacing: " 0.44px", //letterSpacing in pixels = (percentage / 100) * font-size
      backgroundColor: "#F1F0ED",
      color: "#000"
    },
    ActiveTabinfo: {
      backgroundColor: "#000 !important",
      color: "#FFF !important"
    },

    results: {
      flex: "none",
      marginRight: "30px !important",
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        marginRight: "40px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        marginRight: "60px !important"
      }
    },
    saleDivider: {
      paddingBottom: "20px",
      border: 0,
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingBottom: "40px"
      }
    },
    customerService: {
      textTransform: "capitalize !important" as "capitalize",
      textDecoration: "underline",
      "&:hover": {
        borderBottom: "none"
      }
    },
    rhrPromoDisplayBanner: {
      marginTop: 0,
      marginBottom: 20
    },
    membershipMessage: {
      color: "#000000",
      fontFamily: "RHSans-Thin !important",
      fontSize: "13px",
      display: "inline",
      position: "relative",
      right: 0,
      float: "right",
      fontWeight: 300,
      whiteSpace: "nowrap",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center",
        position: "relative",
        float: "none",
        display: "block"
      }
    }
  })
);
