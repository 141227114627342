import React, { FC, useCallback, useState, useEffect, useMemo } from "react";
import {
  ListItem,
  Container,
  Theme,
  makeStyles,
  createStyles,
  useMediaQuery,
  Hidden
} from "utils/material-ui-core";
import { queryCollectionGallery } from "graphql-client/queries/collection-gallery";
import GalleryCollectionCategorySelector from "component-gallery-collection-category-selector";
import { useQuery } from "@apollo/client";
import useAppData from "hooks/useAppData";
import RHLink from "component-rh-link";
import buildPath from "utils/buildPath";
import useParams, { useNewURLStructureParams } from "hooks/useParams";
import { useLocalization } from "hooks/useLocalization";
import { useEnv } from "hooks/useEnv";
import {
  COLLECTION_GALLERY_DEFAULT_COUNT,
  SKELETON_ANIMATION,
  CG_ITEMS_PER_PAGE_PREFERENCE
} from "utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { NetworkStatus } from "@apollo/client";
import HTMLHeadTags from "component-html-head-tags";
import RHScrollUpButton from "component-rh-scroll-up-button";
import PageHeader from "component-page-header";
import CollectionGallerySkeleton from "skeleton-collection-gallery";
import CollectionCardSkeleton from "skeleton-collection-card";
import analyticsLoader from "analytics/loader";
import useTypographyStyles, {
  commonTypography
} from "hooks/useTypographyStyles";
import BelowNavPromo from "component-promo/BelowNavPromo";
import { shouldShowBelowNavPromo } from "utils/belowNavPromo";
import memoize from "utils/memoize";
import useUserContext from "hooks/useUserContext";
import { processEnvServer } from "hooks/useSsrHooks";
import useDidMountEffect from "hooks/useDidMountEffect";
import GridController from "component-product-grid/GridController";
import RHPagination from "component-pagination";
import ItemsPerPage from "./ItemsPerPage";
import classNames from "classnames";
import { useIsoCookies } from "hooks/useIsoCookies";
import { processEnvServer as isServer } from "hooks/useSsrHooks";
import yn from "yn";
import useMeasurementType from "hooks/useMeasurementType";
import { FONT_BARON_SANS, FONT_LIGHT } from "utils/constants";
import useIsoRedirect from "hooks-use-isoredirect";
import useLocale from "hooks-use-locale/useLocale";
import Promo from "component-promo";
import { useCookies } from "hooks/useCookies";
import RHDivider from "component-rh-divider";
import useUserPreferences from "hooks/useUserPreferences";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import { useHistory, useLocation } from "react-router-dom";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { isGTMLoaded } from "utils/analytics/utils";
import { getClientOrigin } from "utils/getClientOrigin";
import { SaleContextFilter } from "graphql-client/queries/app";
import useSite from "hooks/useSite";
import { useFetchParams } from "hooks/useFetchParams/useFetchParams";
import { getReqContext } from "utils/reqContext";
import { useFetchModel } from "hooks/useFetchModel";
import { useCountry } from "hooks/useCountry";
import { useRhUserAtomValue } from "hooks/atoms";
import { useGetSchema } from "hooks/useGetSchema";
import useGeneratePath from "hooks/useGeneratePath";
import { currencySymbolToCurrencyCodeMapper } from "resources/countries-config.json";

const DEFAULT_GRID_COL = 12;
const CG_GRID_CHOICE = "cgGridChoice";
const CG_ID = "cgId";
const CG_ID_PREVIEW = "cgIdPreview";
const DEFAULT_PAGINATION_CG_COUNT = "24";

export interface CollectionyalleryProps {
  amountRemaining: number;
  nextStart: number;
  data: CollectionCategory[];
  onLoadMore: (page: number) => void;
  networkStatus: NetworkStatus;
  isDeepLoad: boolean;
  collectionGalleryLoading?: boolean;
  saleContextParam?: SaleContextFilter;
  isNextPageLoading: boolean;
  isLazyLoadEnabled: boolean;
  totalNumRecs: number;
  bannerTitle: string;
  bannerSubTitle: string;
}

interface CollectionGridParamsProps {
  categoryId: string;
  topCatId: string;
  parentCatId: string;
  count: string;
  start: string;
}

interface CollectionGridProps {
  root: CollectionCategory | any;
  keywords: string;
  isRHR: boolean;
  data: CollectionCategory[];
  params: CollectionGridParamsProps;
  gridColumns: number;
  saleContextParam?: SaleContextFilter;
  generatePath: (saleContextFilter: any, data: any) => string;
  saveSelectedCategory: (cgId: string) => void;
  handleRemoveCookie?: (remove: string) => void;
}

const useStyles = makeStyles((theme: Theme) => {
  const commonTypographyStyles = commonTypography(theme);
  return createStyles({
    rhrPromoCustomBanner: {
      marginTop: 40
    },
    carousel: {
      width: "100%",
      "& .slick-disabled": {
        display: "none"
      },
      "& .slick-dots": {
        position: "absolute",
        bottom: "15px",
        "& li": {
          width: "10px",
          "& button:before": {
            backgroundColor: "white",
            borderRadius: "50%",
            width: "8px",
            height: "8px",
            border: "0.5px solid rgba(0, 0, 0, 0.8)",
            content: "''"
          },
          "&.slick-active button:before": {
            width: "10px",
            height: "10px",
            marginTop: "-1px"
          }
        }
      },
      ".cg-slide-12 &": {
        "& .slick-dots": {
          [theme.breakpoints.up("sm")]: {
            display: "flex !important",
            justifyContent: "flex-end",
            bottom: "-9.2%",
            left: "40%",
            width: "60%"
          },
          [theme.breakpoints.up("md")]: {},
          [theme.breakpoints.up("lg")]: {},
          [theme.breakpoints.up("xl")]: {}
        }
      },
      [theme.breakpoints.up("md")]: {
        "& > div:nth-last-child(2)": {
          display: "none",
          "&:hover:not(.slick-disabled)": { display: "flex" }
        },
        "& > div:nth-child(1)": {
          display: "none",
          "&:hover:not(.slick-disabled)": { display: "flex" }
        },
        "&:hover": {
          "& > div:nth-last-child(2):not(.slick-disabled)": {
            display: "flex"
          },
          "& > div:nth-child(1):not(.slick-disabled)": {
            display: "flex"
          }
        }
      }
    },
    rhrCgGridContainer: {
      "&.grid-item-12": {
        "grid-template-columns": "repeat(1,minmax(0,1fr))"
      },
      "&.grid-item-6": {
        "grid-template-columns": "repeat(2,minmax(0,1fr))"
      },
      "&.grid-item-4": {
        "grid-template-columns": "repeat(3,minmax(0,1fr))"
      },
      "& .cg-slide": {
        maxWidth: "100%"
      },

      "& .cg-promo": {
        "grid-column": "1 / -1"
      }
    },
    rhrPromoContainer: {
      "& > *": {
        paddingLeft: 0,
        paddingRight: 0
      },
      display: "flex",
      alignItems: "center",
      height: "100%",
      "& .container-in-stock": {
        padding: "5px 0",
        "& a": {
          root: {
            fontFamily: FONT_BARON_SANS,
            fontWeight: FONT_LIGHT,
            color: "#000000",
            letterSpacing: 0.5,
            textTransform: "initial",
            fontSize: theme.typography.pxToRem(11),
            lineHeight: theme.typography.pxToRem(13)
          },
          muiLink: {
            textDecoration: "underline",
            textUnderlineOffset: "3px",
            textDecorationThickness: 0.5
          },
          textTransform: "Uppercase",
          textAlign: "left",
          "& span": {
            display: "none"
          }
        }
      },
      "& .rh-base-link-1": {
        ...commonTypographyStyles?.rhBaseLink1
      },
      "& .rh-base-link-2": {
        ...commonTypographyStyles?.rhBaseLink2
      },
      "& .rh-base-link-3": {
        ...commonTypographyStyles?.rhBaseLink2
      }
    },
    cgSlide: {
      paddingBottom: "32px",

      [theme.breakpoints.up("md")]: {
        paddingBottom: "20px !important",
        paddingTop: "20px !important",
        "& .MuiTypography-root .MuiListItem-root": {
          paddingTop: "0px !important"
        }
      },
      "&.cg-slide-4": {
        [theme.breakpoints.up("md")]: {
          paddingBottom: "30px"
        },
        [theme.breakpoints.up("lg")]: {
          paddingBottom: "40px"
        }
      },
      "&.cg-slide-12": {
        [theme.breakpoints.up("md")]: {
          borderBottom: "0.5px solid #dddddd"
        }
      }
    },
    noBorderBottom: {
      "&.cg-slide-12": {
        [theme.breakpoints.up("sm")]: {
          borderBottom: "none"
        }
      }
    },
    reveal: {
      opacity: 0
    },

    fadeInUp: {
      animationDuration: "0s",
      animationTimingFunction: "easeIn",
      animationDelay: "0s",
      animationIterationCount: 1,
      animationDirection: "normal",
      animationFillMode: "none",
      animationPlayState: "running",
      animationName: "fadeInUp"
    }
  });
});

type CollectionGalleryProps = any;

export const CollectionGallery: FC<CollectionGalleryProps> = ({
  amountRemaining,
  nextStart,
  data = [],
  onLoadMore,
  isDeepLoad,
  networkStatus,
  collectionGalleryLoading,
  saleContextParam,
  isNextPageLoading,
  isLazyLoadEnabled = true,
  totalNumRecs,
  bannerTitle,
  bannerSubTitle
}) => {
  const env = useEnv();
  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(CG_ITEMS_PER_PAGE_PREFERENCE)
    : undefined;
  const params = useParams({
    categoryId: "",
    topCatId: "",
    parentCatId: "",
    count: storedItemsPerPagePreference ?? DEFAULT_PAGINATION_CG_COUNT,
    start: ""
  });
  const isPaginationFeatureEnabled = yn(env.FEATURE_CG_PAGINATION);

  const req = getReqContext();
  const newURLStructureParams = useNewURLStructureParams();
  const _params = useFetchParams(
    processEnvServer ? req?.path : location.pathname,
    yn(env.FEATURE_URL_CHANGE) ? "cg" : "collections.jsp"
  );
  let [categoryId] = _params;

  if (params.categoryId === "") {
    params.categoryId = categoryId?.replace("&ct=true", "");
  }

  const classes = useStyles();
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const rhUser = useRhUserAtomValue();
  const root = data[0] as CollectionCategory;
  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const typographyStyles = useTypographyStyles({
    keys: ["collectionGalleryHeader", "collectionGallerySubHeader"]
  });
  const isRHR = yn(env?.FEATURE_RHR);
  const [, , removeCookie] = useCookies([
    CG_GRID_CHOICE,
    CG_ID,
    CG_ID_PREVIEW,
    "pgId"
  ]);
  const { setCookieWrapper, setStorageValueWrapper } =
    useCookiesWithPermission();
  const { previousState } = useUserPreferences();
  const prefix = useLocalization();
  const cookie = useIsoCookies([CG_GRID_CHOICE, CG_ID, CG_ID_PREVIEW, "pgId"]);
  const history = useHistory();
  const isLoadedGTM = isGTMLoaded();
  const [gridColumns, setGridColumns] = useState<number>(() => {
    const defaultGridView =
      cookie[CG_GRID_CHOICE] && parseInt(cookie[CG_GRID_CHOICE]);

    return defaultGridView ? defaultGridView : DEFAULT_GRID_COL;
  });
  const [scrolling, setScrolling] = useState<boolean>(false);

  const { pageContent } = useFetchModel(
    `${prefix}/admin/products`,
    false,
    false
  );
  const ItemsPerPageOptions = JSON.parse(
    pageContent?.items_per_page_options || "[]"
  );

  /*
  below effect is writen w.r.t the issue i.e
  "when navigated back from PDP or PG to CG. Automatically set previous selected grid."
  */
  useEffect(() => {
    const defaultGridView =
      cookie.cgGridChoice && parseInt(cookie.cgGridChoice);

    if (defaultGridView && [4, 6, 12].includes(defaultGridView)) {
      const newGridColumns =
        defaultGridView === 4 && !smUp && xsDown
          ? DEFAULT_GRID_COL
          : defaultGridView;

      setGridColumns(newGridColumns);
      setCookieWrapper(CG_GRID_CHOICE, newGridColumns);
      removeCookie(CG_ID, {
        path: "/"
      });
    }
  }, [cookie.cgGridChoice, smUp, xsDown, gridColumns]);

  const saveGridCol = useCallback(
    col => {
      setCookieWrapper(CG_GRID_CHOICE, col);
      setGridColumns(col);
      removeCookie(CG_ID, {
        path: "/"
      });
    },
    [removeCookie, setCookieWrapper]
  );

  const saveSelectedCategory = useCallback(
    cgId => {
      setCookieWrapper(
        CG_ID,
        cgId,
        {
          path: "/"
        },
        previousState.country
      );
      setCookieWrapper(
        CG_ID_PREVIEW,
        cgId,
        {
          path: "/"
        },
        previousState.country
      );
    },
    [previousState.country, setCookieWrapper]
  );

  /*
  below  function is writen w.r.t the issue i.e
  "CG when navigated back from PDP to PG and CG. Automatically going back to the previous selection."
  on scroll both useEffect will remove the cookie
  */
  const handleRemoveCookie = useCallback(
    remove => {
      if (history.action === "POP" && cookie.cgId && remove) {
        removeCookie(CG_ID, {
          path: "/"
        });
        if (cookie.pgId) {
          removeCookie("pgId", {
            path: "/"
          });
        }
      }
    },
    [cookie, history]
  );

  // useEffect(() => {
  //   if (window) {
  //     window.history.scrollRestoration = "manual";

  //     // applying this condition as some of the pages in SSR
  //     // require reload below code causes to remove cookie
  //     // this cookie will be removed once user clicks on any nav options
  //     if (!isSSRToggled) {
  //       // remove scroll restoration cookie on refresh
  //       window.addEventListener("unload", () => {
  //         removeCookie(CG_ID, {
  //           path: "/"
  //         });
  //       });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (window) {
      window.history.scrollRestoration = "manual";
      // remove scroll restoration cookie on refresh
      window.addEventListener("unload", () => {
        removeCookie(CG_ID, {
          path: "/"
        });
      });
    }
  }, [removeCookie]);

  if (!processEnvServer) {
    useEffect(() => {
      if (
        root &&
        root.displayName &&
        rhUser?.akamaiCountryCode &&
        isLoadedGTM?.isGTMLoaded
      ) {
        setStorageValueWrapper({
          storageKey: "analytics-category-title",
          value: root?.displayName
        });
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.GA4_VIEW_PAGE_PRODUCT.INT_TYPE,
            {
              displayName: root.displayName,
              id: root?.id
            }
          )
        );

        // const itemList = data?.map(rec => ({
        //   item_name: rec?.displayName,
        //   item_id: rec?.id,
        //   currencyCode: countryNameMapper[getCountryFromUrl() ?? "US"],
        //   category: rec?.displayName
        // }));

        // analyticsLoader(a =>
        //   a.emitAnalyticsEvent(
        //     document.querySelector("#spa-root > *")! as HTMLElement,
        //     a.EVENTS.VIEW_ITEM_LIST.INT_TYPE,
        //     {
        //       itemList,
        //       item_list_name: true,
        //       item_list_name_view_item: root?.displayName,
        //       item_list_id: root?.id
        //     }
        //   )
        // );
      }
    }, [
      root?.displayName,
      rhUser?.akamaiCountryCode,
      isLoadedGTM?.isGTMLoaded
    ]);
  }
  // Break the word "collections" to new line
  const formattedTitle = (title: string, rhr: boolean = false) => {
    const collections = "collections",
      index = title.toLowerCase().indexOf(collections);

    return index <= 0
      ? title
      : () =>
          rhr ? (
            <span className="uppercase !font-light">
              {`${title.slice(0, index)} ${collections}`}
            </span>
          ) : (
            <>
              <span className={typographyStyles.collectionGalleryHeader}>
                {title.slice(0, index)}
              </span>
              <br />
              <span className={typographyStyles.collectionGallerySubHeader}>
                {collections}
              </span>
            </>
          );
  };

  const ssrFormatTitle = (title: string) => {
    return () => (
      <span className="uppercase !text-black !text-[2.6666666666666665rem] !font-primary-ultra-thin !leading-[2.6666666666666665rem] !tracking-[-0.02em]">
        {title}
      </span>
    );
  };

  const keywords = React.useMemo(
    () => root?.keywords?.join(", "),
    [root?.keywords]
  );
  const promotionDisplayItems = data?.[0]?.promotionDisplayItems;

  const generatePath = (saleContextFilter, data) => {
    const buildPathOption = {
      fromCG: "true"
    };
    const pageTitle = data?.displayName?.toLowerCase().replace(/\s+/g, "-");
    if (yn(env.FEATURE_URL_CHANGE)) {
      return buildPath(`/${newURLStructureParams?.category}/pg/${pageTitle}`, {
        ...buildPathOption
      });
    }

    // call buildPath with pgterm if feature flag is true
    if (yn(env.FEATURE_AUTOMATE_SEARCH_PGS)) {
      return buildPath(
        `/catalog/category/products.jsp/${data?.id}/category:${data?.id}`,
        {
          ...buildPathOption
        }
      );
    } else {
      return buildPath(`/catalog/category/products.jsp/${data?.id}`, {
        ...buildPathOption
      });
    }
  };

  const filteredData = data.filter(item => item.collection);

  const hostName = isServer ? getClientOrigin() : window?.location?.origin;

  const metaCollectionList = useMemo(() => {
    return filteredData?.map((collection, index) => ({
      position: index + 1,
      name: `${collection?.bannerTitle} ${collection?.bannerSubTitle}`,
      image: collection?.cgBannerImage
        ? `http:${collection.cgBannerImage}`
        : "",
      lowPrice: collection?.priceInfo?.memberPrice || 0,
      highPrice: collection?.priceInfo?.listPrice || 0,
      currencySymbol: collection?.priceInfo?.currencySymbol
        ? currencySymbolToCurrencyCodeMapper[
            collection?.priceInfo?.currencySymbol
          ]
        : "",
      url: `${hostName}${prefix}${generatePath(saleContextParam, collection)}`
    }));
  }, [filteredData]);

  const itemListSchema = useGetSchema("ItemList", {
    name: root?.seoPageTitle || root?.displayName,
    description: root?.seoDescription || "",
    url: `${hostName}${prefix}${generatePath(saleContextParam, root)}`,
    itemList: metaCollectionList
  });

  // TODO may need to change/remove/add extra condition for this for rehydration
  if (
    !processEnvServer &&
    collectionGalleryLoading &&
    [NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus)
  ) {
    return <CollectionGallerySkeleton animation={SKELETON_ANIMATION} />;
  }

  return (
    <div>
      {root && (
        <>
          <div className={classNames(isRHR && "pb-4", xsDown ? "!pb-0" : "")}>
            <div
              className={`grid sm:justify-between md:grid-cols-2 items-center`}
            >
              <div className={classNames([xsDown ? "pb-1" : ""])}>
                <PageHeader
                  title={
                    isSSRToggledWithCSR || processEnvServer
                      ? ssrFormatTitle(
                          bannerTitle || root.bannerTitle || root.displayName
                        )
                      : formattedTitle(
                          bannerTitle || root.bannerTitle || root.displayName,
                          isRHR
                        )
                  }
                  subtitle={bannerSubTitle || root.bannerSubTitle}
                  titleStyle={
                    isRHR
                      ? {
                          textAlign: xsDown ? "center" : "left",
                          textTransform: "uppercase",
                          fontWeight: 300
                        }
                      : { textTransform: "uppercase" }
                  }
                  subtitleStyle={isRHR ? { textAlign: "left" } : {}}
                  rootStyle={{
                    paddingBottom: isRHR ? 0 : undefined
                  }}
                />
              </div>
              {isRHR && promotionDisplayItems?.eventbanner && (
                <div className={`md:place-self-end h-full grid items-center`}>
                  <Container className="text-right">
                    <Promo data={promotionDisplayItems?.eventbanner} />
                  </Container>
                </div>
              )}
            </div>
          </div>
          {isRHR && (
            <Container
              className={classNames([
                "sm:mb-0 md:mb-0 lg:mb-0",
                `grid-controller-${gridColumns}`
              ])}
            >
              <div className={`grid justify-end`}>
                <div>
                  {promotionDisplayItems?.addbanner && (
                    <div className={classes.rhrPromoContainer}>
                      <Promo data={promotionDisplayItems?.addbanner} />
                    </div>
                  )}
                </div>
                <Hidden xsDown>
                  <div className="flex">
                    <GridController
                      gridColumns={gridColumns}
                      setGridColumns={saveGridCol}
                      isCG={false}
                      ssr={isSSRToggledWithCSR}
                      hide2Column={
                        yn(env.FEATURE_CG)
                          ? smUp && !mdUp
                            ? false
                            : true
                          : false
                      }
                      hide3Column={
                        yn(env.FEATURE_CG) ? (mdUp ? false : true) : false
                      }
                    />
                  </div>
                </Hidden>
              </div>
            </Container>
          )}
          {/* {isRHR && promotionDisplayItems?.custombanner && (
            <Container className={classes.rhrPromoCustomBanner}>
              <Promo data={promotionDisplayItems?.custombanner} />
            </Container>
          )} */}
        </>
      )}
      <HTMLHeadTags
        title={root?.seoPageTitle || root?.displayName}
        description={root?.seoDescription}
        keywords={keywords}
        displayName={root?.displayName}
        image={data[1]?.cgBannerImage}
        schema={itemListSchema}
      />
      {
        /* IF server, just load first few rows. for now. */
        isLazyLoadEnabled ? (
          <InfiniteScroll
            dataLength={data?.length}
            next={onLoadMore}
            hasMore={amountRemaining > 0}
            loader={
              <ListItem key="cg-infinite-loader">
                <CollectionCardSkeleton animation={SKELETON_ANIMATION} />
              </ListItem>
            }
            scrollThreshold={0.1}
            onScroll={() => {}}
          >
            <GalleryGrid
              root={root}
              keywords={keywords}
              isRHR={isRHR}
              data={data}
              params={params}
              gridColumns={gridColumns}
              saleContextParam={saleContextParam}
              generatePath={generatePath}
              saveSelectedCategory={saveSelectedCategory}
              handleRemoveCookie={handleRemoveCookie}
            />
            <RHScrollUpButton />
          </InfiniteScroll>
        ) : (
          <>
            {collectionGalleryLoading ? (
              <ListItem key="loader">
                <CollectionCardSkeleton animation={SKELETON_ANIMATION} />
              </ListItem>
            ) : (
              <>
                <GalleryGrid
                  root={root}
                  keywords={keywords}
                  isRHR={isRHR}
                  data={data}
                  params={params}
                  gridColumns={gridColumns}
                  saleContextParam={saleContextParam}
                  generatePath={generatePath}
                  saveSelectedCategory={saveSelectedCategory}
                  handleRemoveCookie={handleRemoveCookie}
                />
                <RHScrollUpButton />
              </>
            )}
          </>
        )
      }
      {!isNextPageLoading && isPaginationFeatureEnabled ? (
        <div>
          {ItemsPerPageOptions.includes(Number(params.count)) && (
            <RHPagination
              recsPerPage={Number(params.count)}
              lastRecNum={nextStart > -1 ? nextStart - 1 : data?.length - 1}
              totalNumRecs={totalNumRecs}
              loadMoreData={onLoadMore}
              pageContent={pageContent}
              start={"start"}
              count={"count"}
              itemsPerPagePreference={CG_ITEMS_PER_PAGE_PREFERENCE}
              defaultCount={DEFAULT_PAGINATION_CG_COUNT}
            />
          )}
          <ItemsPerPage
            recsPerPage={Number(params.count)}
            lastRecNum={nextStart > -1 ? nextStart - 1 : data?.length - 1}
            totalNumRecs={totalNumRecs}
            loadMoreData={onLoadMore}
            pageContent={pageContent}
            ItemsPerPageOptions={ItemsPerPageOptions}
          />
        </div>
      ) : null}
    </div>
  );
};

CollectionGallery.defaultProps = {};

export const CG = memoize(CollectionGallery);

export const GalleryGrid: FC<CollectionGridProps> = ({
  isRHR,
  data,
  params,
  gridColumns,
  saleContextParam,
  generatePath,
  saveSelectedCategory,
  handleRemoveCookie
}) => {
  const classes = useStyles();
  const prefix = useLocalization();
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const history = useHistory();
  const userCountry = useCountry();
  const cookie = useIsoCookies([CG_GRID_CHOICE, CG_ID, CG_ID_PREVIEW, "pgId"]);
  const env = useEnv();
  const isCGColorizationEnabled = yn(env.FEATURE_CG_COLORIZATION);
  const locale = useLocale();
  const [isSwatchSelected, setIsSwatchSelected] = useState<
    string[] | undefined
  >([]);

  const finalData = !isRHR
    ? data
    : data.filter(
        item =>
          (item?.collection && item?.cgBannerTemplate) ||
          item?.promotionDisplayItems?.custombanner
      );

  return (
    <>
      <div
        className={classNames([
          `!grid pl-4 pr-4 pt-8 gap-y-0 gap-x-4 sm:pl-8 sm:pr-8 sm:gap-x-8 md:pl-10 md:pr-10 md:gap-x-10 xl:pl-20 xl:pr-20 ${classes.rhrCgGridContainer}`,
          `grid-item-${gridColumns} ${isRHR ? "gap-0" : "gap-2"}`
        ])}
        data-category-id={params.categoryId}
        data-parent-cat-id={params.parentCatId}
        data-testid={`collectionGallery-list`}
        data-top-cat-id={params.topCatId}
      >
        {!isRHR
          ? finalData.map((item, index) => (
              <div key={item?.id}>
                <GalleryCollectionCategorySelector
                  key={item.id}
                  collectionCategory={{
                    ...item,
                    parentCatId: params.categoryId
                  }}
                  gridColumns={gridColumns}
                  isImageEager={
                    index < COLLECTION_GALLERY_DEFAULT_COUNT ||
                    index % COLLECTION_GALLERY_DEFAULT_COUNT <= 2
                  }
                />
              </div>
            ))
          : finalData.map((item, index, allItems) =>
              item?.promotionDisplayItems?.custombanner ? (
                <div
                  key={`collection-gallery-${item?.id}-${index}`}
                  className={classNames([
                    {
                      [classes.noBorderBottom]: allItems.length === index + 1
                    },
                    "cg-promo w-full h-full"
                  ])}
                >
                  {gridColumns != 12 && <RHDivider />}
                  <div className={classes.cgSlide} style={{ paddingBottom: 0 }}>
                    <Promo data={item?.promotionDisplayItems?.custombanner} />
                  </div>
                </div>
              ) : (
                <div
                  key={`collection-gallery-${index}`}
                  className={classNames([
                    classes.cgSlide,
                    {
                      [classes.noBorderBottom]: allItems.length === index + 1
                    },
                    `cg-slide`,
                    `galleryVisible`,
                    `cg-slide-${gridColumns}`,
                    "duration-500 delay-[0ms] opacity-100 w-full"
                  ])}
                  style={{
                    animationIterationCount: 1
                  }}
                  id="collection-gallery-grid"
                >
                  <RHLink
                    id={`component-collectionCardASDASD_link-${item.id}`}
                    data-testid={`collectionCardLink-${item.id}`}
                    underline="none"
                    to={
                      item?.useBannerLink && item?.bannerLink
                        ? `${prefix}${item?.bannerLink}`
                        : generatePath(saleContextParam, item)
                    }
                    tabIndex={-1}
                  >
                    <GalleryCollectionCategorySelector
                      collectionCategory={{
                        ...item,
                        parentCatId: params.categoryId
                      }}
                      gridColumns={gridColumns}
                      isImageEager={
                        index < COLLECTION_GALLERY_DEFAULT_COUNT ||
                        index % COLLECTION_GALLERY_DEFAULT_COUNT <= 2
                      }
                      saveSelectedCategory={saveSelectedCategory}
                      selectedProductId={
                        history.action === "POP" && cookie[CG_ID]
                          ? cookie[CG_ID]?.split("_")[0] === item?.id
                          : false
                      }
                      handleRemoveCookie={handleRemoveCookie}
                      isSwatchSelected={isSwatchSelected}
                      setIsSwatchSelected={setIsSwatchSelected}
                    />

                    {/* ERHR-378 Remove "Preview the Collection" button */}
                    {/* {lgUp && gridColumns === 6 ? undefined : (
                        <AccordianToggle
                          data={item}
                          gridColumns={gridColumns}
                          saveSelectedCategory={saveSelectedCategory}
                          defaultOpen={
                            history.action === "POP" && cookie[CG_ID_PREVIEW]
                              ? cookie[CG_ID_PREVIEW]?.split("_")[0] ===
                                  item?.id &&
                                !!cookie[CG_ID_PREVIEW]?.split("_")[1]
                              : false
                          }
                        />
                    )} */}
                  </RHLink>
                </div>
              )
            )}
      </div>
    </>
  );
};

export default memoize((() => {
  const { app } = useAppData();
  const env = useEnv();
  const nextgenCookie = useIsoCookies(["nextgencg"], true)?.nextgencg;
  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(CG_ITEMS_PER_PAGE_PREFERENCE)
    : undefined;
  const params = useParams({
    categoryId: "",
    siteId: "",
    sale: "false",
    count: storedItemsPerPagePreference ?? DEFAULT_PAGINATION_CG_COUNT,
    start: ""
  });
  const req = getReqContext();
  const _params = useFetchParams(
    processEnvServer ? req?.path : location.pathname,
    yn(env.FEATURE_URL_CHANGE) ? "cg" : "collections.jsp"
  );

  let [categoryId] = _params;
  if (params.categoryId === "") {
    params.categoryId = categoryId?.replace("&ct=true", "");
  }

  const userContext = useUserContext();
  const { userType } = useRhUserAtomValue();
  const prefix = useLocalization();
  const { search: locationSearch } = useLocation();
  const measureSystem = useMeasurementType();
  const isDeepLoad =
    useIsoCookies(["deepload"])?.deepload === "true" ||
    yn(env.FEATURE_DEEPLOAD);
  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);

  const { previousState } = useUserPreferences();
  const brand = useSite();
  const siteId = useMemo(
    () => (params?.siteId ? params?.siteId : brand),
    [brand, params?.siteId]
  );
  const locale = useLocale();
  const pc = useIsoCookies(["pc"])?.pc;
  const history = useHistory();

  const postalCode = pc || app.postalCode;
  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode: postalCode,
    userType
  });

  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const isPaginationFeatureEnabled = yn(env.FEATURE_CG_PAGINATION);
  const isPaginationLoadFullPageEnabled =
    yn(env.FEATURE_CG_PAGINATION_LOAD_FULL_PAGE) && isPaginationFeatureEnabled;

  const searchParams = new URLSearchParams(locationSearch);
  const loadAllParam = searchParams.get("loadAll") === "true";
  // lazy load is enabled when the url has loadAll param, which lazyloads items after every 100 items
  const isLazyLoadEnabled = useMemo(() => {
    return (
      !isPaginationFeatureEnabled ||
      (isPaginationLoadFullPageEnabled && loadAllParam)
    );
  }, [loadAllParam]);

  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );
  const variables = {
    categoryId: params.categoryId,
    filter: saleContextParam || null,
    start:
      isPaginationFeatureEnabled && params.start ? Number(params.start) : 0,
    siteId,
    measureSystem,
    count: isDeepLoad
      ? undefined
      : isPaginationFeatureEnabled && params.count
      ? Number(params.count)
      : COLLECTION_GALLERY_DEFAULT_COUNT,
    currencyCode,
    countryCode: previousState.country,
    locale: FEATURE_INTERNATIONAL ? locale : undefined,
    userType,
    nextgenDriven: nextgenCookie === "true"
  };
  const {
    data: { collectionGallery } = {} as Query,
    networkStatus,
    error,
    refetch,
    fetchMore,
    loading: collectionGalleryLoading
  } = useQuery<Query>(queryCollectionGallery, {
    variables,
    skip: !params.categoryId,
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first"
  });

  const debug = useIsoCookies(["debug"], true)?.debug;

  useEffect(() => {
    if (!collectionGalleryLoading) {
      const galleryLength =
        collectionGallery?.collectionGallery?.filter(
          item =>
            (item?.collection && item?.cgBannerTemplate) ||
            item?.promotionDisplayItems?.custombanner
        )?.length || 0;
      // redirect to home page if collection is empty
      if (galleryLength === 0 && !debug) {
        return history.push(FEATURE_INTERNATIONAL ? prefix : "/");
      }
    }
  }, [
    collectionGallery,
    history,
    prefix,
    FEATURE_INTERNATIONAL,
    collectionGalleryLoading
  ]);

  const showBelowNavPromo = shouldShowBelowNavPromo(
    collectionGallery?.collectionGallery?.[0]
  );
  const redirectPath = useGeneratePath(
    saleContextParam,
    collectionGallery?.collectionGallery?.[0]
  );
  const isRHR = yn(env?.FEATURE_RHR);

  useDidMountEffect(() => {
    refetch?.();
    // Used for Canadian customers - popup comes up and requires zip entry.
    // Once zip is entered, this will force page/data reload.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode, userType]);

  const amountRemaining = collectionGallery?.amountRemaining;
  const totalNumRecs =
    collectionGallery?.totalNumRecs ||
    collectionGallery?.amountRemaining + collectionGallery?.nextStart;

  const onLoadMoreCb = useCallback(
    (page?: number, start?: number) => {
      // InfiniteScroll is calling this on first scroll
      // even if hasMoreItems is false
      // subsequent scrolling events when hasMoreItems is false
      // are properly ignored
      if ((amountRemaining ?? 0) < 1) {
        return;
      }
      if (isPaginationLoadFullPageEnabled && !isLazyLoadEnabled) {
        return;
      }
      setIsNextPageLoading(true);

      fetchMore({
        variables: {
          start: collectionGallery?.nextStart ?? 0,
          count:
            isPaginationFeatureEnabled && params.count
              ? Number(params.count)
              : COLLECTION_GALLERY_DEFAULT_COUNT,
          collectionGallery,
          currencyCode
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (networkStatus === NetworkStatus.fetchMore || !fetchMoreResult) {
            return {} as Query;
          }
          // TODO: @GA4M-56 - View_item_list not required in current migration
          // let collectionTitle, collectionId;
          // const itemList =
          //   fetchMoreResult?.collectionGallery?.collectionGallery?.map(
          //     (rec, index) => {
          //       if (index === 0) {
          //         collectionTitle = rec?.displayName;
          //         collectionId = rec?.id;
          //       }
          //       return {
          //         displayName: rec?.displayName,
          //         productId: rec?.id,
          //         quantity: null,
          //         sku: null,
          //         price: null,
          //         currencyCode: countryNameMapper[getCountryFromUrl() ?? "US"],
          //         category: rec?.displayName
          //       };
          //     }
          //   );

          // analyticsLoader(a =>
          //   a.emitAnalyticsEvent(
          //     document.querySelector("#spa-root > *")! as HTMLElement,
          //     a.EVENTS.VIEW_ITEM_LIST.INT_TYPE,
          //     {
          //       itemList,
          //       item_list_name: true,
          //       item_list_name_view_item: collectionTitle,
          //       item_list_id: collectionId
          //     }
          //   )
          // );
          if (isPaginationFeatureEnabled && !isLazyLoadEnabled) {
            return fetchMoreResult;
          }
          return {
            ...fetchMoreResult,
            collectionGallery: {
              ...(fetchMoreResult?.collectionGallery || {}),
              collectionGallery: [
                ...(prev?.collectionGallery?.collectionGallery ?? []),
                ...(fetchMoreResult?.collectionGallery?.collectionGallery ?? [])
              ]
            }
          };
        }
      });
    },
    [
      userContext.currencyCode,
      userType,
      isNextPageLoading,
      networkStatus,
      collectionGallery?.nextStart,
      amountRemaining,
      fetchMore
    ]
  );

  useEffect(() => {
    if (isNextPageLoading) {
      setIsNextPageLoading(false);
    }
  }, [collectionGallery?.collectionGallery]);

  if (!!error && !collectionGallery?.collectionGallery?.length) {
    return useIsoRedirect(`${prefix}/error/category-not-found.jsp`, "redirect");
  }

  // Categories with only 1 item in the response need to redirect to the PG page instead
  if (collectionGallery?.collectionGallery?.length === 1 && !debug) {
    const collection = collectionGallery?.collectionGallery?.[0];
    const viewCollectionsPath =
      collection.useBannerLink && collection.bannerLink
        ? `${prefix}${collection.bannerLink}`
        : redirectPath;

    return useIsoRedirect(viewCollectionsPath, "redirect");
  }

  return (
    <>
      {showBelowNavPromo && !isRHR && <BelowNavPromo />}
      <CG
        amountRemaining={collectionGallery?.amountRemaining}
        nextStart={collectionGallery?.nextStart}
        data={collectionGallery?.collectionGallery}
        networkStatus={networkStatus}
        onLoadMore={onLoadMoreCb}
        isDeepLoad={!!isDeepLoad}
        collectionGalleryLoading={collectionGalleryLoading}
        saleContextParam={saleContextParam}
        isNextPageLoading={isNextPageLoading}
        isLazyLoadEnabled={isLazyLoadEnabled}
        totalNumRecs={totalNumRecs}
        bannerTitle={collectionGallery?.bannerTitle || ""}
        bannerSubTitle={collectionGallery?.bannerSubTitle || ""}
      />
    </>
  );
}) as any);
