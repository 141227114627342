import { makeStyles, createStyles, Theme } from "@material-ui/core";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  FONT_BARON_SANS_ROMAN
} from "utils/constants";

const useStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      configuratorOuter: {
        [theme.breakpoints.up("xs")]: {
          display: "flex",
          padding: "13px 16px 0px !important",
          width: "100% !important"
        },
        [theme.breakpoints.up("sm")]: {
          display: "inline-flex",
          padding: "13px 32px 0px !important",
          width: "100% !important"
        },
        [theme.breakpoints.up("md")]: {
          minWidth: "427px",
          maxWidth: "427px",
          display: "inline-flex",
          padding: "0px 40px !important"
        },
        [theme.breakpoints.up("lg")]: {
          minWidth: "427px",
          maxWidth: "427px",
          display: "inline-flex",
          padding: "0px 40px !important"
        },
        [theme.breakpoints.up("xl")]: {
          minWidth: "467px",
          maxWidth: "467px",
          padding: "0 80px 0 40px !important"
        }
      },
      configuratorOuterPDPV3: {
        [theme.breakpoints.up("xs")]: {
          display: "flex",
          padding: "13px 16px 0px !important",
          width: "100% !important"
        },
        [theme.breakpoints.up("sm")]: {
          display: "inline-flex",
          padding: "13px 32px 0px !important",
          width: "100% !important"
        },
        [theme.breakpoints.up("md")]: {
          minWidth: "357px",
          maxWidth: "357px",
          display: "inline-flex",
          padding: "0px !important"
        },
        [theme.breakpoints.up("lg")]: {
          minWidth: "427px",
          maxWidth: "427px",
          display: "inline-flex",
          padding: "0px 40px !important"
        },
        [theme.breakpoints.up("xl")]: {
          minWidth: "491px",
          maxWidth: "491px",
          padding: "0 80px 0 40px !important"
        }
      },
      slider: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "1920px",
        margin: "0 auto",
        position: "relative",
        pageBreakInside: "avoid",
        marginTop: "128px",
        marginBottom: "20px",
        [theme.breakpoints.up("xl")]: {
          paddingLeft: "90px",
          paddingRight: "90px"
        },
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          marginTop: "96px"
        }
      },
      relatedProductGrid: {
        paddingTop: "96px !important",
        backgroundColor: theme?.palette?.background?.default || "#fafafa",
        [theme.breakpoints.up("xl")]: {
          paddingLeft: "0 !important",
          paddingRight: "0 !important"
        },
        [theme.breakpoints.only("sm")]: {
          paddingTop: "96px !important",
          padding: "10px !important"
        }
      },
      product: {
        width: "100%",
        maxWidth: "1920px",
        margin: "0 auto",
        paddingTop:
          !props?.isNewPdpLayout && theme.breakpoints.down(BREAKPOINT_SM)
            ? ""
            : "0 !important",
        paddingBottom: "0 !important",
        [theme.breakpoints.up("xl")]: {
          paddingBottom: theme.spacing(3.7)
        }
      },
      productOpen: {
        margin: "unset"
      },

      iconStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "RHSans-Light",
        lineHeight: "20px",
        fontSize: "13px",
        letterSpacing: "0.015em",
        transform: "uppercase"
      },
      quantityDisplayContainer: {
        width: "24px",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },

      quantityContainerStyle: {
        width: "72px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        userSelect: "none"
      },

      pricingContainerStyle: {
        marginTop: "36px",
        marginBottom: "24px",
        display: "flex",
        alignItems: "end",
        justifyContent: props.isNewPdpLayout ? "end" : "space-between",
        [theme?.breakpoints?.up(BREAKPOINT_XL)]: {
          width: "370px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_LG, BREAKPOINT_XL)]: {
          width: "347px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
          width: "317px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
          width: "582px",
          marginBottom: "36px"
        },
        [theme?.breakpoints?.down(BREAKPOINT_SM)]: {
          marginBottom: "36px",
          width: "342px"
        },
        "& #productPriceAboveCart": {
          [theme.breakpoints.down(BREAKPOINT_MD)]: {
            "& *": {
              fontSize: "13px !important"
            }
          }
        }
      },
      gutterGridXl: {
        margin: "0 auto",
        width: "100%",
        backgroundColor: "#F9F7F4"
      },
      draperyLink: {
        "& > a": {
          textDecoration: "initial"
        }
      },
      divider: {
        height: 2,
        backgroundColor: theme.palette.common.white,
        width: "100%"
      },
      ul: {
        padding: theme.spacing(0, 0, 3, 0),
        margin: 0,
        listStyle: "initial",
        [`& li[style*="display: list-item"]`]: {
          marginLeft: theme.spacing(2)
        }
      },
      link3: {
        "& b": {
          fontFamily: FONT_BARON_SANS_ROMAN,
          fontSize: "13px",
          letterSpacing: "0.025em",
          lineHeight: "1.66rem",
          textTransform: "uppercase",
          color: "#000000",
          fontWeight: "normal",
          "&:hover": {
            color: "black",
            textDecoration: "none"
          }
        },
        [`& a.sku-info.link`]: {
          textTransform: "uppercase",
          textDecoration: "none",
          fontWeight: 300,
          lineHeight: "13px"
        }
      },
      content: {
        overflow: "auto"
      },
      // This is a temporary hack to format <b> tags
      // being rendered using setInnerHTML.
      collapsablesContainer: {
        "& b": {
          fontFamily: FONT_BARON_SANS_ROMAN,
          fontWeight: 400,
          fontSize: theme.typography.pxToRem(13),
          lineHeight: theme.typography.pxToRem(20)
        }
      },
      closeCollapses: {
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(1),
        transform: "rotate(90deg)",
        transition: "transform 0.3s",
        height: 16,
        width: 16
      },
      openCollapses: {
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(1),
        transform: "rotate(270deg)",
        transition: "transform 0.3s",
        height: 16,
        width: 16
      },
      reveal: {
        opacity: 0
      },

      fadeInUp: {
        animationDuration: "0s",
        animationTimingFunction: "easeIn",
        animationDelay: "0s",
        animationIterationCount: 1,
        animationDirection: "normal",
        animationFillMode: "none",
        animationPlayState: "running",
        animationName: "fadeInUp"
      },
      carosalWraperStyle: {
        "& .MuiGridList-root": {
          flexWrap: "nowrap !important"
        }
      },
      productTitle: {
        fontFamily: "RHSans-UltraThin  ",
        color: "#000",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 75,
        lineHeight: "32px",
        letterSpacing: "-0.02em",
        textTransform: "uppercase"
      },
      priceContainer: {
        paddingTop: "8px"
      },
      productHeader: {
        "& > #product-title-price": {
          [theme?.breakpoints?.up(BREAKPOINT_XL)]: {
            width: "370px"
          },
          [theme?.breakpoints?.between(BREAKPOINT_LG, BREAKPOINT_XL)]: {
            width: "347px"
          },
          [theme?.breakpoints?.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
            width: "317px"
          },
          [theme?.breakpoints?.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
            width: "582px",
            marginBottom: "36px"
          },
          [theme?.breakpoints?.down(BREAKPOINT_SM)]: {
            width: "342px"
          }
        }
      },
      [theme?.breakpoints?.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
        productHeader: {
          padding: "0px 32px",
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between"
        },
        priceContainer: {
          paddingTop: "12px"
        },
        productTitle: {
          fontSize: "20px",
          lineHeight: "23px"
        }
      },
      [theme?.breakpoints?.down(BREAKPOINT_SM)]: {
        priceContainer: {
          paddingTop: "12px"
        },
        productTitle: {
          fontSize: "27px",
          lineHeight: "31px"
        }
      },
      dropDownContainer: {
        margin: "10px 0 0 0",

        [theme?.breakpoints?.up(BREAKPOINT_XL)]: {
          width: "370px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_LG, BREAKPOINT_XL)]: {
          width: "347px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
          width: "317px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
          width: "582px",
          margin: "24px 0 0 0"
        },
        [theme?.breakpoints?.down(BREAKPOINT_SM)]: {
          width: "342px"
        }
      },
      optionLabelWrapper: {
        width: "100%",
        marginRight: theme.spacing(1),
        height: "fit-content",
        color: "#000 !important",
        fontFamily: "RHSans !important",
        fontSize: "11px !important",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "12px !important",
        letterSpacing: "0.44px !important"
      },
      optionLabel: {
        paddingTop: "4px",
        color: "#898886 !important"
      },
      fabricBottomSpace: {
        width: "100%",
        borderBottom: "1px solid #B6B6B6"
      },
      watchTheFilm: {
        color: "var(--reimagine-warm-rh-black, #000)",
        textAlign: "center",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "120%",
        letterSpacing: "0.44px",
        textTransform: "uppercase",
        "& .playIcon": {
          color: "#000000",
          border: "0.75px solid #000000"
        },
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          paddingTop: "29px",
          textAlign: "left"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          paddingTop: "25px",
          textAlign: "left"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          paddingTop: "28px"
        }
      },
      stickyImageContainerPDPV3: {
        position: "sticky",
        top: "0px",
        flex: 1,
        maxWidth: "100%",
        width: "64%",
        marginBottom: "76px",
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          padding: "0 17px"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          padding: "0 61px"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          justifyContent: "space-between",
          padding: "0 40px",
          "& #hero-image-v2-container-square #hero-image-container": {
            height: `calc(100vh - ${props?.isConcierge} - 76px)`,
            "& *": {
              height: `calc(100vh - ${props?.isConcierge} - 76px)`
            },
            "@media (min-height: 1300px)": {
              height: `calc(80vh - ${props?.isConcierge} - 76px)`,
              "& *": {
                height: `calc(80vh - ${props?.isConcierge} - 76px)`
              }
            }
          },
          "& #hero-image-v2-container-horizontal  #hero-image-container": {
            "@media (max-height: 1300px)": {
              height: `calc(50vh - ${props?.isConcierge} - 76px)`,
              "& *": {
                height: `calc(50vh - ${props?.isConcierge} - 76px)`
              }
            },
            "@media (min-height: 1300px)": {
              height: `calc(50vh - ${props?.isConcierge} - 76px)`,
              "& *": {
                height: `calc(50vh - ${props?.isConcierge} - 76px)`
              }
            },
            "@media (max-height: 1200px)": {
              height: `calc(60vh - ${props?.isConcierge} - 76px)`,
              "& *": {
                height: `calc(60vh - ${props?.isConcierge} - 76px)`
              }
            },
            "@media (max-height: 1100px)": {
              height: `calc(70vh - ${props?.isConcierge} - 76px)`,
              "& *": {
                height: `calc(70vh - ${props?.isConcierge} - 76px)`
              }
            },
            "@media (max-height: 1000px)": {
              height: `calc(80vh - ${props?.isConcierge} - 76px)`,
              "& *": {
                height: `calc(80vh - ${props?.isConcierge} - 76px)`
              }
            },
            "@media (max-height: 900px)": {
              height: `calc(100vh - ${props?.isConcierge} - 76px)`,
              "& *": {
                height: `calc(100vh - ${props?.isConcierge} - 76px)`
              }
            }
          },
          "& #hero-image-v2-container-vertical  #hero-image-container": {
            height: `calc(100vh - ${props?.isConcierge} - 76px)`,
            "& *": {
              height: `calc(100vh - ${props?.isConcierge} - 76px)`
            },
            "@media (min-height: 1300px)": {
              height: `calc(80vh - ${props?.isConcierge} - 76px)`,
              "& *": {
                height: `calc(80vh - ${props?.isConcierge} - 76px)`
              }
            }
          }
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          padding: "0 40px 0 80px"
        },
        [theme.breakpoints.down(BREAKPOINT_MD)]: {
          width: "100%",
          position: "unset",
          top: "unset",
          "& #hero-image-v2-container": {
            width: "100%"
          },
          "& > #hero-image-v2-container #component-rh-image-pdp-v3::before": {
            paddingBottom: "unset"
          },

          "& > #hero-image-v2-container #component-rh-image_wrapper": {
            position: "unset"
          }
        },
        "& #hero-image-v2-container": {
          width: "100% !important",
          flex: 1,
          [theme.breakpoints.down(BREAKPOINT_MD)]: {
            flex: "unset",
            height: "40vh !important"
          }
        },
        "& #hero-image-v2-container #component-rh-carousel": {
          height: "36px",
          position: "absolute",
          border: "0",
          marginTop: "40px",
          gap: "45px",
          bottom: "-74px",
          display: "flex",
          alignItems: "center",
          flexFlow: "nowrap",
          [theme.breakpoints.down(BREAKPOINT_MD)]: {
            bottom: "-76px"
          },
          [theme.breakpoints.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
            marginTop: "36px"
          },
          [theme.breakpoints.between(BREAKPOINT_XS, BREAKPOINT_SM)]: {
            marginTop: "32px",
            bottom: "-68px"
          },
          [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
            gap: "51.2px"
          },
          "& #carousel-arrow-button": {
            height: "36px",
            width: "36px"
          }
        }
      },
      stickyPosition: {
        position: "sticky",
        top: 0
      },
      removeDotSpace: {
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          "& #rh-carousels .slick-list": {
            height: "100% !important"
          }
        }
      },
      stickyImageContainer: {
        position: "sticky",
        top: "0px",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flex: 1,
        maxWidth: "100%",
        "& #hero-image-v2-container": {
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            width: "555px"
          },
          [theme.breakpoints.up(BREAKPOINT_LG)]: {
            width: "733px"
          },
          [theme.breakpoints.up(BREAKPOINT_XL)]: {
            width: "1030px"
          }
        },
        "& > #hero-image-v2-container #component-rh-image::before": {
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            height: "401px",
            paddingBottom: "unset"
          },
          [theme.breakpoints.up(BREAKPOINT_LG)]: {
            height: "529px",
            paddingBottom: "unset"
          },
          [theme.breakpoints.up(BREAKPOINT_XL)]: {
            height: "742px",
            paddingBottom: "unset"
          }
        }
      },
      stickyImageContainerMobile: {
        display: "flex",
        justifyContent: "center",
        "& #hero-image-v2-container": {
          [theme.breakpoints.up(BREAKPOINT_XS)]: {
            width: "375px"
          },
          [theme.breakpoints.up(BREAKPOINT_SM)]: {
            width: "704px"
          }
        },
        "& #hero-image-v2-container #rhHeroImageZoomOut": {
          [theme.breakpoints.up(BREAKPOINT_XS)]: {
            height: "375px"
          },
          [theme.breakpoints.up(BREAKPOINT_SM)]: {
            height: "508px"
          }
        },
        "& > #hero-image-v2-container #component-rh-image::before": {
          [theme.breakpoints.up(BREAKPOINT_XS)]: {
            height: "375px",
            paddingBottom: "unset"
          },
          [theme.breakpoints.up(BREAKPOINT_SM)]: {
            height: "508px",
            paddingBottom: "unset"
          }
        }
      },

      imgContainerWithSwatch: {
        position: "fixed",
        width: "50%",
        height: "100vh",
        top: 0,
        zIndex: 1299,
        alignItems: "start",
        flexWrap: "nowrap",
        flexDirection: "column",
        backgroundColor: "#F9F7F4",
        [theme.breakpoints.only("sm")]: {
          width: "51%"
        }
      },
      viewCollection: {
        textTransform: "uppercase",
        textAlign: "center",
        fontSize: "11px",
        marginTop: "8px",
        marginBottom: "8px",
        fontStyle: "normal",
        fontWeight: 700,
        letterSpacing: "0.46px",
        textDecoration: "underline"
      },
      dotContainer: {
        display: "flex",
        gap: "5px",
        justifyContent: "center",
        marginTop: "5px"
      },
      dot: {
        width: "5.156px",
        height: "5.156px",
        borderRadius: "50%",
        border: "0.25px solid #99958F",
        backgroundColor: "#DEDAD4",
        cursor: "pointer"
      },
      activeDot: {
        width: "5.156px",
        height: "5.156px",
        borderRadius: "50%",
        border: "0.25px solid #99958F",
        backgroundColor: "#fff",
        cursor: "pointer"
      },
      inStockCTA: {
        // marginTop: "36px !important",
        marginBottom: "16px",
        textTransform: "uppercase",
        textDecoration: "underline",

        // [theme.breakpoints.up(BREAKPOINT_SM)]: {
        //   display: "flex",
        //   justifyContent: "left"
        // },
        // [theme?.breakpoints?.up(BREAKPOINT_MD)]: {
        //   display: "flex",
        //   justifyContent: "center"
        // },
        [theme?.breakpoints?.up(BREAKPOINT_XL)]: {
          width: "370px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_LG, BREAKPOINT_XL)]: {
          width: "347px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
          width: "317px"
        },
        // [theme?.breakpoints?.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
        //   width: "582px"
        // },
        [theme?.breakpoints?.down(BREAKPOINT_SM)]: {
          width: "342px"
        }
      },
      ctaGrid: {
        "& a": {
          textDecoration: "underline"
        },
        "& p": {
          textTransform: "uppercase",
          fontSize: theme.typography.caption.fontSize
        },
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          marginTop: "36px",
          marginBottom: "16px",
          textAlign: "center",
          display: "block"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          marginTop: "36px",
          marginBottom: "36px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridColumnGap: "30px",
          // placeItems: 'center',
          "& > *:only-child": {
            gridColumn: "1 / span 2",
            justifySelf: "center"
          }
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          marginTop: "36px",
          marginBottom: "36px",
          "&:not(.addToCart)": {
            textAlign: "left"
          },
          display: "block"
        }
      },
      ctaLeft: {
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          textAlign: "center"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          textAlign: "end"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          textAlign: "left"
        }
      },
      ctaRight: {
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          textAlign: "center"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          textAlign: "start"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          textAlign: "left"
        }
      },
      configureStarting: {
        color: "#000",
        fontFamily: "RHSans",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "13.2px",
        letterSpacing: "0.44px",
        textTransform: "uppercase",
        textDecoration: "underline",
        cursor: "pointer",
        textWrap: "nowrap",
        marginTop: "36px",
        [theme?.breakpoints?.down(BREAKPOINT_SM)]: {
          marginTop: "16px"
        },
        [theme?.breakpoints?.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
          marginTop: "unset",
          marginBottom: "58px"
        }
      },
      skuContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        margin: "40px 0px",
        maxWidth: "100%",
        minWidth: "100%",
        "& > #image-container-sku": {
          "& > div": {
            padding: "0px"
          },
          gap: "16px",
          margin: "0px"
        },
        "& *": {
          fontFamily: "RHSans-ExtraLight",
          color: "#000 !important",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: "200 !important",
          lineHeight: "120%",
          letterSpacing: "0.44px"
        },
        [theme?.breakpoints?.down(BREAKPOINT_MD)]: {
          textAlign: "center",
          "& *": {
            justifyContent: "center"
          }
        }
      },
      romanceCopyMainContainterPDPV3: {
        textAlign: "justify",
        fontFamily: "RHSans-ExtraLight",
        color: "#201F1F",
        fontWeight: 200,
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          minWidth: "309px !important",
          maxWidth: "309px !important",
          textAlign: "center !important",
          fontSize: "11px",
          lineHeight: "16.5px",
          letterSpacing: "0.17px",
          paddingBottom: "24px"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          minWidth: "539px !important",
          maxWidth: "539px !important",
          fontSize: "13px",
          lineHeight: "19.5px",
          letterSpacing: "0.2px",
          paddingBottom: "24px"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          minWidth: "555px !important",
          maxWidth: "555px !important",
          textAlign: "justify !important",
          marginTop: "116px",
          paddingBottom: "36px"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          minWidth: "539px !important",
          maxWidth: "539px !important",
          marginTop: "120px",
          paddingBottom: "40px"
        }
      },
      romanceCopyMainContainer: {
        paddingBottom: "40px",
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          minWidth: "347px !important",
          maxWidth: "347px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          minWidth: "704px !important",
          maxWidth: "704px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          minWidth: "319px !important",
          maxWidth: "319px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          minWidth: "347px !important",
          maxWidth: "347px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          minWidth: "539px !important",
          maxWidth: "539px !important"
        },
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          width: "343px",
          minWidth: "343px"
        },
        [theme?.breakpoints?.down(BREAKPOINT_MD)]: {
          display: "flex",
          justifyContent: "center"
        }
      },
      surchargeLink: {
        textDecoration: "underline"
      },
      collectionTitle: {
        height: "28px",
        alignSelf: "stretch",
        flexGrow: 0,
        fontFamily: "RHC-Thin",
        fontSize: "28px",
        fontWeight: 100,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#000000"
      },
      shopLink: {
        flexGrow: 0,
        fontFamily: "RHSans-Roman",
        fontSize: "11px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.1,
        letterSpacing: "0.44px",
        textAlign: "center",
        color: "#000000",
        textDecoration: "underline",
        textTransform: "capitalize",
        margin: "12px 0px 0px 0px"
      },
      collectionContainer: {
        [theme.breakpoints.up("xs")]: {
          width: "100%"
        },
        [theme.breakpoints.up("sm")]: {
          width: "704px"
        },
        [theme.breakpoints.up("md")]: {
          width: "911px"
        },
        [theme.breakpoints.up("lg")]: {
          width: "1121px"
        },
        [theme.breakpoints.up("xl")]: {
          width: "1439px"
        }
      },
      leftSideRomanceCopyContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
      },
      detailDimensionContainer: {
        display: "flex",
        gap: "16px",
        paddingBottom: "16px",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          paddingBottom: "0px"
        }
      },
      detailDimensionButton: {
        display: "flex",
        minWidth: "191.72px",
        minHeight: "36px",
        height: "36px",
        flexDirection: "column",
        justifyContent: "center",
        color: "var(--ReimagineWarm-RH-Black, #000)",
        textAlign: "center",
        fontFamily: "RHSans",
        fontSize: "13px",
        fontWeight: 300,
        lineHeight: "100%" /* 13px */,
        letterSpacing: "0.26px",
        border: "0.5px solid #000",
        background: "transparent",
        textTransform: "capitalize",
        padding: "0px 0.292px 0px 0.987px",
        [theme.breakpoints.down("md")]: {
          minWidth: "166.38px",
          padding: "0px 0.254px 0px 0.857px"
        }
      }
    })
  );

export default useStyles;
