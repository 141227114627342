import React, { FC, useCallback, useState } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  MenuItem,
  Select
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { ITEMS_PER_PAGE_PREFERENCE } from "utils/constants";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import memoize from "utils/memoize";
import useParams from "hooks/useParams";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { processEnvServer as isServer } from "hooks/useSsrHooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectInput: {
      padding: "6px 2px 4px 0px !important",
      "&:focus": {
        backgroundColor: "transparent"
      }
    }
  })
);

export interface ItemsPerPageProps {
  recsPerPage: number;
  lastRecNum: number;
  totalNumRecs: number;
  loadMoreData: (resultCount: number, start?: number) => void;
  pageContent: any;
  ItemsPerPageOptions: [number];
}

const ItemsPerPage: FC<ItemsPerPageProps> = ({
  recsPerPage,
  lastRecNum,
  totalNumRecs,
  loadMoreData,
  pageContent,
  ItemsPerPageOptions
}) => {
  const env = useEnv();
  const isPaginationFeatureEnabled = yn(env.FEATURE_PAGINATION);
  const isPaginationLoadAllFeatureEnabled = yn(env.FEATURE_PAGINATION_LOAD_ALL);
  const isPaginationTuningFeatureEnabled = yn(env.FEATURE_PAGINATION_TUNING);
  const isPaginationLoadFullPageEnabled =
    yn(env.FEATURE_PAGINATION_LOAD_FULL_PAGE) &&
    isPaginationFeatureEnabled &&
    isPaginationTuningFeatureEnabled;

  const [selectOpen, setSelectOpen] = useState(false);
  const classes = useStyles();
  const { search } = useLocation();

  const history = useHistory();

  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(ITEMS_PER_PAGE_PREFERENCE)
    : undefined;

  const params = useParams({
    start: "0",
    count: storedItemsPerPagePreference ?? "48"
  });

  const changeRecsPerPage = useCallback(
    event => {
      const nrpp = event.target.value;
      const searchParams = new URLSearchParams(search);
      let start = +params.start + +params.count;
      if (nrpp === "load-all") {
        searchParams.set("loadAll", "true");
        searchParams.delete("start");
        searchParams.set(
          "count",
          String(
            isPaginationLoadFullPageEnabled
              ? 102
              : totalNumRecs ||
                  (storedItemsPerPagePreference
                    ? +storedItemsPerPagePreference
                    : undefined) ||
                  48
          )
        );
        loadMoreData(totalNumRecs, 0);
      } else {
        if (yn(searchParams.has("loadAll"))) {
          searchParams.delete("loadAll");
          searchParams.delete("start");
        } else {
          if (+params.count > +nrpp) {
            searchParams.set(
              "start",
              start < (totalNumRecs || 0)
                ? ((lastRecNum || 0) + 1 || "").toString()
                : "0"
            );
          } else {
            loadMoreData(nrpp);
          }
        }
        localStorage.setItem(ITEMS_PER_PAGE_PREFERENCE, nrpp);
        searchParams.set("count", nrpp);
      }
      const updatedSearch = searchParams.toString();
      history.replace({
        search: updatedSearch
      });
      const isLoadAll = nrpp === "load-all";
      const target = event.target as HTMLElement;
      const id = (target?.parentNode as HTMLElement)?.id;
      document?.body?.dispatchEvent(
        new CustomEvent("cta_click", {
          detail: {
            item: {
              isPaginationClick: true,
              pageNo: `${nrpp} ${isLoadAll ? "" : "per "}page`,
              pageType: "items per pages click",
              class: target?.className,
              id: target?.id || id
            }
          }
        })
      );
      setTimeout(() => {
        window?.scrollTo(0, 0);
      }, 100);
    },
    [search, history]
  );

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption", "rhBaseBody1", "rhBaseBody2"]
  });

  return (
    <div
      className="flex-none flex items-center justify-center"
      data-testid="itemsPerPage-test"
    >
      <div className="flex">
        <Typography
          className={classNames([
            typographyStyles.rhBaseBody1,
            "cursor-pointer !flex items-center select-none !mr-7 sm:!mr-1 md:!mr-0",
            "!font-primary-rhlight !text-[11px] !leading-[110%] tracking-[0.04em]"
          ])}
          onClick={() => setSelectOpen(true)}
        >
          {pageContent?.items_per_page}
        </Typography>

        <Typography
          className={classNames([
            typographyStyles.rhBaseBody2,
            "cursor-pointer !flex items-center select-none !mr-7 sm:!mr-1 md:!mr-0",
            "!font-primary-rhlight !text-[11px]"
          ])}
        >
          <Select
            id="itemsPerPage"
            value={
              !ItemsPerPageOptions.includes(+params.count)
                ? "load-all"
                : recsPerPage
            }
            open={selectOpen}
            onClose={() => setSelectOpen(false)}
            onOpen={() => setSelectOpen(true)}
            onChange={e => changeRecsPerPage(e)}
            className={"!bg-transparent !pb-0 pl-0 ml-[8px]"}
            inputProps={{
              className: `!font-primary-rhlight !text-[11px] ${classes.selectInput}`
            }}
            IconComponent={() => (
              <KeyboardArrowDown
                onClick={() => setSelectOpen(true)}
                style={{ fontSize: 11, marginLeft: 3, cursor: "pointer" }}
              />
            )}
          >
            {ItemsPerPageOptions.map((item: number) => (
              <MenuItem className={"!text-[14px]"} value={item}>
                {item}
              </MenuItem>
            ))}

            {isPaginationLoadAllFeatureEnabled && (
              <MenuItem className={"!text-[14px]"} value={"load-all"}>
                {pageContent?.LOAD_ALL}
              </MenuItem>
            )}
          </Select>
        </Typography>
      </div>
    </div>
  );
};

export default memoize(ItemsPerPage);
