import React, {
  FC,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext
} from "react";
import {
  Typography,
  Grid,
  GridList,
  GridListTile,
  useTheme,
  Divider,
  useMediaQuery,
  Theme,
  Container,
  GridSpacing,
  Link,
  ButtonBase
} from "utils/material-ui-core";
import useStyles from "./useStyles";
import { Hidden } from "hooks/useSsrHooks";
import Sku from "component-sku";
import { Redirect } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import { RHLink } from "component-rh-link";
import {
  querGetSku,
  queryLineItemSku,
  queryProductV2
} from "graphql-client-product";
import { queryRideAlongProduct } from "graphql-client/queries/base-product";
import { queryFullSkuProduct } from "graphql-client/queries/full-sku-product";
import { queryProductSwatchImage } from "graphql-client/queries/product-swatch-image";
import useState from "hooks/useState";
import { useApolloClient, useQuery } from "@apollo/client";
import { useIsoCookies } from "hooks/useIsoCookies";
import { queryDraperyLink } from "graphql-client/queries/drapery-link";
import useAppData from "hooks/useAppData";
import StructuredData from "structured-data";
import useUnsafeEffect from "hooks/useUnsafeEffect";
import { SaleContextFilter } from "graphql-client/queries/app";
import InternationalPostalCodeDialog from "dialog-international-postal-code";
import PostalCodeDialog from "dialog-postal-code";
import RelatedProductCardSkeleton from "skeleton-related-product-card";
import useResponsiveGridSpacing from "hooks/useResponsiveGridSpacing";
import BaseProductSkeleton from "skeleton-base-product";
import analyticsLoader from "analytics/loader";
import {
  DEFAULT_UPSELL_COMPONENT_TITLE,
  PAGE_BG_GREY,
  PG_SEARCH_TERM,
  POSTAL_CODE_COOKIE,
  MONOGRAM_ORDER_DEFAULT,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  RHUK_HOST,
  NA_COUNTRIES
} from "utils/constants";
import useBrand from "hooks-use-brand/useBrand";
import { InView } from "react-intersection-observer";
import HeroImageV2 from "component-hero-image-v2";
import HTMLHeadTags from "component-html-head-tags";
import memoize from "utils/memoize";
import Slider from "component-slider";
import { processEnvServer } from "hooks/useSsrHooks";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { queryGiftCardTerms } from "graphql-client/queries/gift-card-terms";
import useDidMountEffect from "hooks/useDidMountEffect";
import useParams, { useParams2 } from "hooks/useParams";
import { isEmpty } from "lodash";
import AddAllProductActions from "component-add-all-product-actions";
import { CustomProductMapper } from "component-custom-product";
import { MonogramOrderProps } from "page-monogram-dialog";
import { countryLocaleMapper } from "resources/countries-config.json";
import PrintSection from "component-print-section";
import getCountryFromUrl from "utils/getCountryFromUrl";
import useMeasurementType from "hooks/useMeasurementType";
import useUserPreferences from "hooks/useUserPreferences";
import useTypographyStyles from "hooks/useTypographyStyles";
import { useChangeOnce } from "hooks/useChangeOnce";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import { useLocalization } from "hooks/useLocalization";
import classNames from "classnames";
import useSite from "hooks/useSite";
import { addPgTermFallback } from "utils/pgTerm";
import ReadMore from "utils/ReadMore";

import RHDivider from "component-rh-divider";
import InStockProductsDialog from "dialog-instock-products";
import SwatchPanelDialogV2 from "dialog-swatch-panel-v2";
import RelatedProductCard from "component-related-product-card";

import ProductCard from "component-product-card";
import RHDialog from "component-rh-dialog";
import {
  queryUpsSellProducts,
  queryLineItemLiteV3
} from "graphql-client-product";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import { useFetchModel } from "hooks/useFetchModel";
import { queryRelatedProducts } from "graphql-client/queries/related-product";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { isGTMLoaded } from "utils/analytics/utils";
import { v4 as uuidv4 } from "uuid";
import RelatedProductActionsV2 from "component-related-product-actions-v2";
import AddToCartDialog from "dialog-add-to-cart";
import AddToWishlistDialog from "dialog-add-to-wishlist";
import AddToGiftRegistryDialog from "dialog-add-to-gift-registry";
import {
  CartProductPrice,
  ProductPrice,
  SwatchPanelProductPrice
} from "./productTitle";
import _ from "lodash";
import { queryMultiSkuComponentsV2 } from "graphql-client-product";
import { multiSkuPriceMapper } from "component-price-range-display/utils/convertFusionPriceToRange";
import { useCountry } from "hooks/useCountry";
import { Configurator } from "@RHCommerceDev/component-configurator";

import { useProductImage } from "hooks/useProductImage";
import {
  AddOnDataType,
  AddOnConfigModes
} from "@RHCommerceDev/component-addon-checkbox-v2";
// import { addOnDataType } from "@RHCommerceDev/component-addon-panel-v2";
import { AddOnRenderButtonOptionInfo } from "@RHCommerceDev/component-addon-checkbox-v2";
import { useQuantity } from "hooks-use-quantity";
import useLocale from "hooks-use-locale/useLocale";
import {
  filterProductConfigurationOptionDetails,
  getAllOptionTypes,
  getSelectedOptionLength
} from "@RHCommerceDev/build-lineitem-available-options";

import { ProductConfiguratorContext } from "@RHCommerceDev/custom-providers/ProductConfiguratorProvider";
import { useAppId } from "hooks/useAppId";
import { usePrintHijack } from "printable-pages/usePrintHijack";
import { objectToQueryString } from "utils/objectToQueryString";
import PrintFrame from "printable-pages/PrintFrame";
import { SHIPPING_SURCHARGE } from "resources/page-level-resources-schemas/products.json";
import SurchargeInfoDialog from "dialog-surcharge-info";
import { getCookie } from "utils/cookies";
import { cleanMonogramOrder } from "utils/monogramUtils";
import { useRhUserAtomValue } from "hooks/atoms";
import { useKeycloak } from "utils/Keycloak/KeyCloak";
import { useUserSessionAtomValue } from "hooks/atoms";
import ProductDetailsDialog from "dialog-product-details";
import ProductDimensionsDialog from "dialog-product-dimensions";
import { useFetchParams } from "hooks/useFetchParams/useFetchParams";
import { MultiSkuComponentWithRestrictions } from "components/RelatedProductCard/types";
import { getReqContext } from "utils/reqContext";

const DEFAULT_MAX_QUANTITY = 40;
const IS_VALID_OPTIONS_TYPE = ["Color", "Finish", "Lining Color", "Countertop"];

export interface AddAllItemToCart {
  productId: string;
  sku: string;
  quantity: number;
  brand: string;
  isMainItem?: boolean;
  giftTo?: string;
  giftFrom?: string;
  giftMessage?: string;
  spoTermsAccepted?: boolean;
  spoTerms?: string;
  monogram?: MonogramOrderProps;
  customInfo?: CustomProductMapper | SkuCustomizationType;
  lineItemIndex?: number;
  displayName?: string;
  name?: string;
  imageUrl?: string;
  spo?: boolean;
  preBillMessage?: string;
  options?: (ProductAvailableOption | CustomOption)[];
  salePriceLabel?: string;
  pricing?: ProductSkuPriceInfo;
}

interface CustomOptions {
  diameter: {
    feet: string;
    inches: string;
  };
  width: {
    feet: string;
    inches: string;
  };
  length: {
    feet: string;
    inches: string;
  };
}

export interface PDPV2Props {
  data: Product;
  upSellProducts: UpsellProducts;
  fullSkuId?: string;
  draperyLink?: string;
  isProductLoading?: boolean;
  authenticated: boolean;
  saleContextFilter?: SaleContextFilter;
  postalCode?: string;
  customOptions?: CustomOptions;
  setCustomOptions?: React.Dispatch<React.SetStateAction<CustomOptions>>;
  onOptionsChange?: (op: string[]) => void;
  onTrimOptionsChange?: (op: string[]) => void;
  openGiftCardTerms: boolean;
  setOpenGiftCardTerms: (open: boolean) => void;
  giftCardTerms: string;
  productSwatchLoading?: boolean;
  rideAlongData?: RideAlongData;
  setRideAlongData?: (RideAlongData) => void;
  rideAlongProduct?: Product | null;
  rProducts?: Product[];
  onChange?: (selectedOptions: ProductAvailableOption[], qty: number) => void;
  selectedSwatches?: {
    [groupMaterial: string]: ProductSwatch;
  };
  lineItem?: any;
  isLineItemQueryLoading?: boolean;
  handleConfigPrice?: (isConfigured: boolean) => void;
  multiSkuComponentsDataV2?: any;
  addOnsData?: AddOnDataType[];
  panelAddOnsData?: AddOnDataType[];
  setPanelAddOnsData?: React.Dispatch<React.SetStateAction<AddOnDataType[]>>;
  addOnOptionInfoToRenderButtons?: AddOnRenderButtonOptionInfo;
  onAddOnConfigChange?: (
    addOnProduct: AddOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => void;
  // panelProdConfigurationData?: Maybe<addOnDataType>;
  currencyCode?: string;
  handleNewPDPLineItemCall?: (id, options, qty) => void;
  skuAvailabilityStatus?: SkuAvailabilityStatusType;
  availableOptions?: Maybe<ProductAvailableOptionSet[]>;
  setAvailableOptions?: React.Dispatch<
    React.SetStateAction<ProductAvailableOptionSet[]>
  >;
  availableSwatch?: Maybe<ProductSwatchV2>;
  setAvailableSwatch?: React.Dispatch<React.SetStateAction<ProductSwatchV2>>;
  lazyQueryMultiSkuComponents?: (id, options, qty) => void;
}

export interface StyledTabProps {
  label: string;
}

export interface progressiveDropdownProps {
  type: string;
  status: boolean;
  priority: number | string;
}

export interface swatchPanelDialogProps {
  open: false;
  type: "";
}

/*
  RideAlongData:
    casingProduct: product to be shown instead of replacementCushionProduct
    replacementCushionProduct: to be replaced by casingProduct
    selectedOptionIds: selected options on triggeredProduct
    triggeredProduct: product where hasCasingSkus === true
*/
export interface RideAlongData {
  casingProduct: string | null;
  replacementCushionProduct: string | null;
  selectedSizeId: string | null;
  triggerProductId: string | null;
}

export const defaultRideAlongData: RideAlongData = {
  triggerProductId: null,
  casingProduct: null,
  replacementCushionProduct: null,
  selectedSizeId: null
};
let swatchToggle = 0;
const getVariableData = (
  component,
  isMainItem?: boolean,
  monogramOrder?: MonogramOrderProps,
  customInfo?: SkuCustomizationInput,
  qty?: number
) => {
  return {
    productId: component?.productId,
    sku: component?.fullSkuId,
    isMainItem: isMainItem ?? false,
    fusionId: component?.fullSkuId,
    name: component?.name ?? component?.info?.name,
    imageUrl: component?.imageUrl ?? component?.info?.name,
    multiSkuId: component?.multiSkuId,
    quantity: component?.quantity ? qty * component?.quantity : qty,
    brand: component?.brand,
    giftTo: component?.giftCardTo ?? "",
    giftFrom: component?.giftCardFrom ?? "",
    giftMessage: "",
    spoTermsAccepted: component?.spoAgreed,
    spoTerms: component?.spoAgreed ? component?.preBillMessage : null,
    ...(monogramOrder?.fontCode ||
    monogramOrder?.fontColorCode ||
    monogramOrder?.borderCode
      ? { monogram: cleanMonogramOrder(monogramOrder) }
      : null),
    ...(!isEmpty(customInfo) ? { customInfo } : null)
  };
};
export const PDPV2: FC<PDPV2Props> = memoize(
  ({
    data,
    upSellProducts,
    fullSkuId,
    isProductLoading,
    authenticated,
    saleContextFilter,
    postalCode,
    customOptions,
    onOptionsChange,
    onTrimOptionsChange,
    setOpenGiftCardTerms,
    currencyCode,
    rideAlongData,
    setRideAlongData,
    rideAlongProduct,
    rProducts,
    onChange,
    lineItem,
    isLineItemQueryLoading = false,
    handleConfigPrice,
    multiSkuComponentsDataV2,
    addOnsData,
    panelAddOnsData,
    setPanelAddOnsData,
    addOnOptionInfoToRenderButtons,
    onAddOnConfigChange,
    // panelProdConfigurationData,
    handleNewPDPLineItemCall,
    skuAvailabilityStatus,
    availableOptions,
    setAvailableOptions,
    availableSwatch,
    setAvailableSwatch,
    lazyQueryMultiSkuComponents
  }) => {
    const baseId = "product-v2";
    const { pageContent } = useFetchModel("/admin/product", true, false);
    const history = useHistory();
    const theme = useTheme();
    const env = useEnv();
    const brand = useSite();
    const client = useApolloClient();
    const siteId = useSite();
    const { isConcierge } = useAppId();
    const { rhUser } = useUserSessionAtomValue();

    const [profileType, setProfileType] = useState("ANONYMOUS");

    const params = useParams({
      productId: "",
      fullSkuId: ""
    });

    //for canonical URL support
    const _params = useFetchParams(
      location.pathname,
      yn(env.FEATURE_URL_CHANGE) ? "pdp" : "product.jsp"
    );
    let [productId] = _params;
    if (params.productId === "") {
      params.productId = productId?.replace("&ct=true", "");
    }

    const { params: queryParams } = useParams2<{ [key: string]: string }>(
      { version: "" },
      { toLowerCase: true }
    );
    let { setNewPDPLayout } = useContext(ProductConfiguratorContext);

    const [skuDetails, setSkuDetails] = useState<any | null>({
      fullSkuId: null,
      components: []
    });
    const urlCountry = getCountryFromUrl();
    const internationalFlag = yn(env.FEATURE_INTERNATIONAL);
    const isMultiSkuPhase4 = yn(env.FEATURE_MULTISKU_PHASE_4);
    const isMultiSkuPhase4NA = yn(env.FEATURE_MULTISKU_PHASE_4_NA);
    const isNorthAmerica = NA_COUNTRIES.includes(urlCountry);
    const measureSystem = useMeasurementType();
    const isNewPDPLayout = queryParams?.version === "v2" || data?.newPdpLayout;
    const PDP_DEFAULT_QTY = Number(env.FEATURE_PDP_DEFAULT_QUANTITY);
    const isSSRToggledWithCSR = isSSRToggledWithClientRender();
    const longDescriptionRef = useRef<HTMLDivElement>(null);
    const [productCardContentHeight, updateProductCardContentHeight] =
      useState(0);
    const productCardContentRef = (ref: HTMLDivElement) => {
      if (!ref) return;

      updateProductCardContentHeight(ref?.offsetHeight);
    };

    const isNewPdpLayout = useMemo(() => {
      return Boolean(queryParams?.version === "v2" || data?.newPdpLayout);
    }, [data?.newPdpLayout, queryParams?.version]);

    const [qty, setQty] = useState(
      env.FEATURE_PDP_DEFAULT_QUANTITY ? PDP_DEFAULT_QTY : 1
    );

    const [selectedOptionLabel, setSelectedOptionLabel] = useState(false);
    const analyticsModalStates = useRef({
      dimensionModal: false,
      detailsModal: false
    });
    const classes = useStyles({
      isNewPdpLayout: isNewPdpLayout,
      isConcierge: isConcierge ? "241px" : "180px"
    })();
    const [activeDot, setActiveDot] = useState<number>(0);

    const [selectedRugTrimSwatchId, setSelectedRugTrimSwatchId] = useState<
      string | undefined
    >(""); //only for custom rugs
    const [selectedRugTrimSwatchIds, setSelectedRugTrimSwatchIds] = useState<
      string[] | null
    >(null); //only for custom rugs
    const [filmUrl, setFilmUrl] = useState<string | null>(null);
    const typographyStyles = useTypographyStyles({
      keys: [
        "rhBaseH1",
        "rhBaseH3",
        "rhBaseBody1",
        "rhBaseCaption",
        "uppercaseText",
        "rhBaseBody2"
      ]
    });

    const [
      selectedAddOnsNotFullyConfigured,
      setSelectedAddOnsNotFullyConfigured
    ] = useState<boolean>(false);
    const [addonDataAddToProject, setAddonDataAddToProject] = useState<any>([]);
    const [addOnCartData, setAddOnCartData] = useState<
      Array<AddOnDataType & ProductAddonsInfo>
    >([]);

    const prefix = useLocalization();
    const isLoadedGTM = isGTMLoaded();
    const { setStorageValueWrapper } = useCookiesWithPermission();

    const country = getCountryFromUrl();

    const metaSkuPricingV2 = useMemo(() => {
      return !isEmpty(multiSkuComponentsDataV2)
        ? multiSkuPriceMapper(multiSkuComponentsDataV2, country!)
        : null;
    }, [multiSkuComponentsDataV2, country]);

    const multiSkuInventoryData = useMemo(() => {
      return !isEmpty(multiSkuComponentsDataV2)
        ? multiSkuComponentsDataV2?.skuInventory
        : null;
    }, [multiSkuComponentsDataV2, country]);

    useEffect(() => {
      if (!isConcierge && data?.conciergeOnlyForNewPDP) {
        setNewPDPLayout(false);
      } else {
        setNewPDPLayout(queryParams?.version === "v2" || data?.newPdpLayout);
      }
    }, [data]);

    //Analytics fix - removing data.emptyProduct object to stop interfering with analytics events
    useEffect(() => {
      if (
        !processEnvServer &&
        data?.personalizeInfo &&
        rhUser?.akamaiCountryCode &&
        isLoadedGTM?.isGTMLoaded
      ) {
        setStorageValueWrapper({
          storageKey: "analytics-product-data",
          value: JSON.stringify(data)
        });

        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.GA4_VIEW_PAGE_PRODUCT.INT_TYPE,
            {
              set: {
                name: "analytics-emptyProduct",
                value: JSON.stringify(data.emptyProduct)
              },
              monogrammable: data?.personalizeInfo?.monogrammable,
              personalizable: data?.personalizeInfo?.personalizable
            }
          )
        );
      }
    }, [data?.personalizeInfo, data?.emptyProduct, isLoadedGTM?.isGTMLoaded]);

    useEffect(() => {
      const anchorElements = Array.from(
        longDescriptionRef?.current?.querySelectorAll("a") ?? []
      );

      anchorElements.map(anchorElement => {
        const href = anchorElement.getAttribute("href");
        const hasPgTerm = href.includes(PG_SEARCH_TERM);
        const hasCategoryId = href.includes("categoryId");
        const sanitizedHref = addPgTermFallback(href);
        href.includes("/catalog/category/products.jsp") &&
          !hasPgTerm &&
          hasCategoryId &&
          anchorElement.setAttribute("href", `${prefix}${sanitizedHref}`);
        anchorElement.addEventListener("click", handleClick);
        return anchorElement;
      });

      return () => {
        anchorElements.map(anchorElement =>
          anchorElement.removeEventListener("click", handleClick)
        );
      };

      function handleClick(event: MouseEvent) {
        const href = (event.target as HTMLAnchorElement)?.getAttribute("href");

        if (href?.includes("images")) {
          (event.target as HTMLAnchorElement)?.setAttribute("target", "_blank");
        } else {
          event.preventDefault();
          history.push(href);
        }
      }
      // TODO: Fix eslint error and remove this comment block
      // React Hook useEffect has a missing dependency: 'data.emptyProduct'.
      // Either include it or remove the dependency array  react-hooks/exhaustive-deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.longDescription, history]);

    const [swatchPanelDialog, , setSwatchPanelDialog] =
      useState<swatchPanelDialogProps>({
        open: false,
        type: "" // added this type in order to achieve progressive drop-down effect
      });

    const [stockProductsDialog, , setStockProductsDialog] = useState({
      open: false
    });

    const isAddonFlag = data?.productAddons?.productAddonsInfo?.length;

    useEffect(() => {
      if (!processEnvServer && swatchPanelDialog["open"]) {
        document.body.dispatchEvent(
          new CustomEvent("pre-pdp-click", {
            detail: {
              item: {
                action: "Swatch collection expand"
              }
            }
          })
        );
      }
    }, [swatchPanelDialog["open"]]);

    let [selectedSwatches, setSelectedSwatchesValue] = useState<{
      [key: string]: ProductSwatch;
    }>({});
    // const locale = countryLocaleMapper[getCountryFromUrl()] || "en-us";
    const locale = useLocale();

    const [chosenLineItemOptionId, setChosenLineItemOptionId] = useState([]);
    const [chosenLineItemOptions, setChosenLineItemOptions] = useState({});

    const [
      chosenLineItemUnavailableOptionId,
      setChosenLineItemUnavailableOptionId
    ] = useState([]);
    const [
      chosenLineItemUnavailableOptions,
      setChosenLineItemUnavailableOptions
    ] = useState({});

    useDidMountEffect(() => {
      onChange?.(Object.values(chosenLineItemOptions || {}), 1);
    }, [postalCode]);

    useEffect(() => {
      setChosenLineItemOptionId([]);
      setChosenLineItemOptions({});
      setChosenLineItemUnavailableOptionId([]);
      setChosenLineItemUnavailableOptions({});
    }, [data.productConfiguration, currencyCode]);

    const [selectedHeroImageSwatchUrl, setSelectedHeroImageSwatchUrl] =
      useState("");
    const [rugTrim, setRugTrim] = useState<string>("");
    const [isColorizable, setIsColorizable] = useState(true);
    const [openInStockOnSaleModal, setOpenInStockOnSaleModal] = useState(false);
    const [imageUrlFromChild, setImageUrl] = useState("");
    const [isAllCustomOptionSelected, setIsAllCustomOptionSelect] =
      useState(false);
    const [isCustomOptionSelectionValid, setIsCustomOptionSelectionValid] =
      useState(false);
    const onChangeSwatchImageFromLineItem = (imgUrl: string) => {
      setImageUrl(imgUrl);
    };

    const [monogramOrder, setMonogramOrder] =
      React.useState<MonogramOrderProps>(MONOGRAM_ORDER_DEFAULT);
    const monogrammable = data?.personalizeInfo?.monogrammable;
    const [multiSkuComponentsDeliveryInfo, setMultiSkuComponentsDeliveryInfo] =
      useState<ProductDeliveryInfo>({});
    const [postalCodeDialog, setPostalCodeDialog] = React.useState(false);

    const [selectedOptions, setSelectedOptions] = useState<
      ProductAvailableOption[]
    >([]);
    const [isQtyChanged, setIsQtyChanged] = useState(false);
    const [cartDialog, setCartDialog] = React.useState(false);
    const [wishlistDialog, setWishlistDialog] = React.useState(false);
    const [giftRegistryDialog, setGiftRegistryDialog] = React.useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfiguredPrice, setIsConfiguredPrice] = useState(false);
    const [isColorized, setIsColorized] = useState(false);
    const [addToCartMainItemDetails, setAddToCartMainItemDetails] =
      useState<addToCartItemDetailsProps>({});

    const [maxUpsellHeight, setMaxUpSetHeight] = useState<number[]>([]);

    const template =
      (
        {
          standard: "inline",
          "standard-rugs": "inline",
          "two-color": "panel",
          "single-color": "panel",
          outdoor: "split-panel",
          "single-color-split": "split-panel"
        } as { [layout: string]: "inline" | "panel" | "split-panel" }
      )[data.layout] || "panel";

    const swatchGroups = [
      ...(data.swatchData?.finishSwatchGroups ?? []),
      ...(template === "inline" ? data.swatchData?.swatchGroups ?? [] : [])
    ];

    const stockedSwatchesLength =
      data.swatchData?.swatchGroups?.reduce(
        (acc, cur) => acc + cur.stockedSwatches.length,
        0
      ) ?? 0;
    const customSwatchesLength =
      data.swatchData?.swatchGroups?.reduce(
        (acc, cur) => acc + (cur.customSwatches?.length ?? 0),
        0
      ) ?? 0;
    const groupMaterial =
      data.swatchData?.swatchGroups?.reduce(
        (acc, cur) => acc || cur.groupMaterial,
        ""
      ) ?? "";

    const [isSwatchSelected, setIsSwatchSelected] = useState(false);

    // consolidated selectSwatchValue calls
    const handleSelectSwatchesValue = (group: any, swatch: any) => {
      setSelectedSwatchesValue(prev => ({
        ...prev,
        [group]: swatch
      }));

      if (processEnvServer) {
        selectedSwatches = { ...selectedSwatches, [group]: swatch };
      }
    };
    useEffect(() => {
      setSelectedHeroImageSwatchUrl(imageUrlFromChild); //setting inside useEffect to activate image change from state
    }, [imageUrlFromChild]);

    useEffect(() => {
      const defaultFinish =
        data && data?.swatchBean != null
          ? data?.swatchBean?.primaryOptionId
          : "";

      const options: any = Object.keys(selectedSwatches)
        .map(key => {
          const allOptions = selectedSwatches[key].options.map(option => {
            return option;
          });
          return allOptions;
        })
        .flat();
      if (
        defaultFinish &&
        Object.keys(selectedSwatches).length > 0 &&
        !Object.keys(selectedSwatches).includes("Finish")
      ) {
        options.push({ id: defaultFinish });
      }
      onOptionsChange?.(options.map(e => e.id));
      const label = options.map(e => e.label);
      setSelectedOptionLabel(!!label[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onOptionsChange, selectedSwatches, data]);

    useEffect(
      () => onTrimOptionsChange?.(selectedRugTrimSwatchIds),
      [selectedRugTrimSwatchIds]
    );

    function updateSwatchGroups() {
      swatchGroups.forEach(swatchGroup => {
        swatchGroup?.customSwatches?.forEach(customSwatch => {
          if (data.fullSkuSwatchIds?.includes(customSwatch.swatchId)) {
            handleSelectSwatchesValue(swatchGroup.groupMaterial, customSwatch);
          }
        });

        swatchGroup?.stockedSwatches?.forEach(stockedSwatch => {
          if (data.fullSkuSwatchIds?.includes(stockedSwatch.swatchId)) {
            handleSelectSwatchesValue(swatchGroup.groupMaterial, stockedSwatch);
          }
        });
      });
    }

    if (swatchToggle == 1 && isSSRToggledWithCSR) {
      updateSwatchGroups();
      swatchToggle += 1;
    }

    useUnsafeEffect(() => {
      if (isSSRToggledWithCSR) {
        return;
      }
      swatchGroups.forEach(swatchGroup => {
        swatchGroup?.customSwatches?.forEach(customSwatch => {
          if (data.fullSkuSwatchIds?.includes(customSwatch.swatchId)) {
            handleSelectSwatchesValue(swatchGroup.groupMaterial, customSwatch);
          }
        });

        swatchGroup?.stockedSwatches?.forEach(stockedSwatch => {
          if (data.fullSkuSwatchIds?.includes(stockedSwatch.swatchId)) {
            handleSelectSwatchesValue(swatchGroup.groupMaterial, stockedSwatch);
          }
        });
      });
    }, [data.fullSkuSwatchIds, data.swatchData]);

    const relatedProducts: Product[] = useMemo(() => {
      const isMultiSkuProduct =
        data.metaProduct && data.nextGenDriven === "true";
      const shouldShowMainProd =
        (!data.emptyProduct || isMultiSkuProduct) && data.productLineItem;

      const relatedProductsFromData: Product[] = [
        ...(shouldShowMainProd ? [data] : []),
        ...(rProducts ?? [])
      ];

      if (
        rideAlongData?.replacementCushionProduct &&
        rideAlongData?.casingProduct &&
        rideAlongProduct
      ) {
        return relatedProductsFromData.map(product =>
          product.id === rideAlongData?.replacementCushionProduct
            ? rideAlongProduct
            : product
        );
      }

      return relatedProductsFromData;
    }, [data, rideAlongData, rideAlongProduct, rProducts]);

    const [pli, setPlit] = useState<any>(relatedProducts[0]?.productLineItem);

    useEffect(() => {
      setPlit(relatedProducts[0]?.productLineItem);
      if (relatedProducts[0]?.productLineItem?.image?.productId !== data.id) {
        setPlit({});
      }
    }, [data, rideAlongData, rideAlongProduct, rProducts]);

    const productSkuData = useMemo(
      () => (lineItem?.sku ? lineItem?.sku : pli?.sku),
      [pli, lineItem]
    );

    const productLineItem = useMemo(
      () => (lineItem ? lineItem : pli),
      [pli, lineItem]
    );

    const addOnsExist = useMemo(
      () => !!data?.productAddons?.productAddonsInfo?.length,
      [data?.productAddons]
    );

    // const [panelProdData, setPanelProdData] =
    //   useState<Maybe<ProductAddonsInfo>>(null);

    const [panelProds, setPanelProds] = useState<Array<ProductAddonsInfo>>([]);

    useEffect(() => {
      let _data = [];
      if (isNewPDPLayout) {
        availableOptions?.map(d => {
          let _selectedOptions = d.options.find(
            option => option.status === "selected"
          );
          if (_selectedOptions) _data.push(_selectedOptions);
        });
      } else {
        pli?.availableOptions?.map(d => {
          let _selectedOptions = d.options.find(
            option => option.status === "selected"
          );
          if (_selectedOptions) _data.push(_selectedOptions);
        });
      }
      if (!_data?.length && Object.keys(selectedSwatches)) {
        let finishOption = [];
        Object.values(selectedSwatches).map(item => {
          item?.options?.map(option => {
            finishOption.push(option);
          });
        });
        setSelectedOptions(finishOption);
      } else {
        setSelectedOptions(_data);
      }
    }, [pli?.availableOptions, availableOptions]);

    const gridSpacing = useResponsiveGridSpacing();
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

    const width_BREAKPOINT_LG_XL = useMediaQuery<Theme>(theme =>
      theme.breakpoints.between(BREAKPOINT_LG, BREAKPOINT_XL)
    );

    const width_BREAKPOINT_MD_LG = useMediaQuery<Theme>(theme =>
      theme.breakpoints.between(BREAKPOINT_MD, BREAKPOINT_LG)
    );
    const width_BREAKPOINT_SM_MD = useMediaQuery<Theme>(theme =>
      theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)
    );
    const width_BREAKPOINT_SM = useMediaQuery<Theme>(theme =>
      theme.breakpoints.down(BREAKPOINT_SM)
    );

    const _maxLength = width_BREAKPOINT_SM
      ? 160
      : width_BREAKPOINT_SM_MD
      ? 230
      : width_BREAKPOINT_MD_LG
      ? 160
      : width_BREAKPOINT_LG_XL
      ? 160
      : 160;

    const optionTypeList = useMemo(() => {
      return (lineItem || pli)?.availableOptions
        ?.filter(availableOption => {
          if (IS_VALID_OPTIONS_TYPE?.includes(availableOption?.type)) {
            return;
          }
          return availableOption;
        })
        .map((item, index) => ({
          type: item?.type,
          status: false,
          priority: index
        }));
    }, [lineItem, pli]);

    const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("md"));

    const shouldShow = data.isActive;

    const [stopProgression, setStopProgression] = useState(false);
    const [incorrectProgression, setIncorrectProgression] = useState(null);
    // end-above state for progressive drop-down

    const setFeetAsDecimal = ({ feet = 0, inches = 0 }) => {
      return Number(feet) + Number(inches) / 12;
    };

    // on HeroImage onIndexChange callback, reset HeroImage swatch url only
    const handleHeroImageOnIndexChange = useCallback(() => {
      setSelectedHeroImageSwatchUrl("");
    }, []);
    const addAllToCartFlag = yn(env.FEATURE_PDP_ADD_ALL);
    //add all item to cart
    const [addAllItemToCart, setAddAllItemToCart] = useState<
      Array<AddAllItemToCart>
    >([]);

    const handleAddOnData = (
      addOnData: AddOnDataType,
      addOnConfigModes: AddOnConfigModes
    ) => {
      // if (
      //   addOnData?.optionsLength === addOnData?.selectedOptions?.length
      // ) {
      onAddOnConfigChange?.(addOnData, addOnConfigModes);
      // }

      const remainingAddOnsConfigState =
        addOnsData
          ?.filter(addOn => addOn?.productId !== addOnData?.productId)
          ?.map(addOn => {
            return {
              productId: addOn?.productId,
              productChecked: addOn?.productSelected,
              fullyConfigured:
                addOn?.optionsLength === addOn?.selectedOptions?.length
            };
          }) ?? [];

      const selectedAddOnsConfigState = addOnData?.productSelected
        ? [
            ...remainingAddOnsConfigState,
            {
              productId: addOnData?.productId,
              productChecked: addOnData?.productSelected,
              fullyConfigured:
                addOnData?.optionsLength === addOnData?.selectedOptions?.length
            }
          ]
        : [...remainingAddOnsConfigState];

      const areAllSelectedAddOnsConfigured = selectedAddOnsConfigState
        ?.filter(addOn => addOn.productChecked)
        ?.every(addOn => addOn.fullyConfigured);

      setSelectedAddOnsNotFullyConfigured(!areAllSelectedAddOnsConfigured);
    };

    const handleAddAllItemToCart = useCallback(
      (itemData: AddAllItemToCart) => {
        const { monogram, customInfo } = itemData;

        const itemToAdd = {
          ...itemData,
          isMainItem: false,
          ...(monogram?.fontCode ||
          monogram?.fontColorCode ||
          monogram?.borderCode
            ? { monogram }
            : null),
          ...(!isEmpty(customInfo) ? { customInfo } : null)
        };

        //filter data according to the lineItemIndex
        const uniqueItems = addAllItemToCart.filter(
          item => item.lineItemIndex !== itemData.lineItemIndex
        );

        const allItems = [...uniqueItems, itemToAdd];
        const filteredData = allItems?.filter(
          item => item?.quantity > 0 && item?.sku
        );
        setAddAllItemToCart([...filteredData]);
      },
      [addAllItemToCart, qty]
    );

    useEffect(() => {
      const addOnComponents = addonDataAddToProject
        ?.map(item => ({
          productId: item.id,
          sku: item?.fullSkuId,
          quantity: item?.qty,
          isMainItem: true,
          brand: brand,
          giftMessage: "",
          ...(monogramOrder?.fontCode ||
          monogramOrder?.fontColorCode ||
          monogramOrder?.borderCode
            ? { monogram: cleanMonogramOrder(monogramOrder) }
            : null)
        }))
        ?.filter(item => item?.quantity > 0);

      let mainItem;

      if (
        isMultiSku &&
        isMultiSkuPhase4 &&
        (isMultiSkuPhase4NA ? isNorthAmerica : true)
      ) {
        if (isAddonFlag && addOnComponents?.length) {
          mainItem = [
            ...addOnComponents,
            getVariableData(
              {
                productId: data?.id,
                name: data?.displayName,
                imageUrl: productImageUrl,
                fullSkuId: skuDetails?.fullSkuId,
                multiSkuId: skuDetails?.fullSkuId,
                sku: skuDetails?.fullSkuId,
                giftMessage: "",
                brand: brand
              },
              true,
              monogramOrder,
              {},
              qty
            )
          ];
        } else {
          mainItem = [
            getVariableData(
              {
                productId: data?.id,
                name: data?.displayName,
                imageUrl: productImageUrl,
                fullSkuId: skuDetails?.fullSkuId,
                sku: skuDetails?.fullSkuId,
                giftMessage: "",
                brand: brand
              },
              true,
              monogramOrder,
              {},
              qty
            )
          ];
        }
      } else {
        mainItem =
          isAddonFlag && addOnComponents?.length
            ? multiSkuComponentsDataV2?.length
              ? [
                  ...addOnComponents,
                  ...(multiSkuComponentsDataV2?.components?.length
                    ? multiSkuComponentsDataV2?.components?.map(component =>
                        getVariableData(
                          {
                            ...component,
                            multiSkuId: skuDetails?.fullSkuId,
                            sku: skuDetails?.fullSkuId,
                            brand: brand
                          },
                          true,
                          monogramOrder,
                          {},
                          qty
                        )
                      )
                    : [])
                ]
              : [
                  ...addOnComponents,
                  getVariableData(
                    {
                      productId: data?.id,
                      name: data?.displayName,
                      imageUrl: productImageUrl,
                      fullSkuId: skuDetails?.fullSkuId,
                      sku: skuDetails?.fullSkuId,
                      multiSkuId: skuDetails?.fullSkuId,
                      giftMessage: "",
                      brand: brand
                    },
                    true,
                    monogramOrder,
                    {},
                    qty
                  )
                ]
            : multiSkuComponentsDataV2?.components?.length
            ? multiSkuComponentsDataV2?.components.map(component =>
                getVariableData(
                  {
                    ...component,
                    multiSkuId: skuDetails?.fullSkuId,
                    brand: brand
                  },
                  true,
                  monogramOrder,
                  {},
                  qty
                )
              )
            : [
                getVariableData(
                  {
                    productId: data?.id,
                    name: data?.displayName,
                    imageUrl: productImageUrl,
                    fullSkuId: skuDetails?.fullSkuId,
                    sku: skuDetails?.fullSkuId,
                    giftMessage: "",
                    brand: brand
                  },
                  true,
                  monogramOrder,
                  {},
                  qty
                )
              ];
      }

      const filteredAllItems = addAllItemToCart?.filter(
        item => !item?.isMainItem
      );
      const filteredMainItem = mainItem?.filter(
        item => item?.sku && item?.quantity > 0
      );
      setAddAllItemToCart([...filteredAllItems, ...filteredMainItem]);
    }, [lineItem, qty, brand, addonDataAddToProject]);

    const handleRemoveItemFromAddAll = useCallback(
      (lineItemIndex: number) => {
        const matchedLineItemIndex = addAllItemToCart?.findIndex(
          item => item?.lineItemIndex === lineItemIndex
        );
        if (matchedLineItemIndex >= 0) {
          const filteredData = addAllItemToCart?.filter(
            item => item?.lineItemIndex !== lineItemIndex
          );
          setAddAllItemToCart([...filteredData]);
        }
      },
      [addAllItemToCart]
    );

    // consolidated onSwatchClick and isColorizable, and set HeroImage swatch url

    const handleOnSwatchClick = useCallback(
      (swatchData: {
        swatchGroup: ProductSwatchGroup;
        selectedSwatch: ProductSwatch;
      }) => {
        if (swatchPanelDialog?.type) {
          handleProgressiveOperation(swatchPanelDialog?.type);
        }
        if (swatchData.selectedSwatch.options[0].optionType === "Trim") {
          // only for custom rugs with trim color option
          setSelectedRugTrimSwatchIds(
            swatchData.selectedSwatch.options.map(op => op.id)
          );
          setSelectedRugTrimSwatchId(
            swatchData.selectedSwatch.secondaryOptionId
          );
          // eslint-disable-next-line react-hooks/exhaustive-deps
        } else {
          handleSelectSwatchesValue(
            swatchData?.swatchGroup?.groupMaterial,
            swatchData?.selectedSwatch
          );
          setSelectedRugTrimSwatchId("");
        }
      },
      [data, isColorizable, swatchPanelDialog]
    );

    const handleSwatchProgressionClickControl = useCallback(
      selectedSwatchData => {
        setIsColorized(true);
        handleOnSwatchClick(selectedSwatchData);
        setIsSwatchSelected(true);
      },
      [handleOnSwatchClick]
    );
    const findDefaultSaleSwatch = useCallback(
      (groupMaterial, swatchId) => {
        let selectedSwatchGroup = null;
        const finishSwatchGroups = data.swatchData?.finishSwatchGroups;

        // If finishSwatchGroups exist, find the swatch group with an empty groupMaterial as the API returns an empty groupMaterial
        if (finishSwatchGroups && finishSwatchGroups.length > 0) {
          selectedSwatchGroup = swatchGroups.find(
            group => group.groupMaterial === "Finish"
          );
        } else {
          // If finishSwatchGroups don't exist, find the swatch group based on groupMaterial
          selectedSwatchGroup = swatchGroups.find(
            group => group.groupMaterial === groupMaterial
          );
        }

        if (!selectedSwatchGroup) {
          return null;
        }

        const selectedSwatchId = swatchId.toString();
        const selectedSwatch = selectedSwatchGroup.stockedSwatches.find(
          swatch => swatch.swatchId === selectedSwatchId
        );

        if (selectedSwatch) {
          return {
            swatchGroup: selectedSwatchGroup,
            selectedSwatch
          };
        }

        return null;
      },
      [data.swatchData?.finishSwatchGroups, swatchGroups]
    );
    useEffect(() => {
      // this will be executed only for estore
      if (isNewPDPLayout) {
        if (
          skuAvailabilityStatus?.maxQuantity === 0 &&
          skuAvailabilityStatus?.availabilityStatus === "unavailable"
        ) {
          setQty(0);
        } else {
          setQty(1);
        }
      } else {
        if (PDP_DEFAULT_QTY !== 0) {
          if (pli?.sku?.inventory?.inventoryRemaining !== "0") {
            setQty(1);
          } else {
            setQty(0);
          }
        }
      }
      // TODO: Fix eslint error and remove this comment block
      // React Hook useEffect has a missing dependency: 'pli'. Either include it or remove the
      // dependency array
      // React Hook useEffect has a complex expression in the dependency array.
      // Extract it to a separate variable so it can be statically checked
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pli?.sku?.inventory?.atgSkuId, skuAvailabilityStatus]);

    useDidMountEffect(() => {
      // this is only for PDP version of concierge
      if (PDP_DEFAULT_QTY === 0) {
        const inventoryRemaining = pli?.sku?.inventory?.inventoryRemaining;
        const selectedOpts =
          pli?.availableOptions
            ?.map(
              availableOption =>
                availableOption?.options.find(
                  option => option.status === "selected"
                ) as ProductAvailableOption
            )
            ?.filter(availableOption => !!availableOption) ?? [];

        // if all default options are selected
        const isAllAvailableOptionsSelected =
          pli?.availableOptions?.length === selectedOpts?.length;

        if (
          (!data?.customProduct &&
            isAllAvailableOptionsSelected &&
            !isQtyChanged) ||
          (data?.customProduct &&
            isAllAvailableOptionsSelected &&
            isAllCustomOptionSelected &&
            !isQtyChanged)
        ) {
          // if qty is 0 and inventory is available
          if (
            inventoryRemaining !== "0" &&
            inventoryRemaining !== "" &&
            qty === 0
          ) {
            setQty(1);
          } else if (inventoryRemaining === "0" || inventoryRemaining === "") {
            setQty(0);
          } else if (qty > Number(inventoryRemaining)) {
            setQty(1);
          }
        } else {
          if (!isQtyChanged) {
            setQty(0);
          }
        }
      }
    }, [pli?.availableOptions, isAllCustomOptionSelected]);

    useDidMountEffect(() => {
      const { diameter: circleOptions, ...rectangleOptions } = customOptions;

      const { shape, rugTrims } =
        pli?.customProductOptions?.customProductInformation ?? {};

      if (shape === "rectangle") {
        for (let rectangleOptionKey in rectangleOptions) {
          const { feet } = rectangleOptions[rectangleOptionKey];

          if (!feet) {
            setIsAllCustomOptionSelect(false);
            return;
          }
        }

        const { width, length } = rectangleOptions;

        const totalWidth = setFeetAsDecimal({
          feet: Number(width.feet),
          inches: Number(width.inches)
        });

        const totalLength = setFeetAsDecimal({
          feet: Number(length.feet),
          inches: Number(length.inches)
        });

        if (rugTrims && rugTrim && totalWidth && totalLength) {
          setIsAllCustomOptionSelect(true);
        } else if (!rugTrims && totalWidth && totalLength) {
          setIsAllCustomOptionSelect(true);
        } else {
          setIsAllCustomOptionSelect(false);
        }
      } else if (shape === "round") {
        const { feet } = circleOptions;

        if (feet) {
          if (rugTrims && rugTrim && feet) {
            setIsAllCustomOptionSelect(true);
          } else if (!rugTrims && feet) {
            setIsAllCustomOptionSelect(true);
          } else {
            setIsAllCustomOptionSelect(false);
          }
        } else {
          setIsAllCustomOptionSelect(false);
        }
      }
    }, [
      pli?.customProductOptions?.customProductInformation?.shape,
      pli?.sku?.inventory?.fullSkuId,
      rugTrim,
      customOptions
    ]);

    useEffect(() => {
      if (pli?.sku?.inventory && pli?.sku?.restrictions && isConcierge) {
        if (addOnsExist) {
          const addOnDataSet = [] as any;

          addOnsData?.map(addon => {
            data?.productAddons?.productAddonsInfo?.map(prod => {
              if (
                addon?.productId === prod?.id &&
                addon?.productSelected &&
                addon?.qty > 0
              ) {
                addOnDataSet?.push({
                  ...prod,
                  spo: addon?.spo,
                  preBillMessage: addon?.preBillMessage,
                  fullSkuId: addon?.fullSkuId,
                  pricing: addon?.pricing,
                  qty: addon?.qty
                });
              }
            });
          });

          setAddonDataAddToProject(addOnDataSet);
        }
      }
    }, [addOnsData]);
    // For customProducts only
    useEffect(() => {
      if (
        productSkuData?.info?.skuPriceInfo?.customProductErrorCode ===
        "INVALID_CUSTOM_PRODUCT_PRICE"
      ) {
        setIsCustomOptionSelectionValid(false);
      } else setIsCustomOptionSelectionValid(true);
    }, [productSkuData?.info?.skuPriceInfo?.customProductErrorCode]);

    const isDisabled =
      !data?.isActive ||
      !skuDetails?.fullSkuId ||
      isLineItemQueryLoading ||
      qty <= 0 ||
      (addOnsExist && selectedAddOnsNotFullyConfigured);

    const showItemNumber = !isDisabled;

    const customSelectedOptions = React.useMemo(() => {
      let selectedOptions: any =
        productLineItem?.availableOptions
          ?.map(
            availableOption =>
              availableOption.options.find(
                option => option.status === "selected"
              ) as ProductAvailableOption
          )
          ?.filter(availableOption => !!availableOption) ?? [];

      const { diameter: circleOptions, ...rectangleOptions } = customOptions;
      const { shape, rugTrims } =
        productLineItem?.customProductOptions?.customProductInformation || {};
      let allOptions = [...selectedOptions];

      if (shape === "round") {
        const { feet, inches } = circleOptions || {};
        const totalDiameter = `${feet}&#8242; ${inches || "0"} &#8243;`;
        allOptions = [
          ...allOptions,
          { type: "Diameter", value: totalDiameter }
        ];
      } else if (shape === "rectangle") {
        const { width, length } = rectangleOptions;
        const totalWidth = `${width.feet}&#8242; ${
          width.inches || "0"
        } &#8243;`;
        const totalLength = `${length.feet}&#8242; ${
          length.inches || "0"
        } &#8243;`;
        allOptions = [
          ...allOptions,
          { type: "Width", value: totalWidth },
          { type: "Length", value: totalLength }
        ];
      }
      if (rugTrims) {
        allOptions.push({
          type: "Rug Trim",
          value: rugTrims?.find(({ id }) => id === rugTrim)?.value ?? ""
        });
      }
      return allOptions;
    }, [customOptions, productLineItem, rugTrim]);

    useDidMountEffect(() => {
      const selectedSwatch = findDefaultSaleSwatch("Finish", data.saleSwatchId);
      if (selectedSwatch) {
        handleOnSwatchClick(selectedSwatch);
      }
    }, [data.swatchData, data.saleSwatchId]);

    const containerGridSpacing = (gridSpacing * 2) as GridSpacing;

    const handleOpenInStockOnSaleModal = useCallback(() => {
      setOpenInStockOnSaleModal(true);
    }, []);

    function handleAddToCartClick() {
      const { sku } = !productLineItem?.sku?.info?.skuPriceInfo
        ? ({ ...lineItem } as ProductLineItem)
        : productLineItem;

      if (!isEmpty(sku) && isNewPDPLayout) {
        // we need to add condition here of query params for new pdp
        const formatAddToCartProduct = {
          selectedOptions: data?.productLineItem?.availableOptions
            .flatMap(availableOpt => availableOpt.options)
            .filter(
              option =>
                chosenLineItemOptionId.find(id => id === option.id && option) &&
                option
            ),
          userType: rhUser?.userType,
          productInfo: {
            displayName: data?.displayName,
            imageUrl: sku?.info?.imageUrl,
            skuPriceInfo: sku?.info?.skuPriceInfo,
            qty,
            dateString: sku?.fulfillmentEta?.inventoryMessage
              ? `${sku?.fulfillmentEta?.inventoryMessage} ${pageContent?.shippingMethod?.message1?.bode2} ${sku?.fulfillmentEta?.postalCode}`
              : "",
            returnPolicyMessage: !isEmpty(multiSkuComponentsDataV2)
              ? multiSkuComponentsDataV2?.multiSkuSpoReturnMessage
              : sku?.restrictions?.returnPolicyMessage,
            fullSkuId: skuDetails?.fullSkuId ?? sku?.inventory?.fullSkuId,
            delivery: !isEmpty(multiSkuComponentsDataV2)
              ? multiSkuComponentsDataV2?.multiSkuComponentsDeliveryInfo
              : sku?.delivery,
            skuRestriction: sku?.restrictions,
            availabilityStatus: skuAvailabilityStatus?.availabilityStatus
          }
        } as addToCartItemDetailsProps;

        setAddToCartMainItemDetails(formatAddToCartProduct);
      }
      window?.scrollTo({
        top: 0
      });

      if (
        productSkuData?.inventory &&
        productSkuData?.restrictions &&
        isNewPDPLayout
      ) {
        if (addOnsExist) {
          const selectedAddOnProdIds =
            addOnsData
              ?.filter(addOn => addOn?.productSelected)
              ?.map(addOn => addOn?.productId) ?? [];

          const unselectedProdsForPanel =
            data?.productAddons?.productAddonsInfo?.filter(
              addOn => !selectedAddOnProdIds.includes(addOn.id)
            ) ?? [];

          setPanelProds(unselectedProdsForPanel);

          const panelProdPreselections = unselectedProdsForPanel?.map(addOn => {
            const preselectedOptions = availableOptions
              ?.map(optionSet => {
                const matchingOptionSet =
                  addOn?.productConfiguration?.optionDetails?.find(
                    secondOptionSet => secondOptionSet.type === optionSet.type
                  );
                const matchingOptionIds = optionSet?.options
                  ?.filter(option => option.status === "selected")
                  .map(option => option.id);
                return matchingOptionSet?.options.filter(option =>
                  matchingOptionIds.includes(option.id)
                );
              })
              .filter(opt => opt !== undefined)?.[0];

            return {
              id: addOn?.id,
              preselectedOptions
            };
          });

          if (unselectedProdsForPanel?.length) {
            unselectedProdsForPanel?.forEach(addOn => {
              const panelProdSingularPreSelectedOptions =
                addOn?.productLineItem?.availableOptions
                  ?.map(opt =>
                    opt?.options?.find(opt => opt?.status === "selected")
                  )
                  .filter(opt => opt) as ProductAvailableOption[];

              const preSelectedOptions = panelProdPreselections?.find(
                addon => addon?.id === addOn?.id
              )?.preselectedOptions;
              handleAddOnData(
                {
                  productId: addOn?.id,
                  productSelected: false,
                  selectedOptions:
                    preSelectedOptions ?? panelProdSingularPreSelectedOptions,
                  optionsLength:
                    addOn?.productConfiguration?.optionDetails?.length ?? 0,
                  qty: 1
                },
                {
                  mainItemOptionsChanged: false,
                  productUnchecked: false,
                  panelProdComputation: true
                }
              );
            });
          }

          const addOnCartSet: (AddOnDataType & ProductAddonsInfo)[] = [];
          addOnsData?.map(addon => {
            data?.productAddons?.productAddonsInfo?.map(prod => {
              if (addon?.productId === prod?.id && addon?.productSelected) {
                addOnCartSet?.push({
                  ...prod,
                  productId: prod?.id,
                  spo: addon?.spo,
                  preBillMessage: addon?.preBillMessage,
                  fullSkuId: addon?.fullSkuId,
                  pricing: addon?.pricing,
                  qty: addon?.qty,
                  selectedOptions: addon?.selectedOptions,
                  sku: addon?.sku
                });
              }
            });
          });
          setAddOnCartData(addOnCartSet);
        }
        setCartDialog(true);
      }
    }

    async function handleAddToWishlistClick(
      callingFromNewPDPConfigurator?: boolean
    ) {
      if (productSkuData?.inventory && !isDisabled) {
        if (!callingFromNewPDPConfigurator) {
          window?.scrollTo({
            top: 0
          });
        }
        setWishlistDialog(true);
      }
    }

    const handleAddToGiftRegistryClick = useCallback(() => {
      setGiftRegistryDialog(true);
    }, []);

    const handleCloseAddToCartDialog = useCallback(() => {
      setPanelAddOnsData([]);
      setCartDialog(false);
    }, []);

    const upsellProducts = upSellProducts?.upsellProducts?.map(
      (upsellProduct, i) => (
        <GridListTile
          key={`upsellProduct-${upsellProduct.id}`}
          style={
            !mdUp
              ? {
                  width: (function () {
                    if (xsDown) {
                      return "100%";
                    } else if (smDown) {
                      return "50%";
                    }
                    return "100%";
                  })(),
                  paddingRight: "20px",
                  padding: "0px 20px 20px 20px"
                }
              : {
                  padding: "0px 20px 20px 20px"
                }
          }
        >
          <ProductCard
            {...(i === 0 && { cardContentRef: productCardContentRef })}
            data={upsellProduct as any}
            upsell="upsell"
            objectFit={"contain"}
            colorBg={env?.FEATURE_BG_GREY ? PAGE_BG_GREY : "white"}
            onViewSelectItemsOnSaleClick={handleOpenInStockOnSaleModal}
            drivedStyles={{
              color: "rgba(0, 0, 0, 0.87)",
              margin: "0",
              fontSize: "1.1666666666666667rem",
              fontWeight: "300",
              lineHeight: "1.43",
              backgroundColor: "#f9f7f4",
              fontFamily: "RHSans-Light"
            }}
            isObserver={!mdUp}
            setActiveDot={setActiveDot}
            cardIndex={i}
            maxUpsellHeight={maxUpsellHeight}
            setMaxUpSetHeight={setMaxUpSetHeight}
            totalUpSell={upSellProducts?.upsellProducts?.length}
          />
        </GridListTile>
      )
    );

    const customProductsCarousel = React.useMemo(
      () =>
        data?.customProduct
          ? data?.carouselProdctDetails?.map((customProduct, i) => {
              const newCustomProduct = { ...customProduct };
              newCustomProduct.id = customProduct.productId;

              return (
                <GridListTile
                  key={`customProduct-${newCustomProduct?.id}`}
                  style={
                    !mdUp
                      ? {
                          maxWidth: "450px"
                        }
                      : {}
                  }
                >
                  <ProductCard
                    {...(i === 0 && { cardContentRef: productCardContentRef })}
                    data={newCustomProduct as any}
                    upsell=""
                    showPriceRange={false}
                    colorBg={env?.FEATURE_BG_GREY ? PAGE_BG_GREY : "white"}
                  />
                </GridListTile>
              );
            })
          : null,
      [data?.carouselProdctDetails]
    );

    const isPostalCodeAvailable = useMemo(() => {
      return (
        !!postalCode &&
        postalCode !== "null" &&
        postalCode !== "undefined" &&
        postalCode !== "false"
      );
    }, [postalCode]);

    const handleCloseInStockOnSaleModal = useCallback(
      () => setOpenInStockOnSaleModal(false),
      []
    );

    const [multiSkuComponentsData, setMultiSkuComponentsData] = useState<
      MultiSkuComponentWithRestrictions[]
    >([]);

    const getQtyValue = useMemo(() => {
      return skuAvailabilityStatus?.maxQuantity <= DEFAULT_MAX_QUANTITY
        ? skuAvailabilityStatus?.maxQuantity
        : DEFAULT_MAX_QUANTITY;
    }, [lineItem?.sku, multiSkuComponentsData, skuAvailabilityStatus]);

    const [reloadKey, setReloadKey] = useState(null);

    useEffect(() => {
      // force a re-render on client side for problematic session

      if (!reloadKey) {
        setReloadKey(uuidv4());
      }
    }, []);

    const hasDesiredOptions = (obj, desiredIds) => {
      const { stockedSwatches = [], customSwatches = [] } = obj;
      const allSwatches = [...stockedSwatches, ...customSwatches];

      for (const swatch of allSwatches) {
        const options = swatch.options || [];
        const hasAllDesiredIds = desiredIds.every(id =>
          options.some(option => option.id === id)
        );

        if (hasAllDesiredIds) {
          handleSelectSwatchesValue(obj?.groupMaterial, swatch);
          return true;
        }
      }
      return false;
    };

    useEffect(() => {
      if (params?.fullSkuId) {
        handleStartingPrice(false);
      }
    }, [data.swatchData]);

    const handleStartingPrice = useCallback(
      async (isConfigured = false) => {
        try {
          const res = await handleConfigPrice(isConfigured);
          const { availableOptions } = res;

          const getSelectedOption = type =>
            availableOptions
              ?.find(
                option => option.type === type || option?.type?.includes(type)
              )
              ?.options?.find(color => color.status === "selected");

          const selectedFabric = getSelectedOption("Fabric");
          const selectedColors = getSelectedOption("Color");
          const selectedFinish = getSelectedOption("Finish");
          const selectedLeather = getSelectedOption("Leather");
          const selectedCountertop = getSelectedOption("Countertop");

          const swatchGroupData = data.swatchData?.swatchGroups || [];
          if (
            (!isEmpty(selectedFabric) || !isEmpty(selectedLeather)) &&
            !isEmpty(selectedColors) &&
            !isEmpty(swatchGroupData)
          ) {
            swatchGroupData?.find(obj =>
              hasDesiredOptions(obj, [
                selectedColors.id,
                selectedFabric?.id || selectedLeather.id
              ])
            );
          }

          if (!isEmpty(selectedCountertop)) {
            const stockedSwatches =
              data.swatchData?.swatchGroups?.[0]?.stockedSwatches;
            const countertopSwatch = stockedSwatches?.find(
              option => option.primaryOptionId === selectedCountertop.id
            );
            countertopSwatch &&
              handleSelectSwatchesValue("Countertop", countertopSwatch);
          }

          if (!isEmpty(selectedFinish)) {
            const finishSwatchGroups =
              data.swatchData?.finishSwatchGroups?.[0]?.stockedSwatches;
            const finishSwatch = finishSwatchGroups?.find(
              option => option.primaryOptionId === selectedFinish.id
            );
            finishSwatch && handleSelectSwatchesValue("Finish", finishSwatch);
          }
          setIsConfiguredPrice(true);
        } catch (error) {
          console.error("Error configuring starting price:", error);
        }
      },
      [
        data.swatchData?.finishSwatchGroups,
        data.swatchData?.swatchGroups,
        handleConfigPrice,
        handleSelectSwatchesValue,
        hasDesiredOptions
      ]
    );

    useDidMountEffect(() => {
      setSelectedSwatchesValue({});
      setSelectedOptions([]);
      const resetedPli = (
        (lineItem?.length || 0) === 0 ? pli : lineItem
      )?.availableOptions?.map((availableOption, index) => {
        let options = availableOption?.options?.filter(
          option => option.status !== "unavailable"
        );

        return options.map(option => ({
          ...option,
          status: undefined
        }));
      });
      setPlit(resetedPli);
    }, [locale]);

    const productPriceUi = useMemo(() => {
      return (
        <ProductPrice
          data={data}
          shouldShow={shouldShow}
          lineItem={lineItem}
          multiskuInventory={multiSkuInventoryData}
          selectedOptions={selectedOptions}
          metaSkuPricingV2={metaSkuPricingV2}
          country={country}
          openInStockOnSaleModal={openInStockOnSaleModal}
          handleCloseInStockOnSaleModal={handleCloseInStockOnSaleModal}
          isPostalCodeAvailable={isPostalCodeAvailable}
          postalCode={postalCode}
          onPostalCode={() => setPostalCodeDialog(true)}
          authenticated={authenticated}
          onViewSelectItemsOnSaleClick={handleOpenInStockOnSaleModal}
          isConfiguredPrice={
            getAllOptionTypes(data?.productConfiguration)?.length ===
            getSelectedOptionLength(chosenLineItemOptions)
          }
          handleStartingPrice={handleStartingPrice}
          centerAlignFlag={false}
          hideSalePrice={false}
          hideUpSellSale={true}
          isNewPDPLayout={isNewPDPLayout}
          isSkuLevel={false}
          chosenLineItemOptions={chosenLineItemOptions}
          chosenLineItemOptionId={chosenLineItemOptionId}
          availableOptions={availableOptions}
        />
      );
    }, [
      data,
      lineItem,
      openInStockOnSaleModal,
      isConfiguredPrice,
      metaSkuPricingV2,
      isNewPDPLayout,
      chosenLineItemOptions,
      availableOptions,
      postalCode,
      multiSkuInventoryData,
      postalCodeDialog
    ]);

    const generateImageCaption = useCallback(
      (selectedSwatches: { [key: string]: ProductSwatch }) => {
        if (selectedSwatches) {
          const firstSelectedSwatch = Object.values(
            selectedSwatches
          )[0] as ProductSwatch;
          if (firstSelectedSwatch) {
            const fabricOptions = firstSelectedSwatch.options.filter(
              option => option.optionType === "Fabric"
            );
            const fabricNames = fabricOptions.map(option => option.label);

            if (fabricNames.length > 0) {
              return `${firstSelectedSwatch.title} ${fabricNames.join(" ")}`;
            } else {
              return firstSelectedSwatch.title;
            }
          }
        }

        return ""; // Default caption when no swatches are selected
      },
      []
    );

    const handleProgressiveOperation = useCallback(
      (type: string) => {
        if (optionTypeList?.length) {
          let updatedValue = optionTypeList ?? [];
          let terminate = false;
          // all these below logic is to achieve following use case
          // 1. Must ensure the options begin progressively opening after the first option is selected by the user
          // 2. Must ensure the options open in order from top to bottom
          // 3. Must ensure the option can be closed and end the auto-open process by the user by closing the option without making a selection. In other words, if the user closes an option dropdown that automatically opened without selecting an option, the next option dropdown will NOT open automatically
          // 4. If user does not start with 1st option, progressive load does not apply

          updatedValue?.map((item, index) => {
            if (item.type === type && updatedValue?.length !== index) {
              setIncorrectProgression(prev => {
                if (index > prev || isSwatchSelected) {
                  setStopProgression(true);
                  terminate = true;
                  //had to add below section of code as to revert all of the prior settings
                  updatedValue[
                    index + 1 === updatedValue?.length ? index : index + 1
                  ].status = false;
                  return -1;
                }
                if ((prev === null && index === 0) || prev === index) {
                  return index + 1;
                }
              });

              if (index + 1 > updatedValue?.length) {
                return (item.status = false);
              }
              if (
                index + 1 < updatedValue?.length &&
                !terminate &&
                !stopProgression
              ) {
                return (updatedValue[
                  index + 1 === updatedValue?.length ? index : index + 1
                ].status = true);
              }
            }
            return item;
          });

          updatedValue?.map(item => {
            if (
              (item?.type === "Fabric" || item?.type === "Leather") &&
              item?.status &&
              !terminate &&
              !stopProgression
            ) {
              _.delay(() => {
                setSwatchPanelDialog("open")(true);
                setSwatchPanelDialog("type")(item?.type);
              }, 700);
            }
          });
        }
      },
      [optionTypeList, swatchPanelDialog, stopProgression, isSwatchSelected]
    );

    const handleSwatchPanelClose = useCallback(() => {
      setSwatchPanelDialog("open")(false);
      if (!isEmpty(selectedSwatches)) {
        handleProgressiveOperation(swatchPanelDialog?.type);
      }
    }, [
      swatchPanelDialog,
      setSwatchPanelDialog,
      handleProgressiveOperation,
      selectedSwatches
    ]);

    const lazyQueryGetSku = useCallback(
      async variables => {
        const {
          data: { getSku }
        } = await client.query({
          query: querGetSku,
          variables: {
            productId: data?.id,
            userType: rhUser?.userType,
            monogrammable: data?.personalizeInfo?.monogrammable,
            postalCode,
            currencyCode: currencyCode,
            qty: 1,
            filter:
              data?.id === params?.productId || data?.onSale
                ? saleContextFilter
                : null,
            siteId,
            measureSystem,
            locale: internationalFlag ? locale : undefined,
            isCustom: data?.customProduct,
            ...variables
          },
          fetchPolicy: "network-only",
          errorPolicy: "all"
        });
        return getSku as GetSkuResponse;
      },
      [
        client,
        data?.id,
        data?.personalizeInfo?.monogrammable,
        postalCode,
        currencyCode,
        siteId,
        measureSystem,
        saleContextFilter,
        internationalFlag,
        locale,
        rhUser?.userType,
        pageContent,
        chosenLineItemOptions,
        chosenLineItemOptionId
      ]
    );

    function resolveSku(selectedOptionIds) {
      async function fetchQuery() {
        const getSkuPromise = lazyQueryGetSku({
          fullSkuId: undefined,
          selectedOptionIds: selectedOptionIds
        });
        handleNewPDPLineItemCall(data?.id, chosenLineItemOptionId, qty);
        const [lineItemSkuResponse] = await Promise.all([getSkuPromise]);
        setSkuDetails(lineItemSkuResponse);
        const isMultiSkuProduct =
          ["m", "M"].includes(lineItemSkuResponse?.fullSkuId?.[0]) &&
          data?.metaProduct;
        if (isMultiSkuProduct) {
          lazyQueryMultiSkuComponents(data?.id, chosenLineItemOptionId, qty);
        }
      }
      fetchQuery();
    }

    const fetchOptionStatus = useCallback(
      async variables => {
        const {
          data: { productLineItem }
        } = await client.query({
          query: queryLineItemLiteV3,
          variables: {
            productId: variables?.productId ?? data?.id,
            fullSkuId: variables?.fullSkuId ?? fullSkuId,
            userType: rhUser?.userType,
            monogrammable:
              variables?.monogrammable ?? data?.personalizeInfo?.monogrammable,
            postalCode,
            currencyCode,
            countryCode: country,
            qty: 1,
            filter:
              data?.id === params?.productId || data?.onSale
                ? saleContextFilter
                : null,
            siteId,
            measureSystem,
            locale: internationalFlag ? locale : undefined,
            shouldFetchSku:
              data?.priceRangeDisplay?.hasOnlyOneSku ||
              !data?.productLineItem?.availableOptions?.length
                ? true
                : false,
            nextGenDriven:
              siteId === RHUK_HOST || data?.customProduct
                ? false
                : yn(data?.nextGenDriven),
            shouldFetchCustomProductOptions: data?.customProduct,
            ...variables
          },
          errorPolicy: "all"
        });
        return productLineItem as ProductLineItem;
      },
      [
        client,
        data?.id,
        fullSkuId,
        data?.personalizeInfo?.monogrammable,
        postalCode,
        currencyCode,
        siteId,
        measureSystem,
        saleContextFilter,
        internationalFlag,
        locale,
        rhUser?.userType,
        pageContent,
        chosenLineItemOptions,
        chosenLineItemOptionId
      ]
    );

    const allOptionsSelected =
      getAllOptionTypes(data?.productConfiguration)?.length ===
      getSelectedOptionLength(chosenLineItemOptions);

    useEffect(() => {
      if (data?.productConfiguration && allOptionsSelected) {
        resolveSku(chosenLineItemOptionId);
      }
    }, [chosenLineItemOptionId]);

    const filteredOptions = chosenLineItemOptionId;
    const options = filteredOptions?.map(lineItemOption => ({
      id: lineItemOption
    }));

    const { imageUrl = "" } = useProductImage({
      productId: data?.id,
      options,
      siteId,
      locale,
      skip: !data?.colorizeInfo?.colorizable
    });

    const productImageUrl = imageUrl ?? data?.productLineItem?.image?.imageUrl;

    useEffect(() => {
      if (imageUrl) {
        setSelectedHeroImageSwatchUrl(imageUrl);
      }
    }, [imageUrl]);

    const backdropStyle = {
      backgroundColor:
        stockProductsDialog.open || openInStockOnSaleModal ? "#f9f7f4" : "",
      opacity:
        stockProductsDialog.open || openInStockOnSaleModal ? "15%" : "100%"
    };

    const isMultiSku = useCallback(id => {
      return id ? ["m", "M"].includes(id[0]) : false;
    }, []);

    const romanceCopy = useMemo(() => {
      return data.longDescription ? (
        <Grid xs={12} className={classes.romanceCopyMainContainterPDPV3}>
          <ReadMore content={data.longDescription} maxLength={_maxLength} />

          {data.uxAttributes?.giftCert?.toLocaleLowerCase() === "true" && (
            <Link
              onClick={() => setOpenGiftCardTerms(true)}
              underline="always"
              href="#"
            >
              {pageContent?.GIFT_TERMS}
            </Link>
          )}
        </Grid>
      ) : null;
    }, [
      _maxLength,
      classes.romanceCopyMainContainer,
      classes.romanceCopyMainContainterPDPV3,
      data.longDescription,
      data.uxAttributes?.giftCert
    ]);
    const detailDimensionButtons = useMemo(() => {
      return (
        <Grid xs={12}>
          <div className={classes.detailDimensionContainer}>
            {!!data.featureList?.length && (
              <ProductDetailsDialog
                buttonText={pageContent?.DETAILS}
                title={pageContent?.DETAILS}
                detailsContent={data.featureList}
                centered={!!data.dimensions?.length}
              />
            )}
            {!!data.dimensions?.length && (
              <ProductDimensionsDialog
                buttonText={pageContent?.DIMENSIONS}
                title={pageContent?.DIMENSIONS}
                dimensionsContent={data.dimensions}
                centered={!!data.featureList?.length}
              />
            )}
          </div>
        </Grid>
      );
    }, [classes.detailDimensionContainer, data.featureList, data.dimensions]);

    const HeroImageV2Container = useMemo(
      () => (
        <HeroImageV2
          parentBaseId={baseId}
          key={`${baseId}-heroImageV2`}
          isNewPdpLayout={isNewPdpLayout}
          setFilmUrl={setFilmUrl}
          filmUrl={filmUrl}
          autoplay={data?.autoplay === "true"}
          images={data?.alternateImages}
          isColorizable={isColorizable}
          isColorized={isColorized}
          selectedSwatchImageUrl={selectedHeroImageSwatchUrl}
          onIndexChange={handleHeroImageOnIndexChange}
          selectedOptionLabel={selectedOptionLabel}
          isRHR={data?.rhr}
          imageLayout={data?.pdpImageLayout}
          objectFit={"contain"}
          isSwatchPanelDialogOpen={swatchPanelDialog["open"]}
          imageCaptionText={generateImageCaption(selectedSwatches)}
          priceComponent={
            <SwatchPanelProductPrice
              lineItem={lineItem}
              selectedOptions={selectedOptions}
              metaSkuPricingV2={metaSkuPricingV2}
              country={country}
              data={data}
              isSwatchPanelOpen={swatchPanelDialog["open"]}
            />
          }
          romanceCopy={romanceCopy}
          skuComponent={
            <Sku
              parentBaseId={baseId}
              key={`${baseId}-sku`}
              inStock={data?.instock?.hasInStock}
              skuInventoryMessage={
                isMultiSku(productSkuData?.inventory?.fullSkuId) &&
                data?.metaProduct === true
                  ? multiSkuComponentsDataV2?.skuInventory?.lineItemMessage ||
                    ""
                  : productSkuData?.inventory?.lineItemMessage || ""
              }
              hideRestrictions={
                !showItemNumber ||
                (monogrammable && monogramOrder?.lines?.length === 0)
              }
              sku={
                isMultiSku(productSkuData?.inventory?.fullSkuId) &&
                data?.metaProduct === true
                  ? {
                      ...productSkuData,
                      inventory: multiSkuComponentsDataV2?.skuInventory ?? {},
                      delivery: multiSkuComponentsDeliveryInfo
                    }
                  : productSkuData
              }
              availabilityStatus={skuAvailabilityStatus?.availabilityStatus}
              onPostalCode={() => setPostalCodeDialog(true)}
              showSPOmsg={!isDisabled}
              isMultiSku={
                isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
                data?.metaProduct === true
              }
              isSwatchPanelOpen={swatchPanelDialog["open"]}
              multiSkuSPOReturnMessage={
                multiSkuComponentsDataV2?.components?.[0]?.restrictions
                  ?.returnPolicyMessage
              }
              multiSkuCountryRestrictions={
                multiSkuComponentsDataV2?.components?.[0]?.restrictions
                  ?.countryRestrictions!
              }
            />
          }
        />
      ),
      [
        country,
        data,
        filmUrl,
        handleHeroImageOnIndexChange,
        isColorizable,
        isDisabled,
        lineItem,
        metaSkuPricingV2,
        monogramOrder?.lines?.length,
        monogrammable,
        multiSkuComponentsDeliveryInfo,
        pli?.sku?.inventory?.fullSkuId,
        selectedHeroImageSwatchUrl,
        selectedOptionLabel,
        selectedOptions,
        showItemNumber,
        swatchPanelDialog,
        stopProgression,
        incorrectProgression,
        multiSkuComponentsDataV2,
        romanceCopy,
        isNewPdpLayout
      ]
    );

    /* this details are used in concierge side for product add to project
  ---DO-NOT-REMOVE---
  */
    const productDetails = useMemo(() => {
      return {
        productId: data?.id,
        imageUrl,
        salePriceLabel: data?.priceRangeDisplay?.salePriceLabel
      };
    }, [data?.id, imageUrl]);

    const surchargeMessage = useMemo(
      () =>
        !(productLineItem?.customProductOptions?.customProductInformation
          ? isAllCustomOptionSelected
            ? isDisabled
            : true
          : isDisabled) &&
        productSkuData?.delivery?.shippingSurcharge && (
          <Grid item xs={12}>
            <ButtonBase
              className={classes?.surchargeLink}
              onClick={() => setSurchargeInfoDialog(true)}
            >
              + {pageContent?.SHIPPING_SURCHARGE ?? SHIPPING_SURCHARGE}{" "}
              {productSkuData?.delivery?.shippingSurchargeAmount} ea
            </ButtonBase>
          </Grid>
        ),
      [isAllCustomOptionSelected, productSkuData, productLineItem, classes]
    );

    const [surchargeInfoDialog, setSurchargeInfoDialog] = useState(false);
    const skuSpoMessage = useMemo(() => {
      return (
        <Grid
          item
          xs={12}
          md={8}
          xl={12}
          id="sku-container"
          data-testid={"sku-container"}
          className={classes.skuContainer}
        >
          <Sku
            parentBaseId={baseId}
            key={`${baseId}-sku`}
            inStock={data?.instock?.hasInStock}
            skuInventoryMessage={
              isMultiSku(productSkuData?.inventory?.fullSkuId?.[0]) &&
              data?.metaProduct === true
                ? multiSkuComponentsDataV2?.skuInventory?.lineItemMessage || ""
                : lineItem?.sku?.fulfillmentEta?.lineItemMessage || ""
            }
            hideRestrictions={
              !showItemNumber ||
              (monogrammable && monogramOrder?.lines?.length === 0)
            }
            sku={
              isMultiSku(productSkuData?.inventory?.fullSkuId) &&
              data?.metaProduct === true
                ? {
                    ...productSkuData,
                    inventory: multiSkuComponentsDataV2?.skuInventory ?? {},
                    delivery:
                      multiSkuComponentsDataV2?.multiSkuComponentsDeliveryInfo
                  }
                : productSkuData
            }
            onPostalCode={() => setPostalCodeDialog(true)}
            showSPOmsg={!isDisabled}
            isMultiSku={
              isMultiSku(productSkuData?.inventory?.fullSkuId) &&
              data?.metaProduct === true
            }
            multiSkuSPOReturnMessage={
              multiSkuComponentsDataV2?.components?.[0]?.restrictions
                ?.returnPolicyMessage
            }
            multiSkuCountryRestrictions={
              multiSkuComponentsDataV2?.components?.[0]?.restrictions
                ?.countryRestrictions!
            }
            isNewPDPLayout={true}
            surchargeMessage={surchargeMessage}
          />
          {showItemNumber && isCustomOptionSelectionValid && (
            <>
              <Typography
                data-testid={"item-sku-id-desktop"}
                id="item-sku-id-desktop"
              >
                {`${
                  pageContent?.ITEM_TEXT?.charAt(0).toUpperCase() +
                  pageContent?.ITEM_TEXT?.slice(1).toLowerCase()
                } ${
                  skuDetails?.fullSkuId ?? productSkuData?.inventory?.fullSkuId
                }`}
              </Typography>
            </>
          )}
        </Grid>
      );
    }, [
      data,
      productSkuData,
      multiSkuComponentsDataV2,
      showItemNumber,
      classes,
      surchargeMessage
    ]);

    return (
      <div
        style={{ ...backdropStyle }}
        id="surcharge-info-dialog"
        data-testid={"surcharge-info-dialog"}
      >
        {surchargeInfoDialog && (
          <SurchargeInfoDialog
            open={surchargeInfoDialog}
            onClose={() => setSurchargeInfoDialog(false)}
          />
        )}
        {!processEnvServer ? (
          <Hidden mdUp>
            {isProductLoading ? (
              <BaseProductSkeleton />
            ) : (
              <div
                className={classNames({
                  [classes.stickyImageContainerPDPV3]: true
                })}
              >
                <div
                  className={classNames({
                    [classes.imgContainerWithSwatch]: swatchPanelDialog["open"]
                  })}
                  id="hero-image-v2-container"
                  data-testid={"hero-image-v2-container"}
                >
                  {HeroImageV2Container}
                </div>
              </div>
            )}
          </Hidden>
        ) : (
          ""
        )}
        <Grid
          container
          className={classes.gutterGridXl}
          style={{ display: "block", width: "auto", margin: "0 auto" }}
          spacing={xlUp ? 0 : containerGridSpacing}
        >
          {/* All relatedProduct Grids should be double the parent container, to have full-width bg:
              - [xs] 1.5spacing x 2 = 3
              - [sm] 3spacing x 2 = 6
              - [md, lg] 4spacing x 2 = 8
              - [xl] 9spacing x 2 = 18
            */}
          <HTMLHeadTags
            title={data.displayName}
            withTitleBrand={false}
            description={data.longDescription}
            keywords={data.featureList ? data.featureList.join() : ""}
          />
          {!!data.priceRangeDisplay && <StructuredData product={data} />}
          {/* item child for spacing */}
          <Grid
            classes={{
              root: `${classes.product} ${
                swatchPanelDialog["open"] ? classes.productOpen : ""
              }`
            }}
            style={swatchPanelDialog["open"] ? { paddingLeft: "0px" } : {}}
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
              id="heroImage-configurator-wrapper"
              data-testid={"heroImage-configurator-wrapper"}
            >
              {isProductLoading && !processEnvServer ? (
                <Hidden smDown>
                  <Grid item xs={12}>
                    <BaseProductSkeleton />
                  </Grid>
                </Hidden>
              ) : (
                <>
                  {processEnvServer ? (
                    <Grid
                      item
                      xs={12}
                      md={8}
                      xl={isNewPdpLayout ? 8 : 9}
                      style={{ paddingTop: theme.spacing(1.6) }}
                    >
                      <div>
                        <HeroImageV2
                          parentBaseId={baseId}
                          key={`${baseId}-heroImageV2`}
                          autoplay={data?.autoplay === "true"}
                          setFilmUrl={setFilmUrl}
                          filmUrl={filmUrl}
                          images={data?.alternateImages}
                          isColorizable={isColorizable}
                          isColorized={isColorized}
                          selectedSwatchImageUrl={selectedHeroImageSwatchUrl}
                          onIndexChange={handleHeroImageOnIndexChange}
                          selectedOptionLabel={selectedOptionLabel}
                          isRHR={data?.rhr}
                          imageLayout={data?.pdpImageLayout}
                          objectFit={"contain"}
                          imageCaptionText={generateImageCaption(
                            selectedSwatches
                          )}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <Hidden smDown>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        xl={isNewPdpLayout ? 8 : 9}
                        style={{
                          ...(queryParams?.version === "v2" && {
                            position: "sticky",
                            top: "180px"
                          })
                        }}
                        className={classNames({
                          [classes.stickyImageContainerPDPV3]: true,
                          [classes.removeDotSpace]:
                            data?.alternateImages?.length <= 1
                        })}
                      >
                        <div
                          className={classes.stickyPosition}
                          id={`hero-image-v2-container-${data?.pdpImageLayout}`}
                        >
                          <div
                            className={classNames({
                              [classes.imgContainerWithSwatch]:
                                swatchPanelDialog["open"]
                            })}
                            id="hero-image-v2-container"
                            data-testid={"hero-image-v2-container"}
                          >
                            <HeroImageV2
                              parentBaseId={baseId}
                              key={`${baseId}-heroImageV2`}
                              isNewPdpLayout={true}
                              isNewPdpDots={true}
                              autoplay={data?.autoplay === "true"}
                              setFilmUrl={setFilmUrl}
                              filmUrl={filmUrl}
                              images={data?.alternateImages}
                              isColorizable={isColorizable}
                              selectedSwatchImageUrl={
                                selectedHeroImageSwatchUrl
                              }
                              onIndexChange={handleHeroImageOnIndexChange}
                              selectedOptionLabel={selectedOptionLabel}
                              isRHR={data?.rhr}
                              imageLayout={data?.pdpImageLayout}
                              objectFit={"contain"}
                              isSwatchPanelDialogOpen={
                                swatchPanelDialog["open"]
                              }
                              imageCaptionText={generateImageCaption(
                                selectedSwatches
                              )}
                              isColorized={isColorized}
                              priceComponent={
                                <SwatchPanelProductPrice
                                  lineItem={lineItem}
                                  selectedOptions={selectedOptions}
                                  metaSkuPricingV2={metaSkuPricingV2}
                                  country={country}
                                  data={data}
                                  isSwatchPanelOpen={swatchPanelDialog["open"]}
                                />
                              }
                              skuComponent={
                                <Sku
                                  parentBaseId={baseId}
                                  key={`${baseId}-sku`}
                                  inStock={data?.instock?.hasInStock}
                                  skuInventoryMessage={
                                    isMultiSku(
                                      productSkuData?.inventory?.fullSkuId
                                    ) && data?.metaProduct === true
                                      ? multiSkuComponentsDataV2?.skuInventory
                                          ?.lineItemMessage || ""
                                      : productSkuData?.inventory
                                          ?.lineItemMessage || ""
                                  }
                                  hideRestrictions={
                                    !showItemNumber ||
                                    (monogrammable &&
                                      monogramOrder?.lines?.length === 0)
                                  }
                                  sku={
                                    isMultiSku(
                                      productSkuData?.inventory?.fullSkuId
                                    ) && data?.metaProduct === true
                                      ? {
                                          ...productSkuData,
                                          inventory:
                                            multiSkuComponentsDataV2?.skuInventory ??
                                            {},
                                          delivery:
                                            multiSkuComponentsDeliveryInfo
                                        }
                                      : productSkuData
                                  }
                                  onPostalCode={() => setPostalCodeDialog(true)}
                                  showSPOmsg={!isDisabled}
                                  isMultiSku={
                                    isMultiSku(
                                      pli?.sku?.inventory?.fullSkuId
                                    ) && data?.metaProduct === true
                                  }
                                  isSwatchPanelOpen={swatchPanelDialog["open"]}
                                  multiSkuSPOReturnMessage={
                                    multiSkuComponentsDataV2?.components?.[0]
                                      ?.restrictions?.returnPolicyMessage
                                  }
                                  multiSkuCountryRestrictions={
                                    multiSkuComponentsDataV2?.components?.[0]
                                      ?.restrictions?.countryRestrictions!
                                  }
                                />
                              }
                            />
                          </div>
                          <div
                            className={classes.leftSideRomanceCopyContainer}
                            id="romance-copy"
                            data-testid={"romance-copy"}
                          >
                            {romanceCopy}
                            {detailDimensionButtons}
                          </div>
                        </div>
                      </Grid>
                    </Hidden>
                  )}
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    xl={4}
                    className={classes.configuratorOuterPDPV3}
                    style={{
                      justifyContent: smDown ? "center" : "left",
                      borderLeft: !mdDown ? "0.5px solid #d4d2cf" : ""
                    }}
                    id="product-configurator"
                    data-testid={"product-configurator"}
                  >
                    <Configurator
                      parentBaseId={baseId}
                      key={`${baseId}-configurator`}
                      data={data}
                      lineItem={lineItem}
                      onPostalCode={() => setPostalCodeDialog(true)}
                      productPriceUi={productPriceUi}
                      monogramOrder={monogramOrder}
                      setMonogramOrder={setMonogramOrder}
                      qty={qty}
                      setQty={(qty: number) => setQty(qty)}
                      getQtyValue={getQtyValue}
                      handleAddOnData={handleAddOnData}
                      locale={internationalFlag ? locale : undefined}
                      fetchOptionStatus={fetchOptionStatus}
                      addOnsData={addOnsData}
                      addOnOptionInfoToRenderButtons={
                        addOnOptionInfoToRenderButtons
                      }
                      currencyCode={currencyCode}
                      chosenLineItemOptionId={chosenLineItemOptionId}
                      chosenLineItemUnavailableOptionId={
                        chosenLineItemUnavailableOptionId
                      }
                      setChosenLineItemUnavailableOptionId={
                        setChosenLineItemUnavailableOptionId
                      }
                      chosenLineItemUnavailableOptions={
                        chosenLineItemUnavailableOptions
                      }
                      setChosenLineItemUnavailableOptions={
                        setChosenLineItemUnavailableOptions
                      }
                      chosenLineItemOptions={chosenLineItemOptions}
                      setChosenLineItemOptionId={setChosenLineItemOptionId}
                      setChosenLineItemOptions={setChosenLineItemOptions}
                      availableOptions={availableOptions}
                      setAvailableOptions={setAvailableOptions}
                      availableSwatch={availableSwatch}
                      setAvailableSwatch={setAvailableSwatch}
                      addToCartButton={
                        <RelatedProductActionsV2
                          isDisabled={
                            productLineItem?.customProductOptions
                              ?.customProductInformation
                              ? isAllCustomOptionSelected &&
                                isCustomOptionSelectionValid
                                ? isDisabled
                                : true
                              : isDisabled || isLineItemQueryLoading
                          }
                          isAuthenticated={authenticated}
                          hasInventory={!!productLineItem?.sku?.inventory}
                          isPreOrder={
                            productSkuData?.inventory?.preOrder || false
                          }
                          productDetails={productDetails}
                          selectedOptionIds={chosenLineItemOptionId}
                          qty={qty}
                          isNewPDPV2={data?.newPdpLayout}
                          productLineItem={productLineItem}
                          handleAddToCartClick={handleAddToCartClick}
                          handleAddToWishlistClick={() =>
                            handleAddToWishlistClick(true)
                          }
                          handleAddToGiftRegistryClick={
                            handleAddToGiftRegistryClick
                          }
                          options={customSelectedOptions}
                          multiSkuComponents={multiSkuComponentsDataV2}
                          productAddons={addonDataAddToProject}
                          priceComponent={
                            <CartProductPrice
                              isNewPDPButton={true}
                              lineItem={lineItem}
                              availableOptions={availableOptions}
                              selectedOptions={selectedOptions}
                              metaSkuPricingV2={
                                metaSkuPricingV2 && multiSkuComponentsDataV2
                              }
                              multiskuInventory={multiSkuInventoryData}
                              country={country}
                              data={data}
                              hideSalePrice={true}
                              topLabel="no-label"
                              configureLabel={
                                lineItem?.sku?.inventory?.fullSkuId &&
                                pageContent?.CONFIGURES_PRICE
                              }
                              hideUpSellSale={true}
                              isSkuLevel={true}
                              chosenLineItemOptionId={chosenLineItemOptionId}
                              chosenLineItemOptions={chosenLineItemOptions}
                            />
                          }
                        />
                      }
                      romanceCopy={romanceCopy}
                      detailDimensionButtons={detailDimensionButtons}
                      analyticsModalStates={analyticsModalStates}
                      setIsColorizable={setIsColorizable}
                      skuSpoMessage={skuSpoMessage}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {postalCodeDialog ? (
            internationalFlag ? (
              <InternationalPostalCodeDialog
                open={postalCodeDialog}
                style={{ paddingBottom: smUp ? "0" : "60%" }}
                onClose={() => setPostalCodeDialog(false)}
              />
            ) : (
              <PostalCodeDialog
                open={postalCodeDialog}
                style={{ paddingBottom: smUp ? "0" : "60%" }}
                onClose={() => setPostalCodeDialog(false)}
              />
            )
          ) : null}

          {isProductLoading ? (
            <Grid
              item
              className="gridItem"
              style={{
                width: "100%"
              }}
            >
              <RelatedProductCardSkeleton data-testid="full-product-loading-spinner" />
            </Grid>
          ) : (
            <>
              <PrintSection.Container
                className={classes.gutterGridXl}
                spacing={xsDown ? 0 : 4}
              >
                {/* All relatedProduct Grids should be double the parent container, to have full-width bg:
                    - [xs] 1.5spacing x 2 = 3
                    - [sm] 3spacing x 2 = 6
                    - [md, lg] 4spacing x 2 = 8
                    - [xl] 9spacing x 2 = 18
                  */}
                {!!data.productListTitle && (
                  <>
                    <Typography
                      className={typographyStyles.rhBaseH1}
                      id="product-list-title"
                      data-testid="product-list-title"
                    >
                      {data.productListTitle}
                    </Typography>
                    <RHDivider color="#E6E6E6" style={{ opacity: 1 }} />
                  </>
                )}
                {shouldShow &&
                !data?.layout?.includes("custom") &&
                data?.layout !== "track-hardware"
                  ? relatedProducts.map((item, index) => (
                      <>
                        {index > 0 && (
                          <PrintSection.Item
                            key={`relatedProduct-${item.id}`}
                            className={classes.relatedProductGrid}
                          >
                            <div
                              className={classNames([
                                classes.reveal,
                                classes.fadeInUp
                              ])}
                              style={{
                                animationDuration: "500ms",
                                animationDelay: "0ms",
                                animationIterationCount: 1,
                                opacity: 1
                              }}
                              id="related-product-card-wrapper"
                              data-testid="related-product-card-wrapper"
                            >
                              {item.id === params.productId ? (
                                <RelatedProductCard
                                  data={item}
                                  fullSkuId={fullSkuId}
                                  selectedQuantity={qty}
                                  selectedSwatches={selectedSwatches}
                                  authenticated={authenticated}
                                  saleContextFilter={saleContextFilter}
                                  postalCode={postalCode}
                                  parentChosenLineItemOptions={{
                                    ...chosenLineItemOptions
                                  }}
                                  parentChosenLineItemOptionIds={[
                                    ...chosenLineItemOptionId
                                  ]}
                                  onOptionsSelected={isSelected => {
                                    setSelectedOptionLabel(isSelected);
                                  }}
                                  changeSwatchImageFromLineItem={
                                    onChangeSwatchImageFromLineItem
                                  }
                                  handleAddAllItemToCart={
                                    handleAddAllItemToCart
                                  }
                                  handleRemoveItemFromAddAll={
                                    handleRemoveItemFromAddAll
                                  }
                                  lineItemIndex={index}
                                  rideAlongData={rideAlongData}
                                  setRideAlongData={setRideAlongData}
                                  setMultiSkuComponentsData={
                                    setMultiSkuComponentsData
                                  }
                                  isNewPDPLayout={isNewPDPLayout}
                                  spoOverrideMessage={
                                    data?.spoReturnMessageOverride
                                  }
                                />
                              ) : (
                                <RelatedProductCard
                                  data={item}
                                  fullSkuId={fullSkuId}
                                  selectedQuantity={qty}
                                  selectedSwatches={selectedSwatches}
                                  authenticated={authenticated}
                                  saleContextFilter={saleContextFilter}
                                  postalCode={postalCode}
                                  parentChosenLineItemOptions={{
                                    ...chosenLineItemOptions
                                  }}
                                  parentChosenLineItemOptionIds={[
                                    ...chosenLineItemOptionId
                                  ]}
                                  onOptionsSelected={isSelected => {
                                    setSelectedOptionLabel(isSelected);
                                  }}
                                  handleAddAllItemToCart={
                                    handleAddAllItemToCart
                                  }
                                  handleRemoveItemFromAddAll={
                                    handleRemoveItemFromAddAll
                                  }
                                  lineItemIndex={index}
                                  rideAlongData={rideAlongData}
                                  setRideAlongData={setRideAlongData}
                                  setMultiSkuComponentsData={
                                    setMultiSkuComponentsData
                                  }
                                  isNewPDPLayout={isNewPDPLayout}
                                  spoOverrideMessage={
                                    data?.spoReturnMessageOverride
                                  }
                                />
                              )}
                            </div>
                          </PrintSection.Item>
                        )}
                        {index > 0 && index < relatedProducts.length - 1 && (
                          <Divider className={classes.divider} />
                        )}
                      </>
                    ))
                  : null}
                {shouldShow && data?.layout.includes("custom-rug")
                  ? relatedProducts.map((item, index) => (
                      <>
                        {index > 0 && (
                          <PrintSection.Item key={`custom-rug-${item.id}`}>
                            <Grid
                              style={{
                                minHeight: mdUp ? 400 : 200
                              }}
                              item
                              className={`gridItem ${classes.relatedProductGrid}`}
                              xs={12}
                              id="related-product-grid"
                              data-testid="related-product-grid"
                            >
                              {isSSRToggledWithCSR ? (
                                <RelatedProductCard
                                  data={item}
                                  fullSkuId={fullSkuId}
                                  selectedQuantity={qty}
                                  selectedSwatches={selectedSwatches}
                                  authenticated={authenticated}
                                  saleContextFilter={saleContextFilter}
                                  postalCode={postalCode}
                                  handleAddAllItemToCart={
                                    handleAddAllItemToCart
                                  }
                                  handleRemoveItemFromAddAll={
                                    handleRemoveItemFromAddAll
                                  }
                                  lineItemIndex={index}
                                  rideAlongData={rideAlongData}
                                  setRideAlongData={setRideAlongData}
                                  selectedRugTrimSwatchId={
                                    selectedRugTrimSwatchId
                                  }
                                  setMultiSkuComponentsData={
                                    setMultiSkuComponentsData
                                  }
                                  isNewPDPLayout={isNewPDPLayout}
                                  spoOverrideMessage={
                                    data?.spoReturnMessageOverride
                                  }
                                />
                              ) : (
                                <InView
                                  triggerOnce
                                  initialInView={processEnvServer}
                                >
                                  {({ inView, ref }) => (
                                    <div ref={ref}>
                                      {inView && (
                                        <RelatedProductCard
                                          data={item}
                                          fullSkuId={fullSkuId}
                                          selectedQuantity={qty}
                                          selectedSwatches={selectedSwatches}
                                          authenticated={authenticated}
                                          saleContextFilter={saleContextFilter}
                                          postalCode={postalCode}
                                          handleAddAllItemToCart={
                                            handleAddAllItemToCart
                                          }
                                          handleRemoveItemFromAddAll={
                                            handleRemoveItemFromAddAll
                                          }
                                          lineItemIndex={index}
                                          rideAlongData={rideAlongData}
                                          setRideAlongData={setRideAlongData}
                                          selectedRugTrimSwatchId={
                                            selectedRugTrimSwatchId
                                          }
                                          setMultiSkuComponentsData={
                                            setMultiSkuComponentsData
                                          }
                                          isNewPDPLayout={isNewPDPLayout}
                                          spoOverrideMessage={
                                            data?.spoReturnMessageOverride
                                          }
                                        />
                                      )}
                                    </div>
                                  )}
                                </InView>
                              )}
                            </Grid>
                            {index < relatedProducts.length - 1 && (
                              <Divider className={classes.divider} />
                            )}
                          </PrintSection.Item>
                        )}
                      </>
                    ))
                  : null}
              </PrintSection.Container>

              {addAllToCartFlag && relatedProducts.length > 0 && (
                <AddAllProductActions
                  isDisabled={
                    productLineItem?.customProductOptions
                      ?.customProductInformation
                      ? isAllCustomOptionSelected &&
                        isCustomOptionSelectionValid
                        ? isDisabled
                        : true
                      : isDisabled || isLineItemQueryLoading
                  }
                  addAllItemToCart={addAllItemToCart ?? []}
                  multiSkuComponentsData={multiSkuComponentsData ?? []}
                />
              )}
              {["drape", "customHardware", "trackHardware", "shade"].includes(
                data?.customProductType
              ) || data?.layout === "custom" ? (
                <>
                  {!!data?.carouselProdctDetails?.length && (
                    <Grid
                      item
                      xs={12}
                      wrap={"nowrap"}
                      className={classes.slider}
                      id="ymal-slider-grid"
                      data-testid="ymal-slider-grid"
                    >
                      <Typography
                        variant="h5"
                        style={{
                          paddingBottom: "15px",
                          textTransform: "uppercase"
                        }}
                        align={smDown ? "left" : "center"}
                        id="ymal-text"
                        data-testid="ymal-text"
                      >
                        {pageContent?.YOU_MIGHT_ALSO_LIKE}
                      </Typography>
                      <InView triggerOnce initialInView={processEnvServer}>
                        {({ inView, ref }) => (
                          <div ref={ref}>
                            {inView && (
                              <>
                                {!processEnvServer && (
                                  <GridList
                                    cols={mdUp ? 1 : 1.25}
                                    cellHeight="auto"
                                    style={{
                                      flexWrap: "nowrap",
                                      overflowX: mdUp ? "hidden" : "scroll"
                                    }}
                                  >
                                    <Slider
                                      arrowOffset={
                                        +`-${productCardContentHeight}`
                                      }
                                    >
                                      {customProductsCarousel}
                                    </Slider>
                                  </GridList>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </InView>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {!!upSellProducts?.upsellProducts?.length && (
                    <Grid
                      item
                      xs={12}
                      wrap={"nowrap"}
                      className={classes.slider}
                      style={
                        mdUp || smDown
                          ? {
                              width: "100%",
                              flexWrap: "unset",
                              display: "flex",
                              placeContent: "center"
                            }
                          : {
                              flexWrap: "unset",
                              display: "flex",
                              placeContent: "center"
                            }
                      }
                    >
                      <div
                        className={classNames([
                          classes.reveal,
                          classes.fadeInUp,
                          classes.collectionContainer
                        ])}
                        style={{
                          animationDuration: "500ms",
                          animationDelay: "0ms",
                          animationIterationCount: 1,
                          opacity: 1
                        }}
                      >
                        <Typography
                          variant={"h2"}
                          className={classes.collectionTitle}
                          style={{
                            textTransform: "uppercase",
                            textAlign: "center"
                          }}
                        >
                          {upSellProducts?.componentTitle ||
                            DEFAULT_UPSELL_COMPONENT_TITLE}
                        </Typography>
                        <Typography variant={"h2"}>
                          <RHLink
                            to={`/catalog/category/products.jsp?categoryId=${data.collectionCategoryId}`}
                            style={{
                              paddingBottom: "4px",
                              marginBottom: "2px"
                            }}
                          >
                            <p className={classes.shopLink}>
                              {pageContent?.SHOP_ENTIRE_COLLECTION}
                            </p>
                          </RHLink>
                        </Typography>
                        <>
                          {
                            <GridList
                              cols={mdUp ? 1 : 1.25}
                              cellHeight="auto"
                              style={{
                                flexWrap: "nowrap",
                                overflowX: mdUp ? "hidden" : "scroll",
                                marginTop: "56px"
                              }}
                              className={
                                isSSRToggledWithCSR &&
                                classes.carosalWraperStyle
                              }
                            >
                              {mdUp ? (
                                <Slider
                                  isYmal={isNewPDPLayout}
                                  arrowOffset={+`-${productCardContentHeight}`}
                                >
                                  {upsellProducts}
                                </Slider>
                              ) : (
                                <Slider
                                  arrowOffset={+`-${productCardContentHeight}`}
                                  totalCollectionItems={2}
                                  isYmal={isNewPDPLayout}
                                >
                                  {upsellProducts}
                                </Slider>
                              )}
                            </GridList>
                          }
                        </>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
        <Container>
          <SwatchPanelDialogV2
            product={data}
            open={!!swatchPanelDialog.open}
            onClose={handleSwatchPanelClose}
            stockedSwatchesLength={stockedSwatchesLength}
            customSwatchesLength={customSwatchesLength}
            groupMaterial={groupMaterial}
            template={template}
            selectedSwatches={selectedSwatches}
            onSwatchClick={handleSwatchProgressionClickControl}
            imageComponent={HeroImageV2Container}
          />
        </Container>
        <Container>
          <InStockProductsDialog
            open={!!stockProductsDialog.open}
            onClose={() => setStockProductsDialog("open")(false)}
            postalCode={postalCode}
            productId={data?.id}
            displayName={data?.displayName}
            metaProduct={data?.metaProduct}
            salePriceLabel={data?.priceRangeDisplay?.salePriceLabel ?? ""}
            productLineItem={data?.productLineItem}
            multiSkuComponents={multiSkuComponentsData}
            instockSelected={true}
          />
        </Container>
        {cartDialog && (
          <>
            <AddToCartDialog
              fetchOptionStatus={fetchOptionStatus}
              monogramOrder={monogramOrder}
              open={cartDialog}
              onClose={() => handleCloseAddToCartDialog()}
              productId={data.id}
              fullSkuId={skuDetails?.fullSkuId}
              atgSkuId={productSkuData?.inventory.atgSkuId ?? ""}
              spo={
                isMultiSku(productSkuData?.inventory?.fullSkuId) &&
                data?.metaProduct === true
                  ? multiSkuComponentsDataV2?.isSPO
                  : productSkuData?.restrictions?.spo
              }
              preBillMessage={
                multiSkuComponentsDataV2?.isSPO
                  ? multiSkuComponentsDataV2?.multiSkuSpoPreBillMessage
                  : productSkuData?.restrictions?.preBillMessage
                  ? productSkuData?.restrictions?.preBillMessage
                  : productSkuData?.restrictions?.returnPolicyMessage || ""
              }
              productDisplayName={data?.displayName}
              onCompleted={cartDetails => {
                const pricing = lineItem?.sku?.info?.skuPriceInfo;
                if (!processEnvServer) {
                  analyticsLoader(a =>
                    a.emitAnalyticsEvent(
                      document.querySelector<HTMLInputElement>(
                        "#spa-root > *"
                      )!,
                      a.EVENTS.GA4_ADD_TO_CART.INT_TYPE,
                      {
                        cartdetails: {
                          cartId: cartDetails?.id,
                          cartCreationDate: cartDetails?.createdAt,
                          cartUpdationDate: cartDetails?.lastModifiedAt
                        },
                        item: {
                          name: data?.displayName,
                          id: data?.id,
                          quantity: qty,
                          sku: productSkuData?.inventory.fullSkuId ?? "",
                          pricing,
                          currencyCode: cartDetails?.cartPrice?.currencyCode,
                          color: data?.optionList?.find(
                            opt => opt?.optionType === "Color"
                          )?.label
                        },
                        store_number: "Website",
                        item_list_name: "RHLineItemCard"
                      }
                    )
                  );
                }
              }}
              options={customSelectedOptions}
              qty={qty}
              salePriceLabel={data?.priceRangeDisplay?.salePriceLabel || ""}
              pricing={
                !isEmpty(multiSkuComponentsDataV2)
                  ? metaSkuPricingV2
                  : pli?.sku?.info?.skuPriceInfo
              }
              setConfirmed={setConfirmed}
              confirmed={confirmed}
              multiSkuComponents={multiSkuComponentsDataV2?.components}
              addToCartItemDetails={isNewPDPLayout && addToCartMainItemDetails}
              onPostalCode={() => setPostalCodeDialog(true)}
              productAddons={addOnCartData}
              productAddonsInfo={data?.productAddons?.productAddonsInfo}
              productAddonDisplayOptions={data?.productAddonDisplayOptions}
              onAddOnConfigChange={onAddOnConfigChange}
              panelProds={panelProds}
              panelAddOnsData={panelAddOnsData}
              // panelProdData={panelProdData}
              // panelProdConfigurationData={panelProdConfigurationData}
              mainProductAvailableOptions={availableOptions}
              mainProductAvailableSwatch={availableSwatch}
              mainProductChoosenOptions={chosenLineItemOptions}
              incomingPanelProdConfigData={isLineItemQueryLoading ?? false}
              productLineItemOptions={
                lineItem?.availableOptions as ProductAvailableOptionSet[]
              }
            />
          </>
        )}
        {wishlistDialog && country !== "GB" && (
          <AddToWishlistDialog
            open={wishlistDialog}
            onClose={() => setWishlistDialog(false)}
            productId={data?.id}
            fullSkuId={productSkuData?.inventory?.fullSkuId ?? ""}
            atgSkuId={productSkuData?.inventory?.atgSkuId ?? ""}
            qty={qty}
          />
        )}
        {giftRegistryDialog && country !== "GB" && (
          <AddToGiftRegistryDialog
            open={giftRegistryDialog}
            onClose={() => setGiftRegistryDialog(false)}
            productId={data?.id}
            fullSkuId={skuDetails?.fullSkuId ?? ""}
            atgSkuId={pli?.sku?.inventory?.atgSkuId ?? ""}
            qty={qty}
            name={data?.displayName}
            imageUrl={data?.productLineItem?.image?.imageUrl}
            options={selectedOptions}
            monogramOrder={monogramOrder}
            //customInfo={customProductConfig}
            multiSkuComponents={multiSkuComponentsDataV2?.components}
            isNewPdpLayout={isNewPdpLayout}
          />
        )}
      </div>
    );
  }
);

PDPV2.defaultProps = {};
PDPV2.displayName = "PDP";

export interface ProductProps {
  productItem: Product;
  isLoading: boolean;
}

const clientDefault = (props: ProductProps) => {
  const { productItem, isLoading } = props;
  const skipCall = isLoading === true || !!productItem?.id;

  const w = window as any;
  useEffect(() => {
    w.hardReload = false;
    const handlePageLoad = () => {
      w.hardReload = true;
    };
    window.addEventListener("readystatechange", handlePageLoad);
    return () => {
      window.removeEventListener("readystatechange", handlePageLoad);
    };
  }, []);

  const { app } = useAppData();
  const env = useEnv();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [trimOptions, setTrimOptions] = useState<string[] | null>(null);
  const [openGiftCardTerms, setOpenGiftCardTerms] = useState<boolean>(false);
  const [addOnsData, setAddOnsData] = useState<AddOnDataType[]>([]);
  const [panelAddOnsData, setPanelAddOnsData] = useState<AddOnDataType[]>([]);
  // const [panelProdConfigurationData, setPanelProdConfigurationData] =
  //   useState<Maybe<addOnDataType>>(null);
  const brandCode = useBrand();
  const prefix = useLocalization();
  const locale = useLocale();
  const pc = useIsoCookies([POSTAL_CODE_COOKIE])?.pc || "";
  const params = useParams({
    productId: "",
    fullSkuId: "",
    sale: "",
    version: "",
    swatch: ""
  });
  //for canonical URL support
  const _params = useFetchParams(
    location.pathname,
    yn(env.FEATURE_URL_CHANGE) ? "pdp" : "product.jsp"
  );
  let [productId] = _params;
  if (params.productId === "") {
    params.productId = productId?.replace("&ct=true", "");
  }

  const { keycloak } = useKeycloak();
  const { userType } = useRhUserAtomValue();
  const client = useApolloClient();
  const siteId = useSite();
  const { pageContent } = useFetchModel("/admin/product", true, false);
  const [availableOptions, setAvailableOptions] = useState<
    ProductAvailableOptionSet[]
  >([]);

  const [availableSwatch, setAvailableSwatch] = useState<ProductSwatchV2>(
    {} as ProductSwatchV2
  );
  // More about RideAlong: SHOP-1647.
  const [rideAlongProduct, setRideAlongProduct] = useState<Product | null>(
    null
  );
  const [rideAlongData, setRideAlongData] =
    useState<RideAlongData>(defaultRideAlongData);

  const giftCardTerms =
    useQuery<Query>(queryGiftCardTerms, {
      variables: { key: "rh-gift-card-pdp" }
    }).data?.contentFragment?.text ?? "";

  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const { previousState } = useUserPreferences();
  const measureSystem = useMeasurementType();
  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode: app.postalCode || pc,
    userType
  });
  const pcCookie = getCookie("pc");
  const postalCode = app.postalCode || pcCookie || pc;

  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );

  const productVariables = useMemo(() => {
    return {
      productId: params.productId,
      fullSkuId: params.fullSkuId,
      swatchID: params.swatch,
      filter: saleContextParam,
      userType: userType,
      siteId,
      currencyCode: currencyCode || "",
      measureSystem,
      locale: FEATURE_INTERNATIONAL ? locale : undefined
    };
  }, [
    params.productId,
    params.fullSkuId,
    params.swatch,
    userType,
    siteId,
    currencyCode,
    measureSystem,
    FEATURE_INTERNATIONAL,
    locale,
    saleContextParam
  ]);

  const fetchRideAlongProduct = useCallback<
    (productId: string, variables?: any) => Promise<Product>
  >(
    async (productId, variables) => {
      const {
        data: { product }
      } = await client.query({
        query: queryRideAlongProduct,
        variables: {
          productId,
          filter: saleContextParam || null,
          userType: userType,
          siteId,
          currencyCode,
          measureSystem,
          ...variables
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return product;
    },
    [saleContextParam, siteId, client, currencyCode, measureSystem, userType]
  );

  // Ran into an issue where refetch throws an error instead of putting the error into the hook error output
  // see: https://github.com/apollographql/react-apollo/issues/3571
  // Found a workaround using the errorPolicy
  // errorPolicy info from apollo docs:
  //    "If you set errorPolicy to all, useQuery does not discard query
  //     response data, allowing you to render partial results."
  let {
    loading: isProductLoading,
    data: { product } = {} as Query,
    error: productError,
    refetch
  } = useQuery<Query>(queryProductV2, {
    variables: productVariables,
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    skip: !params.productId || skipCall,
    errorPolicy: "all"
  });

  if (productItem && Object.keys(productItem)?.length) {
    product = productItem;
  }

  const intialFullSkuId = params.fullSkuId || product?.preconfiguredSku;

  const { data: { upsellProducts } = {}, refetch: refetchUpSellProducts } =
    useQuery(queryUpsSellProducts, {
      variables: productVariables,
      skip: !params.productId || !product?.id
    });

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH3"]
  });

  const {
    loading: productSwatchLoading,
    data: { product: productSwatchImage } = {} as Query
  } = useQuery<Query>(queryProductSwatchImage, {
    variables: {
      productId: params.productId,
      optionIds: options,
      siteId,
      locale,
      trimOptionIds: trimOptions ?? null,
      fullskuId: intialFullSkuId ?? ""
    }
  });

  useDidMountEffect(() => {
    refetch(productVariables);
    refetchUpSellProducts(productVariables);
  }, [JSON.stringify(productVariables)]);

  const relatedProductVariable = useMemo(() => {
    return {
      productIds: product?.relatedProductIds,
      filter: saleContextParam,
      userType: userType,
      siteId,
      currencyCode: currencyCode || "USD",
      measureSystem,
      locale: FEATURE_INTERNATIONAL ? locale : undefined
    };
  }, [
    userType,
    siteId,
    currencyCode,
    measureSystem,
    FEATURE_INTERNATIONAL,
    locale,
    saleContextParam,
    product?.relatedProductIds
  ]);

  const { data: { relatedProductsQuery } = {} as Query } = useQuery<Query>(
    queryRelatedProducts,
    {
      variables: relatedProductVariable,
      skip: !Boolean(relatedProductVariable?.productIds?.length)
    }
  );

  const { data: { product: fullSkuProduct } = {} as Query } = useQuery<Query>(
    queryFullSkuProduct,
    {
      variables: {
        productId: params.productId,
        fullSkuId: intialFullSkuId
      },
      skip: !intialFullSkuId,
      onCompleted: () => {
        swatchToggle = 1;
      }
    }
  );

  const { data: { contentFragment } = {} as Query } = useQuery<Query>(
    queryDraperyLink,
    {
      skip: product?.uxAttributes?.productType !== "drapery",
      errorPolicy: "ignore",
      variables: {
        key: `draperyLink-${brandCode}`
      }
    }
  );

  const handleClose = useCallback(() => {
    let currentUrlParams = new URLSearchParams(location.search);
    currentUrlParams.set("sale", (product?.onSale || "").toString());
    if (history) {
      history.replace({
        pathname: `${prefix}/catalog/product/product.jsp`,
        search: `?${currentUrlParams.toString()}`
      });
    }
    setOpen(!open);
  }, [product?.onSale, history, location?.search, open]);

  useEffect(() => {
    async function fetchQuery() {
      let response = await fetchRideAlongProduct?.(
        rideAlongData.casingProduct ?? ""
      );
      setRideAlongProduct(response);
    }
    if (rideAlongData.casingProduct && !rideAlongProduct) {
      fetchQuery();
    }
  }, [rideAlongData.casingProduct, rideAlongProduct, fetchRideAlongProduct]);

  /**
   * SHOP-1432 Maintains that any api call will merge with previous product data so it is never an empty object
   */
  const [combinedProduct, setCombinedProduct] = useState<Product>(
    {} as Product
  );

  useEffect(() => {
    setAvailableOptions(
      filterProductConfigurationOptionDetails(product?.productConfiguration)
        ?.optionDetails || ([] as ProductAvailableOptionSet[])
    );

    setAvailableSwatch(
      (product?.productConfiguration?.swatchData ?? {}) as ProductSwatchV2
    );
  }, [product?.id, product?.productConfiguration, locale]);

  useEffect(() => {
    setCombinedProduct(prevCombinedProduct => ({
      // SHOP-1432 Including previous state in the spread keeps the drop down options selected
      ...prevCombinedProduct,
      ...product,
      ...fullSkuProduct,
      ...productSwatchImage
    }));
  }, [fullSkuProduct, product, productSwatchImage]);

  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const isProductLoadingFirstTime = useChangeOnce(
    isProductLoading || isLoading
  );
  const [customOptions, setCustomOptions] = useState({
    diameter: { feet: "", inches: "" },
    width: { feet: "", inches: "" },
    length: { feet: "", inches: "" }
  });
  const internationalFlag = yn(env.FEATURE_INTERNATIONAL);

  // Dropdown Configurator
  let [lineItem, setLineItem] = useState<null | any>(product?.productLineItem);
  const [isLineItemQueryLoading, setLineItemQueryLoading] = useState(false);
  const _fullSkuId = product?.productLineItem?.sku?.inventory?.fullSkuId;

  const lazyQueryLineItemSku = useCallback(
    async variables => {
      const {
        data: { lineItemSku }
      } = await client.query({
        query: queryLineItemSku,
        variables: {
          productId: product?.id,
          fullSkuId: _fullSkuId,
          userType,
          monogrammable: product?.personalizeInfo?.monogrammable,
          postalCode,
          currencyCode,
          qty: 1,
          filter:
            product?.id === params?.productId || product?.onSale
              ? saleContextParam
              : null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          shouldFetchSku: true,
          nextGenDriven:
            siteId === RHUK_HOST || product?.customProduct
              ? false
              : yn(product?.nextGenDriven),
          shouldFetchCustomProductOptions: product?.customProduct,
          newPdpLayout: params?.version === "v2" || product?.newPdpLayout,
          ...variables
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return lineItemSku as ProductSku;
    },
    [
      client,
      product?.id,
      _fullSkuId,
      product?.personalizeInfo?.monogrammable,
      postalCode,
      currencyCode,
      siteId,
      measureSystem,
      internationalFlag,
      locale,
      userType,
      pageContent
    ]
  );

  const [multiSkuComponentsDataV2, setMultiSkuComponentsDataV2] = useState([]);
  const country = useCountry();

  const { skuAvailabilityStatus } = useQuantity({
    country,
    sku: lineItem?.sku?.inventory?.fullSkuId,
    maxQuantity: DEFAULT_MAX_QUANTITY,
    defaultQuantity: 0
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const lazyQueryMultiSkuComponents = useCallback(
    async (
      productId: string,
      selectedOptionIds: string[],
      qty?: string
    ): Promise<MultiSkuComponentsResponse> => {
      setLineItemQueryLoading(true);

      const {
        data: { pullMultiSkuComponents }
      } = await client.query({
        query: queryMultiSkuComponentsV2,
        variables: {
          productId,
          selectedOptionIds,
          siteId: siteId,
          currencyCode,
          countryCode: country,
          postalCode: pc,
          locale: locale,
          qty,
          userType: userType,
          newLayout: true
        }
      });
      setMultiSkuComponentsDataV2(pullMultiSkuComponents);
      return pullMultiSkuComponents ?? {};
    },
    [client, country, currencyCode, locale, postalCode]
  );

  const handleNewPDPLineItemCall = useCallback(
    async (id, options, qty) => {
      setLineItemQueryLoading(true);
      const finalOptions = {
        productId: id,
        selectedOptionIds: options,
        qty,
        shouldFetchSku: true
      };
      let responseSku = await lazyQueryLineItemSku(finalOptions);
      if (responseSku) {
        setLineItem({
          availableOptions: product?.productConfiguration?.optionDetails,
          sku: responseSku
        });
        setLineItemQueryLoading(false);
      }
    },
    [
      brandCode,
      lazyQueryLineItemSku,
      lazyQueryMultiSkuComponents,
      params?.productId,
      product?.productConfiguration?.optionDetails
    ]
  );

  const onChangeCb = useCallback(
    (selectedOptions: any, qty?: number): void => {
      async function fetchLineitemQuery() {
        const finalOptions = {
          productId: params?.productId,
          monogrammable: product?.personalizeInfo.monogrammable,
          fullSkuId: !selectedOptions?.length ? _fullSkuId : undefined,
          selectedOptionIds: selectedOptions?.length
            ? selectedOptions.map(o => o.split("_")).flat()
            : undefined,
          qty: 1,
          shouldFetchSku:
            getAllOptionTypes(product?.productConfiguration)?.length ===
            selectedOptions?.length,
          postalCode
        };
        let responseSku: any = {};
        if (
          params?.productId &&
          product?.productConfiguration &&
          getAllOptionTypes(product?.productConfiguration)?.length ===
            selectedOptions?.length
        ) {
          responseSku = await lazyQueryLineItemSku(finalOptions);
        }
        setLineItem({
          availableOptions: product?.productConfiguration?.optionDetails,
          sku: responseSku
        });

        // MULTISKU
        const isProductFullyConfigured =
          product?.productConfiguration?.optionDetails?.length ===
          selectedOptions?.length;

        const associatedMultiSkuBundleId = responseSku?.inventory?.fullSkuId;

        const isMultiSkuProduct =
          ["m", "M"].includes(associatedMultiSkuBundleId?.[0]) &&
          product?.metaProduct;

        if (isMultiSkuProduct && isProductFullyConfigured) {
          const selectedOptionIds = selectedOptions?.map(optionSet =>
            optionSet?.id?.toString()
          );
          await lazyQueryMultiSkuComponents(
            params?.productId,
            selectedOptionIds,
            brandCode
          );
        }
        setLineItemQueryLoading(false);
      }
      fetchLineitemQuery();
    },
    [_fullSkuId, lineItem, product, postalCode]
  );

  const {
    imageUrl: addOnImgUrl,
    refetchLineItemImage: refetchAddOnLineItemImage
  } = useProductImage({
    productId: "",
    options: []
  });

  const lazyQueryAddOnGetSku = useCallback(
    async variables => {
      const {
        data: { getSku }
      } = await client.query({
        query: querGetSku,
        variables: {
          productId: variables?.productId,
          userType,
          postalCode,
          currencyCode: currencyCode,
          qty: 1,
          filter: null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          fullSkuId: undefined,
          selectedOptionIds: variables?.selectedOptionIds
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return getSku as GetSkuResponse;
    },
    [
      client,
      postalCode,
      currencyCode,
      siteId,
      measureSystem,
      internationalFlag,
      locale,
      userType,
      pageContent
    ]
  );

  const addOnOptionInfoToRenderButtons = useMemo(() => {
    const addOnsInfo = product?.productAddons?.productAddonsInfo.map(addOn => ({
      id: addOn?.id,
      addOnAvailableOptions: addOn?.productConfiguration?.optionDetails,
      preSelectableOptionTypeIds: product?.productConfiguration?.optionDetails
        ?.map(mainItemOptionSet => {
          const matchingOptionSet =
            addOn?.productConfiguration?.optionDetails?.find(
              addOnOptionSet =>
                addOnOptionSet?.options[0]?.typeId ===
                mainItemOptionSet?.options[0]?.typeId
            );

          return matchingOptionSet?.options[0]?.typeId;
        })
        .filter(opt => opt)
    }));

    const addOnsWithPossibleButtonOptions = addOnsInfo?.map(addOn => ({
      ...addOn,
      remainingOptionsAfterPreselection: addOn?.addOnAvailableOptions?.filter(
        addOnOptionSet =>
          !(addOn?.preSelectableOptionTypeIds ?? []).includes(
            addOnOptionSet?.options[0]?.typeId
          )
      )
    }));
    const addOn = addOnsWithPossibleButtonOptions
      ?.filter(
        addOn =>
          addOn?.addOnAvailableOptions?.length &&
          addOn?.remainingOptionsAfterPreselection?.length
      )
      ?.find(Boolean);

    return {
      id: addOn?.id,
      renderButtonOption:
        addOn?.remainingOptionsAfterPreselection?.find(Boolean)
    };
  }, [product]);

  const onAddonConfigurationChange = useCallback(
    (addOnProduct: AddOnDataType, addOnConfigModes: AddOnConfigModes): void => {
      async function fetchAddOnLineItemQuery() {
        const {
          mainItemOptionsChanged,
          // quantityChanged,
          panelProdComputation,
          productUnchecked
        } = addOnConfigModes;
        const optionIds = addOnProduct?.selectedOptions?.map(
          selectedOption => selectedOption.id
        );

        const addOnData = product?.productAddons?.productAddonsInfo.find(
          addOn => addOn.id === addOnProduct?.productId
        );

        // if (quantityChanged) {
        //   setAddOnsData(existingAddOns => {
        //     const otherAddOns = existingAddOns.filter(
        //       existingAddOn =>
        //         existingAddOn?.productId !== addOnProduct?.productId
        //     );

        //     const currAddOn = existingAddOns.find(
        //       existingAddOn =>
        //         existingAddOn?.productId === addOnProduct?.productId
        //     );

        //     const newAddOnSet = (
        //       currAddOn
        //         ? addOnProduct?.productSelected || mainItemOptionsChanged
        //           ? [
        //               ...otherAddOns,
        //               {
        //                 ...currAddOn,
        //                 qty: addOnProduct?.qty
        //               }
        //             ]
        //           : [...otherAddOns]
        //         : [
        //             ...otherAddOns,
        //             {
        //               ...addOnProduct,
        //               qty: addOnProduct?.qty
        //             }
        //           ]
        //     ) as AddOnDataType[];

        //     return newAddOnSet;
        //   });
        // } else

        if (!panelProdComputation) {
          if (productUnchecked) {
            setAddOnsData(existingAddOns => {
              const otherAddOns = existingAddOns?.filter(
                existingAddOn =>
                  existingAddOn?.productId !== addOnProduct?.productId
              );

              const currAddOn = existingAddOns?.find(
                existingAddOn =>
                  existingAddOn?.productId === addOnProduct?.productId
              );

              const newAddOnSet = (
                currAddOn
                  ? [
                      ...otherAddOns,
                      {
                        ...addOnProduct,
                        productSelected: addOnProduct?.productSelected,
                        fullSkuId: null,
                        colorizedImgURL: null,
                        spo: null,
                        preBillMessage: null,
                        pricing: null,
                        qty: addOnProduct?.qty,
                        itemOptions:
                          product?.productConfiguration?.optionDetails,
                        selectedOptions: addOnProduct?.selectedOptions
                      }
                    ]
                  : [...otherAddOns]
              ) as AddOnDataType[];

              return newAddOnSet;
            });
          } else {
            setLineItemQueryLoading(true);
            const {
              data: {
                productImage: { imageUrl }
              }
            } = await refetchAddOnLineItemImage({
              productId: addOnProduct?.productId ?? "",
              selectedOptionIds: optionIds
            });

            let lineItemSkuResponse: Maybe<ProductSku> = null;
            let getSkuResponse: GetSkuResponse = null;

            const getSkuPromise = lazyQueryAddOnGetSku({
              productId: addOnProduct?.productId,
              selectedOptionIds: optionIds
            });

            if (addOnProduct?.optionsLength === optionIds?.length) {
              [lineItemSkuResponse, getSkuResponse] = await Promise.all([
                lazyQueryLineItemSku({
                  productId: addOnProduct?.productId,
                  postalCode: postalCode,
                  selectedOptionIds: optionIds?.length ? optionIds : undefined,
                  qty: addOnProduct?.qty,
                  shouldFetchSku:
                    addOnProduct?.optionsLength ===
                    addOnProduct?.selectedOptions?.length
                }),
                getSkuPromise
              ]);
              setAddOnsData(existingAddOns => {
                const otherAddOns = existingAddOns.filter(
                  existingAddOn =>
                    existingAddOn?.productId !== addOnProduct?.productId
                );

                const currAddOn = existingAddOns.find(
                  existingAddOn =>
                    existingAddOn?.productId === addOnProduct?.productId
                );

                const newAddOnSet = (
                  currAddOn
                    ? addOnProduct?.productSelected || mainItemOptionsChanged
                      ? [
                          ...otherAddOns,
                          {
                            ...currAddOn,
                            productSelected: addOnProduct?.productSelected,
                            fullSkuId: getSkuResponse?.fullSkuId,
                            colorizedImgURL: imageUrl,
                            spo: lineItemSkuResponse?.restrictions?.spo,
                            preBillMessage:
                              lineItemSkuResponse?.restrictions?.preBillMessage,
                            pricing: lineItemSkuResponse?.info?.skuPriceInfo,
                            qty: addOnProduct?.qty,
                            itemOptions:
                              product?.productConfiguration?.optionDetails,
                            selectedOptions: addOnProduct?.selectedOptions,
                            sku: lineItemSkuResponse
                          }
                        ]
                      : [...otherAddOns]
                    : [
                        ...otherAddOns,
                        {
                          ...addOnProduct,
                          fullSkuId: getSkuResponse?.fullSkuId,
                          colorizedImgURL: imageUrl,
                          spo: lineItemSkuResponse?.restrictions?.spo,
                          preBillMessage:
                            lineItemSkuResponse?.restrictions?.preBillMessage,
                          pricing: lineItemSkuResponse?.info?.skuPriceInfo,
                          qty: addOnProduct?.qty,
                          itemOptions: availableOptions,
                          selectedOptions: addOnProduct?.selectedOptions,
                          sku: lineItemSkuResponse
                        }
                      ]
                ) as AddOnDataType[];

                return newAddOnSet;
              });
            }
          }
        } else {
          if (productUnchecked) {
            setPanelAddOnsData(existingAddOns => {
              const otherAddOns = existingAddOns?.filter(
                existingAddOn =>
                  existingAddOn?.productId !== addOnProduct?.productId
              );

              const currAddOn = existingAddOns?.find(
                existingAddOn =>
                  existingAddOn?.productId === addOnProduct?.productId
              );

              const newAddOnSet = (
                currAddOn
                  ? [
                      ...otherAddOns,
                      {
                        ...addOnProduct,
                        productSelected: addOnProduct?.productSelected,
                        fullSkuId: null,
                        colorizedImgURL: null,
                        spo: null,
                        preBillMessage: null,
                        pricing: null,
                        qty: addOnProduct?.qty,
                        itemOptions:
                          product?.productConfiguration?.optionDetails,
                        selectedOptions: addOnProduct?.selectedOptions
                      }
                    ]
                  : [...otherAddOns]
              ) as AddOnDataType[];

              return newAddOnSet;
            });
          } else {
            setLineItemQueryLoading(true);
            const {
              data: {
                productImage: { imageUrl }
              }
            } = await refetchAddOnLineItemImage({
              productId: addOnProduct?.productId ?? "",
              selectedOptionIds: optionIds
            });

            let lineItemSkuResponse: Maybe<ProductSku> = null;
            let getSkuResponse: GetSkuResponse = null;

            const getSkuPromise = lazyQueryAddOnGetSku({
              productId: addOnProduct?.productId,
              selectedOptionIds: optionIds
            });
            if (addOnProduct?.optionsLength === optionIds?.length) {
              [lineItemSkuResponse, getSkuResponse] = await Promise.all([
                lazyQueryLineItemSku({
                  productId: addOnProduct?.productId,
                  postalCode: postalCode,
                  selectedOptionIds: optionIds?.length ? optionIds : undefined,
                  qty: addOnProduct?.qty,
                  shouldFetchSku:
                    addOnProduct?.optionsLength ===
                    addOnProduct?.selectedOptions?.length
                }),
                getSkuPromise
              ]);
              setPanelAddOnsData(existingAddOns => {
                const otherAddOns = existingAddOns.filter(
                  existingAddOn =>
                    existingAddOn?.productId !== addOnProduct?.productId
                );

                const currAddOn = existingAddOns.find(
                  existingAddOn =>
                    existingAddOn?.productId === addOnProduct?.productId
                );

                const newAddOnSet = (
                  currAddOn
                    ? addOnProduct?.productSelected || mainItemOptionsChanged
                      ? [
                          ...otherAddOns,
                          {
                            ...currAddOn,
                            productSelected: addOnProduct?.productSelected,
                            fullSkuId: getSkuResponse?.fullSkuId,
                            colorizedImgURL: imageUrl,
                            spo: lineItemSkuResponse?.restrictions?.spo,
                            preBillMessage:
                              lineItemSkuResponse?.restrictions?.preBillMessage,
                            pricing: lineItemSkuResponse?.info?.skuPriceInfo,
                            qty: addOnProduct?.qty,
                            itemOptions:
                              product?.productConfiguration?.optionDetails,
                            selectedOptions: addOnProduct?.selectedOptions,
                            sku: lineItemSkuResponse,
                            productConfiguration:
                              addOnData?.productConfiguration
                          }
                        ]
                      : [...otherAddOns]
                    : [
                        ...otherAddOns,
                        {
                          ...addOnProduct,
                          fullSkuId: getSkuResponse?.fullSkuId,
                          colorizedImgURL: imageUrl,
                          spo: lineItemSkuResponse?.restrictions?.spo,
                          preBillMessage:
                            lineItemSkuResponse?.restrictions?.preBillMessage,
                          pricing: lineItemSkuResponse?.info?.skuPriceInfo,
                          qty: addOnProduct?.qty,
                          itemOptions: availableOptions,
                          selectedOptions: addOnProduct?.selectedOptions,
                          sku: lineItemSkuResponse,
                          productConfiguration: addOnData?.productConfiguration
                        }
                      ]
                ) as AddOnDataType[];

                return newAddOnSet;
              });
            }
          }
        }
        setLineItemQueryLoading(false);
      }
      fetchAddOnLineItemQuery();
    },
    [_fullSkuId, lineItem, postalCode, addOnsData, panelAddOnsData]
  );

  const handleConfigPrice = useCallback(
    async (isConfigured = false) => {
      if (product?.id) {
        let responseSku = {};
        if (availableOptions?.length) {
          const selectedOptions = availableOptions
            ?.flatMap(item => item?.options || [])
            .filter(option => option?.status === "selected");
          const selectedOptionIds = selectedOptions.map(item => item.id);

          responseSku = await lazyQueryLineItemSku({
            fullSkuId: undefined,
            selectedOptionIds,
            shouldFetchSku: true
          });
          setLineItem({ availableOptions, sku: responseSku });
        }
        setLineItemQueryLoading(false);
        return { availableOptions, sku: responseSku };
      }
    },
    [product]
  );

  useDidMountEffect(() => {
    setLineItem(null);
  }, [locale]);

  const { printFrameRef, enabled, showLoader, injectIframe, setIframeLoaded } =
    usePrintHijack({
      enabled: yn(env.FEATURE_IFRAME_PRINT),
      spyElementId: "pdp-print-load-check"
    });

  const printSrc = useMemo(() => {
    const fullSkuId =
      lineItem?.sku?.fulfillmentEta?.fullSkuId ||
      lineItem?.sku?.inventory?.fullSkuId ||
      combinedProduct?.sku?.fulfillmentEta?.fullSkuId ||
      combinedProduct?.sku?.inventory?.fullSkuId ||
      intialFullSkuId;
    return `${prefix}/print-product.jsp?${objectToQueryString({
      ...productVariables,
      userType,
      sale: params.sale,
      fullSkuId
    })}`;
  }, [
    lineItem?.sku?.fulfillmentEta?.fullSkuId,
    lineItem?.sku?.inventory?.fullSkuId,
    combinedProduct?.sku?.fulfillmentEta?.fullSkuId,
    combinedProduct?.sku?.inventory?.fullSkuId,
    intialFullSkuId,
    prefix,
    productVariables,
    userType,
    params
  ]);

  // product is 404 if product cannot load.
  if (!!productError && !product) {
    return <Redirect to="/error/product-not-found.jsp" />;
  }

  return (
    <>
      <PrintFrame
        showLoader={showLoader}
        injectIframe={injectIframe}
        onLoad={() => setIframeLoaded(true)}
        ref={printFrameRef}
        enabled={enabled}
        src={printSrc}
      />
      <PDPV2
        lineItem={lineItem}
        data={combinedProduct}
        upSellProducts={upsellProducts}
        productSwatchLoading={productSwatchLoading}
        fullSkuId={intialFullSkuId || undefined}
        draperyLink={contentFragment?.text ?? ""}
        isProductLoading={
          isSSRToggledWithCSR && !document.referrer && w.hardReload
            ? false
            : isProductLoadingFirstTime
        }
        authenticated={keycloak?.authenticated}
        customOptions={customOptions}
        setCustomOptions={setCustomOptions}
        saleContextFilter={saleContextParam}
        postalCode={postalCode}
        onOptionsChange={setOptions}
        onTrimOptionsChange={setTrimOptions}
        openGiftCardTerms={openGiftCardTerms}
        setOpenGiftCardTerms={setOpenGiftCardTerms}
        giftCardTerms={giftCardTerms}
        rideAlongData={rideAlongData}
        setRideAlongData={setRideAlongData}
        rideAlongProduct={rideAlongProduct}
        rProducts={relatedProductsQuery}
        onChange={onChangeCb}
        isLineItemQueryLoading={isLineItemQueryLoading}
        handleConfigPrice={handleConfigPrice}
        multiSkuComponentsDataV2={multiSkuComponentsDataV2}
        onAddOnConfigChange={onAddonConfigurationChange}
        addOnsData={addOnsData}
        panelAddOnsData={panelAddOnsData}
        setPanelAddOnsData={setPanelAddOnsData}
        // @ts-ignore
        addOnOptionInfoToRenderButtons={addOnOptionInfoToRenderButtons}
        // panelProdConfigurationData={panelProdConfigurationData}
        handleNewPDPLineItemCall={handleNewPDPLineItemCall}
        skuAvailabilityStatus={skuAvailabilityStatus}
        setAvailableOptions={setAvailableOptions}
        availableOptions={availableOptions}
        availableSwatch={availableSwatch}
        setAvailableSwatch={setAvailableSwatch}
        lazyQueryMultiSkuComponents={lazyQueryMultiSkuComponents}
        currencyCode={currencyCode}
      />
      {open && (
        <RHDialog
          solidWhite
          open={open}
          onClose={handleClose}
          fullScreen={false}
          DialogContentProps={{
            style: { marginTop: "-30px" }
          }}
        >
          <Typography className={typographyStyles.rhBaseH3}>
            {pageContent?.PRODUCT_NO_LONGER_SALE}
          </Typography>
        </RHDialog>
      )}
    </>
  );
};

const serverDefault = () => {
  const { app } = useAppData();
  const env = useEnv();
  const req = getReqContext();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [trimOptions, setTrimOptions] = useState<string[] | null>(null);
  const [openGiftCardTerms, setOpenGiftCardTerms] = useState<boolean>(false);
  const brandCode = useBrand();
  const prefix = useLocalization();
  const locale = countryLocaleMapper[getCountryFromUrl()] || "en-us";
  const pc = useIsoCookies(["pc"])?.pc;
  const params = useParams({
    productId: "",
    fullSkuId: "",
    sale: ""
  });
  //for canonical URL support
  const _params = useFetchParams(
    req?.path,
    yn(env.FEATURE_URL_CHANGE) ? "pdp" : "product.jsp"
  );
  let [productId] = _params;
  if (params.productId === "") {
    params.productId = productId?.replace("&ct=true", "");
  }
  const { keycloak } = useKeycloak();
  const { userType } = useRhUserAtomValue();
  const client = useApolloClient();
  const siteId = useSite();
  const { pageContent } = useFetchModel("/admin/product", true, false);
  // More about RideAlong: SHOP-1647.
  const [rideAlongProduct, setRideAlongProduct] = useState<Product | null>(
    null
  );
  const [rideAlongData, setRideAlongData] =
    useState<RideAlongData>(defaultRideAlongData);
  const [availableOptions, setAvailableOptions] = useState<
    ProductAvailableOptionSet[]
  >([]);

  const [availableSwatch, setAvailableSwatch] = useState<ProductSwatchV2>(
    {} as ProductSwatchV2
  );

  const giftCardTerms =
    useQuery<Query>(queryGiftCardTerms, {
      variables: { key: "rh-gift-card-pdp" }
    }).data?.contentFragment?.text ?? "";

  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const { previousState } = useUserPreferences();
  const measureSystem = useMeasurementType();
  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode: app.postalCode || pc,
    userType
  });
  const [customOptions, setCustomOptions] = useState({
    diameter: { feet: "", inches: "" },
    width: { feet: "", inches: "" },
    length: { feet: "", inches: "" }
  });

  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );

  const productVariables = useMemo(() => {
    return {
      productId: params?.productId,
      fullSkuId: params?.fullSkuId,
      filter: saleContextParam,
      userType: userType,
      siteId,
      currencyCode: currencyCode,
      measureSystem,
      locale: FEATURE_INTERNATIONAL ? locale : undefined
    };
  }, [
    params.productId,
    params.fullSkuId,
    saleContextParam,
    userType,
    siteId,
    currencyCode,
    measureSystem,
    FEATURE_INTERNATIONAL,
    locale
  ]);

  const fetchRideAlongProduct = useCallback<
    (productId: string, variables?: any) => Promise<Product>
  >(
    async (productId, variables) => {
      const {
        data: { product }
      } = await client.query({
        query: queryRideAlongProduct,
        variables: {
          productId,
          filter: saleContextParam || null,
          userType: userType,
          siteId,
          currencyCode,
          measureSystem,
          ...variables
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return product;
    },
    [saleContextParam, siteId, client, currencyCode, measureSystem, userType]
  );

  // Ran into an issue where refetch throws an error instead of putting the error into the hook error output
  // see: https://github.com/apollographql/react-apollo/issues/3571
  // Found a workaround using the errorPolicy
  // errorPolicy info from apollo docs:
  //    "If you set errorPolicy to all, useQuery does not discard query
  //     response data, allowing you to render partial results."
  const {
    loading: isProductLoading,
    data: { product } = {} as Query,
    error: productError,
    refetch
  } = useQuery<Query>(queryProductV2, {
    variables: productVariables,
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    skip: !params.productId,
    errorPolicy: "all"
  });

  const { data: { upsellProducts } = {}, refetch: refetchUpSellProducts } =
    useQuery(queryUpsSellProducts, {
      variables: productVariables,
      skip: !(product?.id || params?.productId)
    });

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH3"]
  });

  const intialFullSkuId = params.fullSkuId || product?.preconfiguredSku;

  const {
    loading: productSwatchLoading,
    data: { product: productSwatchImage } = {} as Query
  } = useQuery<Query>(queryProductSwatchImage, {
    variables: {
      productId: product?.id || params?.productId,
      optionIds: options,
      siteId,
      locale,
      trimOptionIds: trimOptions ?? null,
      fullskuId: intialFullSkuId ?? ""
    }
  });

  useDidMountEffect(() => {
    refetch(productVariables);
    refetchUpSellProducts(productVariables);
  }, [JSON.stringify(productVariables)]);

  const relatedProductVariable = useMemo(() => {
    return {
      productIds: product?.relatedProductIds,
      filter: saleContextParam,
      userType: userType,
      siteId,
      currencyCode: currencyCode || "USD",
      measureSystem,
      locale: FEATURE_INTERNATIONAL ? locale : undefined
    };
  }, [
    userType,
    siteId,
    currencyCode,
    measureSystem,
    FEATURE_INTERNATIONAL,
    locale,
    saleContextParam,
    product?.relatedProductIds
  ]);

  const { data: { relatedProductsQuery } = {} as Query } = useQuery<Query>(
    queryRelatedProducts,
    {
      variables: relatedProductVariable,
      skip: !Boolean(relatedProductVariable?.productIds?.length)
    }
  );

  const { data: { product: fullSkuProduct } = {} as Query } = useQuery<Query>(
    queryFullSkuProduct,
    {
      variables: {
        productId: product?.id || params?.productId,
        fullSkuId: intialFullSkuId
      },
      skip: !intialFullSkuId
    }
  );
  if (fullSkuProduct) {
    swatchToggle = 1;
  }

  const { data: { contentFragment } = {} as Query } = useQuery<Query>(
    queryDraperyLink,
    {
      skip: product?.uxAttributes?.productType !== "drapery",
      errorPolicy: "ignore",
      variables: {
        key: `draperyLink-${brandCode}`
      }
    }
  );

  const handleClose = useCallback(() => {
    let currentUrlParams = new URLSearchParams(req?.query.toString());
    currentUrlParams.set("sale", (product?.onSale || "").toString());
    if (history) {
      history.replace({
        pathname: `${prefix}/catalog/product/product.jsp`,
        search: `?${currentUrlParams.toString()}`
      });
    }
    setOpen(!open);
  }, [product?.onSale, history, req?.query, open]);

  useEffect(() => {
    async function fetchQuery() {
      let response = await fetchRideAlongProduct?.(
        rideAlongData.casingProduct ?? ""
      );
      setRideAlongProduct(response);
    }
    if (rideAlongData.casingProduct && !rideAlongProduct) {
      fetchQuery();
    }
  }, [rideAlongData.casingProduct, rideAlongProduct, fetchRideAlongProduct]);

  /**
   * SHOP-1432 Maintains that any api call will merge with previous product data so it is never an empty object
   */
  const [combinedProduct, setCombinedProduct] = useState<Product>(
    {} as Product
  );

  useEffect(() => {
    setAvailableOptions(
      filterProductConfigurationOptionDetails(product?.productConfiguration)
        ?.optionDetails || ([] as ProductAvailableOptionSet[])
    );

    setAvailableSwatch(
      (product?.productConfiguration?.swatchData ?? {}) as ProductSwatchV2
    );
  }, [product?.productConfiguration, currencyCode]);

  useEffect(() => {
    setCombinedProduct(prevCombinedProduct => ({
      // SHOP-1432 Including previous state in the spread keeps the drop down options selected
      ...prevCombinedProduct,
      ...product,
      ...fullSkuProduct,
      ...productSwatchImage
    }));
  }, [fullSkuProduct, product, productSwatchImage]);

  const isProductLoadingFirstTime = useChangeOnce(isProductLoading);

  // product is 404 if product cannot load.
  if (!!productError && !product) {
    return <Redirect to="/error/product-not-found.jsp" />;
  }

  return (
    <>
      <PDPV2
        // data={combinedProduct}
        lineItem={product?.productLineItem}
        data={{
          ...product,
          ...fullSkuProduct,
          ...productSwatchImage
        }}
        upSellProducts={upsellProducts}
        currencyCode={currencyCode}
        customOptions={customOptions}
        setCustomOptions={setCustomOptions}
        productSwatchLoading={productSwatchLoading}
        fullSkuId={intialFullSkuId || undefined}
        draperyLink={contentFragment?.text ?? ""}
        isProductLoading={isProductLoadingFirstTime}
        authenticated={keycloak?.authenticated}
        saleContextFilter={saleContextParam}
        postalCode={app.postalCode || pc}
        onOptionsChange={setOptions}
        onTrimOptionsChange={setTrimOptions}
        openGiftCardTerms={openGiftCardTerms}
        setOpenGiftCardTerms={setOpenGiftCardTerms}
        giftCardTerms={giftCardTerms}
        rideAlongData={rideAlongData}
        setRideAlongData={setRideAlongData}
        rideAlongProduct={rideAlongProduct}
        rProducts={relatedProductsQuery}
        setAvailableOptions={setAvailableOptions}
        availableOptions={
          (product?.productConfiguration?.optionDetails ??
            []) as ProductAvailableOptionSet[]
        }
        availableSwatch={
          (product?.productConfiguration?.swatchData ?? []) as ProductSwatchV2
        }
        setAvailableSwatch={setAvailableSwatch}
      />
      {open && (
        <RHDialog
          solidWhite
          open={open}
          onClose={handleClose}
          fullScreen={false}
          DialogContentProps={{
            style: { marginTop: "-30px" }
          }}
        >
          <Typography className={typographyStyles.rhBaseH3}>
            {pageContent?.PRODUCT_NO_LONGER_SALE}
          </Typography>
        </RHDialog>
      )}
    </>
  );
};

export default productProps =>
  processEnvServer ? serverDefault() : clientDefault(productProps);
