import React, { useCallback, useState, useEffect, useMemo } from "react";
import { queryCollectionGallery } from "graphql-client/queries/collection-gallery";
import { useQuery } from "@apollo/client";
import useAppData from "hooks/useAppData";
import useParams from "hooks/useParams";
import { useLocalization } from "hooks/useLocalization";
import { useEnv } from "hooks/useEnv";
import {
  COLLECTION_GALLERY_DEFAULT_COUNT,
  CG_ITEMS_PER_PAGE_PREFERENCE
} from "utils/constants";
import { NetworkStatus } from "@apollo/client";
import BelowNavPromo from "component-promo/BelowNavPromo";
import { shouldShowBelowNavPromo } from "utils/belowNavPromo";
import useUserContext from "hooks/useUserContext";
import { processEnvServer } from "hooks/useSsrHooks";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useIsoCookies } from "hooks/useIsoCookies";
import { processEnvServer as isServer } from "hooks/useSsrHooks";
import yn from "yn";
import useMeasurementType from "hooks/useMeasurementType";
import useIsoRedirect from "hooks-use-isoredirect";
import useLocale from "hooks-use-locale/useLocale";
import useUserPreferences from "hooks/useUserPreferences";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import { useHistory, useLocation } from "react-router-dom";
import { SaleContextFilter } from "graphql-client/queries/app";
import useSite from "hooks/useSite";
import { useFetchParams } from "hooks/useFetchParams/useFetchParams";
import { getReqContext } from "utils/reqContext";
import { useRhUserAtomValue } from "hooks/atoms";
import { CG } from "page-collection-gallery/index";
import memoize from "utils/memoize";

const DEFAULT_PAGINATION_CG_COUNT = "24";

const NextgenCollectionGallery = () => {
  const { app } = useAppData();
  const env = useEnv();
  const nextgenCookie = useIsoCookies(["nextgencg"], true)?.nextgencg;
  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(CG_ITEMS_PER_PAGE_PREFERENCE)
    : undefined;
  const params = useParams({
    categoryId: "",
    siteId: "",
    sale: "false",
    count: storedItemsPerPagePreference ?? DEFAULT_PAGINATION_CG_COUNT,
    start: ""
  });
  const req = getReqContext();
  const _params = useFetchParams(
    processEnvServer ? req?.path : location.pathname,
    yn(env.FEATURE_URL_CHANGE) ? "cg" : "collections.jsp"
  );

  let [categoryId] = _params;
  if (params.categoryId === "") {
    params.categoryId = categoryId?.replace("&ct=true", "");
  }

  const userContext = useUserContext();
  const { userType } = useRhUserAtomValue();
  const prefix = useLocalization();
  const { search: locationSearch } = useLocation();
  const measureSystem = useMeasurementType();
  const isDeepLoad =
    useIsoCookies(["deepload"])?.deepload === "true" ||
    yn(env.FEATURE_DEEPLOAD);
  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);

  const { previousState } = useUserPreferences();
  const brand = useSite();
  const siteId = useMemo(
    () => (params?.siteId ? params?.siteId : brand),
    [brand, params?.siteId]
  );
  const locale = useLocale();
  const pc = useIsoCookies(["pc"])?.pc;
  const history = useHistory();

  const postalCode = pc || app.postalCode;
  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode: postalCode,
    userType
  });

  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const isPaginationFeatureEnabled = yn(env.FEATURE_CG_PAGINATION);
  const isPaginationLoadFullPageEnabled =
    yn(env.FEATURE_CG_PAGINATION_LOAD_FULL_PAGE) && isPaginationFeatureEnabled;

  const searchParams = new URLSearchParams(locationSearch);
  const loadAllParam = searchParams.get("loadAll") === "true";
  // lazy load is enabled when the url has loadAll param, which lazyloads items after every 100 items
  const isLazyLoadEnabled = useMemo(() => {
    return (
      !isPaginationFeatureEnabled ||
      (isPaginationLoadFullPageEnabled && loadAllParam)
    );
  }, [loadAllParam]);

  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );
  const variables = {
    categoryId: params.categoryId,
    filter: saleContextParam || null,
    start:
      isPaginationFeatureEnabled && params.start ? Number(params.start) : 0,
    siteId,
    measureSystem,
    count: isDeepLoad
      ? undefined
      : isPaginationFeatureEnabled && params.count
      ? Number(params.count)
      : COLLECTION_GALLERY_DEFAULT_COUNT,
    currencyCode,
    countryCode: previousState.country,
    locale: FEATURE_INTERNATIONAL ? locale : undefined,
    userType,
    nextgenDriven: nextgenCookie === "true"
  };
  const {
    data: { collectionGallery } = {} as Query,
    networkStatus,
    error,
    refetch,
    fetchMore,
    loading: collectionGalleryLoading
  } = useQuery<Query>(queryCollectionGallery, {
    variables,
    skip: !params.categoryId,
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first"
  });

  const debug = useIsoCookies(["debug"], true)?.debug;

  useEffect(() => {
    if (!collectionGalleryLoading) {
      const galleryLength =
        collectionGallery?.collectionGallery?.filter(
          item =>
            (item?.collection && item?.cgBannerTemplate) ||
            item?.promotionDisplayItems?.custombanner
        )?.length || 0;
      // redirect to home page if collection is empty
      if (galleryLength === 0 && !debug) {
        return history.push(FEATURE_INTERNATIONAL ? prefix : "/");
      }
    }
  }, [
    collectionGallery,
    history,
    prefix,
    FEATURE_INTERNATIONAL,
    collectionGalleryLoading
  ]);

  const showBelowNavPromo = shouldShowBelowNavPromo(
    collectionGallery?.collectionGallery?.[0]
  );
  const isRHR = yn(env?.FEATURE_RHR);

  useDidMountEffect(() => {
    refetch?.();
    // Used for Canadian customers - popup comes up and requires zip entry.
    // Once zip is entered, this will force page/data reload.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode, userType]);

  const amountRemaining = collectionGallery?.amountRemaining;
  const totalNumRecs =
    collectionGallery?.totalNumRecs ||
    collectionGallery?.amountRemaining + collectionGallery?.nextStart;

  const onLoadMoreCb = useCallback(
    (page?: number, start?: number) => {
      // InfiniteScroll is calling this on first scroll
      // even if hasMoreItems is false
      // subsequent scrolling events when hasMoreItems is false
      // are properly ignored
      if ((amountRemaining ?? 0) < 1) {
        return;
      }
      if (isPaginationLoadFullPageEnabled && !isLazyLoadEnabled) {
        return;
      }
      setIsNextPageLoading(true);

      fetchMore({
        variables: {
          start: collectionGallery?.nextStart ?? 0,
          count:
            isPaginationFeatureEnabled && params.count
              ? Number(params.count)
              : COLLECTION_GALLERY_DEFAULT_COUNT,
          collectionGallery,
          currencyCode
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (networkStatus === NetworkStatus.fetchMore || !fetchMoreResult) {
            return {} as Query;
          }
          // TODO: @GA4M-56 - View_item_list not required in current migration
          // let collectionTitle, collectionId;
          // const itemList =
          //   fetchMoreResult?.collectionGallery?.collectionGallery?.map(
          //     (rec, index) => {
          //       if (index === 0) {
          //         collectionTitle = rec?.displayName;
          //         collectionId = rec?.id;
          //       }
          //       return {
          //         displayName: rec?.displayName,
          //         productId: rec?.id,
          //         quantity: null,
          //         sku: null,
          //         price: null,
          //         currencyCode: countryNameMapper[getCountryFromUrl() ?? "US"],
          //         category: rec?.displayName
          //       };
          //     }
          //   );

          // analyticsLoader(a =>
          //   a.emitAnalyticsEvent(
          //     document.querySelector("#spa-root > *")! as HTMLElement,
          //     a.EVENTS.VIEW_ITEM_LIST.INT_TYPE,
          //     {
          //       itemList,
          //       item_list_name: true,
          //       item_list_name_view_item: collectionTitle,
          //       item_list_id: collectionId
          //     }
          //   )
          // );
          if (isPaginationFeatureEnabled && !isLazyLoadEnabled) {
            return fetchMoreResult;
          }
          return {
            ...fetchMoreResult,
            collectionGallery: {
              ...(fetchMoreResult?.collectionGallery || {}),
              collectionGallery: [
                ...(prev?.collectionGallery?.collectionGallery ?? []),
                ...(fetchMoreResult?.collectionGallery?.collectionGallery ?? [])
              ]
            }
          };
        }
      });
    },
    [
      userContext.currencyCode,
      userType,
      isNextPageLoading,
      networkStatus,
      collectionGallery?.nextStart,
      amountRemaining,
      fetchMore
    ]
  );

  useEffect(() => {
    if (isNextPageLoading) {
      setIsNextPageLoading(false);
    }
  }, [collectionGallery?.collectionGallery]);

  if (!!error && !collectionGallery?.collectionGallery?.length) {
    return useIsoRedirect(`${prefix}/error/category-not-found.jsp`, "redirect");
  }

  // Categories with only 1 item in the response need to redirect to the PG page instead
  if (collectionGallery?.collectionGallery?.length === 1 && !debug) {
    return useIsoRedirect(
      `${prefix}${collectionGallery?.collectionGallery?.[0]?.bannerLink}`,
      "redirect"
    );
  }

  return (
    <>
      {showBelowNavPromo && !isRHR && <BelowNavPromo />}
      <CG
        amountRemaining={collectionGallery?.amountRemaining}
        nextStart={collectionGallery?.nextStart}
        data={collectionGallery?.collectionGallery}
        networkStatus={networkStatus}
        onLoadMore={onLoadMoreCb}
        isDeepLoad={!!isDeepLoad}
        collectionGalleryLoading={collectionGalleryLoading}
        saleContextParam={saleContextParam}
        isNextPageLoading={isNextPageLoading}
        isLazyLoadEnabled={isLazyLoadEnabled}
        totalNumRecs={totalNumRecs}
        bannerTitle={collectionGallery?.bannerTitle || ""}
        bannerSubTitle={collectionGallery?.bannerSubTitle || ""}
      />
    </>
  );
};

export default memoize(NextgenCollectionGallery as any);
