import { useApolloClient, useQuery } from "@apollo/client";
import analyticsLoader from "analytics/loader";
import classNames from "classnames";
import AddAllProductActions from "component-add-all-product-actions";
import CustomProduct, { CustomProductMapper } from "component-custom-product";
import HeroImage from "component-hero-image";
import HTMLHeadTags from "component-html-head-tags";
import { ModalTrigger } from "component-modal-trigger";
import PrintSection from "component-print-section";
import { addOnDataType } from "component-product-addon/types";
import RHDialogTitle from "component-rh-dialog-title";
import { RHLink } from "component-rh-link";
import {
  RHLinkListCollapse,
  RHLinkListItem,
  RHLinkListSwatchAnchor
} from "component-rh-link-list";
import Slider from "component-slider";
import CustomWindowDialog from "dialog-custom-window";
import FAQDialog from "dialog-faq";
import { queryProduct } from "graphql-client-product";
import { SaleContextFilter } from "graphql-client/queries/app";
import { queryRideAlongProduct } from "graphql-client/queries/base-product";
import { queryDraperyLink } from "graphql-client/queries/drapery-link";
import { queryFullSkuProduct } from "graphql-client/queries/full-sku-product";
import { queryGiftCardTerms } from "graphql-client/queries/gift-card-terms";
import { queryProductSwatchImage } from "graphql-client/queries/product-swatch-image";
import he from "he";
import useBrand from "hooks-use-brand/useBrand";
import useIsoRedirect from "hooks-use-isoredirect";
import useLocale from "hooks-use-locale/useLocale";
import useAppData from "hooks/useAppData";
import { useChangeOnce } from "hooks/useChangeOnce";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useEnv } from "hooks/useEnv";
import { useFetchParams } from "hooks/useFetchParams/useFetchParams";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useLocalization } from "hooks/useLocalization";
import useMeasurementType from "hooks/useMeasurementType";
import useMediaString from "hooks/useMediaString";
import useParams from "hooks/useParams";
import useResponsiveGridSpacing from "hooks/useResponsiveGridSpacing";
import useSite from "hooks/useSite";
import { Hidden, processEnvServer } from "hooks/useSsrHooks";
import useState from "hooks/useState";
import useTypographyStyles from "hooks/useTypographyStyles";
import useUnsafeEffect from "hooks/useUnsafeEffect";
import useUserPreferences from "hooks/useUserPreferences";
import RHArrowIcon from "icon-arrow";
import GrommetArrowThick from "icon-grommet-arrow-thick";
import FormDialog from "layout-form-dialog";
import { isEmpty } from "lodash";
import template from "lodash/template";
import { MonogramOrderProps } from "page-monogram-dialog";
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { InView } from "react-intersection-observer";
import { Redirect } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { countryNameMapper } from "resources/countries-config.json";
import { ON_SALE } from "resources/page-level-resources-schemas/product.json";
import {
  CARE,
  PERSONALIZATION
} from "resources/page-level-resources-schemas/products.json";
import BaseProductSkeleton from "skeleton-base-product";
import RelatedProductCardSkeleton from "skeleton-related-product-card";
import StructuredData from "structured-data";
import {
  BREAKPOINT_SM,
  IMAGE_ASPECT_RATIO,
  INITIAL_PG_IMAGE_CONTAINER_DIMENSION,
  IN_STOCK_READY_TO_SHIP,
  PAGE_BG_GREY,
  PG_IMAGE_CONTAINER_DIMENSION,
  PG_SEARCH_TERM,
  POSTAL_CODE_COOKIE
} from "utils/constants";
import { convertPathLocale, getLocaleFromPath } from "utils/intlUtils";
import {
  Container,
  Divider,
  Grid,
  GridList,
  GridListTile,
  GridSpacing,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery
} from "utils/material-ui-core";
import memoize from "utils/memoize";
import { addPgTermFallback } from "utils/pgTerm";
import { getReqContext } from "utils/reqContext";
import yn from "yn";

import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import { useGetSchema } from "@RHCommerceDev/hooks/useGetSchema";
import { OnSaleDialogContent } from "component-in-stock-dialog-content";
import InStockOnSaleDialog from "component-in-stock-on-sale-link/InStockOnSaleDialog";
import { convertProductPriceRangeDisplayToPriceRange } from "component-price-range-display/utils/convertProductPriceRangeDisplay";
import ProductCard from "component-product-card";
import Prop65 from "component-prop65";
import RelatedProductCard from "component-related-product-card";
import RHDialog from "component-rh-dialog";
import RHDivider from "component-rh-divider";
import SwatchGroups from "component-swatch-groups";
import { MultiSkuComponentWithRestrictions } from "components/RelatedProductCard/types";
import SwatchPanelDialog from "dialog-swatch-panel";
import { queryUpsSellProducts } from "graphql-client-product";
import { queryRelatedProducts } from "graphql-client/queries/related-product";
import { useRhUserAtomValue } from "hooks/atoms";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { useFetchModel } from "hooks/useFetchModel";
import isEqual from "lodash.isequal";
import { ProductProps } from "pages/ProductV2";
import PrintFrame from "printable-pages/PrintFrame";
import { usePrintHijack } from "printable-pages/usePrintHijack";
import { getSelectorsByUserAgent } from "react-device-detect";
import { useKeycloak } from "utils/Keycloak/KeyCloak";
import { isGTMLoaded, preConfigureProduct } from "utils/analytics/utils";
import { getCookie } from "utils/cookies";
import { filterCustomRugOptionTypes } from "utils/customProductHelper";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { objectToQueryString } from "utils/objectToQueryString";
import { hasPDPWarning } from "utils/pdpHelper";
import prasePGCropRules from "utils/prasePGCropRules";
import {
  isSSRToggledCheck,
  isSSRToggledWithClientRender
} from "utils/ssrHelpers";
import stripTags from "utils/stripTags";
import { useProductData } from "./useProductData";
import { useAppId } from "hooks/useAppId";
import { memoryStorage } from "utils/analytics/storage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slider: {
      "@media print": {
        display: "none"
      }
    },
    relatedProductGrid: {
      backgroundColor: theme?.palette?.background?.default || "#fafafa"
    },
    ul: {
      [`& li[style*="display: list-item"]`]: {
        marginLeft: theme.spacing(2)
      }
    },
    link3: {
      [`& a.sku-info.link`]: {
        textTransform: "uppercase",
        textDecoration: "none",
        fontWeight: 300,
        lineHeight: "13px"
      }
    },
    // This is a temporary hack to format <b> tags
    // being rendered using setInnerHTML.
    carosalWraperStyle: {
      "@media print": {
        display: "none"
      }
    }
  })
);

export interface AddAllItemToCart {
  productId: string;
  sku: string;
  quantity: number;
  brand: string;
  giftTo?: string;
  giftFrom?: string;
  giftMessage?: string;
  spoTermsAccepted?: boolean;
  spoTerms?: string;
  monogram?: MonogramOrderProps;
  customInfo?: CustomProductMapper | SkuCustomizationType;
  lineItemIndex?: number;
  displayName?: string;
  name?: string;
  imageUrl?: string;
  spo?: boolean;
  preBillMessage?: string;
  options?: (ProductAvailableOption | CustomOption)[];
  salePriceLabel?: string;
  pricing?: ProductSkuPriceInfo;
}

export interface relatedProductVariable {
  productIds?: string[];
  filter?: string | null;
  userType?: string;
  siteId?: string;
  currencyCode?: string;
  measureSystem?: string;
  locale?: string | undefined;
}

export interface PDPProps {
  data: Product;
  upSellProducts: UpsellProducts;
  fullSkuId?: string;
  draperyLink?: string;
  isProductLoading?: boolean;
  authenticated: boolean;
  saleContextFilter?: SaleContextFilter;
  postalCode?: string;
  onOptionsChange?: (op: string[]) => void;
  onTrimOptionsChange?: (op: string[]) => void;
  openGiftCardTerms: boolean;
  setOpenGiftCardTerms: (open: boolean) => void;
  giftCardTerms: string;
  productSwatchLoading?: boolean;
  rideAlongData?: RideAlongData;
  setRideAlongData?: (RideAlongData) => void;
  rideAlongProduct?: Product | null;
  rProducts?: Product[];
  gridColumns?: any;
  locale?: string;
  pathLocale?: string;
  selectedSwatchImageUrl?: string;
  relatedProductVariable?: relatedProductVariable;
  setPrintFullSkuId?: (fullSkuId: string) => void;
  relatedProductsloading?: boolean;
  onProductRefetch?: (op: { fetchPolicy: string }) => void;
}

export interface StyledTabProps {
  label: string;
}

/*
  RideAlongData:
    casingProduct: product to be shown instead of replacementCushionProduct
    replacementCushionProduct: to be replaced by casingProduct
    selectedOptionIds: selected options on triggeredProduct
    triggeredProduct: product where hasCasingSkus === true
*/
export interface RideAlongData {
  casingProduct: string | null;
  replacementCushionProduct: string | null;
  selectedSizeId: string | null;
  triggerProductId: string | null;
}

export const defaultRideAlongData: RideAlongData = {
  triggerProductId: null,
  casingProduct: null,
  replacementCushionProduct: null,
  selectedSizeId: null
};
let swatchToggle = 0;

const PDPV2 = memoize((props: ProductProps) => {
  const {
    data,
    upSellProducts,
    fullSkuId,
    draperyLink,
    authenticated,
    saleContextFilter,
    productSwatchLoading,
    postalCode,
    rideAlongProduct,
    rProducts,
    gridColumns,
    locale,
    pathLocale,
    selectedSwatchImageUrl,
    relatedProductVariable,
    relatedProductsloading,
    isColorizable,
    changeSwatchImageOnLineItem,
    selectedHeroImageSwatchUrl,
    getSwatchImageUrl,
    productSwatchImage,
    handleHeroImageOnIndexChange,
    selectedOptionLabel,
    isSwatchSwitch,
    totalSwatchesLength,
    selectedSwatch,
    xlUp,
    mdUp,
    xsDown,
    containerGridSpacing,
    animationCheck,
    shouldShow,
    pageContent,
    priceRangeDisplay,
    params,
    rhUser,
    saleMessageForPriceComponent,
    handleOpenInStockOnSaleModal,
    openInStockOnSaleModal,
    handleCloseInStockOnSaleModal,
    isPostalCodeAvailable,
    longDescriptionRef,
    styledDescription,
    handleRequestConsultationOpen,
    requestConsultationOpen,
    handleRequestConsultationClose,
    layoutTemplate,
    stockedSwatchesLength,
    customSwatchesLength,
    setSwatchPanelDialog,
    groupMaterial,
    triggerSwatchImage,
    swatchGroups,
    selectedOptionsFromLineItem,
    selectedSwatches,
    handleOnSwatchClick,
    openGiftCardTerms,
    setOpenGiftCardTerms,
    giftCardTerms,
    relatedProducts,
    pli,
    rideAlongData,
    setRideAlongData,
    setMultiSkuComponentsData,
    setProductAddonItems,
    setPrintFullSkuId,
    checkSelectedAddOnsForConfig,
    isShellProduct,
    analyticsModalStates,
    toogleModalState,
    filePathRegex,
    handleFAQDialogOpen,
    showFAQDialog,
    handleFAQDialogClose,
    processedDraperyLink,
    allSwatchGroups,
    setSelectedHeroImageSwatchUrl,
    swatchImageOnLineItem,
    setSelectedOptionLabel,
    setSelectedLineItemOptionLabel,
    onChangeSwatchImageFromLineItem,
    onChangeSelectedOptions,
    handleAddAllItemToCart,
    handleRemoveItemFromAddAll,
    selectedRugTrimSwatchId,
    addAllToCartFlag,
    addAllItemToCart,
    multiSkuComponentsData,
    productAddonItems,
    allProductAddons,
    allRProductAddons,
    allSelectedAddOnsConfigured,
    productCardContentHeight,
    customProductsCarousel,
    isSSRToggledWithCSR,
    swatchPanelDialog,
    selectedLineItemOptionLabel,
    isProductLoading,
    upsellProductsUI,
    pdpLoadPerformance,
    isActiveInstockModal,
    printFrameRef,
    enabled,
    showLoader,
    injectIframe,
    setIframeLoaded,
    printSrc
  } = useProductData(props);

  const { setStorageValueWrapper } = useCookiesWithPermission();
  const isLoadedGTM = isGTMLoaded();
  const [preConfiguredSku, setPreConfiguredSku] = useState("");
  const isCustomRug =
    pli?.customProductOptions?.customProductInformation?.customProductType ===
      "rug" || data?.customProductType === "rug";

  const image =
    selectedHeroImageSwatchUrl == ""
      ? getSwatchImageUrl(data?.productSwatchImage as any)
      : selectedHeroImageSwatchUrl;
  const RHImage = "https:" + image;
  const mpn = data?.id?.match(/\d+/)?.[0];
  const brandCode = useBrand();
  const canonical = document.querySelector(
    'link[rel="canonical"]'
  ) as HTMLLinkElement;
  const decodedDescription = he.decode(
    data?.longDescription
      ? `${stripTags(data?.longDescription)}`
      : "Honoring refined design and artisanal craftsmanship, our timeless collections celebrate the distinctive provenance and enduring quality of each piece."
  );

  const req = getReqContext();
  const userAgent = req?.headers["user-agent"];
  const { isConcierge } = useAppId();

  let mobile = false;
  if (userAgent) {
    const { isMobile } = getSelectorsByUserAgent(userAgent);
    mobile = isMobile;
  }
  //Analytics fix - removing data.emptyProduct object to stop interfering with analytics events
  useEffect(() => {
    if (
      !processEnvServer &&
      data?.personalizeInfo &&
      rhUser?.akamaiCountryCode &&
      isLoadedGTM?.isGTMLoaded &&
      !relatedProductsloading &&
      (relatedProducts || relatedProductVariable?.productIds?.length >= 0) &&
      ((params?.fullSkuId && preConfiguredSku) || !params?.fullSkuId)
    ) {
      setStorageValueWrapper({
        storageKey: "analytics-product-data",
        value: JSON.stringify(data)
      });

      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.GA4_VIEW_PAGE_PRODUCT.INT_TYPE,
          {
            set: {
              name: "analytics-emptyProduct",
              value: JSON.stringify(data.emptyProduct)
            },
            monogrammable: data?.personalizeInfo?.monogrammable,
            personalizable: data?.personalizeInfo?.personalizable
          }
        )
      );

      const productData: (Product & { category?: string })[] = [
        ...(relatedProducts?.some(item => item?.id === data?.id) ? [] : [data]),
        ...(relatedProducts || [])
      ];
      const addOnProduct: (ProductAddonsInfo & {
        category?: string;
        fullSkuId?: string;
      })[] = productData?.flatMap(
        rec => rec?.productAddons?.productAddonsInfo || []
      );

      const preRelatedProductConfigureSku = [];
      const itemList = [...(productData || []), ...(addOnProduct || [])]?.map(
        rec => {
          const isCurrentProduct = params?.productId === rec?.id;
          const skuId = rec?.sku || rec?.fullSkuId;
          const item_variant = isCurrentProduct ? params?.fullSkuId : skuId;
          const relatedProductSku =
            rec?.productLineItem?.sku?.fulfillmentEta?.fullSkuId;
          if (relatedProductSku) {
            preRelatedProductConfigureSku?.push(relatedProductSku);
          }
          let productConfig = "";
          if (isCurrentProduct || relatedProductSku) {
            productConfig = preConfigureProduct({
              fullSkuId: item_variant,
              preConfiguredSku: fullSkuId,
              relatedProductSkuId: relatedProductSku
            });
          }
          return {
            item_id: rec?.id,
            item_name: rec?.displayName,
            item_category: rec?.category ?? null,
            item_variant,
            quantity: 1,
            price: null,
            productConfig,
            item_list_name: window?.location?.href?.includes("/search/")
              ? "search"
              : window?.location?.href?.includes("products.jsp")
              ? "pg"
              : null
          };
        }
      );

      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.VIEW_ITEM.INT_TYPE,
          {
            itemList
          }
        )
      );
      memoryStorage.setItem("relatedProductSku", preRelatedProductConfigureSku);
    }
  }, [
    data?.emptyProduct,
    rhUser?.akamaiCountryCode,
    isLoadedGTM?.isGTMLoaded,
    relatedProducts?.length,
    fullSkuId,
    preConfiguredSku
  ]);

  const env = useEnv();

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH1", "rhBaseH2", "rhBaseH3", "rhBaseBody1"]
  });
  const classes = useStyles();

  useEffect(() => {
    const handleScrollToTop = () => {
      setTimeout(() => {
        window?.scrollTo({
          top: 0
        });
      }, 500);
    };

    window?.addEventListener("load", handleScrollToTop);

    return () => {
      window?.removeEventListener("load", handleScrollToTop);
    };
  }, []);

  const productSchema = useGetSchema("Product", {
    name: data?.displayName,
    description: decodedDescription,
    RHImage: RHImage,
    sku: data?.id,
    mpn: mpn,
    brand: brandCode,
    canonicalUrl: canonical?.href,
    lowPrice:
      data?.priceRangeDisplay?.memberPrices?.[0] ||
      data?.priceRangeMultiSkuDisplay?.memberPrices?.[0],
    highPrice:
      data?.priceRangeDisplay?.listPrices?.[0] ||
      data?.priceRangeMultiSkuDisplay?.listPrices?.[0],
    hasInStock: data?.instock?.hasInStock
  });

  return (
    <>
      {!processEnvServer ? (
        <PrintFrame
          showLoader={showLoader}
          injectIframe={injectIframe}
          onLoad={() => setIframeLoaded(true)}
          ref={printFrameRef}
          enabled={enabled}
          src={printSrc}
        />
      ) : null}
      <div>
        {!processEnvServer ? (
          <Hidden mdUp>
            {!data?.alternateImages ? (
              <BaseProductSkeleton />
            ) : (
              <HeroImage
                images={data?.alternateImages}
                isColorizable={isColorizable}
                changeSwatchImageOnLineItem={changeSwatchImageOnLineItem}
                selectedSwatchImageUrl={
                  selectedHeroImageSwatchUrl == ""
                    ? getSwatchImageUrl(data?.productSwatchImage as any)
                    : selectedHeroImageSwatchUrl
                }
                productSwatchLoading={productSwatchLoading}
                onIndexChange={handleHeroImageOnIndexChange}
                selectedOptionLabel={selectedOptionLabel}
                isRHR={data?.rhr}
                isSwatchSwitch={isSwatchSwitch}
                isBannerVisible={totalSwatchesLength > 1}
                saleSwatchId={data?.saleSwatchId}
                selectedSwatch={selectedSwatch}
                imagePresetOverride={data?.imagePresetOverride}
              />
            )}
          </Hidden>
        ) : (
          ""
        )}
        <Grid
          container
          className={"!m-[0_auto] !w-auto bg-white-300 !block"}
          spacing={xlUp ? 0 : containerGridSpacing}
        >
          {/* All relatedProduct Grids should be double the parent container, to have full-width bg:
              - [xs] 1.5spacing x 2 = 3
              - [sm] 3spacing x 2 = 6
              - [md, lg] 4spacing x 2 = 8
              - [xl] 9spacing x 2 = 18
            */}
          <HTMLHeadTags
            title={data?.displayName}
            description={data?.longDescription}
            keywords={data?.keywords}
            displayName={data?.displayName}
            image={
              selectedHeroImageSwatchUrl == ""
                ? getSwatchImageUrl(data?.productSwatchImage as any)
                : selectedHeroImageSwatchUrl
            }
            schema={productSchema}
          />
          {!!data?.priceRangeDisplay && <StructuredData product={data} />}
          {/* item child for spacing */}
          <Grid
            item
            className={` gridItem ${
              processEnvServer ? "!px-[0px]" : ""
            } w-full max-w-[1920px] !my-0 !mx-auto !pt-0 !pb-0 xl:pb-[29.6px] xl:!px-[90px] !print:none`}
          >
            <Grid container spacing={4}>
              {!processEnvServer && isProductLoading ? (
                <Hidden smDown>
                  <Grid item xs={12}>
                    <BaseProductSkeleton />
                  </Grid>
                </Hidden>
              ) : (
                <>
                  {processEnvServer ? (
                    <Grid item xs={12} md={6} className="!pt-[12.8px]">
                      <div>
                        <HeroImage
                          changeSwatchImageOnLineItem={
                            changeSwatchImageOnLineItem
                          }
                          objectFit={"contain"}
                          images={data?.alternateImages}
                          isColorizable={isColorizable}
                          selectedSwatchImageUrl={
                            selectedHeroImageSwatchUrl == ""
                              ? getSwatchImageUrl(
                                  data?.productSwatchImage as any
                                )
                              : selectedHeroImageSwatchUrl
                          }
                          onIndexChange={handleHeroImageOnIndexChange}
                          selectedOptionLabel={selectedOptionLabel}
                          isRHR={data.rhr}
                          isBannerVisible={totalSwatchesLength > 1}
                          isSwatchSwitch={isSwatchSwitch}
                          saleSwatchId={data?.saleSwatchId}
                          selectedSwatch={selectedSwatch}
                          imagePresetOverride={data?.imagePresetOverride}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <Hidden smDown>
                      <Grid item xs={12} md={6} className="!pt-[12.8px]">
                        <div>
                          <HeroImage
                            changeSwatchImageOnLineItem={
                              changeSwatchImageOnLineItem
                            }
                            objectFit={"contain"}
                            images={data?.alternateImages}
                            isColorizable={isColorizable}
                            selectedSwatchImageUrl={
                              selectedHeroImageSwatchUrl == ""
                                ? getSwatchImageUrl(
                                    data?.productSwatchImage as any
                                  )
                                : selectedHeroImageSwatchUrl
                            }
                            onIndexChange={handleHeroImageOnIndexChange}
                            selectedOptionLabel={selectedOptionLabel}
                            productSwatchLoading={
                              !isSSRToggledWithCSR
                                ? productSwatchLoading
                                : false
                            }
                            isRHR={data.rhr}
                            isBannerVisible={totalSwatchesLength > 1}
                            isSwatchSwitch={isSwatchSwitch}
                            selectedLineItemOptionLabel={
                              selectedLineItemOptionLabel
                            }
                            saleSwatchId={data?.saleSwatchId}
                            selectedSwatch={selectedSwatch}
                            imagePresetOverride={data?.imagePresetOverride}
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  )}
                  <Grid
                    xs={12}
                    md={6}
                    className={`${processEnvServer ? "!px-[32px]" : ""}`}
                    {...(!processEnvServer && { item: true })}
                  >
                    <div
                      className={classNames({
                        "opacity-0 animate-[fadeInUp_0s_ease-in_0s_1_normal_none_running]":
                          animationCheck,
                        "duration-0 delay-0": animationCheck,
                        "duration-500 delay-0": !animationCheck,
                        "animate-none": animationCheck,
                        "opacity-100": animationCheck
                      })}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className={`${
                            !processEnvServer ? "md:!mt-0" : ""
                          } !mt-4`}
                        >
                          <Typography
                            variant="h1"
                            className={classNames(
                              [typographyStyles.rhBaseH2],
                              "!uppercase"
                            )}
                            dangerouslySetInnerHTML={{
                              __html: data.displayName
                            }}
                          />
                          {!data.subName?.match(/(^|\W)cat($|\W)/i) && (
                            <Typography
                              variant={mdUp ? "h3" : "h1"}
                              dangerouslySetInnerHTML={{
                                __html: data.subName
                              }}
                            />
                          )}
                        </Grid>
                        {!shouldShow ? (
                          <Grid container item xs={12} className="bg-white-200">
                            <div>
                              <Typography>
                                {pageContent?.PRODUCT_NOT_AVAILABLE}
                                {/* //product no longer available */}
                              </Typography>

                              <RHLink className="inline" href="/">
                                {pageContent?.PLEASE_TEXT}{" "}
                                {pageContent?.CONTINUE_BROWSING}
                                {/* continue browsing */}
                              </RHLink>
                            </div>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} className="!pt-0.5">
                          {shouldShow &&
                          !isCustomRug &&
                          (!data?.layout?.includes("custom") ||
                            data?.layout === "custom-rug") &&
                          data?.layout !== "track-hardware"
                            ? !!priceRangeDisplay && (
                                <>
                                  <RHRPriceDisplay
                                    listPrice={
                                      priceRangeDisplay?.listPrices?.[0]!
                                    }
                                    memberPrice={
                                      priceRangeDisplay?.memberPrices?.[0]!
                                    }
                                    topLabel={
                                      data.priceRangeDisplay
                                        ?.overridePriceLabel ||
                                      pageContent?.["STARTING_AT"]
                                    }
                                    isSaleFilterEnabled={
                                      params?.sale === "true" ?? false
                                    }
                                    onSale={
                                      priceRangeDisplay?.nextGenDrivenOnSale!
                                    }
                                    skulowestMemberPrice={
                                      priceRangeDisplay?.skulowestMemberPrice!
                                    }
                                    showSaleMessage={
                                      Number(
                                        data?.saleInfo?.percentSaleSkus
                                      ) === 0 ||
                                      Number(
                                        data?.saleInfo?.percentSaleSkus
                                      ) === 100
                                        ? false
                                        : true
                                    }
                                    userType={rhUser?.userType! || ""}
                                    pageContent={pageContent}
                                    computedSalePageContent={
                                      saleMessageForPriceComponent
                                    }
                                    variant={"medium"}
                                    centerAlignFlag={false}
                                    showMembershipProductPrice={
                                      data?.uxAttributes?.membershipProduct?.toLowerCase() ===
                                      "true"
                                    }
                                    showGiftCardPrice={
                                      data?.uxAttributes?.giftCert?.toLowerCase() ===
                                      "true"
                                    }
                                    hideCss={true}
                                    onSaleLinkClick={
                                      handleOpenInStockOnSaleModal
                                    }
                                  />

                                  {openInStockOnSaleModal ? (
                                    <InStockOnSaleDialog
                                      open={openInStockOnSaleModal}
                                      onClose={handleCloseInStockOnSaleModal}
                                      isPostalCodeAvailable={
                                        isPostalCodeAvailable
                                      }
                                      postalCodeNotRequired={true}
                                    >
                                      {mdUp ? (
                                        <RHDialogTitle
                                          title={`<span class="text-red-600">${
                                            pageContent.ON_SALE ?? ON_SALE
                                          }</span>`}
                                          onClose={
                                            handleCloseInStockOnSaleModal
                                          }
                                          className="!pl-0"
                                        />
                                      ) : (
                                        <RHDialogTitle
                                          title={`<span class="text-red-600">${
                                            pageContent.ON_SALE ?? ON_SALE
                                          }</span>`}
                                          onBack={handleCloseInStockOnSaleModal}
                                        />
                                      )}
                                      <OnSaleDialogContent
                                        productId={data?.id}
                                        productName={data?.displayName}
                                        postalCode={postalCode}
                                        salePriceLabel={
                                          data?.priceRangeDisplay
                                            ?.salePriceLabel ?? ""
                                        }
                                        productPrice={convertProductPriceRangeDisplayToPriceRange(
                                          data?.priceRangeDisplay
                                        )}
                                        isAuthenticated={authenticated}
                                        userType={rhUser?.userType || ""}
                                        multiSkuAdditionalMessage={
                                          data?.multiSkuAdditionalMessage ?? ""
                                        }
                                        isRHR={data?.rhr}
                                        metaProduct={data?.metaProduct}
                                      />
                                    </InStockOnSaleDialog>
                                  ) : null}
                                </>
                              )
                            : null}
                        </Grid>
                        <Grid xs={12} className="!my-5 !mx-0">
                          <ModalTrigger>
                            <Typography
                              id="long_description"
                              className={classNames(
                                classes.link3,
                                "normal-case [&b]:text-[13px] [&b]:tracking-[0.025em] [&b]:leading-[1.66rem] [&b]:uppercase [&b]:text-black [&b]:hover:text-black [&b]:no-underline"
                              )}
                              innerRef={longDescriptionRef}
                              dangerouslySetInnerHTML={{
                                __html: processEnvServer
                                  ? styledDescription
                                  : data.longDescription
                              }}
                            />
                          </ModalTrigger>
                        </Grid>
                        {(data?.layout?.includes("custom") ||
                          data?.layout === "track-hardware") &&
                        data?.layout !== "custom-rug" ? (
                          <Grid item>
                            <RHLink
                              className="!mt-[30px] !mb-10"
                              onClick={() => null}
                              underline="none"
                            >
                              <Typography
                                className={typographyStyles.rhBaseBody1}
                                onClick={handleRequestConsultationOpen}
                              >
                                {pageContent?.WINDOWS_CONSULTATION}
                                {/* //request a window consultation */}
                                <span>
                                  <GrommetArrowThick />
                                </span>
                              </Typography>
                              <CustomWindowDialog
                                open={requestConsultationOpen}
                                onClose={handleRequestConsultationClose}
                              />
                            </RHLink>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={12}
                          className="[&b]:font-normal [&b]:text-[13px] [&b]:leading-5"
                        >
                          {(layoutTemplate === "panel" ||
                            layoutTemplate === "split-panel") &&
                            (stockedSwatchesLength > 0 ||
                              customSwatchesLength > 0) &&
                            !data?.layout?.includes("custom") &&
                            data?.layout !== "track-hardware" && (
                              <RHLinkListSwatchAnchor
                                onClick={() =>
                                  setSwatchPanelDialog("open")(true)
                                }
                                template={layoutTemplate}
                                stockedSwatchesLength={stockedSwatchesLength}
                                groupMaterial={groupMaterial}
                                customSwatchesLength={customSwatchesLength}
                                triggerSwatchImage={triggerSwatchImage}
                                borderType={{ top: true }}
                              />
                            )}
                          {swatchGroups?.length > 0 && (
                            <div className="mb-10">
                              <SwatchGroups
                                swatchGroups={swatchGroups}
                                selectedOptionsFromLineItem={
                                  selectedOptionsFromLineItem
                                }
                                swatches={["customSwatches", "stockedSwatches"]}
                                selectedSwatches={selectedSwatches}
                                onClick={handleOnSwatchClick}
                                productSwatchImage={data?.productSwatchImage}
                                detail={true}
                              />
                            </div>
                          )}
                          {openGiftCardTerms && (
                            <FormDialog
                              id="gift-card-terms"
                              open={openGiftCardTerms}
                              onClose={() => setOpenGiftCardTerms(false)}
                              hideScroll={false}
                            >
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: giftCardTerms
                                }}
                              />
                            </FormDialog>
                          )}
                          {relatedProducts[0] &&
                            !pli.sku?.restrictions?.serviceSku && (
                              <>
                                <RelatedProductCard
                                  data={relatedProducts?.[0]}
                                  authenticated={authenticated}
                                  saleContextFilter={saleContextFilter}
                                  postalCode={postalCode}
                                  lineItemIndex={0}
                                  inStockReady={
                                    pageContent?.IN_STOCK ||
                                    IN_STOCK_READY_TO_SHIP
                                  }
                                  rideAlongData={rideAlongData}
                                  setRideAlongData={setRideAlongData}
                                  setMultiSkuComponentsData={
                                    setMultiSkuComponentsData
                                  }
                                  swatchGroups={
                                    swatchGroups as [ProductSwatchGroup]
                                  }
                                  setProductAddonItems={setProductAddonItems}
                                  setPrintFullSkuId={setPrintFullSkuId}
                                  heroImageLevel={true}
                                  isActiveInstockModal={isActiveInstockModal}
                                  productId={params?.productId}
                                  checkSelectedAddOnsForConfig={
                                    checkSelectedAddOnsForConfig
                                  }
                                  isShellProduct={isShellProduct}
                                  setPreConfiguredSku={setPreConfiguredSku}
                                />
                              </>
                            )}
                          {!!data.featureList?.length && (
                            <RHLinkListCollapse
                              borderType={{ top: true }}
                              title={pageContent?.DETAILS}
                              open={false}
                              onClick={() => {
                                analyticsModalStates.current.detailsModal =
                                  toogleModalState(
                                    analyticsModalStates.current.detailsModal
                                  );
                                if (!processEnvServer) {
                                  document.body.dispatchEvent(
                                    new CustomEvent("pre-pdp-click", {
                                      detail: {
                                        item: {
                                          action: analyticsModalStates.current
                                            .detailsModal
                                            ? "Details Box Expand"
                                            : "Details box close"
                                        }
                                      }
                                    })
                                  );
                                }
                              }}
                            >
                              <ModalTrigger>
                                <ul
                                  className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                                >
                                  {data.featureList.map((feature, index) => {
                                    const featureFilepath =
                                      feature.match(filePathRegex);
                                    const processedFeature =
                                      featureFilepath?.length > 0
                                        ? feature.replace(
                                            featureFilepath[0],
                                            convertPathLocale(
                                              featureFilepath[0],
                                              "/us/en/",
                                              pathLocale
                                            )
                                          )
                                        : feature;
                                    const warning = hasPDPWarning(feature);

                                    return (
                                      <React.Fragment key={`feature-${index}`}>
                                        {warning?.hasWarning ||
                                        feature.includes("pdpWarning") ? (
                                          <li className="!list-item !normal-case">
                                            <Prop65 warning={warning} />
                                          </li>
                                        ) : (
                                          <Typography
                                            component="li"
                                            className="!list-item !normal-case [&_a]:underline"
                                            dangerouslySetInnerHTML={{
                                              __html: processedFeature
                                            }}
                                          />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </ul>
                              </ModalTrigger>
                            </RHLinkListCollapse>
                          )}

                          {data?.customProductType === "drape" ||
                          data?.customProductType === "shade" ? (
                            <>
                              <Grid item>
                                <RHLinkListItem
                                  title={pageContent?.FAQ}
                                  borderType={{ top: true }}
                                  onClick={handleFAQDialogOpen}
                                  Icon={<RHArrowIcon color="primary" />}
                                />
                                <FAQDialog
                                  open={showFAQDialog}
                                  onClose={handleFAQDialogClose}
                                  customProductType={data?.customProductType}
                                />
                              </Grid>
                            </>
                          ) : null}

                          {!!data.dimensions?.length && (
                            <RHLinkListCollapse
                              borderType={{ top: true }}
                              title={pageContent?.DIMENSIONS}
                              open={false}
                              onClick={() => {
                                analyticsModalStates.current.dimensionModal =
                                  toogleModalState(
                                    analyticsModalStates.current.dimensionModal
                                  );
                                if (!processEnvServer) {
                                  document.body.dispatchEvent(
                                    new CustomEvent("pre-pdp-click", {
                                      detail: {
                                        item: {
                                          action: analyticsModalStates.current
                                            .dimensionModal
                                            ? "Dimensions Box Expand"
                                            : "Dimension box close"
                                        }
                                      }
                                    })
                                  );
                                }
                              }}
                            >
                              <ul
                                className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                              >
                                {data.dimensions.map((dimension, index) => {
                                  const dimensionFilepath =
                                    dimension.match(filePathRegex);
                                  const processedDimension =
                                    dimensionFilepath?.length > 0
                                      ? dimension.replace(
                                          dimensionFilepath[0],
                                          convertPathLocale(
                                            dimensionFilepath[0],
                                            "/us/en/",
                                            pathLocale
                                          )
                                        )
                                      : dimension;
                                  return (
                                    <Typography
                                      key={`dimension-${index}`}
                                      component="li"
                                      className="!normal-case [&_a]:underline"
                                      dangerouslySetInnerHTML={{
                                        __html: processedDimension
                                      }}
                                    />
                                  );
                                })}
                              </ul>
                            </RHLinkListCollapse>
                          )}
                          {data?.productMessage && (
                            <>
                              <RHLinkListCollapse
                                borderType={{ top: true }}
                                title={pageContent?.GUARANTEED_FOR_LIFE}
                                open={false}
                              >
                                <ul
                                  className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                                >
                                  <Typography
                                    component="li"
                                    className="!normal-case [&_a]:underline"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        pageContent?.GUARANTEED_FOR_LIFE_TEXT
                                    }}
                                  />
                                </ul>
                              </RHLinkListCollapse>
                            </>
                          )}
                          {!!data?.personalizeInfo?.features?.length && (
                            <RHLinkListCollapse
                              borderType={{ top: true }}
                              title={
                                pageContent?.PERSONALIZATION ?? PERSONALIZATION
                              }
                              open={false}
                            >
                              <ul
                                className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                              >
                                {data?.personalizeInfo.features.map(
                                  (feature, index) => (
                                    <Typography
                                      key={`feature-${index}`}
                                      component="li"
                                      className="!normal-case [&_a]:underline"
                                      dangerouslySetInnerHTML={{
                                        __html: feature
                                      }}
                                    />
                                  )
                                )}
                              </ul>
                            </RHLinkListCollapse>
                          )}
                          {!!data.careInstructions?.length && (
                            <RHLinkListCollapse
                              borderType={{ top: true }}
                              title={pageContent?.CARE ?? CARE}
                              open={false}
                            >
                              <ul
                                className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                              >
                                {data.careInstructions.map(
                                  (careInstruction, index) => {
                                    const careInstructionFilepath =
                                      careInstruction.match(filePathRegex);
                                    const processedCareInstruction =
                                      careInstructionFilepath?.length > 0
                                        ? careInstruction.replace(
                                            careInstructionFilepath[0],
                                            convertPathLocale(
                                              careInstructionFilepath[0],
                                              "/us/en/",
                                              pathLocale
                                            )
                                          )
                                        : careInstruction;
                                    return (
                                      <Typography
                                        key={`careInstruction-${index}`}
                                        component="li"
                                        className="normal-case [&_a]:underline"
                                        dangerouslySetInnerHTML={{
                                          __html: processedCareInstruction
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </ul>
                            </RHLinkListCollapse>
                          )}
                          {(data.fileLinkUrls || []).map(
                            (fileLinkUrl, index) => {
                              let fileLink = { ...fileLinkUrl };
                              let { link } = fileLinkUrl;
                              const linkFilepath = link.match(filePathRegex);
                              fileLink.link =
                                linkFilepath?.length > 0
                                  ? // @ts-ignore
                                    linkFilepath.replace(
                                      linkFilepath[0],
                                      convertPathLocale(
                                        linkFilepath[0],
                                        "/us/en/",
                                        pathLocale
                                      )
                                    )
                                  : fileLinkUrl.link;
                              return (
                                <RHLinkListItem
                                  key={`fileLink-${index}`}
                                  borderType={{ top: true }}
                                  title={he.decode(fileLinkUrl.label || "")}
                                  onClick={() => {
                                    useIsoRedirect(
                                      convertPathLocale(
                                        fileLink.link,
                                        "/us/en/",
                                        pathLocale
                                      ),
                                      "windowOpen"
                                    );
                                    if (!processEnvServer) {
                                      document.body.dispatchEvent(
                                        new CustomEvent("pre-pdp-click", {
                                          detail: {
                                            item: {
                                              action: he.decode(
                                                fileLinkUrl.label || ""
                                              )
                                            }
                                          }
                                        })
                                      );
                                    }
                                  }}
                                  Icon={<RHArrowIcon color="primary" />}
                                />
                              );
                            }
                          )}
                          {draperyLink && (
                            <RHLinkListItem
                              borderType={{ top: true }}
                              Icon={<RHArrowIcon color="primary" />}
                            >
                              <span
                                className="[&>a]:no-underline"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    processedDraperyLink || draperyLink || ""
                                }}
                              />
                            </RHLinkListItem>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <RHDivider className="text-gray-200 opacity-100 -mt-0.5" />
                        </Grid>
                        {([
                          "drape",
                          "customHardware",
                          "trackHardware",
                          "shade"
                        ].includes(data?.customProductType) ||
                          data?.layout === "custom") && (
                          <Grid item xs={12}>
                            <CustomProduct
                              data={data}
                              fullSkuId={fullSkuId}
                              authenticated={authenticated}
                              loading={isProductLoading}
                              saleContextFilter={saleContextFilter}
                              postalCode={postalCode}
                              swatchGroups={allSwatchGroups}
                              productSwatchImage={data?.productSwatchImage}
                              selectedSwatches={selectedSwatches}
                              handleOnSwatchClick={handleOnSwatchClick}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <>
            <PrintSection.Container
              className="mx-auto w-full bg-white-300"
              spacing={xsDown ? 0 : 4}
            >
              {/* All relatedProduct Grids should be double the parent container, to have full-width bg:
                    - [xs] 1.5spacing x 2 = 3
                    - [sm] 3spacing x 2 = 6
                    - [md, lg] 4spacing x 2 = 8
                    - [xl] 9spacing x 2 = 18
                  */}
              {!!data.productListTitle && (
                <>
                  <Typography className={typographyStyles.rhBaseH1}>
                    {data.productListTitle}
                  </Typography>
                  <RHDivider className="text-gray-200 opacity-100" />
                </>
              )}
              {shouldShow &&
                !data?.layout?.includes("custom") &&
                data?.layout !== "track-hardware" && (
                  <>
                    {relatedProducts[0]?.id === params.productId &&
                    (!processEnvServer ||
                      (processEnvServer && !pdpLoadPerformance) ||
                      isConcierge) ? (
                      isProductLoading ? (
                        <Grid item className="gridItem !w-full">
                          <RelatedProductCardSkeleton data-testid="full-product-loading-spinner" />
                        </Grid>
                      ) : (
                        <PrintSection.Item
                          className={classNames([
                            "xl:!px-0 lg:!px-[32px] p-2.5 xl:!py-[32px]",

                            classes.relatedProductGrid
                          ])}
                        >
                          <div
                            className={classNames({
                              "opacity-0 animate-[fadeInUp_0s_ease-in_0s_1_normal_none_running]":
                                animationCheck,
                              "duration-0 delay-0": animationCheck,
                              "duration-500 delay-0": !animationCheck,
                              "animate-none": animationCheck,
                              "opacity-100": animationCheck
                            })}
                          >
                            <RelatedProductCard
                              data={relatedProducts[0]}
                              setSelectedHeroImageSwatchUrl={
                                setSelectedHeroImageSwatchUrl
                              }
                              fullSkuId={fullSkuId}
                              selectedSwatches={selectedSwatches}
                              authenticated={authenticated}
                              saleContextFilter={saleContextFilter}
                              selectedSwatchImageUrl={swatchImageOnLineItem}
                              postalCode={postalCode}
                              onOptionsSelected={isSelected => {
                                setSelectedOptionLabel(isSelected);
                                setSelectedLineItemOptionLabel(isSelected);
                              }}
                              changeSwatchImageFromLineItem={
                                onChangeSwatchImageFromLineItem
                              }
                              changeSelectedOptions={onChangeSelectedOptions}
                              handleAddAllItemToCart={handleAddAllItemToCart}
                              handleRemoveItemFromAddAll={
                                handleRemoveItemFromAddAll
                              }
                              lineItemIndex={0}
                              rideAlongData={rideAlongData}
                              setRideAlongData={setRideAlongData}
                              setMultiSkuComponentsData={
                                setMultiSkuComponentsData
                              }
                              swatchGroups={
                                swatchGroups as [ProductSwatchGroup]
                              }
                              spoOverrideMessage={
                                data?.spoReturnMessageOverride
                              }
                              setProductAddonItems={setProductAddonItems}
                              setPrintFullSkuId={setPrintFullSkuId}
                              handleOnSwatchClick={handleOnSwatchClick}
                              allSwatchGroups={allSwatchGroups}
                              checkSelectedAddOnsForConfig={
                                checkSelectedAddOnsForConfig
                              }
                              isShellProduct={isShellProduct}
                              productId={params.productId}
                              setPreConfiguredSku={setPreConfiguredSku}
                            />
                          </div>
                        </PrintSection.Item>
                      )
                    ) : null}
                    {relatedProductsloading ? (
                      <Grid item className="gridItem !w-full">
                        <RelatedProductCardSkeleton data-testid="full-product-loading-spinner" />
                      </Grid>
                    ) : (
                      <>
                        {" "}
                        {!processEnvServer ||
                        (processEnvServer && !pdpLoadPerformance) ||
                        isConcierge
                          ? relatedProducts.map((item, index) => {
                              if (item.id === params.productId) {
                                return null;
                              }
                              return (
                                <React.Fragment
                                  key={`relatedProduct-${item.id}`}
                                >
                                  <PrintSection.Item
                                    className={classNames([
                                      "xl:!px-0 lg:!px-[32px] p-2.5 xl:!py-[32px]",
                                      classes.relatedProductGrid
                                    ])}
                                  >
                                    <div
                                      className={classNames({
                                        "opacity-0 animate-[fadeInUp_0s_ease-in_0s_1_normal_none_running]":
                                          animationCheck,
                                        "duration-0 delay-0": animationCheck,
                                        "duration-500 delay-0": !animationCheck,
                                        "animate-none": animationCheck,
                                        "opacity-100": animationCheck
                                      })}
                                    >
                                      <RelatedProductCard
                                        data={item}
                                        fullSkuId={fullSkuId}
                                        selectedSwatches={selectedSwatches}
                                        authenticated={authenticated}
                                        saleContextFilter={saleContextFilter}
                                        postalCode={postalCode}
                                        onOptionsSelected={isSelected => {
                                          setSelectedOptionLabel(isSelected);
                                        }}
                                        handleAddAllItemToCart={
                                          handleAddAllItemToCart
                                        }
                                        handleRemoveItemFromAddAll={
                                          handleRemoveItemFromAddAll
                                        }
                                        changeSelectedOptions={
                                          onChangeSelectedOptions
                                        }
                                        lineItemIndex={index}
                                        rideAlongData={rideAlongData}
                                        setRideAlongData={setRideAlongData}
                                        setMultiSkuComponentsData={
                                          setMultiSkuComponentsData
                                        }
                                        swatchGroups={
                                          swatchGroups as [ProductSwatchGroup]
                                        }
                                        spoOverrideMessage={
                                          data?.spoReturnMessageOverride
                                        }
                                        setProductAddonItems={
                                          setProductAddonItems
                                        }
                                        handleOnSwatchClick={
                                          handleOnSwatchClick
                                        }
                                        allSwatchGroups={allSwatchGroups}
                                        checkSelectedAddOnsForConfig={
                                          checkSelectedAddOnsForConfig
                                        }
                                        isShellProduct={isShellProduct}
                                        setPreConfiguredSku={
                                          setPreConfiguredSku
                                        }
                                      />
                                    </div>
                                  </PrintSection.Item>
                                  {index < relatedProducts?.length - 1 && (
                                    <Divider className="w-full !h-[2px] !bg-white" />
                                  )}
                                </React.Fragment>
                              );
                            })
                          : null}
                      </>
                    )}
                  </>
                )}{" "}
              {shouldShow && data?.layout?.includes("custom-rug")
                ? relatedProducts.map((item, index) => (
                    <>
                      <PrintSection.Item key={`custom-rug-${item.id}`}>
                        <Grid
                          item
                          className={classNames([
                            "gridItem",
                            "xl:!pl-0 xl:!pr-0 sm:!p-[10px] md:!p-[32px]",
                            classes.relatedProductGrid
                          ])}
                          xs={12}
                        >
                          {isSSRToggledWithCSR ? (
                            <RelatedProductCard
                              data={item}
                              fullSkuId={fullSkuId}
                              selectedSwatches={selectedSwatches}
                              authenticated={authenticated}
                              saleContextFilter={saleContextFilter}
                              postalCode={postalCode}
                              handleAddAllItemToCart={handleAddAllItemToCart}
                              handleRemoveItemFromAddAll={
                                handleRemoveItemFromAddAll
                              }
                              lineItemIndex={index}
                              rideAlongData={rideAlongData}
                              setRideAlongData={setRideAlongData}
                              selectedRugTrimSwatchId={selectedRugTrimSwatchId}
                              setMultiSkuComponentsData={
                                setMultiSkuComponentsData
                              }
                              swatchGroups={
                                swatchGroups as [ProductSwatchGroup]
                              }
                              spoOverrideMessage={
                                data?.spoReturnMessageOverride
                              }
                              setProductAddonItems={setProductAddonItems}
                              checkSelectedAddOnsForConfig={
                                checkSelectedAddOnsForConfig
                              }
                              isShellProduct={isShellProduct}
                              setPreConfiguredSku={setPreConfiguredSku}
                            />
                          ) : (
                            <InView
                              triggerOnce
                              initialInView={processEnvServer}
                            >
                              {({ inView, ref }) => (
                                <div ref={ref}>
                                  {inView && (
                                    <RelatedProductCard
                                      setSelectedHeroImageSwatchUrl={
                                        setSelectedHeroImageSwatchUrl
                                      }
                                      data={item}
                                      fullSkuId={fullSkuId}
                                      selectedSwatches={selectedSwatches}
                                      authenticated={authenticated}
                                      saleContextFilter={saleContextFilter}
                                      postalCode={postalCode}
                                      handleAddAllItemToCart={
                                        handleAddAllItemToCart
                                      }
                                      handleRemoveItemFromAddAll={
                                        handleRemoveItemFromAddAll
                                      }
                                      lineItemIndex={index}
                                      rideAlongData={rideAlongData}
                                      setRideAlongData={setRideAlongData}
                                      selectedRugTrimSwatchId={
                                        selectedRugTrimSwatchId
                                      }
                                      setMultiSkuComponentsData={
                                        setMultiSkuComponentsData
                                      }
                                      swatchGroups={
                                        swatchGroups as [ProductSwatchGroup]
                                      }
                                      spoOverrideMessage={
                                        data?.spoReturnMessageOverride
                                      }
                                      setProductAddonItems={
                                        setProductAddonItems
                                      }
                                      checkSelectedAddOnsForConfig={
                                        checkSelectedAddOnsForConfig
                                      }
                                      isShellProduct={isShellProduct}
                                      setPreConfiguredSku={setPreConfiguredSku}
                                    />
                                  )}
                                </div>
                              )}
                            </InView>
                          )}
                        </Grid>
                        {index < relatedProducts.length - 1 && (
                          <Divider className="w-full !h-[2px] !bg-white" />
                        )}
                      </PrintSection.Item>
                    </>
                  ))
                : null}
            </PrintSection.Container>

            {relatedProductsloading ? (
              <Grid item className="gridItem w-full">
                <RelatedProductCardSkeleton data-testid="full-product-loading-spinner" />
              </Grid>
            ) : (
              <>
                {addAllToCartFlag && relatedProducts.length > 0 && (
                  <AddAllProductActions
                    addAllItemToCart={addAllItemToCart ?? []}
                    multiSkuComponentsData={multiSkuComponentsData ?? []}
                    productAddonItems={productAddonItems ?? []}
                    productAddons={[
                      ...(allProductAddons || []),
                      ...(allRProductAddons || [])
                    ]}
                    allSelectedAddOnsConfigured={allSelectedAddOnsConfigured}
                  />
                )}
                {["drape", "customHardware", "trackHardware", "shade"].includes(
                  data?.customProductType
                ) || data?.layout === "custom" ? (
                  <>
                    {!!data?.carouselProdctDetails?.length && (
                      <Grid
                        item
                        xs={12}
                        wrap={"nowrap"}
                        className={classNames([
                          "!max-w-[1920px] !mx-auto !my-0 relative break-inside-avoid xl:px-[90px] bg-[#f9f7f4]",
                          classes.slider
                        ])}
                      >
                        <Typography
                          variant="h5"
                          className="!text-[15px] !uppercase md:items-start items-center"
                        >
                          {pageContent?.YOU_MIGHT_ALSO_LIKE}
                        </Typography>
                        <InView triggerOnce initialInView={processEnvServer}>
                          {({ inView, ref }) => (
                            <div ref={ref}>
                              {inView && (
                                <>
                                  {!processEnvServer && (
                                    <GridList
                                      cols={mdUp ? 1 : 1.25}
                                      cellHeight="auto"
                                      className="!flex-nowrap md:!overflow-x-hidden !overflow-x-scroll"
                                    >
                                      <Slider
                                        arrowOffset={
                                          +`-${productCardContentHeight}`
                                        }
                                      >
                                        {customProductsCarousel}
                                      </Slider>
                                    </GridList>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </InView>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {!!upSellProducts?.upsellProducts?.length && (
                      <Grid
                        item
                        xs={12}
                        wrap={"nowrap"}
                        className={classNames([
                          "!max-w-[1920px] !mx-auto !my-0 relative break-inside-avoid xl:px-[90px] !w-full",
                          `${
                            processEnvServer
                              ? !mobile
                                ? "!px-[90px] !py-0"
                                : "!px-[16px]"
                              : ""
                          }`,
                          classes.slider
                        ])}
                        style={{
                          padding:
                            processEnvServer && mobile
                              ? "16px !important"
                              : undefined
                        }}
                      >
                        <div
                          className={classNames({
                            "opacity-0 animate-[fadeInUp_0s_ease-in_0s_1_normal_none_running]":
                              animationCheck,
                            "duration-0 delay-0": animationCheck,
                            "duration-500 delay-0": !animationCheck,
                            "animate-none": animationCheck,
                            "opacity-100": animationCheck
                          })}
                        >
                          <Typography
                            variant={env.FEATURE_RHR ? "body2" : "h5"}
                            className="!pb-[15px] !uppercase"
                          >
                            {upSellProducts?.componentTitle ||
                              pageContent?.YOU_MIGHT_ALSO_LIKE}
                          </Typography>
                          <>
                            {
                              <GridList
                                cols={
                                  !processEnvServer
                                    ? mdUp
                                      ? 1
                                      : 1.25
                                    : mobile
                                    ? 1
                                    : 1.25
                                }
                                cellHeight="auto"
                                className={classNames(
                                  isSSRToggledWithCSR &&
                                    classes.carosalWraperStyle,
                                  "flex-nowrap md:overflow-x-hidden overflow-x-scroll [&..MuiGridList-root]:!flex-nowrap"
                                )}
                              >
                                {processEnvServer ? (
                                  !mobile ? (
                                    <Slider
                                      arrowOffset={
                                        +`-${productCardContentHeight}`
                                      }
                                    >
                                      {upsellProductsUI}
                                    </Slider>
                                  ) : (
                                    upsellProductsUI
                                  )
                                ) : mdUp ? (
                                  <Slider
                                    arrowOffset={
                                      +`-${productCardContentHeight}`
                                    }
                                  >
                                    {upsellProductsUI}
                                  </Slider>
                                ) : (
                                  upsellProductsUI
                                )}
                              </GridList>
                            }
                          </>
                        </div>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </Grid>
        <Container>
          {!processEnvServer && (
            <SwatchPanelDialog
              product={data}
              open={!!swatchPanelDialog.open}
              onClose={() => setSwatchPanelDialog("open")(false)}
              stockedSwatchesLength={stockedSwatchesLength}
              customSwatchesLength={customSwatchesLength}
              groupMaterial={groupMaterial}
              template={layoutTemplate}
              selectedSwatches={selectedSwatches}
              selectedOptionsFromLineItem={selectedOptionsFromLineItem}
              onSwatchClick={handleOnSwatchClick}
            />
          )}
        </Container>
      </div>
    </>
  );
});

export const PDP = memoize<FC<PDPProps>>(
  ({
    data,
    upSellProducts,
    fullSkuId,
    draperyLink,
    isProductLoading,
    authenticated,
    saleContextFilter,
    postalCode,
    onOptionsChange,
    onTrimOptionsChange,
    openGiftCardTerms,
    setOpenGiftCardTerms,
    giftCardTerms,
    productSwatchLoading,
    rideAlongData,
    setRideAlongData,
    rideAlongProduct,
    rProducts,
    gridColumns,
    locale,
    pathLocale,
    relatedProductVariable,
    setPrintFullSkuId,
    relatedProductsloading,
    onProductRefetch
  }) => {
    const env = useEnv();
    const req = getReqContext();
    const pdpLoadPerformance: boolean = processEnvServer
      ? req.cookies.FEATURE_LOAD_PERFORMANCE === "true"
      : env.FEATURE_LOAD_PERFORMANCE;
    const { pageContent } = useFetchModel("/admin/product", true, false);
    const history = useHistory();
    const classes = useStyles();
    const params = useParams({
      productId: "",
      sale: "",
      swatch: "",
      fullSkuId: ""
    });
    const mediaString = useMediaString();
    const { isConcierge } = useAppId();
    const filePathRegex =
      /http:\/\/images\.restorationhardware\.com\/content\/catalog\/(.*)\.pdf/;
    const isSSRToggledWithCSR = isSSRToggledWithClientRender();
    const animationCheck = !isSSRToggledWithCSR || history?.action === "PUSH";
    const rhUser = useRhUserAtomValue();
    const longDescriptionRef = useRef<HTMLDivElement>(null);
    const [productCardContentHeight, updateProductCardContentHeight] =
      useState(0);
    const productCardContentRef = (ref: HTMLDivElement) => {
      if (!ref) return;

      updateProductCardContentHeight(ref?.offsetHeight);
    };
    const [requestConsultationOpen, setRequestConsultationOpen] =
      useState(false);
    const [showFAQDialog, setFAQDialog] = useState(false);
    const [selectedLineItemOptionLabel, setSelectedLineItemOptionLabel] =
      useState(false);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(false);
    const analyticsModalStates = useRef({
      dimensionModal: false,
      detailsModal: false
    });
    const country = getCountryFromUrl();
    const currencyCode = countryNameMapper[country];
    const [selectedRugTrimSwatchId, setSelectedRugTrimSwatchId] = useState<
      string | undefined
    >(""); //only for custom rugs
    const [selectedRugTrimSwatchIds, setSelectedRugTrimSwatchIds] = useState<
      string[] | null
    >(null); //only for custom rugs

    const typographyStyles = useTypographyStyles({
      keys: ["rhBaseH1", "rhBaseH2", "rhBaseH3", "rhBaseBody1"]
    });

    const shouldShowSaleText =
      (Number(data?.saleInfo?.percentSaleSkus) ?? 0) > 0;

    const selectItemsOnSale =
      Number(data?.saleInfo?.percentSaleSkus) > 0 &&
      Number(data?.saleInfo?.percentSaleSkus) < 100;

    const salePercentageRangeExists =
      data?.saleInfo?.memberSavings?.memberSavingsMax !==
      data?.saleInfo?.memberSavings?.memberSavingsMin;

    const SAVE_ON_SELECT_ITEMS = template(pageContent?.SAVE_ON_SELECT_ITEMS)({
      minPercent: data?.saleInfo?.memberSavings?.memberSavingsMin,
      maxPercent: data?.saleInfo?.memberSavings?.memberSavingsMax
    });

    const SAVE_UP_TO_SELECT_ITEMS = template(
      pageContent?.SAVE_MIN_PERCENT_ON_SELECT_ITEMS
    )({
      minPercent: data?.saleInfo?.memberSavings?.memberSavingsMin
    });

    const saleMessageForPriceComponent =
      shouldShowSaleText && selectItemsOnSale
        ? salePercentageRangeExists
          ? SAVE_ON_SELECT_ITEMS
          : SAVE_UP_TO_SELECT_ITEMS
        : "";

    const isSSRToggled = isSSRToggledCheck();
    const prefix = useLocalization();
    const isLoadedGTM = isGTMLoaded();
    const { setStorageValueWrapper } = useCookiesWithPermission();
    const draperyLinkFilepath = draperyLink.match(filePathRegex);
    const processedDraperyLink =
      draperyLinkFilepath?.length > 0
        ? draperyLink.replace(
            draperyLinkFilepath[0],
            convertPathLocale(draperyLinkFilepath[0], "/us/en/", pathLocale)
          )
        : draperyLink;

    const allProductAddons = data?.productAddons?.productAddonsInfo?.map(
      addon => ({
        ...addon,
        mainItemProductId: data?.id
      })
    );

    const allRProductAddons = rProducts?.reduce((acc, item) => {
      if (
        item.productAddons &&
        Array.isArray(item.productAddons.productAddonsInfo)
      ) {
        const updatedAddons = item.productAddons.productAddonsInfo.map(
          addon => ({
            ...addon,
            mainItemProductId: item.id
          })
        );
        return acc.concat(updatedAddons);
      }
      return acc;
    }, []);

    //Analytics fix - removing data.emptyProduct object to stop interfering with analytics events
    useEffect(() => {
      if (
        !processEnvServer &&
        data?.personalizeInfo &&
        rhUser?.akamaiCountryCode &&
        isLoadedGTM?.isGTMLoaded &&
        !relatedProductsloading &&
        (rProducts || relatedProductVariable?.productIds?.length >= 0)
      ) {
        setStorageValueWrapper({
          storageKey: "analytics-product-data",
          value: JSON.stringify(data)
        });

        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.GA4_VIEW_PAGE_PRODUCT.INT_TYPE,
            {
              set: {
                name: "analytics-emptyProduct",
                value: JSON.stringify(data.emptyProduct)
              },
              monogrammable: data?.personalizeInfo?.monogrammable,
              personalizable: data?.personalizeInfo?.personalizable
            }
          )
        );

        const productData: (Product & { category?: string })[] = [
          data,
          ...(rProducts || [])
        ];
        const addOnProduct: (ProductAddonsInfo & { category?: string })[] =
          productData?.flatMap(
            rec => rec?.productAddons?.productAddonsInfo || []
          );

        const itemList = [...(productData || []), ...(addOnProduct || [])]?.map(
          rec => ({
            item_id: rec?.id,
            item_name: rec?.displayName,
            item_category: rec?.category ?? null,
            item_variant: null,
            quantity: 1,
            price: null,
            item_list_name: window?.location?.href?.includes("/search/")
              ? "search"
              : window?.location?.href?.includes("products.jsp")
              ? "pg"
              : null
          })
        );

        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.VIEW_ITEM.INT_TYPE,
            {
              itemList
            }
          )
        );
      }
    }, [
      data?.personalizeInfo,
      data?.emptyProduct,
      rhUser?.akamaiCountryCode,
      isLoadedGTM?.isGTMLoaded,
      rProducts?.length
    ]);

    useEffect(() => {
      const longDescription =
        longDescriptionRef?.current ||
        document.getElementById("long_description");

      const anchorElements = Array.from(
        longDescription?.querySelectorAll("a") ?? []
      );

      anchorElements.map(anchorElement => {
        const href = anchorElement.getAttribute("href");
        const hasPgTerm = href.includes(PG_SEARCH_TERM);
        const sanitizedHref = addPgTermFallback(href);

        const categoryProductPattern =
          /\/catalog\/category\/(products|collections)\.jsp/;
        const productPattern = /\/catalog\/product\/product\.jsp/;
        const idPattern = /(categoryId|productId)=([a-zA-Z0-9]+)/;

        const hasCategoryOrProductRef =
          categoryProductPattern.test(href) || productPattern.test(href);
        const hasCategoryOrProductId = idPattern.test(href);

        hasCategoryOrProductRef &&
          !hasPgTerm &&
          hasCategoryOrProductId &&
          anchorElement.setAttribute("href", `${prefix}${sanitizedHref}`);
        const anchorClass = anchorElement?.getAttribute("class");
        !(anchorClass && anchorClass === "modal-trigger") &&
          anchorElement.addEventListener("click", handleClick);
        return anchorElement;
      });

      return () => {
        anchorElements.map(anchorElement =>
          anchorElement.removeEventListener("click", handleClick)
        );
      };

      function handleClick(event: MouseEvent) {
        const href = (event.target as HTMLAnchorElement)?.getAttribute("href");

        if (href?.includes("images")) {
          (event.target as HTMLAnchorElement)?.setAttribute("target", "_blank");
        } else {
          event.preventDefault();
          onProductRefetch({
            fetchPolicy: !processEnvServer ? "no-cache" : "cache-and-network"
          });
          history.push(href);
        }
      }
      // TODO: Fix eslint error and remove this comment block
      // React Hook useEffect has a missing dependency: 'data.emptyProduct'.
      // Either include it or remove the dependency array  react-hooks/exhaustive-deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.longDescription, history]);

    const [swatchPanelDialog, , setSwatchPanelDialog] = useState({
      open: false
    });
    useEffect(() => {
      if (!processEnvServer && swatchPanelDialog["open"]) {
        document.body.dispatchEvent(
          new CustomEvent("pre-pdp-click", {
            detail: {
              item: {
                action: "Swatch collection expand"
              }
            }
          })
        );
      }
    }, [swatchPanelDialog["open"]]);
    let [selectedSwatches, setSelectedSwatchesValue] = useState<{
      [key: string]: ProductSwatch;
    }>({});
    let [selectedHeroImageSwatchUrl, setSelectedHeroImageSwatchUrl] =
      useState("");
    const [isColorizable, setIsColorizable] = useState(true);
    const [openInStockOnSaleModal, setOpenInStockOnSaleModal] = useState(false);

    const onChangeSwatchImageFromLineItem = (imgUrl: string) => {
      setSelectedHeroImageSwatchUrl(imgUrl);
    };

    const [selectedOptionsFromLineItem, setSelectedOptions] = useState([]);
    const [isSwatchSwitch, setIsSwatchSwitch] = useState(0);
    const [selectedSwatch, setSelectedSwatch] = useState(null);
    const onChangeSelectedOptions = options => {
      setSelectedOptions(options);
    };
    // FIXME: Product.subTemplate.layout is not even
    // fetched anymore, so all of this logic is broken
    const layoutTemplate =
      (
        {
          standard: "inline",
          "standard-rugs": "inline",
          "two-color": "panel",
          "single-color": "panel",
          outdoor: "split-panel",
          "single-color-split": "split-panel"
        } as { [layout: string]: "inline" | "panel" | "split-panel" }
      )[data.layout] || "panel";

    const swatchGroups = [
      ...(data.swatchData?.finishSwatchGroups ?? []),
      ...(layoutTemplate === "inline"
        ? data.swatchData?.swatchGroups ?? []
        : [])
    ];

    const allSwatchGroups = [
      ...(data.swatchData?.finishSwatchGroups ?? []),
      ...(data.swatchData?.swatchGroups ?? [])
    ];

    const totalSwatchesLength =
      allSwatchGroups?.reduce(
        (acc, cur) =>
          acc + cur.customSwatches?.length || acc + cur.stockedSwatches?.length,
        0
      ) ?? 0;

    const stockedSwatchesLength =
      data.swatchData?.swatchGroups?.reduce(
        (acc, cur) => acc + cur.stockedSwatches.length,
        0
      ) ?? 0;
    const customSwatchesLength =
      data.swatchData?.swatchGroups?.reduce(
        (acc, cur) => acc + (cur.customSwatches?.length ?? 0),
        0
      ) ?? 0;
    const groupMaterial =
      data.swatchData?.swatchGroups?.reduce(
        (acc, cur) => acc || cur.groupMaterial,
        ""
      ) ?? "";

    const getSwatchImageUrl = (pImage: Maybe<ProductImage>) => {
      return pImage ? `${pImage.imageUrl}` : " ";
    };

    // consolidated selectSwatchValue calls
    const handleSelectSwatchesValue = (group: any, swatch: any) => {
      setIsColorizable(data?.colorizeInfo?.colorizable);
      setSelectedSwatchesValue({
        ...selectedSwatches,
        [group]: { ...swatch, groupMaterial: group }
      });
      if (processEnvServer) {
        selectedSwatches = { ...selectedSwatches, [group]: swatch };
      }
    };
    useEffect(() => {
      onOptionsChange?.(selectedOptionsFromLineItem.map(e => e.id));
      const label = selectedOptionsFromLineItem.map(e => e.value);
      setSelectedOptionLabel(!!label[0]);
    }, [onOptionsChange, selectedOptionsFromLineItem]);

    useEffect(() => {
      const options: any = Object.keys(selectedSwatches)
        .map(key => {
          const allOptions = selectedSwatches[key].options.map(option => {
            return option;
          });
          return allOptions;
        })
        .flat();
      onOptionsChange?.(options.map(e => e.id));
      const label = options.map(e => e.label);
      setSelectedOptionLabel(!!label[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onOptionsChange, selectedSwatches, data]);

    useEffect(
      () => onTrimOptionsChange?.(selectedRugTrimSwatchIds),
      [selectedRugTrimSwatchIds]
    );

    function updateSwatchGroups() {
      swatchGroups.forEach(swatchGroup => {
        swatchGroup?.customSwatches?.forEach(customSwatch => {
          if (data.fullSkuSwatchIds?.includes(customSwatch.swatchId)) {
            handleSelectSwatchesValue(swatchGroup.groupMaterial, customSwatch);
          }
        });

        swatchGroup?.stockedSwatches?.forEach(stockedSwatch => {
          if (data.fullSkuSwatchIds?.includes(stockedSwatch.swatchId)) {
            handleSelectSwatchesValue(swatchGroup.groupMaterial, stockedSwatch);
          }
        });
      });
    }

    if (swatchToggle == 1 && isSSRToggledWithCSR) {
      updateSwatchGroups();
      swatchToggle += 1;
    }

    useUnsafeEffect(() => {
      if (isSSRToggledWithCSR) {
        return;
      }
      swatchGroups.forEach(swatchGroup => {
        swatchGroup?.customSwatches?.forEach(customSwatch => {
          if (data.fullSkuSwatchIds?.includes(customSwatch.swatchId)) {
            handleSelectSwatchesValue(swatchGroup.groupMaterial, customSwatch);
          }
        });
        if (swatchGroup?.stockedSwatches?.length === 1) {
          handleSelectSwatchesValue(
            swatchGroup.groupMaterial,
            swatchGroup?.stockedSwatches[0]
          );
        } else {
          swatchGroup?.stockedSwatches?.forEach(stockedSwatch => {
            if (data.fullSkuSwatchIds?.includes(stockedSwatch.swatchId)) {
              handleSelectSwatchesValue(
                swatchGroup.groupMaterial,
                stockedSwatch
              );
            }
          });
        }
      });
    }, [data.fullSkuSwatchIds, data.swatchData]);
    let triggerSwatchImage = "";
    if (data.uxAttributes && data.uxAttributes.triggerSwatchImage) {
      triggerSwatchImage = data.uxAttributes.triggerSwatchImage;
    } else {
      if (data.layout === "single-color" || data.layout === "two-color") {
        triggerSwatchImage = (
          {
            Leather: `<img src="//media.restorationhardware.com/is/image/rhis/hpdp_swatch_leather_20160405?wid=674&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`,
            Fabric: `<img src="//media.restorationhardware.com/is/image/rhis/hpdp_swatch_fabric_20160405?wid=674&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`
          } as { [key: string]: string }
        )[groupMaterial];
      } else if (data.layout === "outdoor") {
        triggerSwatchImage = `<img src="//media.restorationhardware.com/is/image/rhis/OD_Fabric_Swatch19_1146002?wid=674&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`;
      } else {
        triggerSwatchImage = (
          {
            Leather: `<img src="//media.restorationhardware.com/is/image/rhis/hpdp_swatch_leather_20160405?wid=674&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`,
            Fabric: `<img src="//media.restorationhardware.com/is/image/rhis/btn_pdp_cloud_uph_072016?wid=320&fmt=jpeg&qlt=80,0&op_sharpen=0&resMode=sharp&op_usm=0.3,1.0,5,0&iccEmbed=1" />`
          } as { [key: string]: string }
        )[groupMaterial];
      }
    }
    const relatedProducts: Product[] = useMemo(() => {
      const isMultiSkuProduct =
        data.metaProduct && data.nextGenDriven === "true";

      const shouldShowMainProd =
        (!data.emptyProduct || isMultiSkuProduct) && data.productLineItem;

      const relatedProductsFromData: Product[] = [
        ...(shouldShowMainProd ? [data] : []),
        ...(rProducts ?? [])
      ];

      if (
        rideAlongData?.replacementCushionProduct &&
        rideAlongData?.casingProduct &&
        rideAlongProduct
      ) {
        return relatedProductsFromData.map(product =>
          product.id === rideAlongData?.replacementCushionProduct
            ? rideAlongProduct
            : product
        );
      }

      return relatedProductsFromData;
    }, [data, rideAlongData, rideAlongProduct, rProducts]);

    const pli = relatedProducts[0]?.productLineItem;

    const [selectedAddOnsConfigured, setSelectedAddOnsConfigured] = useState<{
      [key: number]: boolean;
    }>(() => {
      return relatedProducts.reduce((acc, item, index) => {
        if (item?.productAddons) {
          acc[index] = false;
        }
        return acc;
      }, {} as { [key: number]: boolean });
    });
    const [allSelectedAddOnsConfigured, setAllSelectedAddOnsConfigured] =
      useState<boolean>(true);

    const checkSelectedAddOnsForConfig = (
      index: number | any,
      condition: boolean
    ) => {
      setSelectedAddOnsConfigured(prevConditions => {
        const newConditions = { ...prevConditions, [index]: condition };
        const allTrue = Object.values(newConditions).every(cond => !cond);
        setAllSelectedAddOnsConfigured(allTrue);
        return newConditions;
      });
    };
    const gridSpacing = useResponsiveGridSpacing();
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
    const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const shouldShow = data?.isActive ?? true;
    const toogleModalState = booleanInput => !booleanInput;

    // on HeroImage onIndexChange callback, reset HeroImage swatch url only
    const handleHeroImageOnIndexChange = useCallback(() => {
      setSelectedHeroImageSwatchUrl("");
    }, []);
    const addAllToCartFlag = yn(env.FEATURE_PDP_ADD_ALL);
    const FEATURE_SUPPRESS_PRE_CONFIGUREED_SWATCH = yn(
      env.FEATURE_SUPPRESS_PRE_CONFIGUREED_SWATCH
    );
    //add all item to cart
    const [addAllItemToCart, setAddAllItemToCart] = useState<
      Array<AddAllItemToCart>
    >([]);

    const handleAddAllItemToCart = useCallback(
      (data: AddAllItemToCart) => {
        const { monogram, customInfo } = data;
        //filter data according to the lineItemIndex
        const uniqueItems = addAllItemToCart.filter(
          item => item.lineItemIndex !== data.lineItemIndex
        );

        const allItems = [
          ...uniqueItems,
          {
            ...data,
            ...(monogram?.fontCode ||
            monogram?.fontColorCode ||
            monogram?.borderCode
              ? { monogram }
              : null),
            ...(!isEmpty(customInfo) ? { customInfo } : null)
          }
        ];
        const filteredData = allItems?.filter(
          item => item?.quantity > 0 && item?.sku
        );
        setAddAllItemToCart([...filteredData]);
      },
      [addAllItemToCart]
    );

    const handleRemoveItemFromAddAll = useCallback(
      (lineItemIndex: number) => {
        const matchedLineItemIndex = addAllItemToCart?.findIndex(
          item => item?.lineItemIndex === lineItemIndex
        );
        if (matchedLineItemIndex >= 0) {
          const filteredData = addAllItemToCart?.filter(
            item => item?.lineItemIndex !== lineItemIndex
          );
          setAddAllItemToCart([...filteredData]);
        }
      },
      [addAllItemToCart]
    );

    // consolidated onSwatchClick and isColorizable, and set HeroImage swatch url

    const handleOnSwatchClick = useCallback(
      (swatchData: {
        swatchGroup: ProductSwatchGroup;
        selectedSwatch: ProductSwatch;
      }) => {
        let count = isSwatchSwitch;
        if (swatchData?.selectedSwatch) {
          setIsSwatchSwitch(++count);
          setSelectedSwatch(swatchData?.selectedSwatch);
        }
        setSelectedOptions([]);
        if (swatchData?.selectedSwatch?.options[0]?.optionType === "Trim") {
          // only for custom rugs with trim color option
          setSelectedRugTrimSwatchIds(
            swatchData.selectedSwatch.options.map(op => op.id)
          );
          setSelectedRugTrimSwatchId(
            swatchData.selectedSwatch.secondaryOptionId
          );
          // eslint-disable-next-line react-hooks/exhaustive-deps
        } else {
          handleSelectSwatchesValue(
            swatchData?.swatchGroup?.groupMaterial,
            swatchData?.selectedSwatch
          );
          setSelectedRugTrimSwatchId("");
        }
      },
      [data, isColorizable]
    );
    const findDefaultSaleSwatch = useCallback(
      (groupMaterial, swatchId) => {
        let selectedSwatchGroup = null;
        const finishSwatchGroups = data.swatchData?.finishSwatchGroups;

        // If finishSwatchGroups exist, find the swatch group with an empty groupMaterial as the API returns an empty groupMaterial
        if (finishSwatchGroups && finishSwatchGroups.length > 0) {
          selectedSwatchGroup = swatchGroups.find(
            group => group.groupMaterial === "Finish"
          );
        } else {
          // If finishSwatchGroups don't exist, find the swatch group based on groupMaterial
          selectedSwatchGroup = swatchGroups.find(
            group => group.groupMaterial === groupMaterial
          );
        }

        if (!selectedSwatchGroup) {
          return null;
        }

        const selectedSwatchId = swatchId?.toString();
        const selectedSwatch = selectedSwatchGroup.stockedSwatches.find(
          swatch => swatch.swatchId === selectedSwatchId
        );

        if (selectedSwatch) {
          return {
            swatchGroup: selectedSwatchGroup,
            selectedSwatch
          };
        }

        return null;
      },
      [data.swatchData?.finishSwatchGroups, swatchGroups]
    );

    useEffect(() => {
      if (!params?.swatch && params?.fullSkuId) {
        return;
      }
      const selectedSwatch = findDefaultSaleSwatch(
        "Finish",
        params?.swatch || data.saleSwatchId
      );
      if (selectedSwatch && !FEATURE_SUPPRESS_PRE_CONFIGUREED_SWATCH) {
        handleOnSwatchClick(selectedSwatch);
      }
    }, [data.swatchData, data.saleSwatchId, params?.swatch]);

    const { productSwatchImage } = data;
    if (processEnvServer) {
      selectedHeroImageSwatchUrl = getSwatchImageUrl(productSwatchImage);
    }

    const containerGridSpacing = (gridSpacing * 2) as GridSpacing;

    const handleOpenInStockOnSaleModal = useCallback(() => {
      setOpenInStockOnSaleModal(true);
    }, []);

    const parsedDerivedUpsellProductList = useMemo(() => {
      const maxContainerHeight = upSellProducts?.upsellProducts?.reduce(
        (accMaxContainerHeight: number, itm: any) => {
          const imgContainerHeight = prasePGCropRules(itm?.pgCropRules)?.height;

          const [MAX_IMG_CONTAINER_HEIGHT] =
            PG_IMAGE_CONTAINER_DIMENSION?.[gridColumns]?.[mediaString] ??
            INITIAL_PG_IMAGE_CONTAINER_DIMENSION;

          const imageContainerHeight =
            (imgContainerHeight / 100) * MAX_IMG_CONTAINER_HEIGHT;

          return accMaxContainerHeight >= imageContainerHeight
            ? accMaxContainerHeight
            : imageContainerHeight;
        },
        0
      );
      return upSellProducts?.upsellProducts?.map(
        (groupedDerivedProduct: any) => {
          const [MAX_IMG_CONTAINER_HEIGHT] =
            PG_IMAGE_CONTAINER_DIMENSION?.[gridColumns]?.[mediaString] ??
            INITIAL_PG_IMAGE_CONTAINER_DIMENSION;

          const imgHeight = `${
            (prasePGCropRules(groupedDerivedProduct?.pgCropRules)?.height /
              100) *
            MAX_IMG_CONTAINER_HEIGHT
          }px`;

          return {
            ...groupedDerivedProduct,
            imageStyle: {
              objectFit: "fill",
              alignSelf: "flex-end",
              maxWidth: "100% !important",
              maxHeight: groupedDerivedProduct?.rhr ? imgHeight : "auto",
              width: "auto",
              height: "auto",
              transitionProperty: "opacity"
            },
            imageContainerStyle: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              height: groupedDerivedProduct?.rhr ? maxContainerHeight : "100%",
              aspectRatio: groupedDerivedProduct?.rhr
                ? null
                : IMAGE_ASPECT_RATIO["verticalProductTile"]?.toString()
            },
            isShopByRoom: true
          };
        }
      );
    }, [upSellProducts?.upsellProducts, mediaString, gridColumns]);

    const upsellProducts = parsedDerivedUpsellProductList?.map(
      (upsellProduct, i) => (
        <GridListTile
          key={`upsellProduct-${upsellProduct.id}`}
          className="md:w-auto w-[81%] md:max-w-none max-w-[310px] pr-5"
        >
          <ProductCard
            {...(i === 0 && { cardContentRef: productCardContentRef })}
            data={upsellProduct as any}
            upsell="upsell"
            objectFit={"contain"}
            colorBg={env?.FEATURE_BG_GREY ? PAGE_BG_GREY : "white"}
            onViewSelectItemsOnSaleClick={handleOpenInStockOnSaleModal}
          />
        </GridListTile>
      )
    );

    const customProductsCarousel = React.useMemo(
      () =>
        data?.customProduct
          ? data?.carouselProdctDetails?.map((customProduct, i) => {
              const newCustomProduct = { ...customProduct };
              newCustomProduct.id = customProduct.productId;

              return (
                <GridListTile
                  key={`customProduct-${newCustomProduct?.id}`}
                  className="md:max-w-[450px]"
                >
                  <ProductCard
                    {...(i === 0 && { cardContentRef: productCardContentRef })}
                    data={newCustomProduct as any}
                    upsell=""
                    showPriceRange={false}
                    colorBg={env?.FEATURE_BG_GREY ? PAGE_BG_GREY : "white"}
                  />
                </GridListTile>
              );
            })
          : null,
      [data?.carouselProdctDetails]
    );

    const isPostalCodeAvailable = useMemo(() => {
      return (
        !!postalCode &&
        postalCode !== "null" &&
        postalCode !== "undefined" &&
        postalCode !== "false"
      );
    }, [postalCode]);

    const priceRangeDisplay = useMemo(() => {
      const isMultiSkuPopulated =
        !!data.priceRangeMultiSkuDisplay &&
        !data.priceRangeMultiSkuDisplay.isUnavailable &&
        !data?.metaProduct;

      return isMultiSkuPopulated
        ? {
            ...data.priceRangeDisplay,
            listPrices: data?.priceRangeMultiSkuDisplay?.listPrices,
            memberPrices: data?.priceRangeMultiSkuDisplay?.memberPrices,
            skulowestMemberPrice:
              data?.priceRangeMultiSkuDisplay?.skulowestMemberPrice,
            nextGenDrivenOnSale:
              data?.priceRangeMultiSkuDisplay?.nextGenDrivenOnSale
          }
        : data.priceRangeDisplay;
    }, [data.priceRangeDisplay, data.priceRangeMultiSkuDisplay]);

    const handleFAQDialogOpen = useCallback(() => setFAQDialog(true), []);

    const handleFAQDialogClose = useCallback(() => setFAQDialog(false), []);

    const handleRequestConsultationOpen = useCallback(
      () => setRequestConsultationOpen(true),
      []
    );

    const handleRequestConsultationClose = useCallback(
      () => setRequestConsultationOpen(false),
      []
    );

    const handleCloseInStockOnSaleModal = useCallback(
      () => setOpenInStockOnSaleModal(false),
      []
    );

    const [multiSkuComponentsData, setMultiSkuComponentsData] = useState<
      MultiSkuComponentWithRestrictions[]
    >([]);
    const [productAddonItems, setProductAddonItems] = useState<
      Array<addOnDataType>
    >([]);

    const [swatchImageOnLineItem, changeSwatchImageOnLineItem] = useState("");

    const styledDescription = data?.longDescription?.replace(
      /(<a\s+class="sku-info link")(.*?)(>)/g,
      `<a$2 style="text-transform: uppercase; font-family: 'RHSANS-THIN'; text-decoration: none; font-weight: 300; line-height: 13px;"$3`
    );

    const isShellProduct = data?.emptyProduct;

    return (
      <div>
        {!processEnvServer ? (
          <Hidden mdUp>
            {!data?.alternateImages ? (
              <BaseProductSkeleton />
            ) : (
              <HeroImage
                images={data?.alternateImages}
                isColorizable={isColorizable}
                changeSwatchImageOnLineItem={changeSwatchImageOnLineItem}
                selectedSwatchImageUrl={
                  selectedHeroImageSwatchUrl == ""
                    ? getSwatchImageUrl(productSwatchImage)
                    : selectedHeroImageSwatchUrl
                }
                productSwatchLoading={productSwatchLoading}
                onIndexChange={handleHeroImageOnIndexChange}
                selectedOptionLabel={selectedOptionLabel}
                isRHR={data?.rhr}
                isSwatchSwitch={isSwatchSwitch}
                isBannerVisible={totalSwatchesLength > 1}
                saleSwatchId={data?.saleSwatchId}
                selectedSwatch={selectedSwatch}
                imagePresetOverride={data?.imagePresetOverride}
              />
            )}
          </Hidden>
        ) : (
          ""
        )}
        <Grid
          container
          className={"!m-[0_auto] !w-auto bg-white-300 !block"}
          spacing={xlUp ? 0 : containerGridSpacing}
        >
          {/* All relatedProduct Grids should be double the parent container, to have full-width bg:
              - [xs] 1.5spacing x 2 = 3
              - [sm] 3spacing x 2 = 6
              - [md, lg] 4spacing x 2 = 8
              - [xl] 9spacing x 2 = 18
            */}
          <HTMLHeadTags
            title={data?.displayName}
            description={data?.longDescription}
            keywords={data?.keywords}
            displayName={data?.displayName}
            image={
              selectedHeroImageSwatchUrl == ""
                ? getSwatchImageUrl(productSwatchImage)
                : selectedHeroImageSwatchUrl
            }
            // sku={data?.id}
            // lowPrice={
            //   data?.priceRangeDisplay?.memberPrices?.[0] ||
            //   data?.priceRangeMultiSkuDisplay?.memberPrices?.[0]
            // }
            // highPrice={
            //   data?.priceRangeDisplay?.listPrices?.[0] ||
            //   data?.priceRangeMultiSkuDisplay?.listPrices?.[0]
            // }
          />
          {!!data?.priceRangeDisplay && <StructuredData product={data} />}
          {/* item child for spacing */}
          <Grid
            item
            className="gridItem"
            classes={{
              root: "w-full max-w-[1920px] !my-0 !mx-auto !pt-0 !pb-0 xl:pb-[29.6px] xl:px-[90px] !print:none"
            }}
          >
            <Grid container spacing={4}>
              {!processEnvServer && isProductLoading ? (
                <Hidden smDown>
                  <Grid item xs={12}>
                    <BaseProductSkeleton />
                  </Grid>
                </Hidden>
              ) : (
                <>
                  {processEnvServer ? (
                    <Grid item xs={12} md={6} className="!pt-[12.8px]">
                      <div>
                        <HeroImage
                          changeSwatchImageOnLineItem={
                            changeSwatchImageOnLineItem
                          }
                          objectFit={"contain"}
                          images={data?.alternateImages}
                          isColorizable={isColorizable}
                          selectedSwatchImageUrl={
                            selectedHeroImageSwatchUrl == ""
                              ? getSwatchImageUrl(productSwatchImage)
                              : selectedHeroImageSwatchUrl
                          }
                          onIndexChange={handleHeroImageOnIndexChange}
                          selectedOptionLabel={selectedOptionLabel}
                          isRHR={data.rhr}
                          isBannerVisible={totalSwatchesLength > 1}
                          isSwatchSwitch={isSwatchSwitch}
                          saleSwatchId={data?.saleSwatchId}
                          selectedSwatch={selectedSwatch}
                          imagePresetOverride={data?.imagePresetOverride}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <Hidden smDown>
                      <Grid item xs={12} md={6} className="!pt-[12.8px]">
                        <div>
                          <HeroImage
                            changeSwatchImageOnLineItem={
                              changeSwatchImageOnLineItem
                            }
                            objectFit={"contain"}
                            images={data?.alternateImages}
                            isColorizable={isColorizable}
                            selectedSwatchImageUrl={
                              selectedHeroImageSwatchUrl == ""
                                ? getSwatchImageUrl(productSwatchImage)
                                : selectedHeroImageSwatchUrl
                            }
                            onIndexChange={handleHeroImageOnIndexChange}
                            selectedOptionLabel={selectedOptionLabel}
                            productSwatchLoading={
                              !isSSRToggledWithCSR
                                ? productSwatchLoading
                                : false
                            }
                            isRHR={data.rhr}
                            isBannerVisible={totalSwatchesLength > 1}
                            isSwatchSwitch={isSwatchSwitch}
                            selectedLineItemOptionLabel={
                              selectedLineItemOptionLabel
                            }
                            saleSwatchId={data?.saleSwatchId}
                            selectedSwatch={selectedSwatch}
                            imagePresetOverride={data?.imagePresetOverride}
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  )}
                  <Grid item xs={12} md={6}>
                    <div
                      className={classNames({
                        "opacity-0 animate-[fadeInUp_0s_ease-in_0s_1_normal_none_running]":
                          animationCheck,
                        "duration-0 delay-0": animationCheck,
                        "duration-500 delay-0": !animationCheck,
                        "animate-none": animationCheck,
                        "opacity-100": animationCheck
                      })}
                    >
                      <Grid container>
                        <Grid item xs={12} className="md:!mt-0 !mt-4">
                          <Typography
                            variant="h1"
                            className={classNames(
                              [typographyStyles.rhBaseH2],
                              "!uppercase"
                            )}
                            dangerouslySetInnerHTML={{
                              __html: data.displayName
                            }}
                          />
                          {!data.subName?.match(/(^|\W)cat($|\W)/i) && (
                            <Typography
                              variant={mdUp ? "h3" : "h1"}
                              dangerouslySetInnerHTML={{
                                __html: data.subName
                              }}
                            />
                          )}
                        </Grid>
                        {!shouldShow ? (
                          <Grid container item xs={12} className="bg-white-200">
                            <div>
                              <Typography>
                                {pageContent?.PRODUCT_NOT_AVAILABLE}
                                {/* //product no longer available */}
                              </Typography>

                              <RHLink className="inline" href="/">
                                {pageContent?.PLEASE_TEXT}{" "}
                                {pageContent?.CONTINUE_BROWSING}
                                {/* continue browsing */}
                              </RHLink>
                            </div>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} className="!pt-0.5">
                          {shouldShow &&
                          (!data?.layout?.includes("custom") ||
                            data?.layout === "custom-rug") &&
                          data?.layout !== "track-hardware"
                            ? !!priceRangeDisplay && (
                                <>
                                  <RHRPriceDisplay
                                    listPrice={
                                      priceRangeDisplay?.listPrices?.[0]!
                                    }
                                    memberPrice={
                                      priceRangeDisplay?.memberPrices?.[0]!
                                    }
                                    topLabel={
                                      data.priceRangeDisplay
                                        ?.overridePriceLabel ||
                                      pageContent?.["STARTING_AT"]
                                    }
                                    isSaleFilterEnabled={
                                      params?.sale === "true" ?? false
                                    }
                                    onSale={
                                      priceRangeDisplay?.nextGenDrivenOnSale!
                                    }
                                    skulowestMemberPrice={
                                      priceRangeDisplay?.skulowestMemberPrice!
                                    }
                                    showSaleMessage={
                                      Number(
                                        data?.saleInfo?.percentSaleSkus
                                      ) === 0 ||
                                      Number(
                                        data?.saleInfo?.percentSaleSkus
                                      ) === 100
                                        ? false
                                        : true
                                    }
                                    userType={rhUser?.userType! || ""}
                                    pageContent={pageContent}
                                    computedSalePageContent={
                                      saleMessageForPriceComponent
                                    }
                                    variant={"medium"}
                                    centerAlignFlag={false}
                                    showMembershipProductPrice={
                                      data?.uxAttributes?.membershipProduct?.toLowerCase() ===
                                      "true"
                                    }
                                    showGiftCardPrice={
                                      data?.uxAttributes?.giftCert?.toLowerCase() ===
                                      "true"
                                    }
                                    hideCss={true}
                                    onSaleLinkClick={
                                      handleOpenInStockOnSaleModal
                                    }
                                  />

                                  {openInStockOnSaleModal ? (
                                    <InStockOnSaleDialog
                                      open={openInStockOnSaleModal}
                                      onClose={handleCloseInStockOnSaleModal}
                                      isPostalCodeAvailable={
                                        isPostalCodeAvailable
                                      }
                                      postalCodeNotRequired={true}
                                    >
                                      {mdUp ? (
                                        <RHDialogTitle
                                          title={`<span class="text-red-600">${
                                            pageContent.ON_SALE ?? ON_SALE
                                          }</span>`}
                                          onClose={
                                            handleCloseInStockOnSaleModal
                                          }
                                          className="!pl-0"
                                        />
                                      ) : (
                                        <RHDialogTitle
                                          title={`<span class="text-red-600">${
                                            pageContent.ON_SALE ?? ON_SALE
                                          }</span>`}
                                          onBack={handleCloseInStockOnSaleModal}
                                        />
                                      )}
                                      <OnSaleDialogContent
                                        productId={data?.id}
                                        productName={data?.displayName}
                                        postalCode={postalCode}
                                        salePriceLabel={
                                          data?.priceRangeDisplay
                                            ?.salePriceLabel ?? ""
                                        }
                                        productPrice={convertProductPriceRangeDisplayToPriceRange(
                                          data?.priceRangeDisplay
                                        )}
                                        isAuthenticated={authenticated}
                                        userType={rhUser?.userType || ""}
                                        multiSkuAdditionalMessage={
                                          data?.multiSkuAdditionalMessage ?? ""
                                        }
                                        isRHR={data?.rhr}
                                        metaProduct={data?.metaProduct}
                                      />
                                    </InStockOnSaleDialog>
                                  ) : null}
                                </>
                              )
                            : null}
                        </Grid>
                        <Grid xs={12} className="!my-5 !mx-0">
                          <ModalTrigger>
                            <Typography
                              id="long_description"
                              className={classNames(
                                classes.link3,
                                "normal-case [&b]:text-[13px] [&b]:tracking-[0.025em] [&b]:leading-[1.66rem] [&b]:uppercase [&b]:text-black [&b]:hover:text-black [&b]:no-underline"
                              )}
                              innerRef={longDescriptionRef}
                              dangerouslySetInnerHTML={{
                                __html: processEnvServer
                                  ? styledDescription
                                  : data.longDescription
                              }}
                            />
                          </ModalTrigger>
                        </Grid>
                        {(data?.layout?.includes("custom") ||
                          data?.layout === "track-hardware") &&
                        data?.layout !== "custom-rug" ? (
                          <Grid item>
                            <RHLink
                              className="!mt-[30px] !mb-10"
                              onClick={() => null}
                              underline="none"
                            >
                              <Typography
                                className={typographyStyles.rhBaseBody1}
                                onClick={handleRequestConsultationOpen}
                              >
                                {pageContent?.WINDOWS_CONSULTATION}
                                {/* //request a window consultation */}
                                <span>
                                  <GrommetArrowThick />
                                </span>
                              </Typography>
                              <CustomWindowDialog
                                open={requestConsultationOpen}
                                onClose={handleRequestConsultationClose}
                              />
                            </RHLink>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={12}
                          className="[&b]:font-normal [&b]:text-[13px] [&b]:leading-5"
                        >
                          {(layoutTemplate === "panel" ||
                            layoutTemplate === "split-panel") &&
                            (stockedSwatchesLength > 0 ||
                              customSwatchesLength > 0) &&
                            !data?.layout?.includes("custom") &&
                            data?.layout !== "track-hardware" && (
                              <RHLinkListSwatchAnchor
                                onClick={() =>
                                  setSwatchPanelDialog("open")(true)
                                }
                                template={layoutTemplate}
                                stockedSwatchesLength={stockedSwatchesLength}
                                groupMaterial={groupMaterial}
                                customSwatchesLength={customSwatchesLength}
                                triggerSwatchImage={triggerSwatchImage}
                                borderType={{ top: true }}
                              />
                            )}
                          {swatchGroups?.length > 0 && (
                            <div className="mb-10">
                              <SwatchGroups
                                swatchGroups={swatchGroups}
                                selectedOptionsFromLineItem={
                                  selectedOptionsFromLineItem
                                }
                                swatches={["customSwatches", "stockedSwatches"]}
                                selectedSwatches={selectedSwatches}
                                onClick={handleOnSwatchClick}
                                productSwatchImage={productSwatchImage}
                                detail={true}
                              />
                            </div>
                          )}
                          {openGiftCardTerms && (
                            <FormDialog
                              id="gift-card-terms"
                              open={openGiftCardTerms}
                              onClose={() => setOpenGiftCardTerms(false)}
                              hideScroll={false}
                            >
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: giftCardTerms
                                }}
                              />
                            </FormDialog>
                          )}
                          {relatedProducts[0] &&
                            !processEnvServer &&
                            !pli.sku?.restrictions?.serviceSku && (
                              <>
                                <RelatedProductCard
                                  data={relatedProducts?.[0]}
                                  authenticated={authenticated}
                                  saleContextFilter={saleContextFilter}
                                  postalCode={postalCode}
                                  lineItemIndex={0}
                                  inStockReady={
                                    pageContent?.IN_STOCK ||
                                    IN_STOCK_READY_TO_SHIP
                                  }
                                  rideAlongData={rideAlongData}
                                  setRideAlongData={setRideAlongData}
                                  setMultiSkuComponentsData={
                                    setMultiSkuComponentsData
                                  }
                                  swatchGroups={
                                    swatchGroups as [ProductSwatchGroup]
                                  }
                                  setProductAddonItems={setProductAddonItems}
                                  setPrintFullSkuId={setPrintFullSkuId}
                                  heroImageLevel={true}
                                  checkSelectedAddOnsForConfig={
                                    checkSelectedAddOnsForConfig
                                  }
                                  isShellProduct={isShellProduct}
                                />
                              </>
                            )}
                          {!!data.featureList?.length && (
                            <RHLinkListCollapse
                              borderType={{ top: true }}
                              title={pageContent?.DETAILS}
                              open={false}
                              onClick={() => {
                                analyticsModalStates.current.detailsModal =
                                  toogleModalState(
                                    analyticsModalStates.current.detailsModal
                                  );
                                if (!processEnvServer) {
                                  document.body.dispatchEvent(
                                    new CustomEvent("pre-pdp-click", {
                                      detail: {
                                        item: {
                                          action: analyticsModalStates.current
                                            .detailsModal
                                            ? "Details Box Expand"
                                            : "Details box close"
                                        }
                                      }
                                    })
                                  );
                                }
                              }}
                            >
                              <ModalTrigger>
                                <ul
                                  className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                                >
                                  {data.featureList.map((feature, index) => {
                                    const featureFilepath =
                                      feature.match(filePathRegex);
                                    const processedFeature =
                                      featureFilepath?.length > 0
                                        ? feature.replace(
                                            featureFilepath[0],
                                            convertPathLocale(
                                              featureFilepath[0],
                                              "/us/en/",
                                              pathLocale
                                            )
                                          )
                                        : feature;
                                    const warning = hasPDPWarning(feature);

                                    return (
                                      <React.Fragment key={`feature-${index}`}>
                                        {warning?.hasWarning ||
                                        feature.includes("pdpWarning") ? (
                                          <li className="!list-item !normal-case">
                                            <Prop65 warning={warning} />
                                          </li>
                                        ) : (
                                          <Typography
                                            component="li"
                                            className="!list-item !normal-case"
                                            dangerouslySetInnerHTML={{
                                              __html: processedFeature
                                            }}
                                          />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </ul>
                              </ModalTrigger>
                            </RHLinkListCollapse>
                          )}

                          {data?.customProductType === "drape" ||
                          data?.customProductType === "shade" ? (
                            <>
                              <Grid item>
                                <RHLinkListItem
                                  title={pageContent?.FAQ}
                                  borderType={{ top: true }}
                                  onClick={handleFAQDialogOpen}
                                  Icon={<RHArrowIcon color="primary" />}
                                />
                                <FAQDialog
                                  open={showFAQDialog}
                                  onClose={handleFAQDialogClose}
                                  customProductType={data?.customProductType}
                                />
                              </Grid>
                            </>
                          ) : null}

                          {!!data.dimensions?.length && (
                            <RHLinkListCollapse
                              borderType={{ top: true }}
                              title={pageContent?.DIMENSIONS}
                              open={false}
                              onClick={() => {
                                analyticsModalStates.current.dimensionModal =
                                  toogleModalState(
                                    analyticsModalStates.current.dimensionModal
                                  );
                                if (!processEnvServer) {
                                  document.body.dispatchEvent(
                                    new CustomEvent("pre-pdp-click", {
                                      detail: {
                                        item: {
                                          action: analyticsModalStates.current
                                            .dimensionModal
                                            ? "Dimensions Box Expand"
                                            : "Dimension box close"
                                        }
                                      }
                                    })
                                  );
                                }
                              }}
                            >
                              <ul
                                className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                              >
                                {data.dimensions.map((dimension, index) => {
                                  const dimensionFilepath =
                                    dimension.match(filePathRegex);
                                  const processedDimension =
                                    dimensionFilepath?.length > 0
                                      ? dimension.replace(
                                          dimensionFilepath[0],
                                          convertPathLocale(
                                            dimensionFilepath[0],
                                            "/us/en/",
                                            pathLocale
                                          )
                                        )
                                      : dimension;
                                  return (
                                    <Typography
                                      key={`dimension-${index}`}
                                      component="li"
                                      className="!normal-case"
                                      dangerouslySetInnerHTML={{
                                        __html: processedDimension
                                      }}
                                    />
                                  );
                                })}
                              </ul>
                            </RHLinkListCollapse>
                          )}
                          {data?.productMessage && (
                            <>
                              <RHLinkListCollapse
                                borderType={{ top: true }}
                                title={pageContent?.GUARANTEED_FOR_LIFE}
                                open={false}
                              >
                                <ul
                                  className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                                >
                                  <Typography
                                    component="li"
                                    className="!normal-case"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        pageContent?.GUARANTEED_FOR_LIFE_TEXT
                                    }}
                                  />
                                </ul>
                              </RHLinkListCollapse>
                            </>
                          )}
                          {!!data?.personalizeInfo?.features?.length && (
                            <RHLinkListCollapse
                              borderType={{ top: true }}
                              title={
                                pageContent?.PERSONALIZATION ?? PERSONALIZATION
                              }
                              open={false}
                            >
                              <ul
                                className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                              >
                                {data?.personalizeInfo.features.map(
                                  (feature, index) => (
                                    <Typography
                                      key={`feature-${index}`}
                                      component="li"
                                      className="!normal-case"
                                      dangerouslySetInnerHTML={{
                                        __html: feature
                                      }}
                                    />
                                  )
                                )}
                              </ul>
                            </RHLinkListCollapse>
                          )}
                          {!!data.careInstructions?.length && (
                            <RHLinkListCollapse
                              borderType={{ top: true }}
                              title={pageContent?.CARE ?? CARE}
                              open={false}
                            >
                              <ul
                                className={`p-0 !pb-[24px] m-0 list-[initial] ${classes.ul}`}
                              >
                                {data.careInstructions.map(
                                  (careInstruction, index) => {
                                    const careInstructionFilepath =
                                      careInstruction.match(filePathRegex);
                                    const processedCareInstruction =
                                      careInstructionFilepath?.length > 0
                                        ? careInstruction.replace(
                                            careInstructionFilepath[0],
                                            convertPathLocale(
                                              careInstructionFilepath[0],
                                              "/us/en/",
                                              pathLocale
                                            )
                                          )
                                        : careInstruction;
                                    return (
                                      <Typography
                                        key={`careInstruction-${index}`}
                                        component="li"
                                        className="normal-case"
                                        dangerouslySetInnerHTML={{
                                          __html: processedCareInstruction
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </ul>
                            </RHLinkListCollapse>
                          )}
                          {(data.fileLinkUrls || []).map(
                            (fileLinkUrl, index) => {
                              let fileLink = { ...fileLinkUrl };
                              let { link } = fileLinkUrl;
                              const linkFilepath = link.match(filePathRegex);
                              fileLink.link =
                                linkFilepath?.length > 0
                                  ? // @ts-ignore
                                    linkFilepath.replace(
                                      linkFilepath[0],
                                      convertPathLocale(
                                        linkFilepath[0],
                                        "/us/en/",
                                        pathLocale
                                      )
                                    )
                                  : fileLinkUrl.link;
                              return (
                                <RHLinkListItem
                                  key={`fileLink-${index}`}
                                  borderType={{ top: true }}
                                  title={he.decode(fileLinkUrl.label || "")}
                                  onClick={() => {
                                    useIsoRedirect(
                                      convertPathLocale(
                                        fileLink.link,
                                        "/us/en/",
                                        pathLocale
                                      ),
                                      "windowOpen"
                                    );
                                    if (!processEnvServer) {
                                      document.body.dispatchEvent(
                                        new CustomEvent("pre-pdp-click", {
                                          detail: {
                                            item: {
                                              action: he.decode(
                                                fileLinkUrl.label || ""
                                              )
                                            }
                                          }
                                        })
                                      );
                                    }
                                  }}
                                  Icon={<RHArrowIcon color="primary" />}
                                />
                              );
                            }
                          )}
                          {draperyLink && (
                            <RHLinkListItem
                              borderType={{ top: true }}
                              Icon={<RHArrowIcon color="primary" />}
                            >
                              <span
                                className="[&>a]:no-underline"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    processedDraperyLink || draperyLink || ""
                                }}
                              />
                            </RHLinkListItem>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <RHDivider className="text-gray-200 opacity-100 -mt-0.5" />
                        </Grid>
                        {([
                          "drape",
                          "customHardware",
                          "trackHardware",
                          "shade"
                        ].includes(data?.customProductType) ||
                          data?.layout === "custom") && (
                          <Grid item xs={12}>
                            <CustomProduct
                              data={data}
                              fullSkuId={fullSkuId}
                              authenticated={authenticated}
                              loading={isProductLoading}
                              saleContextFilter={saleContextFilter}
                              postalCode={postalCode}
                              swatchGroups={allSwatchGroups}
                              productSwatchImage={productSwatchImage}
                              selectedSwatches={selectedSwatches}
                              handleOnSwatchClick={handleOnSwatchClick}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <>
            <PrintSection.Container
              className="mx-auto w-full bg-white-300"
              spacing={xsDown ? 0 : 4}
            >
              {/* All relatedProduct Grids should be double the parent container, to have full-width bg:
                    - [xs] 1.5spacing x 2 = 3
                    - [sm] 3spacing x 2 = 6
                    - [md, lg] 4spacing x 2 = 8
                    - [xl] 9spacing x 2 = 18
                  */}
              {!!data.productListTitle && (
                <>
                  <Typography className={typographyStyles.rhBaseH1}>
                    {data.productListTitle}
                  </Typography>
                  <RHDivider className="text-gray-200 opacity-100" />
                </>
              )}
              {shouldShow &&
                !data?.layout?.includes("custom") &&
                data?.layout !== "track-hardware" && (
                  <>
                    {relatedProducts[0]?.id === params.productId &&
                    (!processEnvServer ||
                      (processEnvServer && !pdpLoadPerformance) ||
                      isConcierge) ? (
                      isProductLoading ? (
                        <Grid item className="gridItem !w-full">
                          <RelatedProductCardSkeleton data-testid="full-product-loading-spinner" />
                        </Grid>
                      ) : (
                        <PrintSection.Item
                          className={classNames([
                            "xl:!pl-0 xl:!pr-0 sm:!p-[10px] md:!p-[32px]",
                            classes.relatedProductGrid
                          ])}
                        >
                          <div
                            className={classNames({
                              "opacity-0 animate-[fadeInUp_0s_ease-in_0s_1_normal_none_running]":
                                animationCheck,
                              "duration-0 delay-0": animationCheck,
                              "duration-500 delay-0": !animationCheck,
                              "animate-none": animationCheck,
                              "opacity-100": animationCheck
                            })}
                          >
                            <RelatedProductCard
                              data={relatedProducts[0]}
                              setSelectedHeroImageSwatchUrl={
                                setSelectedHeroImageSwatchUrl
                              }
                              fullSkuId={fullSkuId}
                              selectedSwatches={selectedSwatches}
                              authenticated={authenticated}
                              saleContextFilter={saleContextFilter}
                              selectedSwatchImageUrl={swatchImageOnLineItem}
                              postalCode={postalCode}
                              onOptionsSelected={isSelected => {
                                setSelectedOptionLabel(isSelected);
                                setSelectedLineItemOptionLabel(isSelected);
                              }}
                              changeSwatchImageFromLineItem={
                                onChangeSwatchImageFromLineItem
                              }
                              changeSelectedOptions={onChangeSelectedOptions}
                              handleAddAllItemToCart={handleAddAllItemToCart}
                              handleRemoveItemFromAddAll={
                                handleRemoveItemFromAddAll
                              }
                              lineItemIndex={0}
                              rideAlongData={rideAlongData}
                              setRideAlongData={setRideAlongData}
                              setMultiSkuComponentsData={
                                setMultiSkuComponentsData
                              }
                              swatchGroups={
                                swatchGroups as [ProductSwatchGroup]
                              }
                              spoOverrideMessage={
                                data?.spoReturnMessageOverride
                              }
                              setProductAddonItems={setProductAddonItems}
                              setPrintFullSkuId={setPrintFullSkuId}
                              handleOnSwatchClick={handleOnSwatchClick}
                              allSwatchGroups={allSwatchGroups}
                              checkSelectedAddOnsForConfig={
                                checkSelectedAddOnsForConfig
                              }
                              isShellProduct={isShellProduct}
                            />
                          </div>
                        </PrintSection.Item>
                      )
                    ) : null}
                    {relatedProductsloading ? (
                      <Grid item className="gridItem !w-full">
                        <RelatedProductCardSkeleton data-testid="full-product-loading-spinner" />
                      </Grid>
                    ) : (
                      <>
                        {" "}
                        {!processEnvServer ||
                        (processEnvServer && !pdpLoadPerformance) ||
                        isConcierge
                          ? relatedProducts.map((item, index) => {
                              if (item.id === params.productId) {
                                return null;
                              }
                              return (
                                <React.Fragment
                                  key={`relatedProduct-${item.id}`}
                                >
                                  <PrintSection.Item
                                    className={classNames([
                                      "xl:!px-0 lg:!px-[32px] p-2.5 xl:!py-[32px]",
                                      classes.relatedProductGrid
                                    ])}
                                  >
                                    <div
                                      className={classNames({
                                        "opacity-0 animate-[fadeInUp_0s_ease-in_0s_1_normal_none_running]":
                                          animationCheck,
                                        "duration-0 delay-0": animationCheck,
                                        "duration-500 delay-0": !animationCheck,
                                        "animate-none": animationCheck,
                                        "opacity-100": animationCheck
                                      })}
                                    >
                                      <RelatedProductCard
                                        data={item}
                                        fullSkuId={fullSkuId}
                                        selectedSwatches={selectedSwatches}
                                        authenticated={authenticated}
                                        saleContextFilter={saleContextFilter}
                                        postalCode={postalCode}
                                        onOptionsSelected={isSelected => {
                                          setSelectedOptionLabel(isSelected);
                                        }}
                                        handleAddAllItemToCart={
                                          handleAddAllItemToCart
                                        }
                                        handleRemoveItemFromAddAll={
                                          handleRemoveItemFromAddAll
                                        }
                                        changeSelectedOptions={
                                          onChangeSelectedOptions
                                        }
                                        lineItemIndex={index}
                                        rideAlongData={rideAlongData}
                                        setRideAlongData={setRideAlongData}
                                        setMultiSkuComponentsData={
                                          setMultiSkuComponentsData
                                        }
                                        swatchGroups={
                                          swatchGroups as [ProductSwatchGroup]
                                        }
                                        spoOverrideMessage={
                                          data?.spoReturnMessageOverride
                                        }
                                        setProductAddonItems={
                                          setProductAddonItems
                                        }
                                        handleOnSwatchClick={
                                          handleOnSwatchClick
                                        }
                                        allSwatchGroups={allSwatchGroups}
                                        checkSelectedAddOnsForConfig={
                                          checkSelectedAddOnsForConfig
                                        }
                                        isShellProduct={isShellProduct}
                                      />
                                    </div>
                                  </PrintSection.Item>
                                  {index < relatedProducts?.length - 1 && (
                                    <Divider className="w-full !h-[2px] !bg-white" />
                                  )}
                                </React.Fragment>
                              );
                            })
                          : null}
                      </>
                    )}
                  </>
                )}{" "}
              {shouldShow && data?.layout?.includes("custom-rug")
                ? relatedProducts.map((item, index) => (
                    <>
                      <PrintSection.Item key={`custom-rug-${item.id}`}>
                        <Grid
                          item
                          className={classNames([
                            "gridItem",
                            "xl:!pl-0 xl:!pr-0 sm:!p-[10px] md:!p-[32px]",
                            classes.relatedProductGrid
                          ])}
                          xs={12}
                        >
                          {isSSRToggledWithCSR ? (
                            <RelatedProductCard
                              data={item}
                              fullSkuId={fullSkuId}
                              selectedSwatches={selectedSwatches}
                              authenticated={authenticated}
                              saleContextFilter={saleContextFilter}
                              postalCode={postalCode}
                              handleAddAllItemToCart={handleAddAllItemToCart}
                              handleRemoveItemFromAddAll={
                                handleRemoveItemFromAddAll
                              }
                              lineItemIndex={index}
                              rideAlongData={rideAlongData}
                              setRideAlongData={setRideAlongData}
                              selectedRugTrimSwatchId={selectedRugTrimSwatchId}
                              setMultiSkuComponentsData={
                                setMultiSkuComponentsData
                              }
                              swatchGroups={
                                swatchGroups as [ProductSwatchGroup]
                              }
                              spoOverrideMessage={
                                data?.spoReturnMessageOverride
                              }
                              setProductAddonItems={setProductAddonItems}
                              checkSelectedAddOnsForConfig={
                                checkSelectedAddOnsForConfig
                              }
                              isShellProduct={isShellProduct}
                            />
                          ) : (
                            <InView
                              triggerOnce
                              initialInView={processEnvServer}
                            >
                              {({ inView, ref }) => (
                                <div ref={ref}>
                                  {inView && (
                                    <RelatedProductCard
                                      setSelectedHeroImageSwatchUrl={
                                        setSelectedHeroImageSwatchUrl
                                      }
                                      data={item}
                                      fullSkuId={fullSkuId}
                                      selectedSwatches={selectedSwatches}
                                      authenticated={authenticated}
                                      saleContextFilter={saleContextFilter}
                                      postalCode={postalCode}
                                      handleAddAllItemToCart={
                                        handleAddAllItemToCart
                                      }
                                      handleRemoveItemFromAddAll={
                                        handleRemoveItemFromAddAll
                                      }
                                      lineItemIndex={index}
                                      rideAlongData={rideAlongData}
                                      setRideAlongData={setRideAlongData}
                                      selectedRugTrimSwatchId={
                                        selectedRugTrimSwatchId
                                      }
                                      setMultiSkuComponentsData={
                                        setMultiSkuComponentsData
                                      }
                                      swatchGroups={
                                        swatchGroups as [ProductSwatchGroup]
                                      }
                                      spoOverrideMessage={
                                        data?.spoReturnMessageOverride
                                      }
                                      setProductAddonItems={
                                        setProductAddonItems
                                      }
                                      checkSelectedAddOnsForConfig={
                                        checkSelectedAddOnsForConfig
                                      }
                                      isShellProduct={isShellProduct}
                                    />
                                  )}
                                </div>
                              )}
                            </InView>
                          )}
                        </Grid>
                        {index < relatedProducts.length - 1 && (
                          <Divider className="w-full !h-[2px] !bg-white" />
                        )}
                      </PrintSection.Item>
                    </>
                  ))
                : null}
            </PrintSection.Container>

            {relatedProductsloading ? (
              <Grid item className="gridItem w-full">
                <RelatedProductCardSkeleton data-testid="full-product-loading-spinner" />
              </Grid>
            ) : (
              <>
                {addAllToCartFlag && relatedProducts.length > 0 && (
                  <AddAllProductActions
                    addAllItemToCart={addAllItemToCart ?? []}
                    multiSkuComponentsData={multiSkuComponentsData ?? []}
                    productAddonItems={productAddonItems ?? []}
                    productAddons={[
                      ...(allProductAddons || []),
                      ...(allRProductAddons || [])
                    ]}
                    allSelectedAddOnsConfigured={allSelectedAddOnsConfigured}
                  />
                )}
                {["drape", "customHardware", "trackHardware", "shade"].includes(
                  data?.customProductType
                ) || data?.layout === "custom" ? (
                  <>
                    {!!data?.carouselProdctDetails?.length && (
                      <Grid
                        item
                        xs={12}
                        wrap={"nowrap"}
                        className={classNames([
                          "!max-w-[1920px] !mx-auto !my-0 relative break-inside-avoid xl:px-[90px] bg-[#f9f7f4]",
                          classes.slider
                        ])}
                      >
                        <Typography
                          variant="h5"
                          className="!text-[15px] !uppercase md:items-start items-center"
                        >
                          {pageContent?.YOU_MIGHT_ALSO_LIKE}
                        </Typography>
                        <InView triggerOnce initialInView={processEnvServer}>
                          {({ inView, ref }) => (
                            <div ref={ref}>
                              {inView && (
                                <>
                                  {!processEnvServer && (
                                    <GridList
                                      cols={mdUp ? 1 : 1.25}
                                      cellHeight="auto"
                                      className="!flex-nowrap md:!overflow-x-hidden !overflow-x-scroll"
                                    >
                                      <Slider
                                        arrowOffset={
                                          +`-${productCardContentHeight}`
                                        }
                                      >
                                        {customProductsCarousel}
                                      </Slider>
                                    </GridList>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </InView>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {!!upSellProducts?.upsellProducts?.length && (
                      <Grid
                        item
                        xs={12}
                        wrap={"nowrap"}
                        className={classNames([
                          "!max-w-[1920px] !mx-auto !my-0 relative break-inside-avoid xl:px-[90px] !w-full",
                          classes.slider,
                          `${processEnvServer ? "!px-[90px] !py-0" : ""}`
                        ])}
                      >
                        <div
                          className={classNames({
                            "opacity-0 animate-[fadeInUp_0s_ease-in_0s_1_normal_none_running]":
                              animationCheck,
                            "duration-0 delay-0": animationCheck,
                            "duration-500 delay-0": !animationCheck,
                            "animate-none": animationCheck,
                            "opacity-100": animationCheck
                          })}
                        >
                          <Typography
                            variant={env.FEATURE_RHR ? "body2" : "h5"}
                            className="!pb-[15px] !uppercase"
                          >
                            {upSellProducts?.componentTitle ||
                              pageContent?.YOU_MIGHT_ALSO_LIKE}
                          </Typography>
                          <>
                            {
                              <GridList
                                cols={mdUp ? 1 : 1.25}
                                cellHeight="auto"
                                className={classNames(
                                  isSSRToggledWithCSR &&
                                    classes.carosalWraperStyle,
                                  "flex-nowrap md:overflow-x-hidden overflow-x-scroll [&..MuiGridList-root]:!flex-nowrap"
                                )}
                              >
                                {mdUp ? (
                                  <Slider
                                    arrowOffset={
                                      +`-${productCardContentHeight}`
                                    }
                                  >
                                    {upsellProducts}
                                  </Slider>
                                ) : (
                                  <>{upsellProducts}</>
                                )}
                              </GridList>
                            }
                          </>
                        </div>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </Grid>
        <Container>
          {!processEnvServer && (
            <SwatchPanelDialog
              product={data}
              open={!!swatchPanelDialog.open}
              onClose={() => setSwatchPanelDialog("open")(false)}
              stockedSwatchesLength={stockedSwatchesLength}
              customSwatchesLength={customSwatchesLength}
              groupMaterial={groupMaterial}
              template={layoutTemplate}
              selectedSwatches={selectedSwatches}
              selectedOptionsFromLineItem={selectedOptionsFromLineItem}
              onSwatchClick={handleOnSwatchClick}
            />
          )}
        </Container>
      </div>
    );
  }
);

PDP.displayName = "PDP";

const clientDefault = (props: ProductProps) => {
  const { productItem, isLoading } = props;
  const skipCall = isLoading === true || !!productItem?.id;

  const w = window as any;
  useEffect(() => {
    w.hardReload = false;
    const handlePageLoad = () => {
      w.hardReload = true;
    };
    window.addEventListener("readystatechange", handlePageLoad);
    return () => {
      window.removeEventListener("readystatechange", handlePageLoad);
    };
  }, []);

  const { app } = useAppData();
  const env = useEnv();

  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [printFullSkuId, setPrintFullSkuId] = useState<string>("");
  const [trimOptions, setTrimOptions] = useState<string[] | null>(null);
  const [openGiftCardTerms, setOpenGiftCardTerms] = useState<boolean>(false);
  const brandCode = useBrand();
  const prefix = useLocalization();
  const pc = useIsoCookies([POSTAL_CODE_COOKIE])?.pc || "";
  const { keycloak } = useKeycloak();
  const { userType } = useRhUserAtomValue();

  const client = useApolloClient();
  const siteId = useSite();
  const { pageContent } = useFetchModel("/admin/product", true, false);

  let params = useParams({
    productId: "",
    fullSkuId: "",
    sale: ""
  });
  const _params = useFetchParams(
    location.pathname,
    yn(env.FEATURE_URL_CHANGE) ? "pdp" : "product.jsp"
  );

  let [productId] = _params;

  if (params.productId === "") {
    params.productId = productId?.replace("&ct=true", "");
  }

  // More about RideAlong: SHOP-1647.
  const [rideAlongProduct, setRideAlongProduct] = useState<Product | null>(
    null
  );
  const [rideAlongData, setRideAlongData] =
    useState<RideAlongData>(defaultRideAlongData);

  const giftCardTerms =
    useQuery<Query>(queryGiftCardTerms, {
      variables: { key: "rh-gift-card-pdp" }
    }).data?.contentFragment?.text ?? "";

  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const { previousState } = useUserPreferences();
  const measureSystem = useMeasurementType();
  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode: app.postalCode || pc,
    userType
  });

  const pcCookie = getCookie("pc");
  const postalCode = app.postalCode || pcCookie || pc;
  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );

  const locale = useLocale();
  const req = processEnvServer ? getReqContext() : false;
  const pathLocale =
    getLocaleFromPath(
      processEnvServer ? (req ? req.path : "/") : location.pathname
    )?.locale || "/us/en/";

  const productVariables = useMemo(() => {
    return {
      productId: params.productId,
      filter: saleContextParam,
      userType: userType,
      siteId,
      currencyCode: currencyCode || "",
      measureSystem,
      locale: FEATURE_INTERNATIONAL ? locale : undefined,
      postalCode: pc || app.postalCode
    };
  }, [
    params.productId,
    userType,
    siteId,
    currencyCode,
    measureSystem,
    FEATURE_INTERNATIONAL,
    locale,
    saleContextParam,
    pc
  ]);

  const fetchRideAlongProduct = useCallback<
    (productId: string, variables?: any) => Promise<Product>
  >(
    async (productId, variables) => {
      const {
        data: { product }
      } = await client.query({
        query: queryRideAlongProduct,
        variables: {
          productId,
          filter: saleContextParam || null,
          userType: userType,
          siteId,
          currencyCode,
          measureSystem,
          ...variables
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return product;
    },
    [saleContextParam, siteId, client, currencyCode, measureSystem, userType]
  );

  // Ran into an issue where refetch throws an error instead of putting the error into the hook error output
  // see: https://github.com/apollographql/react-apollo/issues/3571
  // Found a workaround using the errorPolicy
  // errorPolicy info from apollo docs:
  //    "If you set errorPolicy to all, useQuery does not discard query
  //     response data, allowing you to render partial results."
  let {
    loading: isProductLoading,
    data: { product } = {} as Query,
    error: productError,
    refetch
  } = useQuery<Query>(queryProduct, {
    variables: productVariables,
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    skip: !params.productId || skipCall,
    errorPolicy: "all",
    fetchPolicy: "cache-only"
  });

  if (productItem && Object.keys(productItem)?.length) {
    product = productItem;
  }

  const { data: { upsellProducts } = {}, refetch: refetchUpSellProducts } =
    useQuery(queryUpsSellProducts, {
      variables: productVariables,
      skip: !params.productId || !product?.id
    });

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH3"]
  });

  const productSwatchQueryVariables = {
    productId: params.productId,
    optionIds: options,
    siteId,
    locale,
    trimOptionIds: trimOptions ?? null,
    fullskuId: params?.fullSkuId ?? ""
  };

  const {
    loading: productSwatchLoading,
    data: { product: productSwatchImage } = {} as Query,
    refetch: refetchQueryProductSwatchImage
  } = useQuery<Query>(queryProductSwatchImage, {
    fetchPolicy: "cache-and-network",
    variables: productSwatchQueryVariables
  });

  const [
    refetchQueryProductSwatchImageLoading,
    setRefetchQueryProductSwatchImageLoading
  ] = useState(false);
  useDidMountEffect(() => {
    setRefetchQueryProductSwatchImageLoading(true);
    refetchQueryProductSwatchImage(productVariables).finally(() => {
      setRefetchQueryProductSwatchImageLoading(false);
    });
  }, [JSON.stringify(productSwatchQueryVariables)]);

  useDidMountEffect(() => {
    refetch(productVariables);
    refetchUpSellProducts(productVariables);
  }, [JSON.stringify(productVariables)]);

  const relatedProductVariable = useMemo(() => {
    return {
      productIds: product?.relatedProductIds,
      //passing null irrepective of sale=true in url
      filter: product?.emptyProduct === true ? saleContextParam : null,
      userType: userType,
      siteId,
      currencyCode: currencyCode || "USD",
      measureSystem,
      locale: FEATURE_INTERNATIONAL ? locale : undefined
    };
  }, [
    userType,
    siteId,
    currencyCode,
    measureSystem,
    FEATURE_INTERNATIONAL,
    locale,
    saleContextParam,
    product?.relatedProductIds
  ]);

  const {
    data: { relatedProductsQuery } = {} as Query,
    loading: relatedProductsloading
  } = useQuery<Query>(queryRelatedProducts, {
    variables: relatedProductVariable,
    skip: !Boolean(relatedProductVariable?.productIds?.length)
  });

  const { data: { product: fullSkuProduct } = {} as Query } = useQuery<Query>(
    queryFullSkuProduct,
    {
      variables: {
        productId: params.productId,
        fullSkuId: params.fullSkuId
      },
      skip: !params.fullSkuId,
      onCompleted: () => {
        swatchToggle = 1;
      }
    }
  );

  const { data: { contentFragment } = {} as Query } = useQuery<Query>(
    queryDraperyLink,
    {
      skip: product?.uxAttributes?.productType !== "drapery",
      errorPolicy: "ignore",
      variables: {
        key: `draperyLink-${brandCode}`
      }
    }
  );

  const handleClose = useCallback(() => {
    let currentUrlParams = new URLSearchParams(location.search);
    currentUrlParams.set("sale", (product?.onSale || "").toString());
    if (history) {
      history.replace({
        pathname: `${prefix}/catalog/product/product.jsp`,
        search: `?${currentUrlParams.toString()}`
      });
    }
    setOpen(!open);
  }, [product?.onSale, history, location?.search, open]);

  useEffect(() => {
    async function fetchQuery() {
      let response = await fetchRideAlongProduct?.(
        rideAlongData.casingProduct ?? ""
      );
      setRideAlongProduct(response);
    }
    if (rideAlongData.casingProduct && !rideAlongProduct) {
      fetchQuery();
    }
  }, [rideAlongData.casingProduct, rideAlongProduct, fetchRideAlongProduct]);

  const DEFAULT_GRID_COL = 4;
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(BREAKPOINT_SM)
  );

  const [gridColumns, setGridColumns] = useState<number>(DEFAULT_GRID_COL);

  useDidMountEffect(() => {
    if (!smUp) {
      setGridColumns(6);
    } else {
      setGridColumns(4);
    }
  }, [mdUp, smUp, smDown]);

  /**
   * SHOP-1432 Maintains that any api call will merge with previous product data so it is never an empty object
   */
  const [combinedProduct, setCombinedProduct] = useState<Product>(
    {} as Product
  );

  useEffect(() => {
    if (productSwatchLoading || refetchQueryProductSwatchImageLoading) {
      return;
    }
    const newCombinedProduct = {
      ...combinedProduct,
      ...productSwatchImage,
      ...product,
      ...fullSkuProduct
    };

    // Only update state if there are changes
    if (!isEqual(combinedProduct, newCombinedProduct)) {
      setCombinedProduct(newCombinedProduct);
    }
  }, [
    combinedProduct,
    fullSkuProduct,
    product,
    productSwatchImage,
    productSwatchLoading,
    refetchQueryProductSwatchImageLoading
  ]);

  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const isProductLoadingFirstTime = useChangeOnce(isProductLoading);

  const { printFrameRef, enabled, showLoader, injectIframe, setIframeLoaded } =
    usePrintHijack({
      enabled: yn(env.FEATURE_IFRAME_PRINT),
      spyElementId: "pdp-print-load-check"
    });

  const printSrc = useMemo(() => {
    return `${prefix}/print-product.jsp?${objectToQueryString({
      ...productVariables,
      userType,
      sale: params.sale,
      fullSkuId:
        printFullSkuId || combinedProduct?.preconfiguredSku || params.fullSkuId
    })}`;
  }, [
    combinedProduct?.sku?.fulfillmentEta?.fullSkuId,
    combinedProduct?.sku?.inventory?.fullSkuId,
    prefix,
    productVariables,
    userType,
    params
  ]);

  // product is 404 if product cannot load.
  if (!!productError && !product) {
    return <Redirect to={`${prefix}/error/product-not-found.jsp`} />;
  }

  return (
    <>
      <PrintFrame
        showLoader={showLoader}
        injectIframe={injectIframe}
        onLoad={() => setIframeLoaded(true)}
        ref={printFrameRef}
        enabled={enabled}
        src={printSrc}
      />
      <PDP
        key={combinedProduct.id}
        data={combinedProduct}
        upSellProducts={upsellProducts}
        productSwatchLoading={
          productSwatchLoading || refetchQueryProductSwatchImageLoading
        }
        fullSkuId={params.fullSkuId || undefined}
        draperyLink={contentFragment?.text ?? ""}
        isProductLoading={
          (isSSRToggledWithCSR &&
            !document.referrer &&
            w.hardReload &&
            history.action !== "PUSH") ||
          (isSSRToggledWithCSR && window.location.href.includes("product.jsp/"))
            ? false
            : isProductLoadingFirstTime
        }
        authenticated={keycloak?.authenticated}
        saleContextFilter={saleContextParam}
        postalCode={postalCode}
        onOptionsChange={setOptions}
        onTrimOptionsChange={setTrimOptions}
        openGiftCardTerms={openGiftCardTerms}
        setOpenGiftCardTerms={setOpenGiftCardTerms}
        giftCardTerms={giftCardTerms}
        rideAlongData={rideAlongData}
        setRideAlongData={setRideAlongData}
        setPrintFullSkuId={setPrintFullSkuId}
        rideAlongProduct={rideAlongProduct}
        rProducts={relatedProductsQuery}
        gridColumns={gridColumns}
        locale={locale}
        pathLocale={pathLocale}
        selectedSwatchImageUrl={
          combinedProduct?.productLineItem?.image?.imageUrl
        }
        relatedProductVariable={relatedProductVariable}
        relatedProductsloading={relatedProductsloading}
        onProductRefetch={refetch}
      />
      {open && (
        <RHDialog
          solidWhite
          open={open}
          onClose={handleClose}
          fullScreen={false}
          DialogContentProps={{
            style: { marginTop: "-30px" }
          }}
        >
          <Typography className={typographyStyles.rhBaseH3}>
            {pageContent?.PRODUCT_NO_LONGER_SALE}
          </Typography>
        </RHDialog>
      )}
    </>
  );
};

const serverDefault = () => {
  const { app } = useAppData();
  const env = useEnv();
  const req = getReqContext();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [trimOptions, setTrimOptions] = useState<string[] | null>(null);
  const [openGiftCardTerms, setOpenGiftCardTerms] = useState<boolean>(false);
  const brandCode = useBrand();
  const prefix = useLocalization();
  const locale = useLocale();
  const pc = useIsoCookies(["pc"])?.pc;
  const { isConcierge } = useAppId();

  const params = useParams({
    productId: "",
    fullSkuId: "",
    sale: ""
  });
  const _params = useFetchParams(
    req?.path,
    yn(env.FEATURE_URL_CHANGE) ? "pdp" : "product.jsp"
  );

  let [productId] = _params;

  if (params.productId === "") {
    params.productId = productId?.replace("&ct=true", "");
  }
  const { keycloak } = useKeycloak();
  const { userType } = useRhUserAtomValue();

  const client = useApolloClient();
  const siteId = useSite();
  const { pageContent } = useFetchModel("/admin/product", true, false);

  // More about RideAlong: SHOP-1647.
  const [rideAlongProduct, setRideAlongProduct] = useState<Product | null>(
    null
  );
  const [rideAlongData, setRideAlongData] =
    useState<RideAlongData>(defaultRideAlongData);

  const giftCardTerms =
    useQuery<Query>(queryGiftCardTerms, {
      variables: { key: "rh-gift-card-pdp" }
    }).data?.contentFragment?.text ?? "";

  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const { previousState } = useUserPreferences();
  const measureSystem = useMeasurementType();
  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode: app.postalCode || pc,
    userType
  });

  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );

  const DEFAULT_GRID_COL = 4;
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(BREAKPOINT_SM)
  );

  const [gridColumns, setGridColumns] = useState<number>(DEFAULT_GRID_COL);

  useDidMountEffect(() => {
    if (!smUp) {
      setGridColumns(6);
    } else {
      setGridColumns(4);
    }
  }, [mdUp, smUp, smDown]);

  const productVariables = useMemo(() => {
    return {
      productId: params.productId,
      filter: saleContextParam,
      userType: userType,
      siteId,
      currencyCode: currencyCode,
      measureSystem,
      locale: FEATURE_INTERNATIONAL ? locale : undefined,
      postalCode: pc || app.postalCode
    };
  }, [
    params.productId,
    saleContextParam,
    userType,
    siteId,
    currencyCode,
    measureSystem,
    FEATURE_INTERNATIONAL,
    locale,
    pc
  ]);

  const fetchRideAlongProduct = useCallback<
    (productId: string, variables?: any) => Promise<Product>
  >(
    async (productId, variables) => {
      const {
        data: { product }
      } = await client.query({
        query: queryRideAlongProduct,
        variables: {
          productId,
          filter: saleContextParam || null,
          userType: userType,
          siteId,
          currencyCode,
          measureSystem,
          ...variables
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return product;
    },
    [saleContextParam, siteId, client, currencyCode, measureSystem, userType]
  );

  // Ran into an issue where refetch throws an error instead of putting the error into the hook error output
  // see: https://github.com/apollographql/react-apollo/issues/3571
  // Found a workaround using the errorPolicy
  // errorPolicy info from apollo docs:
  //    "If you set errorPolicy to all, useQuery does not discard query
  //     response data, allowing you to render partial results."

  const {
    loading: isProductLoading,
    data: { product: productResponse } = {} as Query,
    error: productError,
    refetch
  } = useQuery<Query>(queryProduct, {
    variables: productVariables,
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    skip: !params.productId,
    errorPolicy: "all"
  });

  const product = useMemo(() => {
    return {
      ...productResponse,
      productLineItem: filterCustomRugOptionTypes(
        productResponse?.productLineItem
      )
    };
  }, [productResponse]);

  let _upsellProducts,
    _refetchUpSellProducts,
    _relatedProductVariable,
    _relatedProductsQuery,
    _relatedProductsloading;

  const pdpLoadPerformance: boolean =
    req.cookies.FEATURE_LOAD_PERFORMANCE === "true";

  if (!pdpLoadPerformance || isConcierge) {
    const { data: { upsellProducts } = {}, refetch: refetchUpSellProducts } =
      useQuery(queryUpsSellProducts, {
        variables: productVariables,
        skip: !params.productId
      });

    _upsellProducts = upsellProducts;
    _refetchUpSellProducts = refetchUpSellProducts;

    const relatedProductVariable = useMemo(() => {
      return {
        productIds: product?.relatedProductIds,
        //passing null irrepective of sale=true in url
        filter: product?.emptyProduct === true ? saleContextParam : null,
        userType: userType,
        siteId,
        currencyCode: currencyCode || "USD",
        measureSystem,
        locale: FEATURE_INTERNATIONAL ? locale : undefined
      };
    }, [
      userType,
      siteId,
      currencyCode,
      measureSystem,
      FEATURE_INTERNATIONAL,
      locale,
      saleContextParam,
      product?.relatedProductIds
    ]);

    _relatedProductVariable = relatedProductVariable;

    const {
      data: { relatedProductsQuery } = {} as Query,
      loading: relatedProductsloading
    } = useQuery<Query>(queryRelatedProducts, {
      variables: relatedProductVariable,
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      skip: !Boolean(relatedProductVariable?.productIds?.length)
    });

    _relatedProductsQuery = relatedProductsQuery;
    _relatedProductsloading = relatedProductsloading;
  }

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH3"]
  });

  const {
    loading: productSwatchLoading,
    data: { product: productSwatchImage } = {} as Query
  } = useQuery<Query>(queryProductSwatchImage, {
    fetchPolicy: "cache-and-network",
    variables: {
      productId: params.productId,
      optionIds: options,
      siteId,
      locale,
      trimOptionIds: trimOptions ?? null,
      fullskuId: params?.fullSkuId ?? ""
    }
  });

  useDidMountEffect(() => {
    refetch(productVariables);
    _refetchUpSellProducts(productVariables);
  }, [JSON.stringify(productVariables)]);

  const { data: { product: fullSkuProduct } = {} as Query } = useQuery<Query>(
    queryFullSkuProduct,
    {
      variables: {
        productId: params.productId,
        fullSkuId: params.fullSkuId
      },
      skip: !params.fullSkuId
    }
  );
  if (fullSkuProduct) {
    swatchToggle = 1;
  }

  const { data: { contentFragment } = {} as Query } = useQuery<Query>(
    queryDraperyLink,
    {
      skip: product?.uxAttributes?.productType !== "drapery",
      errorPolicy: "ignore",
      variables: {
        key: `draperyLink-${brandCode}`
      }
    }
  );

  const handleClose = useCallback(() => {
    let currentUrlParams = new URLSearchParams(req?.query.toString());
    currentUrlParams.set("sale", (product?.onSale || "").toString());
    if (history) {
      history.replace({
        pathname: `${prefix}/catalog/product/product.jsp`,
        search: `?${currentUrlParams.toString()}`
      });
    }
    setOpen(!open);
  }, [product?.onSale, req?.query, history, open]);

  useEffect(() => {
    async function fetchQuery() {
      let response = await fetchRideAlongProduct?.(
        rideAlongData.casingProduct ?? ""
      );
      setRideAlongProduct(response);
    }
    if (rideAlongData.casingProduct && !rideAlongProduct) {
      fetchQuery();
    }
  }, [rideAlongData.casingProduct, rideAlongProduct, fetchRideAlongProduct]);

  /**
   * SHOP-1432 Maintains that any api call will merge with previous product data so it is never an empty object
   */
  const [combinedProduct, setCombinedProduct] = useState<Product>(
    {} as Product
  );

  useEffect(() => {
    const newCombinedProduct = {
      ...combinedProduct,
      ...productSwatchImage,
      ...product,
      ...fullSkuProduct
    };

    // Only update state if there are changes
    if (!isEqual(combinedProduct, newCombinedProduct)) {
      setCombinedProduct(newCombinedProduct);
    }
  }, [combinedProduct, fullSkuProduct, product, productSwatchImage]);

  const isProductLoadingFirstTime = useChangeOnce(isProductLoading);

  // product is 404 if product cannot load.
  if (!!productError && !product) {
    return <Redirect to={`${prefix}/error/product-not-found.jsp`} />;
  }

  return (
    <>
      {product && (
        <>
          <PDP
            data={{
              // ...baseProduct,
              ...product,
              ...fullSkuProduct,
              ...productSwatchImage
            }}
            upSellProducts={_upsellProducts}
            productSwatchLoading={productSwatchLoading}
            fullSkuId={params.fullSkuId || undefined}
            draperyLink={contentFragment?.text ?? ""}
            isProductLoading={isProductLoadingFirstTime}
            authenticated={keycloak?.authenticated}
            saleContextFilter={saleContextParam}
            postalCode={app.postalCode || pc}
            onOptionsChange={setOptions}
            onTrimOptionsChange={setTrimOptions}
            openGiftCardTerms={openGiftCardTerms}
            setOpenGiftCardTerms={setOpenGiftCardTerms}
            giftCardTerms={giftCardTerms}
            rideAlongData={rideAlongData}
            setRideAlongData={setRideAlongData}
            rideAlongProduct={rideAlongProduct}
            rProducts={_relatedProductsQuery}
            locale={locale}
            relatedProductVariable={_relatedProductVariable}
            relatedProductsloading={_relatedProductsloading}
            onProductRefetch={refetch}
          />
          {open && (
            <RHDialog
              solidWhite
              open={open}
              onClose={handleClose}
              fullScreen={false}
              DialogContentProps={{
                style: { marginTop: "-30px" }
              }}
            >
              <Typography className={typographyStyles.rhBaseH3}>
                {pageContent?.PRODUCT_NO_LONGER_SALE}
              </Typography>
            </RHDialog>
          )}
        </>
      )}
      <div className="hidden">SSR V1</div>
    </>
  );
};

export default productProps => {
  return (
    <>
      <PDPV2 {...productProps} /> <div className="hidden">SSR V1</div>
    </>
  );
};
