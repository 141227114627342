import { NetworkStatus, useQuery } from "@apollo/client";
import {
  Button,
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import analyticsLoader from "analytics/loader";
import classNames from "classnames";
import HTMLHeadTags from "component-html-head-tags";
import ProductGrid from "component-product-grid";
import GridController from "component-product-grid/GridController";
import RefineMenuDropdown from "component-refine-menu-dropdown";
import RHLink from "component-rh-link";
import RHScrollUpButton from "component-rh-scroll-up-button";
import { LoadingContext } from "graphql-client/contexts/LoadingContext";
import { querySearch } from "graphql-client/queries/search";
import { useAppId } from "hooks-use-app-id";
import useBrand from "hooks-use-brand/useBrand";
import useIsoRedirect from "hooks-use-isoredirect";
import useAppData from "hooks/useAppData";
import useButtonStyles from "hooks/useButtonStyles";
import { useCookies } from "hooks/useCookies";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useEnv } from "hooks/useEnv";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useLocalization } from "hooks/useLocalization";
import { useParams2 } from "hooks/useParams";
import { processEnvServer as isServer } from "hooks/useSsrHooks";
import useTypographyStyles from "hooks/useTypographyStyles";
import useUserContext from "hooks/useUserContext";
import useUserPreferences from "hooks/useUserPreferences";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import RHRProductListSkeleton from "skeleton-rhr-product-list";
import {
  BRAND_NAME_TO_BRAND_CODE,
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  DEFAULT_SELECTED_BRAND,
  ITEMS_PER_PAGE_PREFERENCE,
  QUERY_PARAM_SITE,
  RHUK_HOST,
  SKELETON_ANIMATION,
  ZERO_RESULTS
} from "utils/constants";
import memoize from "utils/memoize";
import yn from "yn";
// import { ImageSizeProvider } from "graphql-client/contexts/ImageSizeContext";
import AsyncContent from "component-async-content";
import { usePageContent } from "customProviders/LocationProvider";
import useLocale from "hooks-use-locale/useLocale";
import { useRhUserAtomValue } from "hooks/atoms";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { useCountry } from "hooks/useCountry";
import { useFetchModel } from "hooks/useFetchModel";
import { useFetchParams } from "hooks/useFetchParams/useFetchParams";
import { useGetSchema } from "hooks/useGetSchema";
import { memoryStorage } from "utils/analytics/storage";
import { isGTMLoaded } from "utils/analytics/utils";
import buildPath from "utils/buildPath";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import { getReqContext } from "utils/reqContext";
import {
  isSSRToggledCheck,
  isSSRToggledWithClientRender
} from "utils/ssrHelpers";
import { getLocaleFromPath } from "utils/intlUtils";
import { getMemberSavingsText } from "./utils";
import { useCookies as reactUseCookies } from "react-cookie";
import { getSelectorsByUserAgent } from "react-device-detect";
import { getClientOrigin } from "@RHCommerceDev/utils/getClientOrigin";
import { usePGProductItemList } from "@RHCommerceDev/hooks/usePGProductItemList";
import { removeNttSearchParams } from "@RHCommerceDev/utils/formatSearchUrl";

const DEFAULT_GRID_COL = 4;
const PG_GRID_CHOICE = "pgGridChoice";
const SIP_ID = "sipId";
const LAST_SORTMENU = "lastSortMenu";
const SELECTED_BRAND_COOKIE = "selected_brand";
const COOKIES_MAX_AGE = 2592000;

export type SearchQueryVariables = {
  ntt: string;
  n: any;
  contentType: any;
  nrpp: any;
  ns: any;
  no: any;
  country: any;
  currencyCode: string;
  userType: any;
  view: string;
  show_finish?: string;
  categoryId?: string;
  site?: string;
  pgterm?: string;
  sale?: string;
  fromCG?: string;
  locale?: string;
  maxnrpp?: any;
  version?: string;
  activeTab?: string;
  skipng?: string;
  isClearAll?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    galleryContainer: {
      display: "block"
    },
    actionTitleWrapper: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        flexDirection: "column"
      }
    },
    actionTitle: {
      display: "inline",
      textTransform: "uppercase !important" as "uppercase",
      marginRight: 20,
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center",
        marginRight: 0,
        display: "block"
      },
      "&.search-title": {
        display: "none"
      }
    },
    membershipContainer: {
      marginLeft: "auto",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        margin: "auto"
      },
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        flexDirection: "column"
      }
    },
    actionSaleTitle: {
      fontSize: 18,
      fontFamily: "RHSans-Thin",
      lineHeight: "27px",
      color: "#CA2022 !important",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      }
    },
    actionSaleBanner: {
      color: "var(--ReimagineWarm-RH-Red, #CA2022)",
      fontFamily: "RHSans-Thin",
      fontSize: "20px",
      lineHeight: "120%",
      letterSpacing: "1.6px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      }
    },
    actionSaleMessage: {
      color: "var(--ReimagineWarm-RH-Red, #CA2022)",
      fontFamily: "RHSans-Roman",
      fontSize: "11px",
      lineHeight: "135%",
      letterSpacing: "0.165px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      }
    },
    actionStandardMembershipMessage: {
      fontFamily: "RHSans-Roman",
      fontSize: "11px",
      lineHeight: "135%",
      letterSpacing: "0.165px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      }
    },
    refinementContainer: {
      paddingBottom: 40,
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        paddingBottom: "24px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingBottom: "36px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingBottom: "48px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingBottom: "60px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        paddingBottom: "80px !important"
      }
    },
    refinementContainerMobile: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingBottom: "32px !important"
      }
    },
    sticky: {
      position: "sticky",
      top: 0,
      zIndex: 1
    },
    refinementBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    tabinfoWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      cursor: "pointer",
      margin: "25px 0"
    },
    defaultTabinfo: {
      padding: "17.5px 20px !important",
      borderRadius: "50px",
      fontSize: "11px",
      fontFamily: "RHSans-Light",
      lineHeight: "120%",
      letterSpacing: " 0.44px", //letterSpacing in pixels = (percentage / 100) * font-size
      backgroundColor: "#F1F0ED",
      color: "#000"
    },
    ActiveTabinfo: {
      backgroundColor: "#000 !important",
      color: "#FFF !important"
    },

    results: {
      flex: "none",
      marginRight: "30px !important",
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        marginRight: "40px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        marginRight: "60px !important"
      }
    },
    saleDivider: {
      paddingBottom: "20px",
      border: 0,
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingBottom: "40px"
      }
    },
    customerService: {
      textTransform: "capitalize !important" as "capitalize",
      textDecoration: "underline",
      "&:hover": {
        borderBottom: "none"
      }
    },
    rhrPromoDisplayBanner: {
      marginTop: 0,
      marginBottom: 20
    },
    membershipMessage: {
      color: "#000000",
      fontFamily: "RHSans-Thin !important",
      fontSize: "13px",
      display: "inline",
      position: "relative",
      right: 0,
      float: "right",
      fontWeight: 300,
      whiteSpace: "nowrap",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center",
        position: "relative",
        float: "none",
        display: "block"
      }
    }
  })
);

type RHRProductGalleryProps = {
  onError: () => void;
  categoryId?: string;
  pgterm?: string;
};

const RHRProductGallery: FC<RHRProductGalleryProps> = (({
  onError,
  categoryId,
  pgterm
}) => {
  const env = useEnv();
  const { search: locationSearch } = useLocation();
  const isPaginationFeatureEnabled = yn(env.FEATURE_PAGINATION);
  const isPaginationTuningFeatureEnabled = yn(env.FEATURE_PAGINATION_TUNING);
  const isPaginationLoadFullPageEnabled =
    yn(env.FEATURE_PAGINATION_LOAD_FULL_PAGE) &&
    isPaginationFeatureEnabled &&
    isPaginationTuningFeatureEnabled;
  const userCountry = useCountry();
  const { pathname } = useLocation();
  const hostName = isServer ? getClientOrigin() : window?.location?.origin;
  const { pageContent } = usePageContent();
  const { isConcierge } = useAppId();
  const [, , removeCookie] = useCookies([SIP_ID, PG_GRID_CHOICE]);
  const cookie = useIsoCookies([SIP_ID, PG_GRID_CHOICE, LAST_SORTMENU]);
  const nextgenCookie = useIsoCookies(["nextgenpg"], true)?.nextgenpg;
  // const [productList, setProductList] = useState<Array<any>>([]);
  const [gridColumns, setGridColumns] = useState<number>(() => {
    const defaultGridView =
      cookie[PG_GRID_CHOICE] && parseInt(cookie[PG_GRID_CHOICE]);
    return defaultGridView ? defaultGridView : DEFAULT_GRID_COL;
  });
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [scrolling, setScrolling] = useState<boolean>(false);
  const isRHRImage = yn(env.FEATURE_RHR_IMAGE);

  const searchParams = new URLSearchParams(locationSearch);
  const loadAllParam = searchParams.get("loadAll") === "true";
  // lazy load is enabled when the url has loadAll param, which lazyloads items after every 100 items
  const isLazyLoadEnabled = useMemo(() => {
    return isPaginationLoadFullPageEnabled && loadAllParam;
  }, [loadAllParam]);

  const selectedRefinementContainer = React.useRef<HTMLDivElement | null>(null);
  const isDeepLoad =
    useIsoCookies(["deepload"])?.deepload === "true" ||
    yn(env.FEATURE_DEEPLOAD);
  const typographyClasses = useTypographyStyles({
    keys: [
      "saleTitle",
      "saleSubTitle",
      "searchInput",
      "searchAutoSuggestResult",
      "rhrSearchTitle",
      "rhrSearchAutoSuggestResult",
      "rhBaseH4",
      "rhBaseH1",
      "rhBaseH2",
      "rhBaseCaption",
      "rhBody1"
    ]
  });
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn"]
  });

  // const [sortMenu, setSortMenu] = useState<SearchResultSortOption[]>([]);
  // const [refMenu, setRefMenu] = useState<SearchRefinementMenu[]>([]);
  // const [sizeFacets, setSizeFacets] = useState<string[]>([]);
  // const [booleanFacets, setBooleanFacets] = useState<SearchRefinementMenu[]>(
  //   []
  // );
  // const [materialFacets, setMaterialFacets] = useState<string[]>([]);
  // const [totalNumRecs, setTotalNumRecs] = useState<number>(0);
  // const [categoryName, setCategoryName] = useState<string>("");
  // const [pgCropRules, setPgCropRules] = useState<string>("");
  // const [promoDisplay, setPromoDisplay] = useState<SearchPromoDisplay | null>(
  //   null
  // );
  // const [templateString, setTemplateString] = useState<string>("");
  const [isSaleFilterEnabled, setIsSaleFilterEnabled] =
    useState<boolean>(false);

  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const { app } = useAppData();
  const userContext = useUserContext([app.postalCode]);
  const brand = useBrand();
  const rhUser = useRhUserAtomValue();
  const classes = useStyles();
  const history = useHistory();
  const prefix = useLocalization();
  const { searchText } = useParams<{ searchText: string }>();
  const searchPath = yn(env?.FEATURE_URL_CHANGE) ? searchText : "results.jsp";
  const pc = useIsoCookies(["pc"])?.pc;
  const [, setCookie] = reactUseCookies([PG_GRID_CHOICE]);
  const { setCookieWrapper, setStorageValueWrapper } =
    useCookiesWithPermission();

  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(BREAKPOINT_SM)
  );

  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const isSSRToggled = isSSRToggledCheck();
  const isLoadedGTM = isGTMLoaded();
  const {
    previousState: { country }
  } = useUserPreferences();

  const currencyCode = useCurrencyCode({
    postalCode: app.postalCode || pc,
    userType: rhUser?.userType,
    country
  });

  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(ITEMS_PER_PAGE_PREFERENCE)
    : undefined;

  const { params } = useParams2<SearchQueryVariables>(
    {
      ntt: "",
      n: undefined,
      contentType: undefined,
      nrpp: isPaginationFeatureEnabled ? "12" : undefined,
      ns: undefined,
      no: undefined,
      country,
      currencyCode,
      userType: rhUser?.userType || undefined,
      view: "",
      show_finish: "0",
      categoryId: "",
      sale: "",
      site: "",
      pgterm: "",
      fromCG: "",
      locale: "",
      maxnrpp: storedItemsPerPagePreference ?? "48",
      version: "",
      activeTab: "",
      skipng: "",
      isClearAll: ""
    },
    {
      toLowerCase: true
    }
  );

  const req = getReqContext();
  const _params = useFetchParams(
    isServer ? req?.path : location.pathname,
    yn(env.FEATURE_URL_CHANGE) ? "pg" : "products.jsp"
  );

  let mobile = false;
  const userAgent = req && req?.headers["user-agent"];
  if (userAgent) {
    const { isMobile } = getSelectorsByUserAgent(userAgent);
    mobile = isMobile;
  }

  const [_categoryId, _pgterm] = _params;

  if (params.categoryId === "") {
    params.categoryId = _categoryId;
    if (_pgterm) {
      params.pgterm = _pgterm?.replace(/-/g, " ")?.replace("&ct=true", "");
    }
  }

  const isPGPage = Boolean(
    pathname === `${prefix}/catalog/category/products.jsp` ||
      pathname === `${prefix}/outdoor/catalog/category/products.jsp` ||
      pathname ===
        `${prefix}/catalog/category/products.jsp/${_categoryId}/${_pgterm?.replace(
          /[ +]/g,
          "-"
        )}` ||
      pathname === `${prefix}/living/pg/${_pgterm?.replace(/[ +]/g, "-")}` ||
      pathname ===
        `${prefix}/outdoor/catalog/category/products.jsp/${_categoryId}/${_pgterm?.replace(
          /[ +]/g,
          "-"
        )}` ||
      pathname ===
        `${prefix}/outdoor/catalog/category/products.jsp/${_categoryId}` ||
      pathname === `${prefix}/catalog/category/products.jsp/${_categoryId}`
  );

  //AEM models
  const { pageContent: aemAsyncHeaderModel } = useFetchModel(
    `${prefix}/category/${params.categoryId || categoryId}/header`,
    false,
    true,
    null
  );
  const { pageContent: aemAsyncFooterModel } = useFetchModel(
    `${prefix}/category/${params.categoryId || categoryId}/footer`,
    false,
    true,
    null
  );
  const isAemEnabled =
    aemAsyncFooterModel?.templateName || aemAsyncHeaderModel?.templateName;
  useEffect(() => {
    let defaultGridView =
      (cookie[PG_GRID_CHOICE] && parseInt(cookie[PG_GRID_CHOICE])) ||
      (smDown
        ? 12
        : (smUp && env.ABTEST_PG_2UP && params.fromCG === "true" && 6) ||
          (mdUp ? 4 : 6));
    if (mdUp && defaultGridView === 12) {
      defaultGridView = 4;
    }
    if (defaultGridView && [4, 6, 12].includes(defaultGridView)) {
      setGridColumns(defaultGridView === 4 && !smUp ? 6 : defaultGridView);
      if (isConcierge) {
        setCookie(PG_GRID_CHOICE, gridColumns, {
          path: "/"
        });
      } else {
        setCookieWrapper(PG_GRID_CHOICE, gridColumns, {
          maxAge: COOKIES_MAX_AGE,
          domain: `.${env.ENV_DOMAIN}`,
          path: "/"
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie[PG_GRID_CHOICE], mdUp, smUp, params.fromCG, smDown]);

  useEffect(() => {
    if (country !== userCountry && history.action !== "POP") {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has("no")) {
        queryParams.delete("no");
        history.replace({
          search: queryParams.toString()
        });
      }
    }
  }, [userCountry]);

  const saveGridCol = useCallback(
    col => {
      if (isConcierge) {
        setCookie(PG_GRID_CHOICE, col, {
          path: "/"
        });
      } else {
        setCookieWrapper(PG_GRID_CHOICE, col, {
          maxAge: COOKIES_MAX_AGE,
          domain: `.${env.ENV_DOMAIN}`,
          path: "/"
        });
      }
      setGridColumns(col);
      removeCookie(SIP_ID, {
        path: "/",
        domain: `.${env.ENV_DOMAIN}`
      });
    },
    [env.ENV_DOMAIN, removeCookie, setCookieWrapper, setGridColumns]
  );

  // The sales page will be considered when the sale filter is not applied to persist the saleContext header.
  const isSale = params.sale === "true";
  const isStockedFilterActive = params.n?.includes(
    '{!tag=sku_stocked}sku_stocked:("In-Stock")'
  );

  const isRefinementFilterActive = params.n?.includes(
    "{!tag=sku_color_family_name}sku_color_family_name"
  );

  const isNewArrivalsFilterActive =
    params.n?.includes('{!tag=new_arrivals}new_arrivals:("New Arrivals")') ??
    false;

  const site =
    checkCountryIsEu({ country }) && brand === "RH" ? RHUK_HOST : brand;
  const locale = useLocale();

  const locationPath = isServer ? req?.path : location?.pathname;

  const pathLocale = getLocaleFromPath(locationPath) || {
    locale: "/us/en/"
  };
  const isSort = !!params.ns && params.ns !== "product.sale|1";

  const contactUsPath = `/customer-experience/contact-us`;

  if (isSale && !isStockedFilterActive) {
    params.n = `{!tag=sku_showOnly}sku_showOnly:("Sale")`;
  }

  const { activeTab, ...restParams } = params;

  let decodedNtt;

  if (restParams?.ntt) {
    const decodedValue = restParams?.ntt?.replace(/&amp;/g, "&");
    if (decodedValue?.includes("%")) {
      decodedNtt = decodeURIComponent(
        encodeURIComponent(decodedValue?.replace(/%25/g, "%"))
      );
    } else {
      decodedNtt = decodeURIComponent(decodedValue);
    }
  } else {
    decodedNtt = undefined;
  }

  const {
    data,
    error,
    refetch,
    fetchMore,
    networkStatus,
    loading: searchQueryLoading
  } = useQuery<Pick<Query, "search">, QuerySearchArgs>(querySearch, {
    variables: {
      ...restParams,
      ntt: yn(env?.FEATURE_URL_CHANGE)
        ? searchText
        : decodedNtt?.replace(/&amp;/g, "&"),
      categoryId: params.categoryId || categoryId,
      sale: isSale,
      nrpp: isDeepLoad
        ? 200
        : isPaginationFeatureEnabled && params.maxnrpp
        ? Number(params.maxnrpp)
        : params.nrpp
        ? Number(params.nrpp)
        : isSSRToggledWithCSR
        ? 48
        : isPaginationFeatureEnabled
        ? params.nrpp
        : storedItemsPerPagePreference
        ? +storedItemsPerPagePreference
        : 48,
      no: params.no ? Number(params.no) : undefined,
      currencyCode,
      country,
      site: isConcierge ? params?.site || site : params?.site || site,
      maxSearchTab: !params?.site,
      isSearchBoxUse: !isPGPage,
      ns: cookie?.[LAST_SORTMENU] || (params.ns !== "" ? params.ns : undefined),
      ...(isPGPage &&
        (params.pgterm || pgterm) && {
          ntt: yn(env.FEATURE_URL_CHANGE)
            ? ""
            : decodeURIComponent(params.pgterm || pgterm)
        }),
      locale: FEATURE_INTERNATIONAL ? locale : undefined,
      userType: rhUser?.userType,
      nextgenDriven: false,
      isClearAll: params?.isClearAll === "true" ? true : false,
      ...(params?.site && { activeTab: activeTab })
    },
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    fetchPolicy: isLazyLoadEnabled ? "cache-first" : "cache-and-network",
    // fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    skip: !rhUser?.userType,
    onCompleted: () => {
      setGridColumns(gridColumns);
      if (!yn(env.REMOVE_ANALYTICS_LOADER)) {
        if (!isServer && location.pathname.includes("/search/")) {
          memoryStorage.setItem("ruleExecuted", search?.ruleExecuted);
          const itemListViewPage = search?.resultList?.records?.map(
            rec => rec?.sku?.fullSkuId
          );
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              [a.EVENTS.GA4_VIEW_PAGE_SEARCH.INT_TYPE],
              {
                rule: search?.ruleExecuted,
                itemListViewPage,
                item_list_name: true
              }
            )
          );
          if (data?.search?.resultList) {
            // const itemList = search?.resultList?.records?.map(rec => {
            //   return {
            //     item_name: rec?.product?.displayName,
            //     item_id: rec?.sku?.fullSkuId
            //   };
            // });
            // analyticsLoader(a =>
            //   a.emitAnalyticsEvent(
            //     document.querySelector("#spa-root > *")! as HTMLElement,
            //     a.EVENTS.VIEW_ITEM_LIST.INT_TYPE,
            //     {
            //       itemList,
            //       item_list_name: true,
            //       item_list_name_view_item: search?.saleMemberInfo?.headerConfig.title,
            //       item_list_id: search?.q.includes("category:") ? search?.q.split("category:")[1] : search?.q
            //     }
            //   )
            // );
            if (yn(env.FEATURE_ANALYTICS_FORM_DATALAYER)) {
              analyticsLoader(a =>
                a.emitAnalyticsEvent(
                  document.querySelector("#spa-root > *")! as HTMLElement,
                  a.EVENTS.SEARCH.INT_TYPE,
                  {
                    anchor:
                      data?.search?.resultList?.records?.[0]?.product?.anchor ??
                      params?.ntt,
                    search_results_number: search?.resultList?.totalNumRecs || 0
                  }
                )
              );
            }
          }
        }
        if (
          !isServer &&
          !location.search.includes("pgterm") &&
          search?.resultList?.firstRecNum === 0
        ) {
          const fusionData = {
            fusion_id: search?.searchQueryId,
            fusion_filter: search?.filterQueries
          };
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.FUSION_SEARCH_SIGNAL.INT_TYPE,
              {
                search: true,
                fusionData: JSON.stringify(fusionData)
              }
            )
          );
        }
        if (!data?.search?.resultList) {
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.SEARCH.INT_TYPE,
              {
                rule: search?.ruleExecuted,
                search_results_number: 0
              }
            )
          );
        }
      }
    },
    onError: () => {
      if (
        !yn(env.REMOVE_ANALYTICS_LOADER) &&
        !isServer &&
        !location.search.includes("pgterm")
      ) {
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.SEARCH.INT_TYPE,
            {
              rule: search?.ruleExecuted,
              search_results_number: 0
            }
          )
        );
      }
    }
  });

  const search: SearchResponse = data?.search;
  const productTitle =
    search && search?.adjustedSearches?.length
      ? search?.adjustedSearches[0]?.adjustedTerms
      : search?.saleMemberInfo?.headerConfig?.title
      ? search?.saleMemberInfo?.headerConfig?.title
      : yn(env?.FEATURE_URL_CHANGE)
      ? searchText
      : params?.ntt;
  const seoPageTitle = search?.saleMemberInfo?.headerConfig?.seoPageTitle;
  const seoPageDescription =
    search?.saleMemberInfo?.headerConfig?.seoDesc_s || "";
  const templateString =
    isRHRImage &&
    search?.saleMemberInfo?.headerConfig?.template?.toLowerCase() ===
      "horizontal"
      ? search?.saleMemberInfo?.headerConfig?.template?.toLowerCase()
      : "";
  const productList = search?.resultList?.records || [];
  const sortMenu = search?.resultList?.sortOptions || [];
  const refMenu = search?.refinementMenus || [];
  const booleanFacets = search?.booleanFacets || [];
  const totalNumRecs = search?.resultList?.totalNumRecs || 0;
  const dynamicFacet: any = search?.dynamicFacets || [];

  const loadMoreData = useCallback(
    (page?: number, no?: number) => {
      if (!no && !isLazyLoadEnabled) {
        if (
          isPaginationFeatureEnabled &&
          page &&
          productList?.length + 1 > page
        ) {
          return;
        }
        if (
          isNextPageLoading ||
          +params.no + productList?.length >= totalNumRecs
        ) {
          return;
        }
      }
      if (isNextPageLoading || productList?.length >= totalNumRecs) {
        return;
      }
      setIsNextPageLoading(true);
      if (isPaginationLoadFullPageEnabled && !isLazyLoadEnabled) {
        return;
      }
      fetchMore({
        variables: {
          no: no ?? (+(params.no || 0) + productList?.length || ZERO_RESULTS),
          infiniteScroll: isLazyLoadEnabled ? true : false,
          nrpp:
            isPaginationFeatureEnabled && params.maxnrpp
              ? Number(params.maxnrpp)
              : 12
        },
        updateQuery: (prev: Pick<Query, "search">, { fetchMoreResult }) => {
          if (!yn(env.REMOVE_ANALYTICS_LOADER)) {
            // const itemList = fetchMoreResult?.search?.resultList?.records?.map(
            //   rec => rec?.sku?.fullSkuId
            // );
            // analyticsLoader(a =>
            //   a.emitAnalyticsEvent(
            //     document.querySelector("#spa-root > *")! as HTMLElement,
            //     a.EVENTS.VIEW_ITEM_LIST.INT_TYPE,
            //     {
            //       itemList,
            //       item_list_name: true,
            //       item_list_name_view_item: search?.saleMemberInfo?.headerConfig.title,
            //       item_list_id: search?.q.includes("category:") ? search?.q.split("category:")[1] : search?.q
            //     }
            //   )
            // );
          }
          if (networkStatus === NetworkStatus.fetchMore || !fetchMoreResult) {
            return prev;
          }
          if (isPaginationFeatureEnabled && !isLazyLoadEnabled) {
            return fetchMoreResult;
          }
          return {
            ...prev,
            search: {
              ...prev?.search,
              resultList: {
                ...prev?.search?.resultList,
                records: prev?.search?.resultList?.records
                  ? [
                      ...prev?.search?.resultList?.records,
                      ...fetchMoreResult?.search?.resultList?.records
                    ]
                  : fetchMoreResult?.search?.resultList?.records
              },
              headerConfig: fetchMoreResult?.search?.saleMemberInfo
                ?.headerConfig
                ? { ...fetchMoreResult?.search?.saleMemberInfo?.headerConfig }
                : prev?.search?.headerConfig
            }
          };
        }
      });
    },
    [networkStatus, isNextPageLoading, productList, totalNumRecs, fetchMore]
  );

  useEffect(() => {
    const newIsNextPageLoading = false;
    if (isNextPageLoading === newIsNextPageLoading) return;
    setIsNextPageLoading(newIsNextPageLoading);
  }, [productList]);

  useEffect(() => {
    // if total number of result is 1 redirect to PDP
    if (!searchQueryLoading && search) {
      const { resultList, redirectUrl } = search || {};
      const productId = search?.resultList?.records?.[0]?.product?.repositoryId;
      const totalNumRecs = resultList?.totalNumRecs;
      const targetUrl = "/catalog/product/product.jsp";

      if (redirectUrl) {
        let localizedRedirectUrl = "";
        if (
          redirectUrl?.startsWith("https") ||
          redirectUrl?.startsWith("http")
        ) {
          useIsoRedirect(redirectUrl, "windowAssign");
        } else {
          localizedRedirectUrl = prefix + redirectUrl;

          totalNumRecs
            ? history.push(localizedRedirectUrl)
            : history.replace(localizedRedirectUrl);
        }
      }

      if (
        ((totalNumRecs === 1 && !redirectUrl && productId) ||
          (!totalNumRecs && !!redirectUrl)) &&
        location?.pathname?.includes("products.jsp")
      ) {
        if (!redirectUrl) {
          history.replace(
            buildPath(`${prefix}${targetUrl}`, {
              productId: productId
            })
          );
        }
      }
    }
  }, [search, searchQueryLoading, history, prefix]);

  useEffect(() => {
    const newIsLoading =
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables;
    if (isLoading === newIsLoading) return;
    setIsLoading(newIsLoading);
  }, [networkStatus]);

  // const { data: { productGallery } = {} as Query } = useQuery<Query>(
  //   queryProductGallery,
  //   {
  //     variables: {
  //       categoryId: params?.categoryId || categoryId || "",
  //       siteId: site,
  //       locale,
  //       countryCode: userCountry
  //     },
  //     skip: true,
  //     errorPolicy: "all"
  //   }
  // );

  // const root = useMemo(
  //   () =>
  //     isPGPage
  //       ? (productGallery?.productGallery?.[0] as CollectionCategory)
  //       : null,
  //   [isPGPage, productGallery?.productGallery]
  // );

  // const promotionDisplayItems = root?.promotionDisplayItems;

  useEffect(() => {
    // if total number of result is 0 redirect to homepage
    if (isPGPage && !searchQueryLoading) {
      const { resultList, redirectUrl } = search || {};
      const totalNumRecs = resultList?.totalNumRecs;

      // Redirect if no results are found or if resultList is not available && no redirectUrl is present
      if ((!resultList || totalNumRecs === 0) && !redirectUrl) {
        const redirectPath = FEATURE_INTERNATIONAL ? prefix : "/";
        history.push(redirectPath);
      }
    }
  }, [
    search,
    searchQueryLoading,
    isPGPage,
    history,
    prefix,
    FEATURE_INTERNATIONAL
  ]);

  if (
    search?.resultTabs?.length &&
    isConcierge &&
    !isServer &&
    search &&
    Object.isExtensible(search)
  ) {
    const host = window.location.protocol + "//" + window.location.host;
    search?.resultTabs?.map((resultTab: SearchResultTab) => {
      if (resultTab?.link?.host || resultTab?.displayName) {
        resultTab.link.host = host;
        const brandCode =
          resultTab?.displayName in BRAND_NAME_TO_BRAND_CODE
            ? BRAND_NAME_TO_BRAND_CODE[resultTab?.displayName]
            : DEFAULT_SELECTED_BRAND;
        if (
          resultTab?.link?.navigationState &&
          !resultTab?.link?.navigationState.includes(QUERY_PARAM_SITE)
        ) {
          resultTab.link.navigationState = `${resultTab?.link?.navigationState}&site=${brandCode}`;
        }
      }
    });
  }

  const { productItemList } = usePGProductItemList({
    hostName,
    prefix,
    isStockedFilterActive,
    isRefinementFilterActive,
    totalNumRecs,
    isSale,
    isSaleFilterEnabled,
    isConcierge,
    search,
    nextgenCookie,
    productList,
    rhUser
  });

  const itemListSchema = useGetSchema("ItemList", {
    name: seoPageTitle || productTitle,
    description: seoPageDescription,
    itemList: productItemList
  });

  const navigateToOtherResult = useCallback(() => {
    if (
      !search?.resultList &&
      (search?.zeroResults?.otherBrandSearchResults || []).length > 0
    ) {
      //add brand code with max number of result
      const largestBrandResult: SearchOtherBrandSearchResult =
        search?.zeroResults?.otherBrandSearchResults.reduce((max, brand) => {
          return brand.brandResultCount > max.brandResultCount ? brand : max;
        }, search?.zeroResults?.otherBrandSearchResults?.[0]);
      const brandCode =
        largestBrandResult?.brandName in BRAND_NAME_TO_BRAND_CODE
          ? BRAND_NAME_TO_BRAND_CODE[largestBrandResult?.brandName]
          : DEFAULT_SELECTED_BRAND;

      if (isConcierge && !isServer) {
        const host = window.location.protocol + "//" + window.location.host;
        let navigationState = "";
        search?.zeroResults?.otherBrandSearchResults?.map(
          (otherBrandSearchResult: SearchOtherBrandSearchResult, index) => {
            if (otherBrandSearchResult?.brandLink?.host) {
              // otherBrandSearchResult.brandLink.host = host;

              // --- bug fix NGSEARCH-212 picking the last present branch code causing incorrect site id to passed in navigationState ---//

              // const brandCode =
              //   otherBrandSearchResult?.brandName in BRAND_NAME_TO_BRAND_CODE
              //     ? BRAND_NAME_TO_BRAND_CODE[otherBrandSearchResult?.brandName]
              //     : DEFAULT_SELECTED_BRAND;

              if (index === 0) {
                setCookieWrapper(SELECTED_BRAND_COOKIE, brandCode, {
                  expires: new Date(2147483647000)
                });
              }
              if (
                otherBrandSearchResult?.brandLink?.navigationState &&
                !otherBrandSearchResult?.brandLink?.navigationState.includes(
                  QUERY_PARAM_SITE
                )
              ) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const Ns = urlParams.get("Ns");
                const Ntt = urlParams.get("Ntt");
                // otherBrandSearchResult.brandLink.navigationState = `?Ns=${encodeURIComponent(
                //   Ns || ""
                // )}&Ntt=${Ntt}&site=${brandCode}`;
                navigationState = `?Ns=${encodeURIComponent(
                  Ns || ""
                )}&Ntt=${Ntt}&site=${brandCode}`;
              } else {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const Ns = urlParams.get("Ns");
                const Ntt = urlParams.get("Ntt");
                // otherBrandSearchResult.brandLink.navigationState = `?Ns=${encodeURIComponent(
                //   Ns || ""
                // )}&Ntt=${Ntt}&site=${brandCode}`;
                navigationState = `?Ns=${encodeURIComponent(
                  Ns || ""
                )}&Ntt=${Ntt}&site=${brandCode}`;
              }
              navigationState = removeNttSearchParams(navigationState);
              return useIsoRedirect(
                `${host}${
                  env.FEATURE_INTERNATIONAL && prefix
                }/search/${searchPath}${navigationState}`,
                "windowAssign"
              );
            }
          }
        );
      } else {
        const navState = removeNttSearchParams(
          `${
            (search?.zeroResults?.otherBrandSearchResults || [])[0]?.brandLink
              ?.navigationState
          }`
        );
        return useIsoRedirect(
          `${
            (search?.zeroResults?.otherBrandSearchResults || [])[0]?.brandLink
              ?.host
          }${
            env.FEATURE_INTERNATIONAL && prefix
          }/search/${searchPath}${navState}`,
          "windowAssign"
        );
      }
    }
  }, [
    env.FEATURE_INTERNATIONAL,
    isConcierge,
    prefix,
    search?.resultList,
    search?.zeroResults?.otherBrandSearchResults
  ]);

  const [reloadKey, setReloadKey] = useState(null);

  // useEffect(() => {
  //   // force a re-render on client side for problematic session

  //   if (!reloadKey) {
  //     setReloadKey(uuidv4());
  //   }
  // }, []);

  useEffect(() => {
    if (gridColumns) {
      setGridColumns(gridColumns);
    }
  }, [gridColumns]);

  // useEffect(() => {
  //   setProductList(search?.resultList?.records);

  //   if (search?.resultList?.sortOptions) {
  //     setSortMenu(search.resultList.sortOptions);
  //   }

  //   if (search?.refinementMenus) {
  //     setRefMenu(search.refinementMenus);
  //   }

  //   if (search?.booleanFacets) {
  //     setBooleanFacets(search?.booleanFacets);
  //   }

  //   if (search?.sizeFacets) {
  //     setSizeFacets(search?.sizeFacets);
  //   }

  //   if (search?.materialFacets) {
  //     setMaterialFacets(search?.materialFacets);
  //   }

  //   if (search?.resultList?.totalNumRecs) {
  //     setTotalNumRecs(search?.resultList?.totalNumRecs);
  //   }

  //   if (search?.saleMemberInfo?.headerConfig?.title) {
  //     setCategoryName(search?.saleMemberInfo?.headerConfig?.title);
  //   }

  //   if (search?.saleMemberInfo?.headerConfig?.promoDisplay) {
  //     setPromoDisplay(search?.saleMemberInfo?.headerConfig?.promoDisplay);
  //   }

  //   if (
  //     isRHRImage &&
  //     search?.saleMemberInfo?.headerConfig?.template?.toLowerCase() ===
  //       "horizontal"
  //   ) {
  //     setTemplateString(
  //       search?.saleMemberInfo?.headerConfig?.template?.toLowerCase()
  //     );
  //   } else {
  //     setTemplateString("");
  //   }

  //   if (gridColumns) {
  //     setGridColumns(gridColumns);
  //   }
  // }, [data?.search, env?.FEATURE_RHR_IMAGE, categoryName, search, gridColumns]);

  useEffect(() => {
    if (window) {
      window.history.scrollRestoration = "manual";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env]);

  useDidMountEffect(() => {
    removeCookie(SIP_ID, {
      path: "/",
      domain: `.${env.ENV_DOMAIN}`
    });
  }, [params]);

  useDidMountEffect(() => {
    if (gridColumns === 4 && !smUp) {
      setGridColumns(6);
      if (isConcierge) {
        setCookie(PG_GRID_CHOICE, 6, {
          path: "/"
        });
      } else {
        setCookieWrapper(PG_GRID_CHOICE, 6, {
          maxAge: COOKIES_MAX_AGE,
          domain: `.${env.ENV_DOMAIN}`,
          path: "/"
        });
      }
    }
  }, [smUp]);

  useDidMountEffect(() => {
    refetch?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.currencyCode, country]);

  useEffect(() => {
    if (
      !isServer &&
      (search?.resultList?.totalNumRecs || 0) > 0 &&
      location?.search.includes("pgterm") &&
      search?.saleMemberInfo?.headerConfig?.title &&
      rhUser?.akamaiCountryCode &&
      isLoadedGTM?.isGTMLoaded &&
      !yn(env.REMOVE_ANALYTICS_LOADER)
    ) {
      memoryStorage.setItem("ruleExecuted", search?.ruleExecuted);
      setStorageValueWrapper({
        storageKey: "analytics-category-title",
        value: search?.saleMemberInfo?.headerConfig?.title
      });
      const itemListViewPage = search?.resultList?.records?.map(
        rec => rec?.sku?.fullSkuId
      );
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          [a.EVENTS.GA4_VIEW_PAGE_PRODUCT.INT_TYPE],
          {
            displayName: search?.saleMemberInfo?.headerConfig?.title,
            promoDisplay: search?.saleMemberInfo?.headerConfig?.promoDisplay,
            rule: search?.ruleExecuted,
            itemListViewPage,
            item_list_name: true
          }
        )
      );
      // const itemList = search?.resultList?.records?.map(rec => {
      //   return {
      //     item_name: rec?.product?.displayName,
      //     item_id: rec?.sku?.fullSkuId
      //   };
      // });

      // analyticsLoader(a =>
      //     a.emitAnalyticsEvent(
      //         document.querySelector("#spa-root > *")! as HTMLElement,
      //         a.EVENTS.VIEW_ITEM_LIST.INT_TYPE,
      //         {
      //           itemList,
      //           item_list_name: true,
      //           item_list_name_view_item: search?.saleMemberInfo?.headerConfig.title,
      //           item_list_id: search?.q.includes("category:") ? search?.q.split("category:")[1] : search?.q
      //         }
      //     )
      // );
    }
  }, [
    search?.saleMemberInfo?.headerConfig?.title,
    search?.saleMemberInfo?.headerConfig?.promoDisplay,
    rhUser?.akamaiCountryCode,
    isLoadedGTM?.isGTMLoaded
  ]);

  //Show sale message in PG header for CG -> PG
  const activeSaleFilter =
    params.fromCG === "true"
      ? true
      : (params.n ?? "").includes('sku_showOnly:("Sale")');

  // Add "sale=true" to ProductCard URL when SALE filter is active
  // TODO: convert it to a hook
  useEffect(() => {
    if ((params.n ?? "").includes('sku_showOnly:("Sale")')) {
      setIsSaleFilterEnabled?.(true);
    } else {
      setIsSaleFilterEnabled?.(false);
    }
  }, [params.n]);

  const resetRefinement = useCallback(() => {
    if (isPGPage) {
      let resetUrl = `pgterm=${params.pgterm || pgterm}&categoryId=${
        params.categoryId || categoryId
      }`;
      if (params?.skipng === "true") {
        resetUrl += "&skipng=true";
      }

      if (params?.fromCG === "true") {
        resetUrl += "&fromCG=true";
      }

      const serarchValue = new URLSearchParams();
      serarchValue.set("isClearAll", "true");

      history.push({
        search: resetUrl
      });
    } else {
      const serarchValue = new URLSearchParams();
      serarchValue.set("Ntt", params.ntt);
      if (params?.site && yn(env.FEATURE_SEARCH_ALL_BRANDS)) {
        serarchValue.set("site", params?.site);
        serarchValue.set("activeTab", `${params?.activeTab}`);
      }

      if (params?.fromCG === "true") {
        serarchValue.set("fromCG", "true");
      }
      serarchValue.set("isClearAll", "true");
      const navState = removeNttSearchParams(serarchValue.toString());
      history.push({
        search: navState
      });
    }
  }, [
    history,
    isPGPage,
    params.categoryId,
    categoryId,
    params.ntt,
    params.pgterm,
    pgterm,
    params.site
  ]);

  if (!isLoading && search && !yn(env.REMOVE_ANALYTICS_LOADER)) {
    const fusionData = {
      fusion_id: search.searchQueryId,
      fusion_filter: search.filterQueries
    };
    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        document.querySelector("#spa-root > *")! as HTMLElement,
        a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
        {
          set: {
            name: "fusion_data",
            value: JSON.stringify(fusionData)
          }
        }
      )
    );
  }

  const saveSelectedProduct = useCallback(
    sipId => {
      setCookieWrapper(
        SIP_ID,
        sipId,
        {
          path: "/",
          domain: `.${env.ENV_DOMAIN}`
        },
        userCountry,
        true
      );
    },
    [country, setCookieWrapper]
  );

  const singleRecordRedirectURL = useCallback(() => {
    const productId = search?.resultList?.records[0]?.product?.repositoryId;
    const fullSkuId = search?.resultList?.records[0]?.sku?.fullSkuId;
    const trimmedArray = search?.sku_siteId?.map(str => str.trimStart());
    const skuSiteIdResult = trimmedArray?.includes(site);
    const prevNtt = search?.q;

    const url = skuSiteIdResult
      ? `${prefix}/catalog/product/product.jsp?productId=${productId}&fullSkuId=${fullSkuId}&categoryId=search${
          prevNtt?.split(" ")?.[1] ? `&prevfullSkuId=${prevNtt}` : ""
        }`
      : `${prefix}/catalog/product/product.jsp?productId=${productId}&fullSkuId=${fullSkuId}&categoryId=search${
          prevNtt?.split(" ")?.[1] ? `&prevfullSkuId=${prevNtt}` : ""
        }`;

    return url;
  }, [
    prefix,
    search?.resultList?.records,
    search?.resultTabs,
    search?.sku_siteId,
    site
  ]);

  const forceSaleFlag =
    !!search?.ATGCollectionGalleryInfo?.collectionGallery?.[0]?.forceSaleFlag;
  const percentSaleSkus = Number(
    search?.saleMemberInfo?.pgSaleStats?.percentSaleSkus
  );
  const memberSavings = useMemo(
    () =>
      Math.round(
        (search?.saleMemberInfo?.pgMemberSavings?.memberSavingsMax ?? 0) / 5
      ) * 5,
    [search?.saleMemberInfo?.pgMemberSavings?.memberSavingsMax]
  );

  const dynamicMemberSavingsText = getMemberSavingsText(
    pageContent,
    percentSaleSkus,
    search?.saleMemberInfo?.pgMemberSavings?.memberSavingsMin,
    search?.saleMemberInfo?.pgMemberSavings?.memberSavingsMax
  );

  const shouldShowSaleText = useMemo(
    () => !isNewArrivalsFilterActive,
    [isNewArrivalsFilterActive]
  );
  const allItemsOnSale = useMemo(
    () => percentSaleSkus === 100 || forceSaleFlag,
    [percentSaleSkus, forceSaleFlag]
  );

  const selectSaleItemsOnSale = useMemo(
    () =>
      !isNaN(percentSaleSkus)
        ? percentSaleSkus > 0 && percentSaleSkus < 2
        : true,
    [percentSaleSkus]
  );

  const selectItemsOnSale = useMemo(
    () => percentSaleSkus >= 20 && percentSaleSkus < 100,
    [percentSaleSkus]
  );

  const standardMemberSavingsMessage = useMemo(() => {
    return !selectItemsOnSale && !selectSaleItemsOnSale && !allItemsOnSale;
  }, [allItemsOnSale, selectSaleItemsOnSale, selectItemsOnSale]);

  // const keywords = React.useMemo(
  //   () => root?.keywords?.join(", "),
  //   [root?.keywords]
  // );
  useEffect(() => {
    if (error && onError) {
      onError();
      return null;
    }
  }, [error, onError]);

  const handleTabClick = useCallback(
    tabData => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("site", tabData?.site);
      searchParams.set("activeTab", tabData?.tabNo);
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    },
    [isServer ? req : location]
  );

  const tapInfoOptions = useMemo(() => {
    return (search?.tabInfo?.tabs || []).filter(tab => tab?.numResults);
  }, [search?.tabInfo?.tabs]);

  const currectActiveTab = useMemo(() => {
    return tapInfoOptions.find(item => item.activeTab === "true");
  }, [tapInfoOptions]);

  if (
    !!error &&
    !(search?.resultList || search?.zeroResults || search?.redirectUrl)
  ) {
    useIsoRedirect("/error/search-not-available.jsp", "redirect");
    return null;
  }

  const checkVisibility = () => {
    if (
      !isServer &&
      (search?.resultList?.totalNumRecs || 0) > 0 &&
      (location?.search.includes("pgterm") ||
        location?.pathname?.includes("/search/")) &&
      (search?.saleMemberInfo?.headerConfig?.title || search?.q) &&
      rhUser?.akamaiCountryCode &&
      isLoadedGTM?.isGTMLoaded &&
      !yn(env.REMOVE_ANALYTICS_LOADER)
    ) {
      const products = document?.querySelectorAll(".productVisible");
      const itemListAll = search?.resultList?.records?.map((rec, index) => {
        return {
          item_name: rec?.product?.displayName,
          item_id: rec?.sku?.fullSkuId,
          index: index,
          item_list_name: search?.saleMemberInfo?.headerConfig?.title ?? "pdp"
        };
      });

      let arrayOfVisibleItems = [];
      products?.forEach(product => {
        if (product) {
          const rect = product.getBoundingClientRect();
          const isVisible =
            rect.top >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight);
          // remove contains if add all visible items to dataLayer
          if (isVisible && !product?.classList.contains("visibleViewItem")) {
            let skuOfProduct = product?.id.split("__")[1];
            let indexOfProduct = product?.id.split("__")[2];
            arrayOfVisibleItems.push({
              sku: skuOfProduct,
              skuIndex: skuOfProduct + "__" + indexOfProduct
            });
            product.classList.add("visibleViewItem");
          }
        }
      });

      const targetIds = new Set(
        arrayOfVisibleItems.map(item => item?.skuIndex)
      );
      const itemList = itemListAll?.filter(item => {
        const combinedId = `${item?.item_id}__${item?.index}`;
        return targetIds?.has(combinedId);
      });

      if (itemList?.length > 0 && location.search.includes("pgterm")) {
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.VIEW_ITEM_LIST.INT_TYPE,
            {
              itemList,
              item_list_id: search?.q.includes("category:")
                ? search?.q.split("category:")[1]
                : search?.q,
              item_list_name_view_item:
                search?.saleMemberInfo?.headerConfig?.title ?? "pdp"
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    if (
      !isServer &&
      (search?.resultList?.totalNumRecs || 0) > 0 &&
      (location?.search.includes("pgterm") ||
        location.pathname.includes("/search/")) &&
      (search?.saleMemberInfo?.headerConfig?.title || search?.q) &&
      rhUser?.akamaiCountryCode &&
      isLoadedGTM?.isGTMLoaded &&
      !yn(env.REMOVE_ANALYTICS_LOADER)
    ) {
      let scrollTimeout;

      const handleScroll = () => {
        setScrolling(true);
        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(() => {
          setScrolling(false);
          checkVisibility();
        }, 100);
      };
      window.addEventListener("load", checkVisibility);
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", checkVisibility);

      checkVisibility();

      // Cleanup function to remove the event listeners
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", checkVisibility);
        window.removeEventListener("load", checkVisibility);
        clearTimeout(scrollTimeout);
      };
    }
  }, [
    search,
    loadAllParam,
    rhUser?.akamaiCountryCode,
    isLoadedGTM?.isGTMLoaded
  ]);

  if (search?.skuSearch && !!search?.resultList?.totalNumRecs) {
    const skuSiteIdResult = search?.sku_siteId?.includes(site);
    return isSSRToggled || skuSiteIdResult
      ? useIsoRedirect(singleRecordRedirectURL(), "windowAssign")
      : location?.replace(singleRecordRedirectURL());
  }

  const fusionPromoDisplay = (search && (search?.saleMemberInfo as any))
    ?.headerConfig?.promoDisplay?.promoDisplay?.MediaText;

  const facetLength = useMemo(() => {
    return (
      booleanFacets.filter(e => e?.refinements?.length !== 0).length +
      Object.keys(refMenu).filter(
        e => refMenu?.[e]?.[0]?.refinements?.length !== 0
      ).length
    );
  }, [booleanFacets, refMenu]);

  // let fusionPromoDisplayObjectdata = fusionPromoDisplay?.map((data, index) => {
  //   return data?.MEDIA;
  // });

  return (
    <>
      <HTMLHeadTags
        title={seoPageTitle || productTitle}
        // description={
        //   params.fromCG === "true"
        //     ? root?.seoDescription || root?.bannerCopy
        //     : root?.seoDescription
        // }
        // keywords={keywords}
        image={productList?.length > 0 ? productList[0]?.product?.imageUrl : ""}
        description={seoPageDescription}
        schema={itemListSchema}
      />
      <div style={{ display: "none" }}>SSR V1</div>
      {/*AsyncContent loads synchronously when not being loaded as an AEM component */}
      {isAemEnabled ? (
        <AsyncContent
          path={`${prefix}/category/${params.categoryId || categoryId}/header`}
          isInEditor={false}
          model={aemAsyncHeaderModel}
        />
      ) : null}
      <LoadingContext.Provider value={{ isLoading }}>
        {!isLoading &&
        (!yn(env.FEATURE_SEARCH_ALL_BRANDS) || isPGPage
          ? search?.zeroResults ||
            (search?.skuSearch &&
              !search?.resultList?.totalNumRecs &&
              !search?.redirectUrl)
          : !tapInfoOptions.length) ? (
          <>
            {(search?.zeroResults?.otherBrandSearchResults || []).length > 0 ? (
              <div style={{ background: "F8F8F8", paddingTop: 26 }}>
                {productTitle ? (
                  <Container>
                    <h1
                      className="text-4xl font-primary-ultra-thin uppercase"
                      dangerouslySetInnerHTML={{
                        __html: productTitle
                      }}
                    />
                  </Container>
                ) : null}

                <Container className={classes.refinementContainer}>
                  <Typography
                    className={classNames([
                      typographyClasses.rhBaseCaption,
                      classes.results
                    ])}
                  >
                    {pageContent?.SEARCH_RESULTS}{" "}
                    {`( ${
                      (search?.zeroResults?.otherBrandSearchResults || [])[0]
                        ?.brandResultCount
                    } IN ${
                      (search?.zeroResults?.otherBrandSearchResults || [])[0]
                        ?.brandName
                    } )`}
                  </Typography>
                </Container>

                <Container style={{ paddingTop: 24 }}>
                  <Button
                    className={buttonStyles.primaryBlackBtn}
                    data-testid="add-to-cart-dialog-opener"
                    onClick={navigateToOtherResult}
                  >
                    {`VIEW ${
                      (search?.zeroResults?.otherBrandSearchResults || [])[0]
                        ?.brandName
                    } ${pageContent?.SEARCH_RESULTS}`}
                  </Button>
                </Container>
              </div>
            ) : (
              <div style={{ background: "F8F8F8", paddingTop: 26 }}>
                <Container>
                  <Typography
                    variant="h1"
                    className={classNames([
                      classes.actionTitle,
                      typographyClasses.rhBaseH1
                    ])}
                    dangerouslySetInnerHTML={{
                      __html: productTitle
                    }}
                  />
                </Container>
                <Container className={classes.refinementContainer}>
                  <Typography
                    className={classNames([
                      typographyClasses.rhBaseCaption,
                      classes.results
                    ])}
                  >
                    {pageContent?.SEARCH_RESULTS} (0)
                  </Typography>
                </Container>
                <Container style={{ paddingTop: 24 }}>
                  <Typography className={typographyClasses.rhBaseCaption}>
                    {
                      pageContent?.WERE_SORRY_WE_CANNOT_FIND_WHAT_YOU_ARE_LOOKING_FOR
                    }
                  </Typography>
                  <Typography className={typographyClasses.rhBaseCaption}>
                    {pageContent?.PLEASE_TRY_A_NEW_SEARCH_OR_CONTACT}

                    <RHLink
                      to={contactUsPath}
                      data-testid={"customer-service-link"}
                      style={{ display: "initial" }}
                    >
                      <Typography
                        className={classNames([
                          typographyClasses.rhrSearchAutoSuggestResult,
                          typographyClasses.rhBaseCaption,
                          classes.customerService
                        ])}
                      >
                        {pageContent?.CUSTOMER_EXPERIENCE}
                      </Typography>
                    </RHLink>
                  </Typography>
                </Container>
              </div>
            )}
          </>
        ) : (
          <>
            {/* the bottom section should not render if it is a AEM page */}
            {!isAemEnabled && (
              <>
                <Container
                  key={(reloadKey ? reloadKey : 123) + "productTitle"}
                  className={classes.galleryContainer}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div
                    className={classNames([classes.actionContainer])}
                    style={
                      isPGPage || !yn(env.FEATURE_SEARCH_ALL_BRANDS)
                        ? { paddingBottom: 8, alignItems: "center" }
                        : {}
                    }
                  >
                    <div className={classes.actionTitleWrapper}>
                      {productTitle ? (
                        <Typography
                          variant="h1"
                          className={classNames([
                            classes.actionTitle,
                            typographyClasses.rhBaseH2
                          ])}
                          style={{}}
                          dangerouslySetInnerHTML={{
                            __html: productTitle
                          }}
                        />
                      ) : null}
                      {productTitle &&
                        shouldShowSaleText &&
                        standardMemberSavingsMessage && (
                          <div className={classes.membershipContainer}>
                            <Typography className={classes.membershipMessage}>
                              <RHLink
                                to="/membership"
                                underline="always"
                                data-testid={"rh-membership-link-pg"}
                                style={{
                                  display: "inline",
                                  color: "#000000",
                                  fontFamily: "RHSans-Thin !important",
                                  fontSize: "13px"
                                }}
                              >
                                <span className="font-primary-rhthin underline">
                                  {pageContent?.RH_MEMBERS_PROGRAM}{" "}
                                </span>
                              </RHLink>
                              {pageContent?.SAVE_25_ON_EVERYTHING}
                            </Typography>
                          </div>
                        )}
                    </div>
                    {shouldShowSaleText &&
                    activeSaleFilter &&
                    (selectItemsOnSale || allItemsOnSale) &&
                    productTitle ? (
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "8px"
                        }}
                      >
                        <Typography
                          variant="h1"
                          className={classes.actionSaleBanner}
                          dangerouslySetInnerHTML={{
                            __html: "SALE"
                          }}
                        />
                      </div>
                    ) : null}
                    {productTitle &&
                      shouldShowSaleText &&
                      activeSaleFilter &&
                      !standardMemberSavingsMessage && (
                        <div
                          style={{
                            width: "100%",
                            paddingTop: selectSaleItemsOnSale ? "8px" : "0px"
                          }}
                        >
                          <Typography
                            variant="h1"
                            className={classes.actionSaleMessage}
                            dangerouslySetInnerHTML={{
                              __html: dynamicMemberSavingsText
                            }}
                          />
                        </div>
                      )}
                    {/* {shouldShowSaleText && activeSaleFilter ? (
                      <>
                        <div
                          style={{
                            width: "100%"
                          }}
                        >
                          <Typography
                            variant="h4"
                            className={
                              allItemsOnSale
                                ? classes.actionSaleTitle
                                : classNames([
                                    classes.actionSaleTitle,
                                    typographyClasses.saleTitle
                                  ])
                            }
                          >
                            {allItemsOnSale ? CATALOG_SALE_NAME : SAVE_UP_TO}
                          </Typography>
                          <Typography
                            variant="h4"
                            className={
                              allItemsOnSale
                                ? classes.actionSaleTitle
                                : classNames([
                                    classes.actionSaleTitle,
                                    typographyClasses.saleSubTitle
                                  ])
                            }
                            dangerouslySetInnerHTML={{
                              __html: allItemsOnSale
                                ? memberSavingsText
                                : pageContent?.WITH_RH_MEMEBERSHIP
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      productTitle && (
                        <div className={classes.membershipContainer}>
                          <Typography className={classes.membershipMessage}>
                            <RHLink
                              to="/membership"
                              underline="always"
                              data-testid={"rh-membership-link-pg"}
                              style={{
                                display: "inline",
                                color: "#000000",
                                fontFamily: "RHSans-Thin !important",
                                fontSize: "inherit"
                              }}
                            >
                              <span className="font-primary-rhthin">
                                {pageContent?.RH_MEMBERS_PROGRAM}{" "}
                              </span>
                            </RHLink>
                            {pageContent?.SAVE_25_ON_EVERYTHING}
                          </Typography>
                        </div>
                      )
                    )} */}
                  </div>
                </Container>

                {fusionPromoDisplay && !isSale && (
                  <Container className={classes.rhrPromoDisplayBanner}>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: fusionPromoDisplay?.map(data => data?.MEDIA)
                      }}
                    />
                  </Container>
                )}

                {yn(env.FEATURE_SEARCH_ALL_BRANDS) && !isPGPage && (
                  <Container className={classes.tabinfoWrapper}>
                    {tapInfoOptions?.map(data => (
                      <Button
                        className={classNames([
                          classes.defaultTabinfo,
                          { [classes.ActiveTabinfo]: yn(data?.activeTab) }
                        ])}
                        onClick={() => handleTabClick(data)}
                        disabled={yn(data?.activeTab)}
                      >
                        {`${data?.displayName} (${data?.numResults})`}
                      </Button>
                    ))}
                  </Container>
                )}
              </>
            )}

            <Container
              className={classNames(classes.refinementContainer, {
                [classes.sticky]: yn(env.FEATURE_STICKY_REFINEMENT),
                [classes.refinementContainerMobile]: gridColumns === 6
              })}
              key={(reloadKey ? reloadKey : 123) + "refinementContainer"}
            >
              <div
                className={classes.refinementBar}
                style={{ paddingTop: "15px" }}
              >
                <RefineMenuDropdown
                  refinementMenus={
                    isSSRToggledWithCSR
                      ? search?.refinementMenus || refMenu
                      : refMenu
                  }
                  sortMenu={
                    isSSRToggledWithCSR
                      ? search?.resultList?.sortOptions || sortMenu
                      : sortMenu
                  }
                  totalCount={
                    isSSRToggledWithCSR
                      ? search?.resultList?.totalNumRecs || totalNumRecs
                      : totalNumRecs
                  }
                  resetRefinement={resetRefinement}
                  resultTabs={search?.resultTabs}
                  showResetRefinement={!!params.n}
                  selectedRefinementContainer={
                    selectedRefinementContainer.current
                  }
                  categoryId={params.categoryId || categoryId}
                  isSale={isSale}
                  booleanFacet={
                    isSSRToggledWithCSR
                      ? search?.booleanFacets || booleanFacets
                      : booleanFacets
                  }
                  loading={isLoading}
                  version={params?.version}
                  activeTab={
                    yn(env.FEATURE_SEARCH_ALL_BRANDS) ? currectActiveTab : null
                  }
                  dynamicFacet={
                    isSSRToggledWithCSR
                      ? search?.dynamicFacets || dynamicFacet
                      : dynamicFacet
                  }
                />

                <GridController
                  gridColumns={gridColumns}
                  setGridColumns={saveGridCol}
                  sortMenu={search?.resultList?.sortOptions || sortMenu}
                  categoryId={params.categoryId || categoryId}
                  hide1Column={isServer ? !mobile : mdUp ? true : false}
                  hide3Column={isServer && mobile}
                  ssr={isSSRToggledWithCSR}
                  facetlength={facetLength}
                  activeTab={
                    yn(env.FEATURE_SEARCH_ALL_BRANDS) ? currectActiveTab : null
                  }
                />
              </div>

              <div
                ref={selectedRefinementContainer}
                id="refinement-container"
              ></div>
            </Container>
            {isAemEnabled && !aemAsyncHeaderModel?.templateName && (
              <Container>
                <div style={{ textAlign: "center", paddingBottom: "40px" }}>
                  <Typography
                    variant="h1"
                    className={classNames([
                      classes.actionTitle,
                      typographyClasses.rhBaseH2
                    ])}
                    style={{}}
                    dangerouslySetInnerHTML={{
                      __html: productTitle
                    }}
                  />
                </div>
              </Container>
            )}
            <Container>
              {!isServer &&
              (!isPaginationFeatureEnabled || isLazyLoadEnabled
                ? !isNextPageLoading && isLoading
                : isLoading) ? (
                <RHRProductListSkeleton
                  hasBanner={true}
                  columns={gridColumns}
                  numItems={12}
                  disableProductInfoSkeleton
                  animation={SKELETON_ANIMATION}
                />
              ) : (
                <>
                  {/* <ImageSizeProvider> */}
                  <ProductGrid
                    isSale={isSale}
                    isSaleFilterEnabled={isSaleFilterEnabled}
                    totalNumRecs={
                      isSSRToggledWithCSR
                        ? search?.resultList?.totalNumRecs || totalNumRecs
                        : totalNumRecs
                    }
                    firstRecNum={search?.resultList?.firstRecNum}
                    lastRecNum={search?.resultList?.lastRecNum}
                    loadMoreData={loadMoreData}
                    recsPerPage={params.maxnrpp}
                    productList={
                      isServer ? search?.resultList?.records : productList
                    }
                    isStockedFilterActive={isStockedFilterActive}
                    isRefinementFilterActive={isRefinementFilterActive}
                    gridColumns={gridColumns}
                    view={params.view || templateString || "vertical"}
                    brand={brand}
                    isSort={isSort}
                    selectedProductId={
                      history.action === "POP" || history.action === "REPLACE"
                        ? cookie[SIP_ID]
                        : null
                    }
                    productClickHandler={saveSelectedProduct}
                    disableFadeEffect={isConcierge}
                    productTitle={productTitle}
                    // pgCropRulesFromCg={pgCropRules}
                    infiniteScrollEnabled={
                      !isPaginationFeatureEnabled || isLazyLoadEnabled
                    }
                    isNextPageLoading={isNextPageLoading}
                    filterQueries={search?.filterQueries}
                    inStockFlow={search?.inStockFlow}
                  />
                  {<RHScrollUpButton />}
                  {/* </ImageSizeProvider> */}
                </>
              )}
            </Container>
          </>
        )}
      </LoadingContext.Provider>
      {/*AsyncContent loads synchronously when not being loaded as an AEM component */}
      {isAemEnabled ? (
        <AsyncContent
          path={`${prefix}/category/${params.categoryId || categoryId}/footer`}
          isInEditor={false}
          model={aemAsyncFooterModel}
        />
      ) : null}
    </>
  );
}) as any;

export default memoize(RHRProductGallery);
