type Props = {
  index: number;
  isOneLine: boolean;
  min: number;
  max: number;
  prevSteps: unknown[][];
  translate: (key: string, options?: any) => string;
};

export const getFieldTitle = ({
  index,
  isOneLine,
  min,
  max,
  prevSteps,
  translate
}: Props) => {
  const step = getStep(index, prevSteps);

  const title = isOneLine
    ? translate("textField.default")
    : translate("textField.numeric", index + 1);

  return `${step}. ${title} (${min}-${max} ${translate(
    "textField.characters"
  )})`;
};

function getStep(start: number, prevSteps: Props["prevSteps"]) {
  return prevSteps.reduce((acc, prevStep) => {
    return prevStep.length ? ++acc : acc;
  }, 2 + start);
}
